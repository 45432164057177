var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      staticClass: "pb-1",
      attrs: {
        outlined: _vm.flatCard,
        rounded: _vm.inEditor ? "t-sm b-lg" : "lg",
        "data-test": "sig-cloud-storage-file-list-dialog",
      },
    },
    [
      _vm.showTitle
        ? _c(
            "v-card-title",
            { staticClass: "py-3 px-4" },
            [
              _vm._v("Signaloid Cloud Storage "),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "text--primary",
                  attrs: {
                    color: "white",
                    small: "",
                    outlined: "",
                    height: "36",
                    elevation: "0",
                  },
                  on: { click: _vm.closeDialog },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showTitle ? _c("v-divider") : _vm._e(),
      _c(
        "v-card-text",
        {
          staticClass: "py-2 px-1",
          staticStyle: { "max-width": "1000px" },
          style: `min-width: ${_vm.minWidth}; min-height: ${_vm.minHeight}`,
        },
        [
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _vm.loadingFiles
                ? _c("LoadingCard", { attrs: { loadingText: "Loading Files" } })
                : _c(
                    "div",
                    [
                      _vm.dataFileTree && _vm.dataFileTree.length > 0
                        ? _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: _vm.fileMoveMode ? "6" : "12",
                                  },
                                },
                                [
                                  _vm.fileMoveMode
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "ml-3 text-subtitle-2 font-italic",
                                        },
                                        [_vm._v(" Source ")]
                                      )
                                    : _vm._e(),
                                  _c("v-treeview", {
                                    staticClass: "text-body-2 mr-2",
                                    attrs: {
                                      items: _vm.dataFileTree,
                                      open: _vm.sourceOpenTrees,
                                      active: _vm.sourcePath,
                                      "item-key": "path",
                                      "expand-icon": "mdi-chevron-down",
                                      "return-object": "",
                                      transition: "",
                                      dense: "",
                                      activatable: _vm.fileMoveMode,
                                      selectable: _vm.selectForDownload,
                                      "open-on-click":
                                        _vm.$vuetify.breakpoint.mobile &&
                                        !_vm.fileMoveMode,
                                    },
                                    on: {
                                      "update:items": function ($event) {
                                        _vm.dataFileTree = $event
                                      },
                                      "update:open": function ($event) {
                                        _vm.sourceOpenTrees = $event
                                      },
                                      "update:active": function ($event) {
                                        _vm.sourcePath = $event
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend",
                                          fn: function ({ item, open }) {
                                            return [
                                              item.type == "dir"
                                                ? _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            open
                                                              ? "mdi-folder-open"
                                                              : "mdi-folder"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.util.fileIcons(
                                                              item.type
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "append",
                                          fn: function ({ item }) {
                                            return [
                                              !_vm.fileMoveMode &&
                                              _vm.util.isTextFile(item.type)
                                                ? _c("TooltipButton", {
                                                    attrs: {
                                                      icon: true,
                                                      tooltipText: "View file",
                                                      loading:
                                                        item.path ==
                                                        _vm.fileBeingOpened,
                                                      "data-test": `view-file-button-${item.name}`,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openFileInFileViewer(
                                                          item
                                                        )
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "btnContent",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-eye"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  })
                                                : _vm._e(),
                                              !_vm.fileMoveMode &&
                                              item.type != "dir"
                                                ? _c("TooltipButton", {
                                                    attrs: {
                                                      icon: true,
                                                      loading:
                                                        item.path ==
                                                        _vm.fileBeingDownloaded,
                                                      tooltipText: "Download",
                                                      "data-test": `download-file-button-${item.name}`,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.downloadFile(
                                                          item
                                                        )
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "btnContent",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-download-outline"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  })
                                                : _vm._e(),
                                              !_vm.fileMoveMode &&
                                              item.type == "dir"
                                                ? _c("TooltipButton", {
                                                    attrs: {
                                                      icon: true,
                                                      tooltipText: "Upload",
                                                      "data-test": `upload-file-button-${item.name}`,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openUploadDialog(
                                                          item
                                                        )
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "btnContent",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-cloud-upload-outline"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  })
                                                : _vm._e(),
                                              !_vm.fileMoveMode &&
                                              item.type == "dir"
                                                ? _c("TooltipButton", {
                                                    attrs: {
                                                      icon: true,
                                                      tooltipText:
                                                        "Create Directory",
                                                      "data-test": `create-directory-button-${item.name}`,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openCreateDirectoryDialog(
                                                          item
                                                        )
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "btnContent",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-folder-plus-outline"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  })
                                                : _vm._e(),
                                              !_vm.fileMoveMode
                                                ? _c("TooltipButton", {
                                                    attrs: {
                                                      icon: true,
                                                      tooltipText: "Delete",
                                                      "data-test":
                                                        "delete-file-button",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openDeletePathDialog(
                                                          item
                                                        )
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "btnContent",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-trash-can"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  })
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      943232041
                                    ),
                                    model: {
                                      value: _vm.fileListBuffer,
                                      callback: function ($$v) {
                                        _vm.fileListBuffer = $$v
                                      },
                                      expression: "fileListBuffer",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.fileMoveMode
                                ? [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "auto" } },
                                      [
                                        _c("v-icon", { staticClass: "ml-n3" }, [
                                          _vm._v("mdi-chevron-double-right"),
                                        ]),
                                        _c("div", {
                                          staticClass:
                                            "mt-3 mr-2 vertical-divider",
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "5" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "ml-3 text-subtitle-2 font-italic",
                                          },
                                          [_vm._v("Destination")]
                                        ),
                                        _c("v-treeview", {
                                          staticClass: "text-body-2 mr-2",
                                          attrs: {
                                            items: _vm.dataFileTree,
                                            open: _vm.destinationOpenTrees,
                                            active: _vm.destinationPath,
                                            "item-key": "path",
                                            "expand-icon": "mdi-chevron-down",
                                            "return-object": "",
                                            transition: "",
                                            dense: "",
                                            activatable: "",
                                          },
                                          on: {
                                            "update:items": function ($event) {
                                              _vm.dataFileTree = $event
                                            },
                                            "update:open": function ($event) {
                                              _vm.destinationOpenTrees = $event
                                            },
                                            "update:active": function ($event) {
                                              _vm.destinationPath = $event
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "prepend",
                                                fn: function ({ item, open }) {
                                                  return [
                                                    item.type == "dir"
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  open
                                                                    ? "mdi-folder-open"
                                                                    : "mdi-folder"
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.util.fileIcons(
                                                                    item.type
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            4014930252
                                          ),
                                          model: {
                                            value: _vm.fileListBuffer,
                                            callback: function ($$v) {
                                              _vm.fileListBuffer = $$v
                                            },
                                            expression: "fileListBuffer",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _c(
                            "v-alert",
                            {
                              staticClass: "d-flex justify-center my-5 mx-auto",
                              attrs: {
                                outlined: "",
                                width: "80%",
                                type: "warning",
                                dense: "",
                                border: "left",
                              },
                            },
                            [_vm._v(" No files found... ")]
                          ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-card-actions",
        { staticClass: "pt-1 pb-0" },
        [
          _c("v-spacer"),
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _vm.fileMoveMode
              ? _c(
                  "div",
                  { key: "editButtons" },
                  [
                    _c("TooltipButton", {
                      attrs: {
                        color: "primary",
                        width: "100px",
                        btnClass: "ml-2",
                        disabled: !_vm.validMove,
                        tooltipText: "Copy Files",
                      },
                      on: { click: _vm.copyFiles },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "btnContent",
                            fn: function () {
                              return [
                                _c(
                                  "v-icon",
                                  { staticClass: "pr-1", attrs: { small: "" } },
                                  [_vm._v("mdi-content-copy")]
                                ),
                                _vm._v(" Copy "),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3809936779
                      ),
                    }),
                    _c("TooltipButton", {
                      attrs: {
                        color: "primary",
                        width: "100px",
                        btnClass: "ml-2",
                        disabled: true,
                        btnText: "Move",
                        btnIcon: "mdi-folder-move-outline",
                        tooltipText: "Move Files",
                      },
                      on: { click: _vm.moveFiles },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  { key: "defaultButtons", staticStyle: { margin: "2px 0" } },
                  [
                    _c("TooltipButton", {
                      attrs: {
                        color: "primary",
                        width: "100px",
                        btnClass: "ml-2",
                        btnIcon: "mdi-folder-plus-outline",
                        tooltipText: "Create Directory",
                        "data-test": "create-directory-button",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openCreateDirectoryDialog()
                        },
                      },
                    }),
                    _c("TooltipButton", {
                      attrs: {
                        color: "primary",
                        width: "100px",
                        btnClass: "ml-2",
                        btnIcon: "mdi-cloud-upload-outline",
                        tooltipText: "Upload Files",
                        "data-test": "upload-file-button",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openUploadDialog()
                        },
                      },
                    }),
                  ],
                  1
                ),
          ]),
        ],
        1
      ),
      _vm.showDeleteConfirmDialog && _vm.deletePathData
        ? _c("SigCloudStorageFileDeleteDialog", {
            attrs: {
              showDialog: _vm.showDeleteConfirmDialog,
              deletePathData: _vm.deletePathData,
            },
            on: {
              "close-dialog": _vm.closeDeletePathDialog,
              "delete-successful": _vm.deleteSuccessfulCallback,
            },
          })
        : _vm._e(),
      _vm.showUploadDialog
        ? _c("SigCloudStorageFileUploadDialog", {
            attrs: {
              showDialog: _vm.showUploadDialog,
              initialUploadDestination: _vm.uploadDestinationPath,
              fileTree: _vm.dataFileTree,
            },
            on: {
              "close-dialog": _vm.closeUploadDialog,
              "upload-successful": _vm.uploadSuccessfulCallback,
            },
          })
        : _vm._e(),
      _vm.showEditPathDialog && _vm.editPathData
        ? _c("EditValueDialog", {
            attrs: {
              title:
                _vm.editPathData.type == "dir"
                  ? "Rename Directory"
                  : "Rename File",
              valueHint:
                _vm.editPathData.type == "dir" ? "Directory Name" : "File Name",
              showDialog: _vm.showEditPathDialog,
              value: _vm.editPathData.name,
            },
            on: {
              "update:showDialog": _vm.closeEditPathDialog,
              "update:value": _vm.updateFileName,
            },
          })
        : _vm._e(),
      _vm.showCreateDirectoryDialog
        ? _c("SigCloudStorageCreateDirectoryDialog", {
            attrs: {
              showDialog: _vm.showCreateDirectoryDialog,
              parentDir: _vm.createDirectoryData,
            },
            on: {
              "update:showDialog": _vm.closeCreateDirectoryDialog,
              "upload-successful": _vm.getUserFiles,
            },
          })
        : _vm._e(),
      _c("FileViewer", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showFileViewer,
            expression: "showFileViewer",
          },
        ],
        attrs: {
          showDialog: _vm.showFileViewer,
          title: _vm.fileBeingOpened,
          fileText: _vm.fileViewerFileText,
        },
        on: {
          "update:showDialog": _vm.closeFileViewer,
          "download-file-request": _vm.downloadFileRequestHandler,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }