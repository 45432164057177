
import { defineComponent } from "vue";

// Components
import LimitableActionButton from "@/components/Common/LimitableActionButton.vue";

// Utilities
import * as dsUtil from "@/components/DataSources/utilities";
import { mockTimeout } from "@/js/githubClient";
import { putFileUploadURL } from "@/js/signaloidClient";
import { monitoringCaptureError } from "@/plugins/monitoring";

// Libraries
import axios from "axios";

// Stores
import { useUserStore } from "@/stores/user";

//Types
import { UserLimitsE } from "@/js/tierconfig";

type File = {
	name: string;
	size: number;
};

type FileUploadTask = {
	task: string;
	status: FileUploadTaskStatus;
	text: string;
	uploadProgress: null | number;
	inputText?: string;
	userInput?: boolean;
};

type AlertType = "info" | "success" | "warning" | "error";
type UploadTaskStatus = "idle" | "completed" | "aborted" | "in progress" | "error_retry";
type FileUploadTaskStatus = "in progress" | "success" | "fail" | "ignored";

export default defineComponent({
	name: "SigCloudStorageFileUploadDialog",
	components: { LimitableActionButton },
	props: {
		showDialog: { type: Boolean, default: false },
		initialUploadDestination: { type: String, default: undefined },
		fileTree: { type: Array, default: undefined },
	},
	data: (): {
		uploadInProgress: boolean;
		filesToUpload: File[];
		uploadDestination: string;
		validateAndUpload: boolean;
		uploadTasks: FileUploadTask[];
		alertType: AlertType;
		showError: boolean;
		errorMessage: string;
		uploadTaskStatus: UploadTaskStatus;
		formValid: boolean;
	} => ({
		uploadInProgress: false,
		filesToUpload: [],
		uploadDestination: "",
		validateAndUpload: false,
		uploadTasks: [],
		alertType: "info",
		showError: false,
		errorMessage: "",
		uploadTaskStatus: "idle",
		formValid: false,
	}),
	setup() {
		const userStore = useUserStore();
		return { userStore, UserLimitsE };
	},
	methods: {
		taskStatusColor(taskStatus: FileUploadTaskStatus) {
			switch (taskStatus) {
				case "in progress":
					return "info";
				case "success":
					return "success";
				case "fail":
					return "error";
				case "ignored":
					return "grey";
				default:
					return "error";
			}
		},
		closeDialog() {
			this.resetErrorAlert();
			this.$emit("update:showDialog", false);
			this.$emit("close-dialog", false);
		},
		async initiateFileUploadFlow() {
			this.validateAndUpload = true;
			this.resetErrorAlert();
			this.validateInputsAndUpload();
		},

		async validateInputsAndUpload() {
			let uploadFileIndex = 0;
			try {
				// check all files have path length < 1024B
				if (!this.allPathsLessThanMaxPathSize) {
					this.validateAndUpload = false;
					throw new Error(`None of the file paths can be longer than ${dsUtil.awsS3MaxPathSizeBytes}B long.`);
				} else if (!this.allPathsHaveNoSpaces) {
					this.validateAndUpload = false;
					throw new Error(`None of the file paths can contain spaces.`);
				} else if (!this.allPathsHaveNoRestrictedCharacters) {
					this.validateAndUpload = false;
					throw new Error(
						`None of the file paths can contain ${dsUtil.awsS3RestrictedCharacters.reduce((t, c, idx) => {
							return idx == dsUtil.awsS3RestrictedCharacters.length - 1
								? t + " , or " + c
								: t + " , " + c;
						})}.`
					);
				} else {
					for (uploadFileIndex = 0; uploadFileIndex < this.filesToUpload.length; uploadFileIndex++) {
						const file = this.filesToUpload[uploadFileIndex];
						this.uploadTasks.push({
							task: `sig_uploadFile_${uploadFileIndex}`,
							status: "in progress",
							text: `Uploading ${file.name}. File ${uploadFileIndex + 1} of ${
								this.filesToUpload.length
							}...`,
							uploadProgress: null,
						});
						const uploadPath = this.uploadDestination
							? `${this.uploadDestination}/${file.name}`
							: file.name;
						while (this.getTask(`sig_uploadFile_${uploadFileIndex}`).status != "success") {
							try {
								const uploadURL = await putFileUploadURL(uploadPath, file.size);
								if (uploadURL) {
									const response = await axios.put(uploadURL, file, {
										onUploadProgress: (progressEvent: any) => {
											this.updateTask(
												`sig_uploadFile_${uploadFileIndex}`,
												"uploadProgress",
												Math.round((progressEvent.loaded / progressEvent.total) * 100)
											);
										},
									});
									this.setTaskStatus(`sig_uploadFile_${uploadFileIndex}`, "success");
								}
							} catch (error) {
								monitoringCaptureError(error, "Upload files to user cloud storage");
								this.setTaskStatus(`sig_uploadFile_${uploadFileIndex}`, "fail");
								if (axios.isAxiosError(error)) {
									if (error.response) {
										//non 2xx response
										const isRestrictedError = error.response.status == 403;

										this.errorMessage = isRestrictedError
											? "Could not upload file due to tier restrictions."
											: "Upload failed. Please try again.";
									} else if (error.request) {
										//no response

										this.errorMessage = "Please check your internet connection";
									} else {
										//making request failed

										this.errorMessage = error.message;
									}
									this.showError = true;
									this.alertType = "warning";
									this.uploadInProgress = false;

									this.updateTask(
										`sig_uploadFile_${uploadFileIndex}`,
										"inputText",
										error + `. Retry upload?`
									);
									const retry = await this.waitForUserInput(
										`sig_uploadFile_${uploadFileIndex}`,
										"userInput"
									);

									this.updateTask(`sig_uploadFile_${uploadFileIndex}`, "inputText", ``);
									this.updateTask(`sig_uploadFile_${uploadFileIndex}`, "userInput", undefined);
									if (!retry) {
										this.setTaskStatus(`sig_uploadFile_${uploadFileIndex}`, "fail");
										this.uploadTaskStatus = "aborted";
										throw new Error("Upload aborted");
									} else {
										this.showError = false;
										this.alertType = "warning";
										this.uploadInProgress = true;
										this.setTaskStatus(`sig_uploadFile_${uploadFileIndex}`, "in progress");
									}
								} else {
									console.log("Unknown error occurred:", error);
								}
							}
						}
					}
				}
			} catch (error) {
				monitoringCaptureError(error, "Upload files to user cloud storage");

				if (error instanceof Error) {
					this.errorMessage = error.message;
				} else {
					this.errorMessage = "Unknown error occurred. Please try again.";
				}
				this.showError = true;
				this.alertType = "error";
				this.uploadInProgress = false;
			} finally {
				if (!this.showError) {
					this.resetErrorAlert();
					this.uploadInProgress = false;
					this.uploadTaskStatus = "completed";
					mockTimeout(1500).then(() => {
						this.$emit("upload-successful");
						this.closeDialog();
					});
				}
			}
		},
		setTaskStatus(taskName: string, status: FileUploadTaskStatus) {
			this.uploadTasks[this.uploadTasks.findIndex((x) => x.task === taskName)].status = status;
		},
		updateTask<T extends keyof FileUploadTask>(taskName: string, key: T, value: FileUploadTask[T]): void {
			const taskID = this.uploadTasks.findIndex((x) => x.task === taskName);
			if (taskID != -1) {
				this.uploadTasks[taskID][key] = value;
			} else {
				console.warn(`Task ${taskName} not found`);
			}
		},
		getTask(taskName: string) {
			return this.uploadTasks[this.uploadTasks.findIndex((x) => x.task === taskName)];
		},
		async waitForUserInput(taskName: string, key: keyof FileUploadTask) {
			const sleep = async (ms: number) => new Promise((res) => setTimeout(res, ms));
			while (this.getTask(taskName)[key] == undefined) await sleep(50);
			return this.getTask(taskName)[key];
		},
		uploadButtonClickHandler() {
			switch (this.uploadTaskStatus) {
				case "completed":
					this.$emit("upload-successful");
					this.closeDialog();
					break;
				case "aborted":
				case "error_retry":
					this.uploadTasks = [];
					this.showError = false;
					this.errorMessage = "";
					this.alertType = "error";
					this.initiateFileUploadFlow();
					break;
				default:
					this.initiateFileUploadFlow();
					break;
			}
		},
		resetErrorAlert() {
			this.uploadInProgress = true;
			this.showError = false;
			this.errorMessage = "";
			this.alertType = "info";
		},
	},
	computed: {
		dialogTitle(): string {
			return this.validateAndUpload ? "Uploading..." : "Choose files to upload";
		},
		rules(): {
			required: CallableFunction;
			counter: CallableFunction;
			maxSize: CallableFunction;
			noSpace: CallableFunction;
			noRestrictedCharacters: CallableFunction;
			atleastOneFileToUpload: CallableFunction;
		} {
			return {
				required: (value: string) => !!value || "Required.",
				counter: (value: string) =>
					value.length <= 50 || "Directory path length should be less than 50 characters",
				maxSize: (value: string) =>
					value.length < dsUtil.awsS3MaxPathSizeBytes ||
					"Directory path length should be less than 1024 Bytes",
				noSpace: (value: string) => value.indexOf(" ") == -1 || "Directory path cannot have spaces",
				noRestrictedCharacters: (value: string) => {
					return (
						dsUtil.awsS3RestrictedCharacters.every((character) => {
							return value.indexOf(character) == -1;
						}) ||
						`Directory path cannot contain ${dsUtil.awsS3RestrictedCharacters.reduce((t, c, idx) => {
							return idx == dsUtil.awsS3RestrictedCharacters.length - 1
								? t + " , or " + c
								: t + " , " + c;
						})}`
					);
				},
				atleastOneFileToUpload: () => this.filesToUpload.length > 0 || "",
			};
		},
		// formValid() {
		// 	return this.filesToUpload.length > 0;
		// },
		allPathsLessThanMaxPathSize(): boolean {
			return this.filesToUpload.every((file) => {
				const uploadPath = this.uploadDestination ? `${this.uploadDestination}/${file.name}` : file.name;
				return this.rules.maxSize(uploadPath) == true ?? false;
			});
		},
		allPathsHaveNoSpaces(): boolean {
			return this.filesToUpload.every((file) => {
				const uploadPath = this.uploadDestination ? `${this.uploadDestination}/${file.name}` : file.name;
				return this.rules.noSpace(uploadPath) == true ?? false;
			});
		},
		allPathsHaveNoRestrictedCharacters(): boolean {
			return this.filesToUpload.every((file) => {
				const uploadPath = this.uploadDestination ? `${this.uploadDestination}/${file.name}` : file.name;
				return this.rules.noRestrictedCharacters(uploadPath) == true ?? false;
			});
		},
		// allPathNamesValidForUpload() {
		// 	return
		// 	this.allPathsLessThanMaxPathSize();
		// 	this.allPathsHaveNoSpaces();
		// 	this.allPathsHaveNoRestrictedCharacters();
		// },
		uploadButtonStyle(): { icon: string; colour: string; text: string } {
			switch (this.uploadTaskStatus) {
				case "completed":
					return { icon: "mdi-cloud-check-outline", colour: "accent", text: "" };
				case "aborted":
				case "error_retry":
					return { icon: "mdi-cloud-sync-outline ", colour: "warning", text: "Retry" };
				default:
					return { icon: "mdi-cloud-upload-outline", colour: "primary", text: "Upload" };
			}
		},
	},
	mounted() {
		this.uploadDestination = this.initialUploadDestination ? this.initialUploadDestination : "";
	},
});
