
import { defineComponent, PropType } from "vue";

// Types
import Panel from "@/components/Panel.vue";
import { TaskOutput } from "@/types/api/tasks";

// Utilities
import * as util from "@/js/utilities";
import * as signaloidClient from "@/js/signaloidClient";
import { monitoringCaptureError } from "@/plugins/monitoring";

// Components
import { ResultsPanelTab } from "@/types/general";

// GlobalVariables
import { defaultTabs } from "@/assets/defaultTabs";

type ComponentData = {
	results: null | ResultsPanelTab[];
	resultsLoading: boolean;
};
export default defineComponent({
	name: "ExecutionResultsCard",
	components: { Panel },
	props: {
		isVisible: { type: Boolean, default: false },
		taskOutputStreams: { type: Object as PropType<null | TaskOutput>, default: null },
		taskID: { type: String },
	},
	data: (): ComponentData => ({
		results: null,
		resultsLoading: false,
	}),
	methods: {
		closeCard() {
			// this.clearResults();
			this.$emit("click-outside");
		},
	},
	computed: {
		resultsPanelText(): readonly ResultsPanelTab[] {
			return this.results ?? defaultTabs;
		},
	},
});
