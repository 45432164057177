
import { defineComponent } from "vue";
import DeleteAccountDialog from "@/components/Settings/DeleteAccountDialog.vue";
import TooltipButton from "@/components/Common/TooltipButton.vue";
//import UpdatePassword from "@/components/UpdatePassword.vue";
import { useUserStore } from "@/stores/user";
import { AWSAuthenticatedUserObject } from "@/types/user";

type ComponentData = {
	deleteAccountDialog: boolean;
	authenticatedUser: undefined | null | AWSAuthenticatedUserObject;
};

export default defineComponent({
	name: "SettingsAccount",
	props: {},
	components: { DeleteAccountDialog, TooltipButton },
	data: (): ComponentData => ({
		deleteAccountDialog: false,
		authenticatedUser: undefined,
	}),
	setup() {
		const userStore = useUserStore();
		return { userStore };
	},
	methods: {
		openDeleteAccountDialog() {
			this.deleteAccountDialog = true;
		},
		closeDeleteAccountDialog() {
			this.deleteAccountDialog = false;
		},
	},
	async created() {
		const authUser = await this.userStore.getCurrentAuthenticatedUserObject();
		this.authenticatedUser = authUser;
	},
});
