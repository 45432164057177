
import { defineComponent } from "vue";

// Types
import { AWSAuthenticatedUserObject } from "@/types/user";
import { UserKeyFormatted, UserKey } from "@/types/api/keys";

// Libraries
import * as signaloidClient from "@/js/signaloidClient";
import { formatCreatedAt, formatValidUntil } from "@/js/dateUtils";
import moment from "moment";

// Utilities
import * as Sentry from "@sentry/vue";

// Components
import TooltipButton from "@/components/Common/TooltipButton.vue";
import DeleteAPIKey from "@/components/Settings/DeleteAPIKey.vue";

// Stores
import { useUserStore } from "@/stores/user";
import axios from "axios";

function formatKeyDates(key: UserKey): UserKeyFormatted {
	return {
		...key,
		FormattedCreatedAt: formatCreatedAt(key.CreatedAt),
		FormattedValidUntil: formatValidUntil(key.ValidUntil),
	};
}

function formatKeyIDs(key: UserKeyFormatted): UserKeyFormatted {
	return {
		...key,
		FormattedKeyID: "scce_" + key.KeyID.substring(0, 8), //+ "…", //"...",
	};
}

type VDataTableHeader = {
	text: string;
	value: string;
	align?: "start" | "center" | "end";
	sortable?: boolean;
	filterable?: boolean;
	groupable?: boolean;
	divider?: boolean;
	class?: string | string[];
	cellClass?: string | string[];
	width?: string | number;
	filter?: (value: any, search: string, item: any) => boolean;
	sort?: (a: any, b: any) => number;
};

type ComponentData = {
	authenticatedUser: undefined | null | AWSAuthenticatedUserObject;
	keysToList: UserKeyFormatted[];
	headers: VDataTableHeader[];
	keyListLoading: boolean;
	deleteKeyDialog: boolean;
	deleteKeyDialogConfig: UserKeyFormatted | null;
	noDataText: string;
};

export default defineComponent({
	name: "ListAPIKey",
	props: {},
	components: { TooltipButton, DeleteAPIKey },
	data: (): ComponentData => ({
		authenticatedUser: undefined,
		keysToList: [],
		keyListLoading: false,
		deleteKeyDialog: false,
		deleteKeyDialogConfig: null,
		noDataText: "You have not created any API keys yet.",
		headers: [
			{
				text: "Name",
				align: "start",
				sortable: false,
				value: "Name",
			},
			{
				text: "Key",
				align: "start",
				value: "FormattedKeyID",
				sortable: false,
			},
			{
				text: "Creation Date",
				align: "start",
				value: "FormattedCreatedAt",
				sortable: false,
			},
			{
				text: "Valid Until",
				align: "start",
				value: "FormattedValidUntil",
				sortable: false,
			},
			{
				text: "Actions",
				align: "center",
				value: "actions",
				sortable: false,
			},
		],
	}),
	computed: {},
	setup() {
		const userStore = useUserStore();

		return { userStore };
	},
	expose: ["refreshList"],
	methods: {
		async refreshList() {
			this.keyListLoading = true;
			try {
				const keysResponse = await signaloidClient.getUserAPIKeys();
				this.keysToList = keysResponse.data.Keys.map(formatKeyDates).map(formatKeyIDs);
			} catch (error) {
				// TODO: Add monitoring
				let severityLevel: Sentry.SeverityLevel = "error";
				if (axios.isAxiosError(error)) {
					if (error.response) {
						/*
						 *	This API can only return: 401, 404 (UserID), 500
						 */
						switch (error.response.status) {
							case 500:
								severityLevel = "fatal";
								this.noDataText =
									`Our system encountered a server error retrieving the API keys (HTTP Code ${error.response.status}).` +
									" Please try refreshing the page. If this error persists, please contact support at developer-support@signaloid.com.";
								break;
							case 401:
								this.noDataText =
									"Our system encountered an authorization error while retrieving the API keys." +
									" Please try refreshing the page. Signing out and back in will ensure you are properly authorized." +
									" If this error persists, please contact support at developer-support@signaloid.com.";
								break;
							case 404:
								severityLevel = "fatal";
								this.noDataText =
									"Our system encountered an error while locating your API keys." +
									" Our team will receive a high priority notification for this issue." +
									" If this error persists, please contact support at developer-support@signaloid.com.";
								break;

							default:
								break;
						}
					} else if (error.request) {
						// no response
						this.noDataText =
							"A network request initiated but there was no response from our servers." +
							" Please ensure that you have an active Internet connection." +
							" If this error persists, please contact support at developer-support@signaloid.com.";
					} else {
						// no request made
						this.noDataText =
							"A browser error ocurred when trying to make the network request." +
							" Please ensure that you have an active Internet connection." +
							" If this error persists, please contact support at developer-support@signaloid.com.";
					}
				} else {
					// other error
					this.noDataText =
						"The network request could not be made due to an unexpected error" +
						" If this error persists, please contact support at developer-support@signaloid.com.";
				}
				console.log(error);
				Sentry.captureException(error, {
					level: severityLevel,
					extra: { error: error, userMessage: this.noDataText },
					tags: { service: "create-task" },
				});
			} finally {
				this.keyListLoading = false;
			}
		},
		handleDeleteKey(item: UserKeyFormatted) {
			this.deleteKeyDialog = true;
			this.deleteKeyDialogConfig = item;
		},
		daysDiff(ts: number | null): number {
			if (ts === null) {
				return Infinity;
			}
			return moment.unix(ts).diff(moment(moment.now()), "days");
		},
	},
	async created() {
		const authUser = await this.userStore.getCurrentAuthenticatedUserObject();
		this.authenticatedUser = authUser;

		await this.refreshList();
	},
});
