var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      staticClass: "text-center",
      attrs: { width: _vm.$vuetify.breakpoint.mobile ? "95%" : "500px" },
      on: {
        "click:outside": _vm.closeDialog,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeDialog.apply(null, arguments)
        },
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "px-5 pa-2 pa-lg-2" },
        [
          _c(
            "p",
            { staticClass: "mt-3 font-weight-medium text-h5 text-center" },
            [
              _vm._v(
                " Would you like to delete the " +
                  _vm._s(
                    _vm.deletePathData.type == "dir" ? "directory" : "file"
                  ) +
                  "? "
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass:
                "font-weight-medium text-body font-italic text-center",
            },
            [_vm._v(" " + _vm._s(_vm.deletePathData.path) + " ")]
          ),
          _c(
            "div",
            { staticClass: "px-12" },
            [
              _c(
                "v-alert",
                {
                  staticClass: "text-body-2 pa-2 pl-5 my-2 text-center",
                  attrs: {
                    icon: false,
                    type: "warning",
                    outlined: "",
                    elevation: "0",
                  },
                },
                [
                  _c("strong", [
                    _vm._v(" This operation can not be undone. "),
                    _vm.dirNotEmpty()
                      ? _c("div", [
                          _vm._v(
                            " Directory is not empty and has " +
                              _vm._s(_vm.deletePathData?.children.length) +
                              " item" +
                              _vm._s(
                                _vm.deletePathData?.children.length > 1
                                  ? "s"
                                  : ""
                              ) +
                              ". "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm.dirNotEmpty()
                ? [
                    _c(
                      "p",
                      { staticClass: "text-body-2 text-center mt-4 mb-1" },
                      [
                        _vm._v(
                          ' Type "permanently delete" if you want to delete directory and all its contents. '
                        ),
                      ]
                    ),
                    _c("v-text-field", {
                      attrs: {
                        outlined: "",
                        dense: "",
                        placeholder: "permanently delete",
                      },
                      model: {
                        value: _vm.recursiveOptionConfirmationText,
                        callback: function ($$v) {
                          _vm.recursiveOptionConfirmationText = $$v
                        },
                        expression: "recursiveOptionConfirmationText",
                      },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-auto",
                  attrs: { color: "primary", elevation: "0", width: "120px" },
                  on: { click: _vm.closeDialog },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-auto white--text",
                  attrs: {
                    color: "danger",
                    loading: _vm.deleteInProgress,
                    disabled: _vm.deleteButtonDisabled(),
                    elevation: "0",
                    width: "120px",
                  },
                  on: { click: _vm.commitDeletePath },
                },
                [_vm._v(" Delete ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }