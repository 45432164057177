
import { defineComponent } from "vue";
import TooltipButton from "@/components/Common/TooltipButton.vue";
import { mockTimeout } from "@/js/githubClient";

import EditorPlaceholder from "@/components/Settings/EditorPlaceholder.vue";
import { updateUserPreferences } from "@/js/signaloidClient";
import DataSourceInlineCard from "@/components/DataSources/DataSourceInlineCard.vue";
import SourcePlaceholderCard from "@/components/DataSources/SourcePlaceholderCard.vue";
import MountDataSourcesDialog from "@/components/MountDataSourcesDialog.vue";

import { mapState } from "pinia";
import { useUserStore } from "@/stores/user";
import { useCoresStore } from "@/stores/cores";
import { useEditorStore } from "@/stores/editor";
import { useRepositoriesStore } from "@/stores/repositories";
import { useDataSourcesStore } from "@/stores/dataSources";

import * as util from "@/js/utilities";
import { LocalMountConfig } from "@/types/api/dataSources";
import { Core } from "@/types/api/cores";
import { monitoringCaptureError } from "@/plugins/monitoring";

type ComponentData = {
	editorTheme: undefined | string;
	editorVariableViewer: undefined | boolean;
	editorLayout: undefined | string;
	coresChosenDefaultCoreID: undefined | string;
	coresChosenDefaultReferenceCoreID: undefined | string;
	settingsSaveInProgress: boolean;
	saveError: boolean;
	mountDataSourcesDialogStatus: {
		show: boolean;
		source?: any;
	};
};

export default defineComponent({
	name: "SettingsGeneral",
	components: {
		EditorPlaceholder,
		TooltipButton,
		DataSourceInlineCard,
		SourcePlaceholderCard,
		MountDataSourcesDialog,
	},
	data: (): ComponentData => ({
		editorTheme: undefined,
		editorVariableViewer: undefined,
		editorLayout: undefined,
		coresChosenDefaultCoreID: undefined,
		coresChosenDefaultReferenceCoreID: undefined,
		settingsSaveInProgress: false,
		saveError: false,
		mountDataSourcesDialogStatus: { show: false, source: null },
	}),
	setup() {
		const userStore = useUserStore();
		const coresStore = useCoresStore();
		const editorStore = useEditorStore();
		const dataSourcesStore = useDataSourcesStore();
		const repositoriesStore = useRepositoriesStore();
		return { userStore, coresStore, editorStore, repositoriesStore, dataSourcesStore };
	},
	computed: {
		...mapState(useEditorStore, {
			editorThemeList: "themeList",
		}),
		...mapState(useCoresStore, {
			filteredCores: "coresFilteredCoresByUserTier",
		}),
		...mapState(useDataSourcesStore, {
			currentEditorDefaultMountConfig: "defaultMountConfig",
			currentRepositoriesDefaultMountConfig: "defaultMountConfig",
		}),
	},
	methods: {
		async saveSettings() {
			// * deprecated
			this.settingsSaveInProgress = true;
			this.editorStore.setEditorTheme(this.editorTheme, false);
			this.editorStore.setVariableViewerVisibility(this.editorVariableViewer ?? false, false);
			const defaultCoreIDToSet = this.coresChosenDefaultCoreID ?? this.coresStore.defaultCoreID;
			if (defaultCoreIDToSet !== undefined) {
				this.coresStore.setDefaultCore(defaultCoreIDToSet, true);
			}
			const defaultRefCoreIDToSet =
				this.coresChosenDefaultReferenceCoreID ?? this.coresStore.defaultReferenceCoreID;
			if (defaultRefCoreIDToSet !== undefined) {
				this.coresStore.setDefaultReferenceCore(defaultRefCoreIDToSet, false);
			}

			// * Try to save to the database
			try {
				const userID = await this.userStore.getCurrentUserID();

				if (!userID) {
					throw new Error("User ID not found");
				}
				const payload = {
					Editor_Theme: this.editorTheme,
					Editor_Layout_VariableViewer: String(this.editorVariableViewer),
					Execution_DefaultCore: this.coresChosenDefaultCoreID,
					Execution_DefaultReferenceCore: this.coresChosenDefaultReferenceCoreID,
				};
				const userPreferencesUpdateResponse = await updateUserPreferences(userID, payload);
				const userDataResponse = await this.userStore.fetchCurrentUserObjectFromDB();
			} catch (error) {
				monitoringCaptureError(error, "Save user settings");

				mockTimeout(5000).then(() => {
					this.saveError = false;
				});
			}
			this.settingsSaveInProgress = false;
		},
		openMountDataSourcesDialog(source) {
			this.mountDataSourcesDialogStatus.source = source;
			this.mountDataSourcesDialogStatus.show = true;
		},
		closeMountDataSourcesDialog() {
			this.mountDataSourcesDialogStatus.source = null;
			this.mountDataSourcesDialogStatus.show = false;
		},
		setDefaultMountConfig(mountConfig: LocalMountConfig) {
			this.dataSourcesStore.setDefaultMountConfig(mountConfig, true);
			this.closeMountDataSourcesDialog();
		},
		removeDefaultMountConfig() {
			this.dataSourcesStore.removeDefaultMountConfig();
		},
		resetDefaultMountConfig() {
			this.dataSourcesStore.resetDefaultMountConfig();
		},
		toggleVariableViewerVisibility() {
			this.editorStore.toggleVariableViewerVisibility(true);
		},
		setDefaultCore(coreID: string) {
			this.coresStore.setDefaultCore(coreID, true);
		},
		setDefaultReferenceCore(coreID: string) {
			this.coresStore.setDefaultReferenceCore(coreID, true);
		},
		setEditorTheme(themeID: string) {
			this.editorStore.setPreferredTheme(themeID);
		},
		disableItem(item: Core) {
			if (item.Disabled) {
				return true;
			}
			return false;
		},
	},
	async beforeMount() {
		// Figure out how to gracefully get this information

		await this.userStore.getCurrentUserPreferences();

		util.waitFor(() => this.editorStore.editorThemeID !== undefined).then(() => {
			this.editorTheme = this.editorStore.editorThemeID;
		});

		util.waitFor(() => this.editorStore.editorThemeID !== undefined).then(() => {
			this.editorVariableViewer = this.editorStore.variableViewerVisibility;
		});

		util.waitFor(() => this.coresStore.defaultCoreID !== undefined).then(() => {
			this.coresChosenDefaultCoreID = this.coresStore.defaultCoreID;
		});

		util.waitFor(() => this.coresStore.defaultReferenceCoreID !== undefined).then(() => {
			this.coresChosenDefaultReferenceCoreID = this.coresStore.defaultReferenceCoreID;
		});
	},
});
