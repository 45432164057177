<template>
	<v-alert
		class="mx-auto"
		:icon="false"
		border="top"
		colored-border
		:type="alertColour"
		elevation="2"
		width="95%"
		max-width="700px"
	>
		<div class="text-center text-sm-h4 text-h5 pa-3 mb-5">Connecting to your GitHub account...</div>
		<div>
			<v-slide-y-transition
				class="py-0"
				group
				tag="v-list"
			>
				<template v-for="(authTask, i) in authTasks">
					<v-list-item :key="`${i}-${authTask.text}`">
						<div
							:class="
								(authTask.done && !authTask.success && 'error--text') ||
								(authTask.done && authTask.success && 'success--text') ||
								'primary--text'
							"
							class="ml-4"
							v-text="authTask.text"
						></div>
						<v-spacer></v-spacer>
						<v-scale-transition hide-on-leave>
							<v-icon
								v-if="authTask.done && authTask.success"
								color="success"
							>
								mdi-check
							</v-icon>
							<v-icon
								v-if="authTask.done && !authTask.success"
								color="error"
							>
								mdi-close
							</v-icon>
							<v-progress-circular
								v-else
								:width="3"
								:size="25"
								color="primary"
								indeterminate
							>
							</v-progress-circular>
						</v-scale-transition>
					</v-list-item>
				</template>
			</v-slide-y-transition>
		</div>
		<v-expand-transition>
			<v-alert
				type="error"
				elevation="2"
				:icon="false"
				v-show="showError"
				class="mx-5 mt-2 mb-0 py-3"
			>
				{{ errorMessage }}
			</v-alert>
		</v-expand-transition>
	</v-alert>
</template>

<script>
import { defineComponent } from "vue";
import { mockTimeout } from "@/js/githubClient";
import { postGithubTokenRequest } from "@/js/signaloidClient";
import { useGithubStore } from "@/stores/github";

import { monitoringCaptureError } from "@/plugins/monitoring";

// @ts-ignore FIXME: add correct types
// type AuthTask = {
// 	done: boolean;
// 	success: boolean;
// 	text: string;
// };
// type ComponentData = {
// 	oauthRequestState: undefined | string | null;
// 	alertColour: string;
// 	showError: boolean;
// 	errorMessage: string;
// 	authTasks: AuthTask[];
// };

export default defineComponent({
	name: "GithubAuth",
	props: ["code", "connect", "state", "error", "errorDescription"],
	data: () => ({
		oauthRequestState: undefined,
		alertColour: "info",
		showError: false,
		errorMessage: "",
		authTasks: [],
	}),
	setup() {
		const githubStore = useGithubStore();
		return { githubStore };
	},
	computed: {},
	methods: {
		async validateAuthRequestAndLogin() {
			this.oauthRequestState = localStorage.getItem("gh-login-state");
			try {
				console.log(this.errorDescription);
				if (this.state == this.oauthRequestState) {
					this.authTasks.at(-1).done = true;
					this.authTasks.at(-1).success = true;
					localStorage.removeItem("gh-login-state");

					// check if authorised
					this.authTasks.push({
						done: false,
						success: false,
						text: "Authorizing with GitHub...",
					});

					if (this.error) {
						this.authTasks.at(-1).done = true;
						this.authTasks.at(-1).success = false;
						throw new Error(this.errorDescription);
					} else {
						this.authTasks.at(-1).done = true;
						this.authTasks.at(-1).success = true;

						// get tokens
						this.authTasks.push({
							done: false,
							success: false,
							text: "Logging into the GitHub account...",
						});

						this.authTasks.at(-1).done = true;
						this.authTasks.at(-1).success = true;

						this.authTasks.push({
							done: false,
							success: false,
							text: "Fetching GitHub account details..",
						});
						await this.githubStore.githubLogin(this.code);
						this.authTasks.at(-1).done = true;
						this.authTasks.at(-1).success = true;

						this.alertColour = "success";
					}
				} else {
					localStorage.removeItem("gh-login-state");
					throw new Error("Unexpected request from GitHub. Please try again.");
				}
			} catch (error) {
				monitoringCaptureError(error, "Github OAuth login");
				this.authTasks.at(-1).done = true;
				this.authTasks.at(-1).success = false;
				this.showError = true;
				this.errorMessage = error;
				this.alertColour = "error";
				await this.githubStore.githubLogout();
			} finally {
				this.authTasks.push({
					done: false,
					success: false,
					text: "...redirecting to Repositories",
				});

				const mockTimeoutInMs = this.showError ? 5000 : 2000;

				const isRedirectionToConnectRepositoryAvailable = this.connect != undefined && this.connect.length > 0;

				mockTimeout(mockTimeoutInMs).then(() => {
					isRedirectionToConnectRepositoryAvailable
						? this.$router.push({ name: "Repositories", query: { connect: this.connect } })
						: this.$router.push({ name: "Repositories" });
				});
			}
		},
	},
	mounted() {
		this.authTasks.push({
			done: false,
			success: false,
			text: "Communicating with GitHub...",
		});
		mockTimeout(500).then(() => {
			this.validateAuthRequestAndLogin();
		});
	},
});
</script>

<style></style>
