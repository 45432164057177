var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "pa-2 pa-sm-3", attrs: { rounded: "lg", outlined: "" } },
    [
      _vm.gatewayData
        ? [
            _c(
              "v-row",
              { attrs: { "no-gutters": "" } },
              [
                _c(
                  "v-col",
                  { attrs: { "align-self": "baseline" } },
                  [
                    _c("v-icon", { staticClass: "mr-1" }, [
                      _vm._v(_vm._s(_vm.dataSourceIcon("Gateway"))),
                    ]),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "", "allow-overflow": true },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g(
                                      _vm._b({}, "span", attrs, false),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.gatewayData.Name) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1879626995
                        ),
                      },
                      [
                        _c("div", [
                          _vm._v(
                            " Gateway Local IP Address: " +
                              _vm._s(_vm.gatewayIPAddress)
                          ),
                          _c("br"),
                          _vm._v(
                            " Unique Source ID: " +
                              _vm._s(_vm.gatewayData.ThingID) +
                              " "
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "", "open-delay": "250" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "a",
                                    { attrs: { target: "_blank" } },
                                    [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "pl-1",
                                              attrs: { small: "" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.showEditNameDialog = true
                                                },
                                              },
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v("mdi-pencil")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3239103014
                        ),
                      },
                      [_c("span", [_vm._v("Edit Gateway Name")])]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "mx-2",
                    attrs: { cols: "auto", "align-self": "start" },
                  },
                  [
                    _vm.gatewayStatusLoading
                      ? [
                          _c("v-progress-circular", {
                            staticClass: "mb-1",
                            attrs: {
                              indeterminate: "",
                              color: "primary",
                              size: 20,
                              width: 2,
                            },
                          }),
                        ]
                      : [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "", "open-delay": "500" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mb-1 mt-0 pt-0",
                                                attrs: {
                                                  outlined: "",
                                                  size: "20",
                                                  color:
                                                    _vm.gatewayStatusIcon
                                                      .colour,
                                                },
                                              },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.gatewayStatusIcon.icon
                                                )
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1146934220
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.gatewayStatus) + " "),
                                _c("br"),
                                _vm._v(
                                  " Last active - " +
                                    _vm._s(_vm.gatewayLastUpdatedString)
                                ),
                              ]),
                            ]
                          ),
                        ],
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "", "open-delay": "500" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "div",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "text-body-2",
                                          staticStyle: { display: "inline" },
                                        },
                                        "div",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.gatewayIPAddress) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3958817312
                        ),
                      },
                      [_c("span", [_vm._v("Gateway Local IP address")])]
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "v-row",
              {
                staticClass: "text-body-2 mt-1 ml-1",
                attrs: { "no-gutters": "" },
              },
              [
                _c("v-col", { staticClass: "ml-0 ml-sm-2" }, [
                  !_vm.$vuetify.breakpoint.mobile
                    ? _c(
                        "div",
                        { staticClass: "mr-3" },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { small: "" } },
                            [_vm._v("mdi-checkbox-marked-circle-plus-outline")]
                          ),
                          _vm._v(
                            "Created: " +
                              _vm._s(
                                _vm.formatDate(_vm.gatewayData.CreatedAt)
                              ) +
                              " "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "mr-3" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { small: "" } },
                        [_vm._v("mdi-update")]
                      ),
                      _vm._v(
                        "Last Updated: " +
                          _vm._s(_vm.formatDate(_vm.gatewayData.UpdatedAt)) +
                          " "
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "v-col",
                  {
                    staticClass: "pt-1 pt-sm-0",
                    attrs: {
                      cols: _vm.$vuetify.breakpoint.mobile ? "12" : "auto",
                      "align-self": "end",
                    },
                  },
                  [
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "", justify: "end" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("TooltipButton", {
                              attrs: {
                                color: "accent",
                                width: "50px",
                                btnIcon: "mdi-archive-eye-outline",
                                tooltipText: "View Data Stream",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showGatewayDataStreamsDialog = true
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "mx-1" }),
                        _c(
                          "v-col",
                          { attrs: { cols: "auto", align: "end" } },
                          [
                            _c("TooltipButton", {
                              attrs: {
                                color: "primary",
                                width: "50px",
                                disabled: !_vm.gatewayIPKnown,
                                target: "_blank",
                                exact: true,
                                href: `http://${_vm.gatewayIPAddress}:5000`,
                                btnIcon: "mdi-cog-outline",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "tooltipContent",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-1",
                                                attrs: {
                                                  color: "white",
                                                  small: "",
                                                },
                                              },
                                              [_vm._v("mdi-open-in-new")]
                                            ),
                                            _vm._v(" Configure Gateway "),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                4240458090
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _c("v-skeleton-loader", { attrs: { type: "list-item-avatar" } }),
      _vm.showEditNameDialog
        ? _c("EditValueDialog", {
            attrs: {
              title: "Set Gateway Name",
              valueHint: "Gateway Name",
              showDialog: _vm.showEditNameDialog,
              value: _vm.gatewayData.Name,
            },
            on: {
              "update:showDialog": function ($event) {
                _vm.showEditNameDialog = $event
              },
              "update:show-dialog": function ($event) {
                _vm.showEditNameDialog = $event
              },
              "update:value": _vm.setGatewayName,
            },
          })
        : _vm._e(),
      _vm.showGatewayDataStreamsDialog
        ? _c("GatewayDataStreamsDialog", {
            attrs: {
              showDialog: _vm.showGatewayDataStreamsDialog,
              gatewayID: _vm.gatewayID,
            },
            on: {
              "update:showDialog": function ($event) {
                _vm.showGatewayDataStreamsDialog = $event
              },
              "update:show-dialog": function ($event) {
                _vm.showGatewayDataStreamsDialog = $event
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }