
import { defineComponent } from "vue";

// Utilities
import { monitoringCaptureError } from "@/plugins/monitoring";

// Libraries
import * as Sentry from "@sentry/vue";
import axios from "axios";

// Components
import IntersectionObserver from "@/components/IntersectionObserver.vue";
import UpgradeButton from "@/components/Common/UpgradeButton.vue";

// Types
import { UserLimitsE } from "@/js/tierconfig";
import { TierLimitEventTypeE } from "@/eventBus/tierLimitEventBus";

// Stores
import { useRootStore } from "@/stores/root";
import { useOutputsStore } from "@/stores/outputs";

export default defineComponent({
	name: "LazyFigure",
	components: { IntersectionObserver, UpgradeButton },
	props: {
		useIntersection: { type: Boolean, default: true },
		// imageURL: { type: String, default: undefined },
		taskID: { type: String, default: undefined },
		valueID: { type: String, default: undefined },
		uxString: { type: String, default: undefined },
		imageMaxHeight: { type: String, default: "20em" },
		imageMaxWidth: { type: String, default: "40em" },
		alertWidth: { type: String, default: undefined },
		justify: { type: String, default: undefined },
		loaderCols: { type: Number, default: 4 },
		eager: { type: Boolean, default: false },
	},
	data: () => ({
		imageUrl: undefined as undefined | string,
		imageLoading: false,
		plotErrorMessage: "",
		apiErrorMessage: undefined,
		tierLimitError: false,
	}),
	setup() {
		const rootStore = useRootStore();
		const tasksOutputStore = useOutputsStore();
		return { rootStore, tasksOutputStore };
	},
	methods: {
		async intersectionHandler() {
			if (!this.tierLimitError && !this.imageUrl && !this.imageLoading) {
				this.getPlotURL();
			}
		},
		async getPlotURL() {
			this.plotErrorMessage = "";
			this.tierLimitError = false;
			this.imageLoading = true;

			try {
				if (this.uxString) {
					const presignedURL = await this.tasksOutputStore.getUxStringPlot(this.uxString);
					// this.$emit("update:imageURL", resp.data.presignedURL);
					this.imageUrl = presignedURL;
				} else if (this.taskID && this.valueID) {
					const presignedURL = await this.tasksOutputStore.getValueIdPlot(this.taskID, this.valueID);
					// this.$emit("update:imageURL", resp.data.presignedURL);
					this.imageUrl = presignedURL;
				} else {
					throw new Error("No ValueID or Ux String provided");
				}
			} catch (error) {
				let severityLevel: Sentry.SeverityLevel = "error";
				if (axios.isAxiosError(error)) {
					if (error.response) {
						const status = error.response.status;
						const statusText = error.response.statusText;
						/*
						 *	The client received a response with HTTP Code != 2xx
						 */
						if (status === 504) {
							this.plotErrorMessage =
								"The distribution plot request timed out because it was taking too long. Please try again in a bit. If this error persists," +
								" please contact support at developer-support@signaloid.com.";
						} else if (status === 502 || status === 500) {
							/*
							 *	A 502 error 99.9% means that a Lambda is failing (the way AWS is currently set up)
							 */
							this.plotErrorMessage =
								"The distribution plot request failed due to an internal error." +
								" Our team will receive a high priority notification of this incident." +
								" If this error persists, please contact support at developer-support@signaloid.com.";

							severityLevel = "fatal"; // This is fatal because it usually means that a Lambda is crashing.
						} else if (status === 503) {
							this.plotErrorMessage =
								"Our systems are currently experiencing an unusually large number of requests." +
								" We are hard at work scaling up our infrastructure. Please try again later." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						} else if (status >= 500) {
							/*
							 *	Other 5xx response
							 */
							this.plotErrorMessage =
								`The distribution plot request failed due to a server error (HTTP Code ${status}).` +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						} else if (status === 401) {
							this.plotErrorMessage =
								"Our system encountered an authorization error while requesting the distribution plot." +
								` The current user is not authorized to plot this value (${this.valueID}) from the current task (${this.taskID}).` +
								" Signing out and back in will ensure you are properly authorized." +
								" If you have further questions, please contact support at developer-support@signaloid.com.";
						} else if (status === 403 || status === 402) {
							this.rootStore.tierLimitEventBus.emit({
								type: TierLimitEventTypeE.LimitExceeded,
								affectedLimits: [UserLimitsE.PlotCount],
							});

							this.plotErrorMessage =
								`Our system could not plot the requested value (${this.valueID}) from the current task (${this.taskID}) as your account's plotting allowance has been used up.` +
								" Please upgrade your account if you would like to continue generating distribution plots for variables." +
								" If you have further questions, please contact support at developer-support@signaloid.com.";
							this.tierLimitError = true;
						} else if (status === 404) {
							this.plotErrorMessage =
								`The distribution plot request failed because our system could not find the requested value (${this.valueID}) for the current task (${this.taskID}).` +
								" Our team will receive a high priority notification for this incident." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
							severityLevel = "fatal"; // This is fatal because if the frontend is asking for values that don't exist it's a problem.
						} else if (status === 408) {
							this.plotErrorMessage =
								"The distribution plot request timed out because our system took too long to respond." +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						} else if (status >= 400) {
							/*
							 *	Other 4xx response
							 */
							this.plotErrorMessage =
								`Our system encountered an error while requesting the distribution plot (HTTP Code ${status} ${statusText}).` +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						} else {
							/*
							 *	Other HTTP errors
							 */
							this.plotErrorMessage =
								`Our system encountered an error while requesting the distribution plot (HTTP Code ${status} ${statusText}).` +
								" If this error persists, please contact support at developer-support@signaloid.com.";
						}

						if (status >= 400 && status < 500 && error.response?.data.message) {
							this.apiErrorMessage = error.response.data.message;
						}
					} else if (error.request) {
						/*
						 *	The client made the request but did not receive a response.
						 */
						this.plotErrorMessage =
							"A distribution plot request was initiated but there was no response from our servers." +
							" Please ensure that you have an active Internet connection." +
							" If this error persists, please contact support at developer-support@signaloid.com.";
					} else {
						/*
						 *	Something happened in setting up the request that triggered an Error in the browser.
						 */
						this.plotErrorMessage =
							"A browser error occurred when trying to create the distribution plot request." +
							" Please ensure that you have an active Internet connection." +
							" If this error persists, please contact support at developer-support@signaloid.com.";
					}
				} else {
					/*
					 *	A different error happened that was outside axios and prevented making the request.
					 */
					this.plotErrorMessage =
						"The distribution plot request could not be created due to an unexpected error." +
						" If this error persists, please contact support at developer-support@signaloid.com.";
				}
				monitoringCaptureError(error, "LazyFigure.vue:plotValue", {
					severity: severityLevel,
					extras: {
						userMessage: this.plotErrorMessage,
						...(this.apiErrorMessage !== undefined && { apiErrorMessage: this.apiErrorMessage }),
					},
					tags: { service: "plotting" },
				});
			} finally {
				this.imageLoading = false;
			}
		},
	},
	beforeMount() {
		if (!this.tierLimitError && (!this.useIntersection || this.eager)) {
			/*
			 *	If we're not waiting for intersection and we don't have a plot URL, then
			 * 	immediately try to get the plot URL.
			 */
			this.imageLoading = true;
			this.getPlotURL();
		}
	},
});
