
import { defineComponent } from "vue";

// Types
import { AWSAuthenticatedUserObject } from "@/types/user";
import { UserLimitsE } from "@/js/tierconfig";

// Libraries
import * as signaloidClient from "@/js/signaloidClient";
import { toISODateString, isFutureDateString } from "@/js/dateUtils";
import axios from "axios";

// Utilities
import * as Sentry from "@sentry/vue";

// Components
import SignaloidDatePicker from "@/components/Common/SignaloidDatePicker.vue";
import LimitableActionButton from "@/components/Common/LimitableActionButton.vue";
import APIKeyCreationDialog from "./APIKeyCreationDialog.vue";

// Stores
import { useUserStore } from "@/stores/user";

type ComponentData = {
	keyName: string | undefined;
	generateKeyButtonLoading: boolean;
	authenticatedUser: undefined | null | AWSAuthenticatedUserObject;
	validityDateOptions: ValidUntilOption[];
	validitySelection: string;
	signaloidDatePickerPickedDate: string;
	showKeyCreationDialog: boolean;
	errorText: string;
	showKeyCreationDialogConfig: {
		key: string;
		name: string;
	} | null;
};

type ValidUntilOption = "7 days" | "30 days" | "60 days" | "90 days" | "One year" | "Custom..." | "Valid forever";

const k7Days: ValidUntilOption = "7 days";
const k30Days: ValidUntilOption = "30 days";
const k60Days: ValidUntilOption = "60 days";
const k90Days: ValidUntilOption = "90 days";
const kOneYear: ValidUntilOption = "One year";
const kCustom: ValidUntilOption = "Custom...";
const kValidForever: ValidUntilOption = "Valid forever";

const kValidityDateOptionList: ValidUntilOption[] = [
	k7Days,
	k30Days,
	k60Days,
	k90Days,
	kOneYear,
	kCustom,
	kValidForever,
];

export default defineComponent({
	name: "GenerateAPIKey",
	props: {},
	emits: ["keyCreated"],
	components: {
		SignaloidDatePicker,
		LimitableActionButton,
		APIKeyCreationDialog,
	},
	data: (): ComponentData => ({
		signaloidDatePickerPickedDate: toISODateString(new Date()),
		keyName: undefined,
		generateKeyButtonLoading: false,
		validitySelection: "30 days",
		authenticatedUser: undefined,
		validityDateOptions: kValidityDateOptionList,
		showKeyCreationDialog: false,
		showKeyCreationDialogConfig: null,
		errorText: "",
	}),
	computed: {
		expirationDateISO(): string | null {
			const validUntilDate = new Date(); // Init value before applying selection
			switch (this.validitySelection) {
				case k7Days:
					validUntilDate.setDate(validUntilDate.getDate() + 7);
					return toISODateString(validUntilDate);
				case k30Days:
					validUntilDate.setDate(validUntilDate.getDate() + 30);
					return toISODateString(validUntilDate);
				case k60Days:
					validUntilDate.setDate(validUntilDate.getDate() + 60);
					return toISODateString(validUntilDate);
				case k90Days:
					validUntilDate.setDate(validUntilDate.getDate() + 90);
					return toISODateString(validUntilDate);
				case kOneYear:
					validUntilDate.setFullYear(validUntilDate.getFullYear() + 1);
					return toISODateString(validUntilDate);
				case kCustom: {
					const pickedDateParsed = new Date(this.signaloidDatePickerPickedDate);
					return toISODateString(pickedDateParsed);
				}
				case kValidForever:
					return null;

				default:
					throw new Error("Unhandled this.validitySelection:" + this.validitySelection);
			}
		},
		expirationDateFormatted(): string {
			if (this.expirationDateISO) {
				// Transform from ISO to local
				const date = new Date(this.expirationDateISO);
				return date.toLocaleDateString();
			}
			return String(this.expirationDateISO);
		},
		generateKeyButtonDisabled(): boolean {
			const disabled = true;

			const keyNameValid = this.keyName !== undefined && this.keyName.length !== 0;
			const dateNever = this.expirationDateISO === null && this.validitySelection === "Valid forever";
			const dateValid = this.expirationDateISO !== null && this.datePickerAllowedDates(this.expirationDateISO);
			if (keyNameValid && (dateNever || dateValid)) {
				return !disabled;
			}
			return disabled;
		},
	},
	setup() {
		const userStore = useUserStore();
		return { userStore, UserLimitsE };
	},
	methods: {
		datePickerAllowedDates: (val: string) => {
			return isFutureDateString(val);
		},
		async generateKey() {
			this.generateKeyButtonLoading = true;
			this.errorText = "";

			try {
				const defaultName = "Signaloid Cloud Key";
				const name = this.keyName ?? defaultName;

				let validUntil: number | null = null;
				if (this.expirationDateISO !== undefined) {
					validUntil = Math.floor(Date.parse(this.expirationDateISO!) / 1000);

					// console.log(new Date(this.expirationDateISO!).toISOString());
					// console.log(new Date(this.expirationDateISO!).toLocaleDateString());
				}

				const response = await signaloidClient.postUserAPIKeys(name, validUntil);
				this.showKeyCreationDialogConfig = {
					key: response.data.Key,
					name: response.data.Name,
				};

				// @ts-ignore
				this.$posthog?.capture("api_key_created");

				this.$emit("keyCreated");
				this.showKeyCreationDialog = true;
				this.keyName = undefined;
				// this.signaloidDatePickerPickedDate = undefined;
			} catch (error) {
				// TODO: Add monitoring
				let severityLevel: Sentry.SeverityLevel = "error";
				if (axios.isAxiosError(error)) {
					if (error.response) {
						/*
						 *	This API can only return: 401, 404 (UserID), 500, 403
						 */
						switch (error.response.status) {
							case 500:
								severityLevel = "fatal";
								this.errorText =
									`Our system encountered a server error retrieving the API keys (HTTP Code ${error.response.status}).` +
									" Please try refreshing the page. If this error persists, please contact support at developer-support@signaloid.com.";
								break;
							case 401:
								this.errorText =
									"Our system encountered an authorization error while retrieving the API keys." +
									" Please try refreshing the page. Signing out and back in will ensure you are properly authorized." +
									" If this error persists, please contact support at developer-support@signaloid.com.";
								break;
							case 404:
								severityLevel = "fatal";
								this.errorText =
									"Our system encountered an error while locating your API keys." +
									" Our team will receive a high priority notification for this issue." +
									" If this error persists, please contact support at developer-support@signaloid.com.";
								break;
							case 403:
								// eslint-disable-next-line
                                const limitReached =
									error.response.data.message !== undefined &&
									(error.response.data.message as string).includes(
										"reached the API Key limit for your account"
									);
								this.errorText =
									"Your account is not allowed to create a new API key." +
									(limitReached ? " You have reached the API Key limit for your account." : "") +
									"  If you have any questions about this, please contact support at developer-support@signaloid.com.";
								break;

							default:
								break;
						}
					} else if (error.request) {
						// no response (this also fires when client is offline)
						this.errorText =
							"A request to create the API key was initiated but there was no response from our servers." +
							" Please ensure that you have an active Internet connection." +
							" If this error persists, please contact support at developer-support@signaloid.com.";
					} else {
						// no request made
						this.errorText =
							"A browser error ocurred when trying to make the network request to create the API key." +
							" Please ensure that you have an active Internet connection." +
							" If this error persists, please contact support at developer-support@signaloid.com.";
					}
				} else {
					// other error
					this.errorText =
						"The network request could not be made due to an unexpected error" +
						" If this error persists, please contact support at developer-support@signaloid.com.";
				}
				console.log(error);
				Sentry.captureException(error, {
					level: severityLevel,
					extra: { userMessage: this.errorText },
					tags: { service: "create-api-key" },
				});
				// TODO: error handling
				//  Can fail with: 401, 403, 400, 500
			} finally {
				this.generateKeyButtonLoading = false;
			}

			//TODO:
			// Post to /keys
			// Show new key (only once!)
		},
	},
	async created() {
		const authUser = await this.userStore.getCurrentAuthenticatedUserObject();
		this.authenticatedUser = authUser;

		// Set default date picker picked date to +30 days.
		const datePickerPickedDate = new Date(this.signaloidDatePickerPickedDate);
		datePickerPickedDate.setDate(datePickerPickedDate.getDate() + 30);
		this.signaloidDatePickerPickedDate = toISODateString(datePickerPickedDate);
	},
});
