var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "500" },
          model: {
            value: _vm.dialogVisible,
            callback: function ($$v) {
              _vm.dialogVisible = $$v
            },
            expression: "dialogVisible",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-2" },
            [
              _c("div", { staticClass: "text-center text-h4 pa-3 mb-5" }, [
                _vm._v(" " + _vm._s(_vm.dialogText) + " "),
              ]),
              _c(
                "v-card-text",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-progress-circular",
                    {
                      attrs: {
                        width: 5,
                        size: 100,
                        color: "primary",
                        indeterminate: "",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { size: "70" } }, [
                        _vm._v("mdi-github"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }