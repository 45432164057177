
import { defineComponent, PropType } from "vue";
import { tiers, AccountGroupCode } from "@/js/tierconfig";

export default defineComponent({
	props: {
		tierCode: { type: String as PropType<AccountGroupCode>, required: true },
		small: { type: Boolean, default: undefined },
	},

	computed: {
		tierColour() {
			switch (this.tierCode) {
				case "free-tier":
					return "grey darken-2";
				case "developer-tier":
					return "primary lighten-1";
				case "pro-tier":
					return "accent lighten-1";
				case "enterprise-tier":
					return "warning lighten-1";
				default:
					return "grey darken-2";
			}
		},
		tierName(): string {
			const tierData = tiers.find((e) => e.code === this.tierCode);
			if (this.small !== undefined && tierData) {
				// for normal view
				return this.small ? tierData.title : tierData.fullTitle;
			} else if (tierData) {
				// for mobile view
				return this.$vuetify.breakpoint.smAndDown ? tierData.title : tierData.fullTitle;
			} else {
				// fallback
				return "---";
			}
		},
	},
});
