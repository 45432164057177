var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      staticClass: "pa-2 pa-sm-3 mx-0",
      staticStyle: { "overflow-y": "visible" },
      attrs: {
        rounded: "lg",
        outlined: "",
        "min-width": "300px",
        "data-test": "task-card",
      },
    },
    [
      _vm.taskData
        ? _c(
            "div",
            [
              _c(
                "v-row",
                {
                  staticClass: "text-sm-body-1 text-body-2",
                  attrs: { align: "center" },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-0", attrs: { cols: "auto" } },
                    [
                      _c("v-icon", { staticClass: "pr-1" }, [
                        _vm._v(" " + _vm._s(_vm.projectSourceIcon) + " "),
                      ]),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "TaskDetail",
                              params: { id: _vm.taskData.TaskID },
                            },
                          },
                        },
                        [
                          _vm._v(" Task: "),
                          _vm.taskData.TaskID != null
                            ? _c(
                                "code",
                                {
                                  staticClass: "font-weight-regular mx-0",
                                  staticStyle: { "font-size": "0.875em" },
                                  attrs: {
                                    "data-test": "task-card-task-id-value",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.util.shortenID(_vm.taskData.TaskID)
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c("CopyToClipboardButton", {
                        staticClass: "mx-0 ml-0 ml-sm-n2 mr-n1",
                        attrs: {
                          "value-to-copy": _vm.taskData.TaskID,
                          "value-name": "Task ID",
                        },
                      }),
                      _vm.taskCore?.Name && !_vm.$vuetify.breakpoint.mobile
                        ? [
                            _vm._v(" on "),
                            _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  top: "",
                                  "open-delay": "500",
                                  color: "rgba(255, 0, 0, 0)",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-chip",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "font-weight-medium",
                                                  attrs: {
                                                    color: "primary",
                                                    outlined: "",
                                                    small: "",
                                                  },
                                                },
                                                "v-chip",
                                                { ...attrs },
                                                false
                                              ),
                                              { ...on }
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { left: "" } },
                                                [_vm._v(" mdi-memory ")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.taskCore?.Name) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1641832949
                                ),
                              },
                              [
                                _c("ActiveCoreTooltip", {
                                  attrs: { "active-core": _vm.taskCore },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-sm-body-2 text-caption mr-1 ml-0 pl-0",
                      attrs: {
                        cols: "auto",
                        "data-test": "task-card-task-status-value",
                      },
                    },
                    [
                      !_vm.$vuetify.breakpoint.smAndDown
                        ? [_vm._v(" Status:" + _vm._s("\xa0") + " ")]
                        : _vm._e(),
                      _vm.remoteError
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "", "open-delay": "250" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mr-1",
                                                attrs: {
                                                  size: "14",
                                                  color: "warning",
                                                },
                                              },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v(" mdi-alert-outline ")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                311381781
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.remoteErrorMessage)),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          class: `font-weight-bold ${_vm.util.taskStatusColor(
                            _vm.taskData?.Status
                          )}--text `,
                        },
                        [_vm._v(_vm._s(_vm.taskData.Status))]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "text-md-body-2 text-caption truncate-lines" },
                [
                  _vm.$vuetify.breakpoint.mobile
                    ? [
                        _c(
                          "v-row",
                          {
                            staticClass: "ml-sm-8 ml-1 mt-2 mb-0",
                            attrs: { "no-gutters": "" },
                          },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "pr-1", attrs: { size: "20" } },
                              [_vm._v("mdi-memory")]
                            ),
                            _c("span", { staticClass: "px-1" }, [
                              _vm._v(" Execution Core "),
                            ]),
                            _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  top: "",
                                  "open-delay": "500",
                                  color: "rgba(255, 0, 0, 0)",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-chip",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "font-weight-medium",
                                                  attrs: {
                                                    color: "primary",
                                                    outlined: "",
                                                    "x-small": "",
                                                  },
                                                },
                                                "v-chip",
                                                { ...attrs },
                                                false
                                              ),
                                              { ...on }
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { left: "" } },
                                                [_vm._v(" mdi-memory ")]
                                              ),
                                              _vm._v(" Build Core Specs "),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  819853622
                                ),
                              },
                              [
                                _c("ActiveCoreTooltip", {
                                  attrs: { "active-core": _vm.taskCore },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "v-row",
                    {
                      staticClass: "ml-sm-8 ml-1 mt-sm-2 mt-1 mb-1",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _vm.isRepositoryTask
                            ? [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "pr-1",
                                    attrs: { size: "20" },
                                  },
                                  [_vm._v("mdi-github")]
                                ),
                                _c(
                                  "a",
                                  { attrs: { href: _vm.cardRepo?.RemoteURL } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.repoFullNameFromUrl(
                                            _vm.cardRepo?.RemoteURL
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            : _vm.isSourceCodeTask
                            ? [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "pr-1",
                                    attrs: { size: "20" },
                                  },
                                  [_vm._v("mdi-code-json")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (
                                        _vm.cardSourceCode?.Code.match(/\n/g) ||
                                        ""
                                      ).length + 1
                                    ) +
                                    " lines "
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        {
                          staticClass: "mt-0 pt-1 pt-sm-0 mr-1",
                          attrs: { cols: "12", sm: "auto" },
                        },
                        [
                          _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v(" mdi-clock-time-four-outline "),
                          ]),
                          _c(
                            "v-tooltip",
                            {
                              attrs: {
                                bottom: "",
                                "open-delay": "250",
                                color: "rgba(255, 0, 0, 0)",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _vm.isTaskActive(_vm.taskData.Status)
                                          ? [
                                              _c(
                                                "span",
                                                _vm._g(
                                                  _vm._b(
                                                    { staticClass: "ml-2" },
                                                    "span",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v("Task in progress")]
                                              ),
                                            ]
                                          : [
                                              _c(
                                                "span",
                                                _vm._g(
                                                  _vm._b(
                                                    { staticClass: "ml-2" },
                                                    "span",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v("Total duration: ")]
                                              ),
                                            ],
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4080172955
                              ),
                            },
                            [
                              _c("TaskStatusTransitionTooltip", {
                                attrs: {
                                  taskStatusTransitions:
                                    _vm.taskData.StatusTransitions,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.isTaskActive(_vm.taskData.Status)
                            ? [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-2",
                                    attrs: {
                                      color: "secondary",
                                      elevation: "0",
                                      outlined: "",
                                      "x-small": "",
                                    },
                                    on: { click: _vm.cancelActiveTask },
                                  },
                                  [_vm._v("Cancel")]
                                ),
                              ]
                            : [_vm._v(" " + _vm._s(_vm.runDuration) + " ")],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm.taskStatusMessageOnError
                    ? _c(
                        "v-row",
                        {
                          staticClass: "ml-sm-8 ml-1 my-1",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "ma-0 pa-0 mb-0 mb-sm-0 mt-n1 mt-sm-auto pb-1",
                              attrs: { cols: "12", sm: "auto" },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "pr-2",
                                  staticStyle: { color: "#b71c1c" },
                                  attrs: { size: "20" },
                                },
                                [_vm._v("mdi-message-alert-outline")]
                              ),
                              _vm._v(" " + _vm._s("\xa0") + " "),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "ma-0 pa-0 flex-nowrap",
                              attrs: { cols: "12", sm: "auto" },
                            },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "code",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "font-weight-light my-3 align-center",
                                                    staticStyle: {
                                                      "font-size": "0.875em",
                                                      "min-width": "50px",
                                                      "min-height": "36px",
                                                      color: "#b71c1c",
                                                      "background-color":
                                                        "#ffebee",
                                                      padding: "5px",
                                                      "border-radius": "4px",
                                                    },
                                                  },
                                                  "code",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.taskStatusMessageOnError
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1261399946
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "Find more information related to this error on 'Show Error Details'"
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.taskArguments
                    ? _c(
                        "v-row",
                        {
                          staticClass: "ml-sm-8 ml-1 my-1",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c("v-icon", { attrs: { size: "20" } }, [
                                _vm._v(" mdi-console "),
                              ]),
                              _vm._v(" " + _vm._s("\xa0") + " "),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "overflow-auto argument-area",
                              staticStyle: {
                                "font-size": "0.8em",
                                height: "fit-content",
                                width: "100%",
                              },
                              style: !_vm.inTaskDetail
                                ? "max-height: 6em"
                                : "height: 6em; resize: vertical",
                              attrs: {
                                "code-area": "",
                                "textarea-narrowLineHeight": "",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(" " + _vm._s(_vm.taskArguments) + " "),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isRepositoryTask
                    ? [
                        _c(
                          "v-row",
                          {
                            staticClass: "ml-sm-8 ml-1 my-1",
                            attrs: { "no-gutters": "" },
                          },
                          [
                            _c("v-icon", { attrs: { size: "20" } }, [
                              _vm._v(" mdi-source-branch "),
                            ]),
                            _c(
                              "v-chip",
                              {
                                staticClass: "mx-1 font-weight-medium",
                                attrs: {
                                  color: "grey-lighten-1",
                                  outlined: "",
                                  small: "",
                                },
                              },
                              [
                                _vm._v(" " + _vm._s(_vm.cardRepo.Branch) + " "),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-0",
                                    attrs: { size: "15" },
                                  },
                                  [_vm._v(" mdi-source-commit ")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.cardRepo.Commit.slice(0, 25)) +
                                    "... "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          {
                            staticClass: "ml-sm-8 ml-1 my-1",
                            attrs: { "no-gutters": "" },
                          },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "pr-2", attrs: { size: "20" } },
                              [_vm._v("mdi-folder-account-outline")]
                            ),
                            _vm._v(" Working on" + _vm._s("\xa0") + " "),
                            _vm.taskData.TaskID != null
                              ? _c(
                                  "code",
                                  {
                                    staticClass: "font-weight-regular py-0",
                                    staticStyle: { "font-size": "0.75em" },
                                  },
                                  [
                                    _vm._v(
                                      "./" + _vm._s(_vm.cardRepo.BuildDirectory)
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.taskData.Status == _vm.TaskStatusE.Completed
                    ? [
                        _vm.executionTimeString
                          ? _c(
                              "v-row",
                              {
                                staticClass: "ml-sm-8 ml-1 my-1",
                                attrs: { "no-gutters": "" },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "pr-2",
                                    attrs: { size: "20" },
                                  },
                                  [_vm._v("mdi-timer-outline")]
                                ),
                                _vm._v(
                                  " C0 execution time: " +
                                    _vm._s(_vm.executionTimeString) +
                                    " "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.dynamicInstructions
                          ? _c(
                              "v-row",
                              {
                                staticClass: "ml-sm-8 ml-1 my-1",
                                attrs: { "no-gutters": "" },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "pr-2",
                                    attrs: { size: "20" },
                                  },
                                  [_vm._v("mdi-counter")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.dynamicInstructions) +
                                    " dynamic instruction credits consumed "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _c(
                    "v-row",
                    {
                      staticClass: "ml-sm-8 ml-1 mt-1",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "pr-1", attrs: { size: "20" } },
                            [_vm._v("mdi-calendar-clock ")]
                          ),
                          _vm.taskData.Status === _vm.TaskStatusE.Accepted ||
                          !_vm.taskData.StartedAt
                            ? [
                                _vm._v(
                                  " Task queued " +
                                    _vm._s(_vm.createdTimestamp) +
                                    " "
                                ),
                              ]
                            : [_vm._v(" " + _vm._s(_vm.startTimestamp) + " ")],
                          _c("v-icon", [_vm._v(" mdi-arrow-right-thin ")]),
                          !_vm.isTaskActive(_vm.taskData.Status) &&
                          _vm.taskData.FinishedAt
                            ? _c("span", [
                                _vm._v(" " + _vm._s(_vm.endTimestamp) + " "),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm.isTaskActive(_vm.taskData.Status)
                        ? _c(
                            "v-col",
                            { attrs: { cols: "1" } },
                            [
                              _c("v-progress-linear", {
                                staticClass: "ml-2 my-3",
                                attrs: {
                                  color: _vm.util.taskStatusColor(
                                    _vm.taskData?.Status
                                  ),
                                  indeterminate: _vm.isTaskActive(
                                    _vm.taskData.Status
                                  ),
                                  value: 100,
                                  striped:
                                    _vm.taskData.Status ===
                                      _vm.TaskStatusE.Stopped ||
                                    _vm.taskData.Status ===
                                      _vm.TaskStatusE.Cancelled,
                                  rounded: "",
                                  height: "3",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-spacer"),
                      !_vm.inTaskDetail
                        ? _c(
                            "v-col",
                            { staticClass: "pt-2", attrs: { cols: "auto" } },
                            [
                              _c("TooltipButton", {
                                attrs: {
                                  color: _vm.taskStatusMessageOnError
                                    ? "secondary"
                                    : "primary",
                                  width: _vm.$vuetify.breakpoint.mobile
                                    ? "70px"
                                    : _vm.taskStatusMessageOnError
                                    ? "160px"
                                    : "120px",
                                  "min-width": "0px",
                                  "btn-text": _vm.$vuetify.breakpoint.mobile
                                    ? "Details"
                                    : _vm.taskStatusMessageOnError
                                    ? "Show Error Details"
                                    : "Show Details",
                                  tooltipText: _vm.taskStatusMessageOnError
                                    ? "Show Error Details"
                                    : "Show Details",
                                  "data-test": "task-card-open-details-button",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openTaskDetails()
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isTaskActive(_vm.taskData.Status) &&
                      _vm.showDeleteTaskButton
                        ? _c(
                            "v-col",
                            {
                              staticClass: "pt-2 ml-1",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c("TooltipButton", {
                                attrs: {
                                  color: "secondary",
                                  width: _vm.$vuetify.breakpoint.mobile
                                    ? "40px"
                                    : "50px",
                                  "min-width": "0px",
                                  outlined: "",
                                  btnIcon: "mdi-delete",
                                  tooltipText: "Remove task from history",
                                  "data-test": "task-card-open-remove-button",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openRemoveTaskDialog()
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("TasksRemoveDialog", {
                attrs: {
                  "show-dialog": _vm.removeTaskDialog,
                  taskID: _vm.taskID,
                },
                on: {
                  closeRemoveTaskDialog: _vm.closeRemoveTaskDialog,
                  removeTaskSuccess: _vm.removeTaskSuccess,
                },
              }),
            ],
            1
          )
        : _c("v-skeleton-loader", {
            attrs: { type: "list-item-two-line, actions" },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }