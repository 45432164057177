import { ResultsPanelTab } from "@/types/general";

export const defaultTabs: Readonly<ResultsPanelTab[]> = [
	{
		name: "stdout",
		code: "Stdout",
		icon: "mdi-eye-outline",
		disabled: false,
		code_tag: true,
	},
	{
		name: "stderr",
		code: "Stderr",
		icon: "mdi-alert-outline",
		disabled: false,
		code_tag: true,
	},
	{
		name: "Build",
		code: "Build",
		icon: "mdi-layers-outline",
		disabled: false,
		code_tag: false,
	},
	// {
	// 	name: "internals",
	// 	code: "Internals",
	// 	icon: "mdi-key",
	// 	text: "",
	// 	disabled: true,
	// 	code_tag: false,
	// },
	{
		name: "Runtime Information",
		code: "Stats",
		icon: "mdi-counter",
		// text: "",
		disabled: false,
		code_tag: false,
	},
	// {
	// 	name: "files",
	// 	code: "Files",
	// 	icon: "mdi-database-outline",
	// 	text: "",
	// 	disabled: false,
	// 	code_tag: false,
	// },
] as const;
