var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.showHelpButton && !_vm.userflowLoaded
    ? _c(
        "v-menu",
        {
          attrs: {
            "content-class": "arrow-menu",
            left: "",
            top: "",
            "close-on-content-click": false,
            origin: "bottom left",
            transition: "scale-transition",
            rounded: "lg",
            "nudge-bottom": "3px",
            "z-index": "11",
            "min-width": "350px",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        _vm._b(
                          {
                            staticClass: "help-button",
                            attrs: { color: _vm.menuColour, height: "30px" },
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [
                        _vm._v("Help "),
                        _c("div", { staticClass: "ml-2 mb-n1" }, [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 100 100",
                                width: "1em",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  fill: "white",
                                  d: "M24.049 79.74h36.003c8.185 0 15.985-6.621 17.43-14.814l6.091-34.556h3.997c8.149 0 13.645 6.63 12.2 14.815l-7.051 40C91.274 93.363 83.474 100 75.288 100h-40c-8.185 0-13.645-6.667-12.2-14.815l.96-5.444Z",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  fill: "currentColor",
                                  "fill-rule": "evenodd",
                                  d: "M12.437 69.63h40c8.148 0 15.978-6.637 17.422-14.815l7.052-40C78.355 6.63 72.896 0 64.711 0h-40C16.533 0 8.726 6.63 7.281 14.815l-7.052 40C-1.215 62.963 4.252 69.63 12.437 69.63Zm33.628-33.798c3.756-2.064 8.725-4.795 9.907-11.844C57.385 15.458 50.065 8.983 42.168 9c-7.452 0-12.771 3.195-17.508 8.892-.858 1.034-.884 2.45-.053 3.212l3.472 3.196c.847.767 2.25.589 3.176-.406 2.842-3.068 4.869-4.83 8.51-4.83 2.864 0 6.077 1.934 5.59 4.847-.37 2.223-2.292 3.334-5.4 5.001l-.28.151c-3.582 1.92-8.134 4.362-9.124 10.247l-.16.956c-.055.28-.05.571.014.85.065.278.188.538.362.76.173.222.391.4.639.522.247.121.518.183.79.18h6.744a2.709 2.709 0 0 0 1.712-.688c.481-.426.81-1.01.934-1.657l.095-.556c.266-1.582 2.101-2.59 4.384-3.845ZM32.837 59c3.552 0 6.955-3.034 7.58-6.769.63-3.729-1.742-6.763-5.293-6.763-3.552 0-6.934 3.028-7.58 6.763C26.9 55.966 29.286 59 32.837 59Z",
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            1793649576
          ),
          model: {
            value: _vm.showMenu,
            callback: function ($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "px-6 py-2 pt-3 font-weight-regular card-header",
                  class: _vm.menuColour,
                },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [_c("span", [_vm._v(" Resource Center ")])]),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.showMenu = false
                                },
                              },
                            },
                            [
                              _c("v-icon", { staticClass: "white--text" }, [
                                _vm._v("mdi-chevron-down"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "px-3 pb-2" },
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-list-item",
                        {
                          staticClass: "mt-0",
                          attrs: {
                            link: "",
                            target: "_blank",
                            href: "https://docs.signaloid.io/",
                            disabled: "",
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-2" },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "text-h6",
                                  staticStyle: { transform: "rotate(90deg)" },
                                },
                                [_vm._v("mdi-shoe-print")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            { staticClass: "text-menu font-weight-medium" },
                            [_vm._v("Walkthroughs (blocked by your browser)")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "mt-0",
                          attrs: {
                            link: "",
                            target: "_blank",
                            href: "https://docs.signaloid.io/",
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-2" },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "text-h6 black--text" },
                                [_vm._v("mdi-file-document-outline")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            { staticClass: "text-menu font-weight-medium" },
                            [_vm._v("Documentation")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            link: "",
                            href: "mailto:developer-support@signaloid.com",
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-2" },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "text-h6 black--text" },
                                [_vm._v("mdi-email-outline")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            { staticClass: "text-menu font-weight-medium" },
                            [_c("span", [_vm._v(" Contact us ")])]
                          ),
                          _c("v-icon", [_vm._v("mdi-chevron-right")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }