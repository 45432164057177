
import { defineComponent, ref } from "vue";

// Utilities
import moment, { Moment } from "moment";

// Components
import UsageDetailCard from "@/components/Usage/UsageDetailCard.vue";
import TierMarker from "@/components/Common/TierMarker.vue";
import UpgradeButton from "@/components/Common/UpgradeButton.vue";

// Stores
import { mapState } from "pinia";
import { useDataSourcesStore } from "@/stores/dataSources";
import { useUserStore } from "@/stores/user";
import { useCoresStore } from "@/stores/cores";

// Types
import { AWSAuthenticatedUserObject, UserResourceUsage } from "@/types/user";
import { AccountTierDetail, TierLimitDisplayObject, UserLimitsE } from "@/js/tierconfig";

type ComponentData = {
	summaryUsage: TierLimitDisplayObject[];
	usageResetDay: undefined | Moment;
	authenticatedUser: undefined | null | AWSAuthenticatedUserObject;
};

export default defineComponent({
	name: "UsageSummaryCard",
	components: { UsageDetailCard, TierMarker, UpgradeButton },
	data: (): ComponentData => ({
		summaryUsage: [
			{
				name: "Dynamic Instructions",
				id: UserLimitsE.DynamicInstructionCount,
				usage: 0,
				limit: 0,
				hasNoLimit: false,
				unit: "",
				quotaSuffix: "",
			},
			{
				name: "Concurrent Tasks",
				id: UserLimitsE.ConcurrentTaskCount,
				usage: 0,
				limit: 0,
				unit: "",
				quotaSuffix: "",
			},
			{
				name: "Custom Cores",
				id: UserLimitsE.CoreCount,
				usage: 0,
				limit: 0,
				hasNoLimit: false,
				unit: "",
				quotaSuffix: "",
			},
			{
				name: "Cloud Storage",
				id: UserLimitsE.CloudStorageBytes,
				usage: 0,
				limit: 0,
				hasNoLimit: false,
				unit: "B",
			},
			{
				name: "Distribution Plots",
				id: UserLimitsE.PlotCount,
				usage: 0,
				limit: 0,
				unit: "",
				hasNoLimit: false,
				quotaSuffix: "",
			},
			{
				name: "Data Drives",
				id: UserLimitsE.DataDriveCount,
				usage: 0,
				limit: 0,
				hasNoLimit: false,
				unit: "",
				quotaSuffix: "",
			},
			{
				name: "Sensor Gateways",
				id: UserLimitsE.GatewayCount,
				usage: 0,
				limit: 0,
				hasNoLimit: false,
				unit: "",
				quotaSuffix: "",
			},
		],
		usageResetDay: undefined,
		authenticatedUser: undefined,
	}),
	watch: {
		currentUserResourceUsage: {
			handler: function (newUsage: UserResourceUsage, oldUsage) {
				if (newUsage) {
					this.usageResetDay = moment(newUsage.ResetsAt * 1000);
					this.summaryUsage = this.summaryUsage.map((v) => {
						v.usage = newUsage[v.id];
						return v;
					});
				}
			},
			deep: true,
			immediate: true,
		},
		currentUserTierDetails: {
			handler: function (newLimits: undefined | AccountTierDetail, oldLimits) {
				if (newLimits) {
					const allowance = newLimits.allowance;

					this.summaryUsage = this.summaryUsage.map((v) => {
						v.limit = allowance[v.id];
						return v;
					});
				}
			},
			deep: true,
			immediate: true,
		},
		driveList: {
			handler: function (newValue, oldValue) {
				this.summaryUsage[this.summaryUsage.findIndex((v) => v.id == UserLimitsE.DataDriveCount)].usage =
					newValue.length;
			},
			immediate: true,
		},
		gatewayList: {
			handler: function (newValue, oldValue) {
				this.summaryUsage[this.summaryUsage.findIndex((v) => v.id == UserLimitsE.GatewayCount)].usage =
					newValue.length;
			},
			immediate: true,
		},
	},
	computed: {
		usageResetDayString(): string {
			return this.usageResetDay?.isValid() ? this.usageResetDay.fromNow() : "";
		},
		usageResetDate(): string {
			return this.usageResetDay?.isValid() ? this.usageResetDay.format("MMM Do") : "";
		},
		usageCardSubtitle(): string {
			if (this.usageResetDay && this.usageResetDay.isValid() && this.usageResetDay?.isSameOrAfter(moment())) {
				return `Usage quota resets ${this.usageResetDay.fromNow()} on ${this.usageResetDate}.`;
			} else {
				return "Upgrade to increase your monthly quota.";
			}
		},
		...mapState(useCoresStore, {
			localCoresList: "localCoresList",
		}),
		...mapState(useDataSourcesStore, {
			driveList: "driveList",
			gatewayList: "gatewayList",
		}),
		...mapState(useUserStore, {
			currentUserResourceUsage: "currentUserResourceUsage",
			currentUserTierDetails: "currentUserTierDetails",
			currentUserTierCode: "currentUserTierCode",
		}),
	},
	setup() {
		const usageDetailCard = ref<InstanceType<typeof UsageDetailCard>>();

		// Note: gets called after beforeCreate in vue2
		const dataSourcesStore = useDataSourcesStore();
		const userStore = useUserStore();
		return { dataSourcesStore, userStore, usageDetailCard };
	},
	async created() {
		this.authenticatedUser = await this.userStore.getCurrentAuthenticatedUserObject();
		this.userStore.getCurrentUserTierDetails();
		this.userStore.fetchCurrentUserObjectFromDB();
		await Promise.allSettled([
			this.dataSourcesStore.fetchGatewayList(),
			this.dataSourcesStore.fetchDriveList(),
			this.dataSourcesStore.fetchBucketList(),
		]);
	},
});
