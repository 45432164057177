import { render, staticRenderFns } from "./Profile.vue?vue&type=template&id=9cf38d66&scoped=true"
import script from "./Profile.vue?vue&type=script&lang=ts"
export * from "./Profile.vue?vue&type=script&lang=ts"
import style0 from "./Profile.vue?vue&type=style&index=0&id=9cf38d66&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9cf38d66",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/buildhome/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9cf38d66')) {
      api.createRecord('9cf38d66', component.options)
    } else {
      api.reload('9cf38d66', component.options)
    }
    module.hot.accept("./Profile.vue?vue&type=template&id=9cf38d66&scoped=true", function () {
      api.rerender('9cf38d66', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Settings/Profile.vue"
export default component.exports