var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-icon",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hide,
          expression: "hide",
        },
      ],
      class: "mr-3 " + _vm.headwayWidgetSelector,
    },
    [_vm._v("mdi-bell")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }