
import { PropType, defineComponent } from "vue";
import TooltipButton from "@/components/Common/TooltipButton.vue";
import VideoDialog from "@/components/Home/VideoDialog.vue";
import axios from "axios";
import { SpotlightSlideInfo } from "@/types/home";

type ComponentData = {
	showVideoDialog: boolean;
};

export default defineComponent({
	name: "SpotlightSlide",
	components: { TooltipButton, VideoDialog },
	props: {
		slideData: { type: Object as PropType<SpotlightSlideInfo>, required: true },
	},
	data: (): ComponentData => ({ showVideoDialog: false }),
	computed: {
		slideTag() {
			switch (this.slideData.type) {
				case "application":
					return { text: "Application", colour: "primary" };
				case "caseStudy":
					return { text: "Case Study", colour: "warning" };
				case "blogpost":
					return { text: "Blog Post", colour: "accent" };
				case "research":
					return { text: "Research", colour: "warning" };
				default:
					return undefined;
			}
		},
		applicationURL(): URL | undefined {
			return this.slideData.applicationURL ? new URL(this.slideData.applicationURL) : undefined;
		},
		applicationHostIcon() {
			if (this.applicationURL === undefined) {
				return "";
			}
			switch (this.applicationURL.hostname) {
				case "github.com":
					return "mdi-github";
				default:
					return "mdi-source-repository";
			}
		},
		applicationConnectObject():
			| string
			| {
					name: "Repositories";
					query: { connect: string };
			  } {
			if (this.applicationURL === undefined) {
				return "";
			}

			switch (this.applicationURL.hostname) {
				case "github.com":
					return {
						name: "Repositories",
						query: { connect: this.applicationURL.href },
					};
			}
			return "";
		},
		slideVideoURL(): URL | undefined {
			if (!this.slideData.videoURL) {
				return undefined;
			}

			// check if referring to a bundled asset
			if (this.slideData.videoURL.includes("@public")) {
				return new URL(this.slideData.videoURL.replace("@public", ""), window.location.origin);
			} else {
				return new URL(this.slideData.videoURL, process.env.VUE_APP_ASSET_SERVER_URL);
			}
		},
		slideImageURL(): string | undefined {
			if (!this.slideData || !this.slideData.imageURL) {
				return undefined;
			}

			// check if referring to a bundled asset
			if (this.slideData.imageURL.includes("@public")) {
				//@ts-ignore
				return new URL(this.slideData.imageURL.replace("@public", ""), window.location.origin).href;
			} else if (this.slideData.imageURL.startsWith("/")) {
				const base = process.env.VUE_APP_ASSET_SERVER_URL;
				//@ts-ignore
				return new URL(this.slideData.imageURL, base).href;
			} else if (this.slideData.imageURL.startsWith("@/assets")) {
				// If we got the asset dynamically ex: require(this.slideData.imageURL), would cause a VUE error
				//@ts-ignore
				return require('@/assets/images/overviewVideoThumbnail_illuminatingUncertainty.webp');
			} else {
				//@ts-ignore
				return this.slideData.imageURL;
			}
		},
	},
	methods: {
		// get the video thumbnail from vimeo link if not provided
		async fetchVideoThumbnail() {
			if (this.slideVideoURL !== undefined && this.slideImageURL === undefined) {
				if (this.slideVideoURL.hostname.includes("vimeo.com")) {
					const videoData = await axios.get(
						"https://vimeo.com/api/oembed.json?url=" + this.slideVideoURL.href + "&height=720"
					);
					this.$set(this.slideData, "imageURL", videoData.data.thumbnail_url);
				}
			}
		},
	},
	updated() {
		if (this.slideData?.videoURL) {
			this.fetchVideoThumbnail();
		}
	},
});
