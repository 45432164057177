
import { defineComponent } from "vue";
import axios from "axios";
import VideojsPlayer from "@/components/Common/VideojsPlayer.vue";
import { monitoringCaptureError } from "@/plugins/monitoring";

type VideoOptions = {
	autoplay: boolean;
	controls: boolean;
	fluid: boolean;
	responsive: boolean;
	sources: { src: string }[];
};

export default defineComponent({
	name: "VideoPlayerComponent",
	components: { VideojsPlayer },
	props: {
		videoURL: { type: String, required: true },
		imageURL: { type: String },
	},
	computed: {
		videoOptions(): VideoOptions {
			return {
				autoplay: true,
				controls: true,
				fluid: true,
				responsive: true,
				sources: [
					{
						src: this.videoURL,
					},
				],
			};
		},
	},
	methods: {
		// get the video thumbnail from vimeo link if not provided
		async getVideoThumbnail() {
			if (this.imageURL) {
				return this.imageURL;
				// FIXME: potentially throws .videoURL.includes is not a function
			} else if (this.videoURL.includes("vimeo.com")) {
				try {
					const videoData = await axios.get(
						"https://vimeo.com/api/oembed.json?url=" + this.videoURL + "&height=720"
					);
					return videoData.data.thumbnail_url;
				} catch (error) {
					monitoringCaptureError(error, "Get video thumbnail");
				}
			}
		},
	},
	mounted() {
		// if (this.videoURL) {
		// 	this.imageURL = this.getVideoThumbnail();
		// }
	},
});
