
import { defineComponent } from "vue";

// Components
import CopyToClipboardButton from "@/components/Common/CopyToClipboardButton.vue";
import TooltipButton from "@/components/Common/TooltipButton.vue";

// Stores
import { useUserStore } from "@/stores/user";

export default defineComponent({
	name: "APIKeyCreationDialog",
	components: {
		CopyToClipboardButton,
		TooltipButton,
	},
	data: () => ({
		buttonIsLoading: false,
		email: "",
		showSupportMessage: false,
		keyVisible: false,
	}),
	props: {
		showDialog: { type: Boolean, default: false },
		passedName: {
			type: String,
			default: null,
		},
		passedKey: {
			type: String,
			default: null,
		},
	},
	computed: {},
	setup() {
		const userStore = useUserStore();
		return { userStore };
	},
	methods: {
		toggleKeyVisible() {
			this.keyVisible = !this.keyVisible;
		},
		closeDialog() {
			this.$emit("update:showDialog", false);
		},
	},
});
