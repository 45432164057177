var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      staticClass: "card-grid pa-6",
      attrs: {
        progress: "",
        "min-height": "400px",
        "max-width": "650px",
        width: "100%",
      },
    },
    [
      _c("div", { staticClass: "card-grid-title" }, [
        _c("div", { staticClass: "text-h5" }, [
          _vm._v("Welcome to the Signaloid Cloud Developer Platform"),
        ]),
      ]),
      _c("div", { staticClass: "card-grid-side-image" }, [
        _c("img", {
          staticClass: "side-image",
          attrs: {
            src: require("@/assets/Cores/signaloid-08.2023-graphics-CxChip-green_0.25xZoom.png"),
          },
        }),
      ]),
      _c(
        "v-card-text",
        {
          staticClass: "px-1 card-grid-details d-flex flex-column",
          staticStyle: { "min-width": "300px" },
        },
        [
          _c("div", { staticClass: "text-body-2 mt-2 text--primary" }, [
            _vm._v(
              " The Signaloid Cloud Developer Platform is a development environment for running compute tasks on "
            ),
            _c("strong", [_vm._v("Signaloid uncertainty-tracking processors")]),
            _vm._v(". "),
          ]),
          _c("div", { staticClass: "text-body-2 mt-2 text--primary" }, [
            _vm._v(
              " Use the platform to build applications that run compute tasks on the "
            ),
            _c("strong", [
              _vm._v("Signaloid Cloud Compute Engine using REST API"),
            ]),
            _vm._v(". "),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "card-grid-actions" },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _vm._t("cardActions", function () {
                    return [
                      _c("TooltipButton", {
                        attrs: {
                          color: "accent",
                          width: "200px",
                          "btn-text": "Get started",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("next")
                          },
                        },
                      }),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }