
import { defineComponent, PropType } from "vue";
import { toStringWithByteMagnitude, shortenID } from "@/js/utilities";

import TooltipButton from "@/components/Common/TooltipButton.vue";
import UpgradeButton from "@/components/Common/UpgradeButton.vue";
import CopyToClipboardButton from "@/components/Common/CopyToClipboardButton.vue";
import { createCore } from "@/js/signaloidClient";
import { useCoresStore } from "@/stores/cores";

import { Core, CoreCorrelationType, CoreCorrelationTypeCodeE } from "@/types/api/cores";
import { monitoringCaptureError } from "@/plugins/monitoring";

type ComponentData = {
	syncError: boolean;
	syncingCore: boolean;
	showDetails: boolean;
};
export default defineComponent({
	name: "CoreCard",
	components: { TooltipButton, UpgradeButton, CopyToClipboardButton },
	data: (): ComponentData => ({
		syncError: false,
		syncingCore: false,
		showDetails: false,
	}),
	props: { coreConfig: { type: Object as PropType<Core>, required: true } },
	setup() {
		const coresStore = useCoresStore();
		return { coresStore, shortenID };
	},
	methods: {
		editCoreRequest() {
			this.$emit("edit-core", this.coreConfig);
		},
		deleteCoreRequest() {
			this.$emit("delete-core", this.coreConfig);
		},
		async syncCoreWithCloud() {
			this.syncingCore = true;
			// console.log("(Syncing core with cloud) sync request :>> ", this.coreConfig);

			try {
				const resp = await createCore(this.coreConfig);
				// console.log("(Syncing core with cloud) response :>> ", resp);
				this.$emit("core-synced", this.coreConfig);
				// console.log("(Syncing core with cloud) removing local core :>> ", this.coreConfig.CoreID);
				this.coresStore.removeCoreFromLocalCoresList(this.coreConfig);
			} catch (error) {
				monitoringCaptureError(error, "Sync core with cloud");
				this.syncError = true;
			} finally {
				this.syncingCore = false;
			}
		},
	},
	computed: {
		isEditableLocalCore(): boolean {
			return !this.coreConfig.Owner && !this.coreConfig?.CoreID?.includes("cor_");
		},
		isRestrictedCore(): boolean {
			return this.coreConfig.Disabled ? this.coreConfig.Disabled : false;
		},
		isSupportedCore(): boolean {
			const supportedMicroarchitectures = this.coresStore.availableMicroarchitectures.map((m) => m.Name);
			return supportedMicroarchitectures.includes(this.coreConfig.Microarchitecture);
		},
		correlationTrackingMethodPrettyName(): null | CoreCorrelationType {
			const supportedCorelationTrackingMethods = this.coresStore
				.correlationTrackingSupportedByCore(this.coreConfig.Microarchitecture)
				.find((m) => {
					return m.Code == this.coreConfig.CorrelationTracking;
				});
			if (!supportedCorelationTrackingMethods) {
				return null;
			}
			return supportedCorelationTrackingMethods.Name;
		},
		coreMemorySizeString(): string {
			return toStringWithByteMagnitude(this.coreConfig.MemorySize);
		},
		coreMicroarchitectureStringFormat(): string {
			return this.coresStore.coreMicroarchitectureStringFormat(
				this.coreConfig.Microarchitecture,
				this.coreConfig.Precision
			);
		},
		correlationTrackingChipStyle(): { color: string; icon: string; text: string } {
			const chipStyle = { color: "inactive", icon: "mdi-restart-off", text: "Off" };

			if (!this.coreConfig.CorrelationTracking) {
				return chipStyle;
			}

			chipStyle.color =
				this.coreConfig.CorrelationTracking !== CoreCorrelationTypeCodeE.Disabled ? "accent" : "inactive";
			chipStyle.icon =
				this.coreConfig.CorrelationTracking !== CoreCorrelationTypeCodeE.Disabled
					? "mdi-restart"
					: "mdi-restart-off";

			chipStyle.text =
				this.coreConfig.CorrelationTracking !== CoreCorrelationTypeCodeE.Disabled
					? "Autocorrelation On"
					: "Autocorrelation Off";

			return chipStyle;
		},
		imageStyle(): string {
			if (this.coreConfig.Class === "C0Pro") {
				return "filter: hue-rotate(90deg)";
			} else {
				return "";
			}
		},
	},
});
