var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.$vuetify.breakpoint.mobile ? "95%" : "500px" },
      on: {
        "click:outside": _vm.closeDialog,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeDialog.apply(null, arguments)
        },
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "pb-1",
          attrs: { "data-test": "sig-cloud-storage-create-directory-dialog" },
        },
        [
          _c("v-card-title", { staticClass: "py-3 px-4" }, [
            _vm._v("Create Directory"),
          ]),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "py-2 px-4" },
            [
              _c(
                "v-form",
                {
                  ref: "mainForm",
                  model: {
                    value: _vm.formValid,
                    callback: function ($$v) {
                      _vm.formValid = $$v
                    },
                    expression: "formValid",
                  },
                },
                [
                  _c("v-text-field", {
                    staticClass: "pt-2 mb-n2",
                    attrs: {
                      placeholder: "Directory Name",
                      rules: [
                        _vm.rules.required,
                        _vm.rules.noSpace,
                        _vm.rules.maxSize,
                        _vm.rules.noRestrictedCharacters,
                      ],
                      autofocus: "",
                      outlined: "",
                      dense: "",
                      "data-test": "directory-name-input",
                    },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.preventDefault()
                        return _vm.createDirectory.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.directoryName,
                      callback: function ($$v) {
                        _vm.directoryName = $$v
                      },
                      expression: "directoryName",
                    },
                  }),
                ],
                1
              ),
              _vm.formValid
                ? _c("div", { staticClass: "text-center" }, [
                    _vm._v(" The new directory will be created at "),
                    _c("br"),
                    _c("em", [_vm._v("./" + _vm._s(_vm.absolutePath))]),
                  ])
                : _vm._e(),
              _c(
                "v-expand-transition",
                [
                  _c(
                    "v-alert",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showError,
                          expression: "showError",
                        },
                      ],
                      staticClass: "text-body-2 pa-2 pl-3 mb-0 mt-2",
                      attrs: {
                        icon: false,
                        border: "left",
                        type: _vm.alertType,
                        outlined: "",
                        elevation: "0",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c(
                "v-row",
                {
                  attrs: {
                    "align-content": "center",
                    justify: "end",
                    "no-gutters": "",
                  },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            elevation: "0",
                            width: "160px",
                            disabled: !_vm.formValid,
                            loading: _vm.uploadInProgress,
                            "data-test": "create-directory-button",
                          },
                          on: { click: _vm.createDirectory },
                        },
                        [_vm._v(" Create Directory ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }