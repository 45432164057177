var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "px-1 px-sm-2", attrs: { flat: "" } },
    [
      _c("v-card-title", { staticClass: "pb-3 pt-1 pt-sm-4 px-1 px-sm-4" }, [
        _c("div", { staticClass: "text-h6 font-weight-bold" }, [
          _vm._v("Apps"),
        ]),
      ]),
      _c("v-card-subtitle", { staticClass: "pb-3 pt-1 pt-sm-4 px-1 px-sm-4" }, [
        _vm._v(
          " Examples of interactive web apps whose underlying computations run on the Signaloid Cloud Compute Engine (SCCE). In most cases, the apps are built by taking an existing legacy C/C++ application that has not been explicitly designed to perform uncertainty quantification, and pairing this computational kernel, running on the SCCE with its automated uncertainty tracking, with a user interface built using a template which Signaloid provides for free to customers. The selection of apps below which you can run is limited by your subscription tier and available subscription tier resource limits. The complete source for these web apps are available upon request by contacting "
        ),
        _c(
          "a",
          {
            staticStyle: { "white-space": "nowrap" },
            attrs: { href: "mailto: developer-support@signaloid.com" },
          },
          [_vm._v("developer-support@signaloid.com")]
        ),
        _vm._v(". "),
      ]),
      _c("v-divider", { staticClass: "mb-2 mb-sm-3 mx-1 mx-sm-4" }),
      _c(
        "v-container",
        {
          staticClass: "card-grid px-sm-4 px-1 py-sm-2 py-1 mx-auto",
          attrs: { fluid: "" },
        },
        _vm._l(_vm.activeDemos, function (demo) {
          return _c(
            "v-card",
            {
              key: demo,
              staticClass: "d-flex flex-column m-4",
              staticStyle: { overflow: "hidden" },
              attrs: { rounded: "lg", elevation: "0", outlined: "" },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "v-img",
                    {
                      staticClass: "align-end text-white",
                      attrs: {
                        height: "120px",
                        src: demo.bannerImage,
                        gradient:
                          "to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.1)",
                      },
                    },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticStyle: {
                            "word-break": "break-word",
                            color: "white",
                          },
                        },
                        [_vm._v(_vm._s(demo.title))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-subtitle",
                { staticStyle: { "word-break": "break-word" } },
                [
                  _c(
                    "v-chip",
                    {
                      attrs: { "x-small": "", color: "primary", outlined: "" },
                    },
                    [_vm._v(" " + _vm._s(demo.requiredCore) + " ")]
                  ),
                  _c(
                    "v-chip",
                    {
                      staticClass: "ml-2",
                      attrs: { "x-small": "", color: "success", outlined: "" },
                    },
                    [_vm._v(" " + _vm._s(demo.atomicNetwork) + " ")]
                  ),
                ],
                1
              ),
              _c("v-card-text", [
                _c("div", {
                  domProps: { innerHTML: _vm._s(demo.description) },
                }),
              ]),
              _c("v-spacer"),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-end" },
                [
                  demo.active
                    ? [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "primary",
                              elevation: "0",
                              small: "",
                              width: "120px",
                              height: "26px",
                              "data-test": "commit-core-button",
                              href: _vm.getUrlBasedOnEnv(demo),
                              target: "_blank",
                            },
                          },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "mr-2", attrs: { small: "" } },
                              [_vm._v("mdi-open-in-new")]
                            ),
                            _vm._v(" Go to App "),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c("UpgradeButton", {
                          attrs: {
                            "btn-text": "Upgrade To Access",
                            height: "26px",
                          },
                        }),
                      ],
                ],
                2
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }