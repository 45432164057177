<template>
	<v-card
		rounded="lg"
		outlined
		class="pa-2 pa-sm-3"
	>
		<template v-if="gatewayData">
			<v-row no-gutters>
				<v-col align-self="baseline">
					<v-icon class="mr-1">{{ dataSourceIcon("Gateway") }}</v-icon>
					<v-tooltip
						bottom
						:allow-overflow="true"
					>
						<template v-slot:activator="{ on, attrs }">
							<span
								v-bind="attrs"
								v-on="on"
							>
								{{ gatewayData.Name }}
							</span>
						</template>
						<div>
							Gateway Local IP Address: {{ gatewayIPAddress }}<br />
							Unique Source ID: {{ gatewayData.ThingID }}
						</div>
					</v-tooltip>
					<v-tooltip
						bottom
						open-delay="250"
					>
						<template v-slot:activator="{ on, attrs }">
							<a target="_blank">
								<v-icon
									class="pl-1"
									v-bind="attrs"
									v-on="on"
									@click="showEditNameDialog = true"
									small
									>mdi-pencil</v-icon
								>
							</a>
						</template>
						<span>Edit Gateway Name</span>
					</v-tooltip>
				</v-col>
				<v-col
					cols="auto"
					class="mx-2"
					align-self="start"
				>
					<template v-if="gatewayStatusLoading">
						<v-progress-circular
							indeterminate
							color="primary"
							:size="20"
							:width="2"
							class="mb-1"
						></v-progress-circular>
					</template>
					<template v-else>
						<v-tooltip
							top
							open-delay="500"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									outlined
									size="20"
									:color="gatewayStatusIcon.colour"
									class="mb-1 mt-0 pt-0"
									v-bind="attrs"
									v-on="on"
								>
									{{ gatewayStatusIcon.icon }}</v-icon
								>
							</template>
							<span
								>{{ gatewayStatus }} <br />
								Last active - {{ gatewayLastUpdatedString }}</span
							>
						</v-tooltip>
					</template>

					<v-tooltip
						top
						open-delay="500"
					>
						<template v-slot:activator="{ on, attrs }">
							<div
								class="text-body-2"
								v-bind="attrs"
								v-on="on"
								style="display: inline"
							>
								{{ gatewayIPAddress }}
							</div>
						</template>
						<span>Gateway Local IP address</span>
					</v-tooltip>
				</v-col>
			</v-row>

			<v-row
				class="text-body-2 mt-1 ml-1"
				no-gutters
			>
				<v-col class="ml-0 ml-sm-2">
					<div
						v-if="!$vuetify.breakpoint.mobile"
						class="mr-3"
					>
						<v-icon
							class="mr-1"
							small
							>mdi-checkbox-marked-circle-plus-outline</v-icon
						>Created: {{ formatDate(gatewayData.CreatedAt) }}
					</div>
					<div class="mr-3">
						<v-icon
							class="mr-1"
							small
							>mdi-update</v-icon
						>Last Updated: {{ formatDate(gatewayData.UpdatedAt) }}
					</div>
				</v-col>
				<v-col
					:cols="$vuetify.breakpoint.mobile ? '12' : 'auto'"
					class="pt-1 pt-sm-0"
					align-self="end"
				>
					<v-row
						no-gutters
						justify="end"
					>
						<!-- <v-col
							class="mx-1"
							cols="auto"
						>
							<TooltipButton
								color="accent"

								width="50px"
								disabled
								btnIcon="mdi-cast"
								tooltipText="View Connected Sensors"
							></TooltipButton>
						</v-col> -->
						<v-col cols="auto">
							<TooltipButton
								color="accent"
								width="50px"
								@click="showGatewayDataStreamsDialog = true"
								btnIcon="mdi-archive-eye-outline"
								tooltipText="View Data Stream"
							></TooltipButton>
						</v-col>
						<div class="mx-1"></div>
						<v-col
							cols="auto"
							align="end"
						>
							<TooltipButton
								color="primary"
								width="50px"
								:disabled="!gatewayIPKnown"
								target="_blank"
								:exact="true"
								:href="`http://${gatewayIPAddress}:5000`"
								btnIcon="mdi-cog-outline"
							>
								<template #tooltipContent
									><span>
										<v-icon
											class="mr-1"
											color="white"
											small
											>mdi-open-in-new</v-icon
										>
										Configure Gateway
									</span></template
								>
							</TooltipButton>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</template>
		<v-skeleton-loader
			v-else
			type="list-item-avatar"
		></v-skeleton-loader>
		<EditValueDialog
			v-if="showEditNameDialog"
			title="Set Gateway Name"
			valueHint="Gateway Name"
			:showDialog.sync="showEditNameDialog"
			:value="gatewayData.Name"
			@update:value="setGatewayName"
		></EditValueDialog>
		<GatewayDataStreamsDialog
			v-if="showGatewayDataStreamsDialog"
			:showDialog.sync="showGatewayDataStreamsDialog"
			:gatewayID="gatewayID"
		></GatewayDataStreamsDialog>
	</v-card>
</template>

<script>
import { defineComponent } from "vue";
import { getThingByID, updateThingName } from "@/js/signaloidClient";
import EditValueDialog from "@/components/DataSources/EditValueDialog.vue";
import GatewayDataStreamsDialog from "@/components/DataSources/GatewayDataStreamsDialog.vue";
import * as dsUtil from "@/components/DataSources/utilities";
import TooltipButton from "@/components/Common/TooltipButton.vue";

export default defineComponent({
	name: "GatewayCard",
	components: { GatewayDataStreamsDialog, EditValueDialog, TooltipButton },
	props: {
		gatewayID: {
			type: String,
			default: "",
		},
	},
	data: () => ({
		gatewayData: undefined,
		showEditNameDialog: false,
		showGatewayDataStreamsDialog: false,
		gatewayStatus: "Disconnected",
		gatewayStatusIcon: {},
		gatewayStatusLoading: false,
		gatewayLastUpdated: "",
	}),
	methods: {
		formatDate(dtString) {
			return dsUtil.formatDateDayString(1000 * dtString);
		},
		getGatewayInfo() {
			return getThingByID(this.gatewayID)
				.then((response) => {
					this.gatewayData = response.data;
					this.getGatewayStatus();
				})
				.catch((error) => console.log(error));
		},

		// * setters
		async setGatewayName(newGatewayName) {
			updateThingName(this.gatewayID, newGatewayName)
				.then((response) => {
					this.getGatewayInfo();
				})
				.catch((error) => console.log(error));
		},
		async getGatewayStatus() {
			this.gatewayStatusLoading = true;
			this.gatewayLastUpdated = await dsUtil.gatewayLastUpdated(this.gatewayData);
			this.gatewayStatus = dsUtil.gatewayStatusFromLastUpdated(this.gatewayLastUpdated);
			this.gatewayStatusIcon = dsUtil.gatewayStatusIcon(this.gatewayStatus);
			if (this.gatewayStatusIcon) {
				this.gatewayStatusLoading = false;
			}
		},
		dataSourceIcon(dataSourceType) {
			return dsUtil.dataSourceIcon(dataSourceType);
		},
	},
	computed: {
		gatewayIPAddress() {
			return dsUtil.gatewayIPAddressString(this.gatewayData);
		},
		gatewayIPKnown() {
			return dsUtil.gatewayIPKnown(this.gatewayData);
		},
		gatewayLastUpdatedString() {
			return dsUtil.formatDateDayString(1000 * this.gatewayLastUpdated);
		},
	},
	mounted() {
		this.getGatewayInfo();
	},
});
</script>

<style></style>
