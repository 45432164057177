var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "px-1 px-sm-2", attrs: { flat: "" } },
    [
      _c(
        "v-card-title",
        {
          staticClass: "pb-3 pt-1 pt-sm-4 px-1 px-sm-4",
          staticStyle: { "min-height": "64px" },
        },
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("div", { staticClass: "text-h6 font-weight-bold" }, [
                  _vm._v("Settings"),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider", { staticClass: "mb-2 mb-sm-3 mx-1 mx-sm-4" }),
      _c(
        "v-container",
        {
          staticClass: "px-sm-4 px-1 py-sm-2 py-1 mx-auto settings-grid",
          attrs: { fluid: true },
        },
        [
          _c(
            "div",
            { staticClass: "nav-grid-area" },
            [
              _c(
                "v-list",
                {
                  staticClass: "px-0 px-sm-2",
                  attrs: { nav: "", dense: "", subheader: "" },
                },
                [
                  _c(
                    "v-list-item-group",
                    {
                      attrs: { value: _vm.selectedSettingsPage, mandatory: "" },
                    },
                    _vm._l(_vm.settingsNavItems, function (navItem, i) {
                      return _c(
                        "div",
                        { key: i },
                        [
                          navItem.type == "heading"
                            ? _c(
                                "div",
                                [
                                  _c("v-subheader", [
                                    _vm._v(_vm._s(navItem.text)),
                                  ]),
                                  _c("v-divider", { staticClass: "mb-1" }),
                                ],
                                1
                              )
                            : _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: navItem.disabled ? "" : navItem.to,
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      staticClass: "list-item",
                                      attrs: {
                                        disabled: navItem.disabled,
                                        "active-class": "active-item text-bold",
                                        "data-test": `settings-navbar-item-${navItem.text.toLowerCase()}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        { staticClass: "mx-auto my-auto" },
                                        [
                                          _c("v-icon", {
                                            attrs: { small: "" },
                                            domProps: {
                                              textContent: _vm._s(navItem.icon),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        { staticClass: "py-0" },
                                        [
                                          _c("v-list-item-title", {
                                            domProps: {
                                              textContent: _vm._s(navItem.text),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "details-grid-area px-0 px-sm-3" },
            [_c("router-view")],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }