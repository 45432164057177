
import { defineComponent } from "vue";
import CopyToClipboardButton from "@/components/Common/CopyToClipboardButton.vue";
import * as util from "@/js/utilities";

export default defineComponent({
	name: "TaskIDDisplay",
	components: {
		CopyToClipboardButton,
	},
	props: {
		taskID: { type: String },
		link: { type: Object },
		showClipboardButton: { type: Boolean, default: false },
		// { name: 'TaskDetail', params: { id: this.projectID }}
	},
	computed: {
		formattedTaskID(): string {
			return this.taskID ? util.shortenID(this.taskID) : "";
		},
	},
});
