<template>
	<div
		ref="editor"
		id="editor-placeholder-container"
		class="editor-placeholder-container"
		:style="style"
	></div>
</template>

<script>
import { defineComponent } from "vue";
import * as monaco from "monaco-editor";
import { mapState } from "pinia";
import { useEditorStore } from "@/stores/editor";

const codePlaceholderText = String.raw`#include <stdio.h>

int
main(int argc, char *  argv[])
{
	printf("Hello, World!\n");
	return 0;
}`;

// const codePlaceholderText = String.raw`#include <stdio.h>

// int
// main(int argc, char *  argv[])
// {
// 	printf("The answer to the ultimate question...\n")
// 	printf("of life, the universe, and everything\n")
// 	printf("is %lf.\n", UxHwDoubleGaussDist(42, 0x7ff0000000000000));
// 	return 0;
// }`;
export default defineComponent({
	name: "EditorPlaceholder",
	props: {
		width: { type: String, default: "100%" },
		height: { type: String, default: "100%" },
		value: { type: String, default: codePlaceholderText },
		options: { type: Object, default: () => ({}) },
		theme: { type: String, required: true },
	},
	watch: {
		value(newValue) {
			if (this.editor) {
				const previousValue = this.editor.getValue();
				if (newValue !== previousValue) {
					this.editor.setValue(newValue);
				}
			}
		},
		theme: {
			handler: function (newTheme) {
				if (!this.loadedThemes.includes(newTheme)) {
					this.loadTheme(newTheme);
				}
				this.setTheme(newTheme);
			},
			immediate: true,
		},
	},
	data: () => ({
		editor: "",
		resizeHandlerBinding: undefined,
		elementObserverBinding: undefined,
		loadedThemes: [],
		uxhwCompletionItemProvider: undefined,
		uxhwHoverProvider: undefined,
	}),
	setup() {
		const editorStore = useEditorStore();
		return { editorStore };
	},
	computed: {
		style() {
			return {
				width: !/^\d+$/.test(this.width) ? this.width : `${this.width}px`,
				height: !/^\d+$/.test(this.height) ? this.height : `${this.height}px`,
			};
		},
		...mapState(useEditorStore, {
			// theme: "theme",
			themeList: "rawThemeList",
		}),
	},
	mounted() {
		const options = {
			value: this.value,
			language: "c",
			fontFamily: '"Roboto Mono", "Courier New", Courier, monospace',
			fontSize: 10,
			theme: this.theme,
			mouseWheelZoom: false,
			tabCompletion: false,
			minimap: {
				enabled: true,
			},
			selectOnLineNumbers: false,
			domReadOnly: true,
			readOnly: true,
			suggestOnTriggerCharacters: false,
			snippetSuggestions: false,
			quickSuggestions: false,
			wordBasedSuggestions: false,
			scrollBeyondLastLine: false,
			lineNumbers: true,
			selectionHighlight: false,
			padding: { top: "10px" },
			scrollbar: { vertical: "hidden", horizontal: "hidden" },
		};

		this.editor = monaco.editor.create(this.$el, options);
		this.editor?.setPosition({ column: 1, lineNumber: 6 });

		/*
		 *  Add resize listener. Monaco editor doesn't auto-resize. Must keep
		 *  the binding reference to be able to remove the handler in
		 *  beforeDestroy.
		 */
		this.resizeHandlerBinding = this.resizeHandler.bind(this);
		window.addEventListener("resize", this.resizeHandlerBinding);

		if (this.elementObserverBinding == undefined) {
			this.elementObserverBinding = new MutationObserver((mutations, observer) => {
				for (const mutation of mutations) {
					if (mutation.type === "attributes") {
						// console.log(mutation);
						/*
						 *  Check if mutation was resize
						 */
						this.resizeHandler();
						/*
						 *  Check if mutation was fontChange (to save current level).
						 */
						// TODO
					}
				}
			});
		}

		this.elementObserverBinding.observe(this.$el, {
			attributes: true,
			attributeOldValue: true,
		});

		/*
		 *  Add editor content change listener to propagate change through
		 *  v-model.
		 */
		// this.editor.onDidChangeModelContent((e) => {
		// 	const value = this.editor.getValue();
		// 	if (this.value !== value) {
		// 		this.$emit("input", value, e);
		// 		this.$emit("change", value, e);
		// 	}
		// });
		this.setTheme();
	},
	updated() {
		this.editor.trigger();
	},
	beforeDestroy() {
		this.uxhwCompletionItemProvider?.dispose();
		this.uxhwHoverProvider?.dispose();
		this.editor?.dispose();
		if (this.resizeHandlerBinding) {
			window.removeEventListener("resize", this.resizeHandlerBinding);
			this.resizeHandlerBinding = undefined;
		}
		if (this.elementObserverBinding) {
			this.elementObserverBinding.disconnect();
		}
	},
	methods: {
		resizeHandler() {
			// console.log('handler');
			this.editor?.layout();
		},
		loadTheme(key) {
			if (!(key in this.themeList)) {
				// reset to default theme
				this.editorStore.setThemeToDefault(false);
				return;
			}
			const filename = this.themeList[key];
			const theme = require(`@/assets/editor-themes/${filename}.json`);
			monaco.editor.defineTheme(key, theme);
		},
		setTheme() {
			// this.editor.defineTheme('github', githubtheme);
			/*
			 *  Check if theme is loaded.
			 */
			// console.log('Setting theme to', this.theme);
			monaco.editor.setTheme(this.theme);
		},
		// goFullscreen() {
		// 	// console.log('Going fullscreen!');
		// 	if (screenfull.isEnabled) {
		// 		screenfull
		// 			.request(this.$el)
		// 			.then(() => {
		// 				this.resizeHandler();
		// 			})
		// 			.catch((e) => {
		// 				console.log(e);
		// 			});
		// 	}
		// },
	},
});
</script>

<style scoped>
.editor-placeholder-container {
	pointer-events: none;
}
</style>

<style>
*|*:full-screen:not(:root),
*|*:fullscreen:not(:root) {
	position: fixed !important;
	top: 0 !important;
	left: 0 !important;
	right: 0 !important;
	bottom: 0 !important;
	width: 100% !important;
	height: 100% !important;
	margin: 0 !important;
	min-width: 0 !important;
	max-width: none !important;
	min-height: 0 !important;
	max-height: none !important;
	box-sizing: border-box !important;
	object-fit: contain;
	transform: none !important;
}
</style>
