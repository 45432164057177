
import { defineComponent } from "vue";

// Types

// Utilities

// Components
import SpotlightCarousel from "@/components/Home/SpotlightCarousel.vue";
import ApplicationSlide from "@/components/Home/ApplicationSlide.vue";
import UsageLineChart from "@/components/Home/UsageLineChart.vue";
import UsageSummaryCard from "@/components/Home/UsageSummaryCard.vue";

// Stores
import { useUserStore } from "@/stores/user";
import { useRootStore } from "@/stores/root";
import { mapActions, mapState } from "pinia";

type ComponentData = {
	usageHistoryAvailable: boolean;
	changelogIFrameURL: string;
};

export default defineComponent({
	name: "Home",
	components: {
		SpotlightCarousel,
		ApplicationSlide,
		UsageLineChart,
		UsageSummaryCard,
	},
	data: (): ComponentData => ({
		usageHistoryAvailable: false,
		changelogIFrameURL: "https://headway-widget.net/widgets/JlO5Q7",
	}),
	setup() {
		const userStore = useUserStore();
		return { userStore };
	},
	computed: {
		...mapState(useRootStore, { spotlightList: "spotlightList", applicationsList: "applicationsList" }),
	},
	methods: {
		slideMargin(i: number, total: number) {
			if (i == 0) {
				return "mr-1 ml-0";
			} else if (i == total - 1) {
				return "mr-0 ml-1";
			} else {
				return "mx-1";
			}
		},
	},
});
