var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0" },
    [
      _c(
        "v-menu",
        {
          ref: "dateSelectionMenuVisible",
          attrs: {
            "close-on-content-click": false,
            transition: "scale-transition",
            "offset-y": "",
            left: "",
            "max-width": "290px",
            "min-width": "auto",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      _vm._b(
                        {
                          ref: "datePickerTextInput",
                          staticClass: "mb-n2",
                          attrs: {
                            "data-test": "date-picker-text-input",
                            outlined: "",
                            dense: "",
                          },
                          on: {
                            blur: _vm.textFieldBlurHandler,
                            input: _vm.textFieldInputHandler,
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return $event.target.blur()
                            },
                          },
                          model: {
                            value: _vm.textFieldDate,
                            callback: function ($$v) {
                              _vm.textFieldDate = $$v
                            },
                            expression: "textFieldDate",
                          },
                        },
                        "v-text-field",
                        { ..._vm.$attrs, ...attrs },
                        false
                      ),
                      { ..._vm.$listeners, ...on }
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dateSelectionMenuVisible,
            callback: function ($$v) {
              _vm.dateSelectionMenuVisible = $$v
            },
            expression: "dateSelectionMenuVisible",
          },
        },
        [
          _c("v-date-picker", {
            attrs: {
              color: "accent",
              "no-title": "",
              "allowed-dates": _vm.allowedDates,
              "first-day-of-week": "1",
            },
            on: { input: _vm.datePickerInputHandler },
            model: {
              value: _vm.datePickerPickedDate,
              callback: function ($$v) {
                _vm.datePickerPickedDate = $$v
              },
              expression: "datePickerPickedDate",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }