var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "px-1 px-sm-2 home", attrs: { flat: "", id: "home" } },
    [
      _c(
        "v-container",
        {
          staticClass: "px-1 px-sm-4 py-sm-2 py-1 mx-auto",
          attrs: { fluid: "" },
        },
        [
          _c("div", { staticClass: "home-grid" }, [
            _c(
              "div",
              { staticClass: "grid-spotlight" },
              [
                _c("SpotlightCarousel", {
                  attrs: { spotlightSlides: _vm.spotlightList },
                }),
              ],
              1
            ),
            _c("h3", { staticClass: "grid-applications-title text" }, [
              _vm._v("Examples on GitHub"),
            ]),
            _c(
              "div",
              { staticClass: "grid-applications pb-3" },
              [
                _vm.applicationsList.length > 0
                  ? _vm._l(
                      _vm.applicationsList.slice(0, 4),
                      function (application, i) {
                        return _c(
                          "ApplicationSlide",
                          { key: i, attrs: { slideInfo: application } },
                          [_vm._v("y ")]
                        )
                      }
                    )
                  : _vm._l([1, 2, 3, 4], function (i) {
                      return _c(
                        "v-card",
                        { key: i, attrs: { outlined: "" } },
                        [
                          _c("v-skeleton-loader", {
                            attrs: {
                              height: "125px",
                              type: " list-item, actions",
                            },
                          }),
                        ],
                        1
                      )
                    }),
              ],
              2
            ),
            _c(
              "div",
              {
                staticClass: "grid-usage-history-title",
                class: !_vm.$vuetify.breakpoint.xl
                  ? ""
                  : "headway-topbar-style",
              },
              [
                _c(
                  "h3",
                  { class: !_vm.$vuetify.breakpoint.xl ? "" : "headway-title" },
                  [_vm._v("Usage History")]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "grid-usage-history" },
              [
                _c(
                  "v-card",
                  {
                    staticClass: "py-",
                    attrs: {
                      flat: "",
                      rounded: "lg",
                      height: "100%",
                      "min-height": "175px",
                      outlined: "",
                    },
                  },
                  [_c("UsageLineChart")],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "grid-quota-title",
                class: !_vm.$vuetify.breakpoint.xl
                  ? ""
                  : "headway-topbar-style",
              },
              [
                _c(
                  "h3",
                  { class: !_vm.$vuetify.breakpoint.xl ? "" : "headway-title" },
                  [_vm._v("Usage Quotas")]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "grid-usage-quota" },
              [_c("UsageSummaryCard")],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }