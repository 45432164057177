var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: {
        scrollable: "",
        width: _vm.$vuetify.breakpoint.mobile ? "95%" : "500px",
      },
      on: {
        "click:outside": _vm.closeDialog,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeDialog.apply(null, arguments)
        },
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pb-1" },
        [
          _c(
            "v-card-title",
            { staticClass: "py-3 px-4" },
            [
              _vm._v("Gateway Data Streams "),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "text--primary",
                  attrs: {
                    color: "white",
                    small: "",
                    outlined: "",
                    height: "36",
                    elevation: "0",
                  },
                  on: { click: _vm.closeDialog },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            {
              staticClass: "py-2 px-1",
              staticStyle: { "max-width": "1000px" },
            },
            [
              _vm.loadingFiles
                ? _c("LoadingCard", {
                    attrs: { loadingText: "Loading Data Stream" },
                  })
                : _c(
                    "div",
                    [
                      _vm.dataFileList && _vm.dataFileList.length > 0
                        ? _c(
                            "v-list",
                            {
                              attrs: {
                                "max-width": "500",
                                dense: "",
                                flat: "",
                              },
                            },
                            _vm._l(_vm.dataFileList, function (file, i) {
                              return _c(
                                "v-list-item",
                                { key: i },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-file-chart-outline"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.formatFilePath(file.path)
                                          ),
                                        },
                                      }),
                                      _c("v-list-item-subtitle", [
                                        _vm._v(
                                          "Updated at " +
                                            _vm._s(
                                              _vm.formatDate(file.updated_at)
                                            )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _c(
                            "v-alert",
                            {
                              staticClass: "d-flex justify-center my-5 mx-auto",
                              attrs: {
                                outlined: "",
                                width: "80%",
                                type: "warning",
                                dense: "",
                                border: "left",
                              },
                            },
                            [_vm._v(" No data streams found... ")]
                          ),
                    ],
                    1
                  ),
            ],
            1
          ),
          _vm.selectable
            ? [
                _c("v-divider"),
                _c(
                  "v-card-actions",
                  [
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "primary",
                          elevation: "0",
                          width: "150px",
                        },
                      },
                      [_vm._v(" Set Data Source ")]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }