
import { defineComponent, ref } from "vue";

// Types
import { AWSAuthenticatedUserObject } from "@/types/user";

// Components
import GenerateAPIKey from "@/components/Settings/GenerateAPIKey.vue";
import ListAPIKeys from "@/components/Settings/ListAPIKeys.vue";

// Stores
import { useUserStore } from "@/stores/user";

type ComponentData = {
	deleteAccountDialog: boolean;
	authenticatedUser: undefined | null | AWSAuthenticatedUserObject;
};

export default defineComponent({
	name: "SettingsCloudEngineAPI",
	props: {},
	components: {
		GenerateAPIKey,
		ListAPIKeys,
	},
	data: (): ComponentData => ({
		deleteAccountDialog: false,
		authenticatedUser: undefined,
	}),
	setup() {
		const userStore = useUserStore();
		const listAPIKeysComponent = ref<InstanceType<typeof ListAPIKeys>>();
		return { userStore };
	},
	methods: {
		handleNewAPIKeyCreated() {
			this.$refs.listAPIKeysComponent!["refreshList"]();
		},
	},
	async created() {
		const authUser = await this.userStore.getCurrentAuthenticatedUserObject();
		this.authenticatedUser = authUser;
	},
});
