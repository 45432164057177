
import { defineComponent } from "vue";

// Utilities
import { signaloidApiClient } from "@/js/signaloidClient";

// Components
import AddRepositoryCard from "@/components/Repositories/AddRepositoryCard.vue";
import IntersectionObserver from "@/components/IntersectionObserver.vue";
import EndOfRepoListMarker from "./EndOfRepoListMarker.vue";
import LoadingCard from "@/components/LoadingCard.vue";

// Stores
import { useGithubStore } from "@/stores/github";
import { useUserStore } from "@/stores/user";
import { Endpoints } from "@octokit/types";
import { RepoConnectionConfig } from "@/types/general";

type ComponentData = {
	repositoriesPerPage: number;
	repositoriesPage: number;
	fullRepoList: Endpoints["GET /user/repos"]["response"]["data"];
	displayRepoList: Endpoints["GET /user/repos"]["response"]["data"];
	loadingRepos: boolean;
	endOfRepoList: boolean;
	searchQuery: undefined | string;
	searchingRepos: boolean;
	showSearchBarEnabled: boolean;
};

export default defineComponent({
	name: "GithubRepoListDialog",
	components: { AddRepositoryCard, IntersectionObserver, LoadingCard, EndOfRepoListMarker },
	props: { dialogVisible: Boolean },
	data: (): ComponentData => ({
		repositoriesPerPage: 50,
		repositoriesPage: 0,
		fullRepoList: [],
		displayRepoList: [],
		loadingRepos: false,
		endOfRepoList: false,
		searchQuery: undefined,
		searchingRepos: false,
		showSearchBarEnabled: false,
	}),
	emits: {
		"close-dialog": () => true,
		"repo-connection-request": (data: RepoConnectionConfig) => true,
		"repository-disconnected": (data: { repositoryID: string }) => true,
	},
	setup() {
		const githubStore = useGithubStore();
		const userStore = useUserStore();
		return { githubStore, userStore };
	},
	methods: {
		loadNextPage() {
			this.loadingRepos = true;
			this.fetchNextRepositoryBatch().then(() => {
				this.loadingRepos = false;
				this.displayRepoList = this.fullRepoList;
			});
		},
		fetchNextRepositoryBatch() {
			this.repositoriesPage += 1;

			return signaloidApiClient
				.get<Endpoints["GET /user/repos"]["response"]["data"]>(
					`proxy/github/user/repos?per_page=${this.repositoriesPerPage}&page=${this.repositoriesPage}`
				)
				.then((response) => {
					if (response.data.length < this.repositoriesPerPage) {
						this.endOfRepoList = true;
					}
					this.fullRepoList = [...this.fullRepoList, ...response.data];
				})
				.catch((error) => console.log(error));
		},
		toggleSearchBar() {
			if (this.showSearchBarEnabled) {
				this.showSearchBarEnabled = false;
			} else {
				// if (!this.$vuetify.breakpoint.mobile) {
				this.showSearchBarEnabled = true;
				// }
			}
		},
		async fetchAllRepositories() {
			while (!this.endOfRepoList) {
				await this.fetchNextRepositoryBatch();
			}
			// console.log("all repositories fetched...");
		},
		async searchUserRepositories() {
			if (!this.searchQuery) {
				this.displayRepoList = this.fullRepoList;
			} else {
				this.searchingRepos = true;
				await this.fetchAllRepositories();

				this.displayRepoList = this.fullRepoList.filter((repo) =>
					// @ts-ignore: need to bind the "this" context correctly
					repo?.full_name.toLowerCase().includes(this.searchQuery.toLowerCase())
				);

				this.searchingRepos = false;
			}
		},
		closeDialog() {
			this.$emit("close-dialog");
		},
		requestRepoConnect(data: RepoConnectionConfig) {
			this.$emit("repo-connection-request", data);
		},
		repoDisconnectionEventHandler(data) {
			this.$emit("repository-disconnected", data);
		},
	},
	computed: {
		showNoResultsBanner(): boolean {
			return (
				!this.loadingRepos &&
				!this.searchingRepos &&
				this.displayRepoList !== undefined &&
				this.displayRepoList.length == 0
			);
		},
		showSearchBar(): boolean {
			if (this.showSearchBarEnabled && this.$vuetify.breakpoint.mobile) {
				return true;
			} else {
				return false;
			}
		},
	},
	created() {
		this.userStore.getCurrentUserTierDetails();
	},
	mounted() {
		if (this.githubStore.githubLoggedIn) {
			this.loadNextPage();
		}
	},
});
