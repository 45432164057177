var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      staticClass: "mx-0 px-0",
      attrs: {
        width: _vm.$vuetify.breakpoint.smAndDown ? "95%" : "500px",
        persistent: "",
      },
      model: {
        value: _vm.dialogVisible,
        callback: function ($$v) {
          _vm.dialogVisible = $$v
        },
        expression: "dialogVisible",
      },
    },
    [
      _c(
        "v-alert",
        {
          staticClass: "mx-auto mb-0 px-sm-2 px-1",
          attrs: {
            icon: false,
            type: _vm.alertType,
            rounded: "lg",
            elevation: "0",
            border: "top",
            "colored-border": "",
          },
        },
        [
          _c("div", { staticClass: "text-center text-h5 pa-1 my-1" }, [
            _vm._v("Connecting to repository"),
          ]),
          _c("div", { staticClass: "text-center text-h7 pa-1 mb-3" }, [
            _vm._v(_vm._s(_vm.fullNameOfRepoToConnect)),
          ]),
          _c(
            "div",
            [
              _c(
                "v-slide-y-transition",
                { staticClass: "py-0", attrs: { group: "" } },
                [
                  _vm._l(_vm.authTasks, function (authTask, i) {
                    return [
                      _c(
                        "v-list-item",
                        { key: `${i}-${authTask.text}` },
                        [
                          _c("div", {
                            staticClass: "ml-1",
                            class:
                              _vm.authTaskStatusColor(authTask.status) +
                              "--text",
                            domProps: { textContent: _vm._s(authTask.text) },
                          }),
                          _c("v-spacer"),
                          _c(
                            "v-scale-transition",
                            { attrs: { "hide-on-leave": "" } },
                            [
                              authTask.status === _vm.TaskStatusE.Success
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color: _vm.authTaskStatusColor(
                                          authTask.status
                                        ),
                                      },
                                    },
                                    [_vm._v(" mdi-check ")]
                                  )
                                : authTask.status == _vm.TaskStatusE.Fail
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color: _vm.authTaskStatusColor(
                                          authTask.status
                                        ),
                                      },
                                    },
                                    [_vm._v(" mdi-close ")]
                                  )
                                : authTask.status == _vm.TaskStatusE.Ignored
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color: _vm.authTaskStatusColor(
                                          authTask.status
                                        ),
                                      },
                                    },
                                    [_vm._v(" mdi-minus ")]
                                  )
                                : _c("v-progress-circular", {
                                    attrs: {
                                      width: 3,
                                      size: 25,
                                      color: _vm.authTaskStatusColor(
                                        authTask.status
                                      ),
                                      indeterminate: "",
                                    },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "v-expand-transition",
            [
              _c(
                "AlertMessage",
                { attrs: { "show-alert": _vm.showError, type: "error" } },
                [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
              ),
            ],
            1
          ),
          _c(
            "v-expand-transition",
            [
              _vm.showError
                ? _c(
                    "v-row",
                    { staticClass: "mx-5 my-3" },
                    [
                      _c("v-spacer"),
                      !_vm.githubStore.githubLoggedIn
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mr-2 text--primary",
                              attrs: {
                                color: "white",
                                elevation: "0",
                                width: _vm.$vuetify.breakpoint.mobile
                                  ? "100px"
                                  : "120px",
                                loading: _vm.forceAddLoading,
                                outlined: "",
                              },
                              on: { click: _vm.githubLogin },
                            },
                            [_vm._v(" Github Login ")]
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          staticClass: "text--primary",
                          attrs: {
                            color: "white",
                            elevation: "0",
                            width: _vm.$vuetify.breakpoint.mobile
                              ? "64px"
                              : "100px",
                            outlined: "",
                          },
                          on: { click: _vm.closeDialog },
                        },
                        [_vm._v(" Close ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }