var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.$vuetify.breakpoint.mobile ? "95%" : "800px" },
      on: {
        "click:outside": _vm.closeDialog,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeDialog.apply(null, arguments)
        },
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pb-1" },
        [
          _c("v-card-title", { staticClass: "text-h6 py-3 px-4" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _c("v-divider"),
          _c(
            "v-card-text",
            {
              staticClass: "py-2 px-4",
              staticStyle: {
                "min-width": "350px",
                "max-height": "800px",
                overflow: "scroll",
              },
            },
            [_c("pre", [_vm._v(_vm._s(_vm.fileText))])]
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c("TooltipButton", {
                attrs: {
                  btnClass:
                    "font-weight-medium text-body-2 copy-btn float-right  px-3",
                  color: "primary",
                  tooltipText: "Download File",
                  btnIcon: "mdi-download",
                  width: "fit-content",
                  btnText: _vm.$vuetify.breakpoint.mobile ? "" : "Download",
                },
                on: {
                  click: function ($event) {
                    return _vm.requestFileDownload()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }