import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { getAuthUserDetails } from "@/js/githubClient";

import * as signaloidClient from "@/js/signaloidClient";

import { useUserStore } from "@/stores/user";
import { Endpoints } from "@octokit/types";

import { useIntegrationsStore } from "@/stores/integrations";
import { monitoringCaptureError } from "@/plugins/monitoring";
import axios from "axios";

type GithubUser = Endpoints["GET /user"]["response"]["data"];

type StoreState = {
	githubUserDetails: undefined | null | GithubUser;
	githubAuthCode: undefined | string;
	userGithubLogin: boolean;
};
export const useGithubStore = defineStore("github", {
	state: (): StoreState => ({
		githubUserDetails: undefined,
		githubAuthCode: undefined,
		userGithubLogin: false,
	}),
	getters: {
		githubLoggedIn: (state) => state.userGithubLogin,
		githubUsername: (state) => state.githubUserDetails?.login,
	},
	actions: {
		setUserDetails(userDetails: null | GithubUser) {
			if (userDetails) {
				this.githubUserDetails = userDetails;
			} else {
				this.githubUserDetails = undefined;
			}
		},
		setGithubLogin(isUserLoggedIn: boolean) {
			this.$state.userGithubLogin = isUserLoggedIn;
		},
		async setupClientAndGetUser() {
			const authUserStore = useUserStore();

			// check if the DB has an integration...
			try {
				// get the current auth user
				const authUserID = await authUserStore.getCurrentUserID();
				if (!authUserID) {
					throw new Error("User ID not found");
				}

				const resp = await signaloidClient.getGithubCredentials(authUserID);

				if (resp.data.GithubUsername) {
					this.setGithubLogin(true);
					await this.getUserData();
				} else {
					this.setGithubLogin(false);
				}
			} catch (error) {
				this.setGithubLogin(false);
				if (!axios.isAxiosError(error)) {
					monitoringCaptureError(error, "Setup github client credentials");
					return;
				}
				// We do not send to sentry 404 to reduce noise
				const isNotFoundError = error.response?.status === 404;
				if (!isNotFoundError) {
					// Send to sentry otherwise
					monitoringCaptureError(error, "Setup github client credentials");
				}
			}
		},
		async getUserData() {
			try {
				const response = await getAuthUserDetails();
				this.setUserDetails(response.data);
			} catch (error) {
				this.setGithubLogin(false);
				// Reset here if anything goes wrong
				if (!axios.isAxiosError(error)) {
					monitoringCaptureError(error, "Setup github user details");
					return;
				}

				const isBadCredentialsError = error.response?.status === 401;
				if (isBadCredentialsError) {
					// Force logout
					await this.githubLogout();
					return;
				}
				// Send to sentry otherwise
				monitoringCaptureError(error, "Setup github user details");
			}
		},
		async githubLogout() {
			localStorage.removeItem("gh-access-token");
			localStorage.removeItem("gh-login-state");
			//! Deprecated: key should not exist anymore
			localStorage.removeItem("gh-logged-in");
			//! Deprecated: key should not exist anymore
			localStorage.removeItem("gh-user-details");

			this.setGithubLogin(false);
			this.setUserDetails(null);
			// FIXME: this function doesnt work
			await this.removeLoginDetailsFromCloud();
		},

		async removeLoginDetailsFromCloud() {
			try {
				const authUserStore = useUserStore();
				const authUserID = await authUserStore.getCurrentUserID();

				if (!authUserID) {
					throw new Error("User ID not found");
				}

				const resp = await signaloidClient.deleteGithubCredentials(authUserID);
			} catch (error) {
				monitoringCaptureError(error, "Remove github user credentials from cloud");
			}
		},
		async addLoginDetailsToCloud(githubAuthCode) {
			try {
				const authUserStore = useUserStore();
				const authUserID = await authUserStore.getCurrentUserID();

				if (!authUserID) {
					throw new Error("User ID not found");
				}
				if (authUserID && githubAuthCode) {
					await signaloidClient.putGithubCredentials(authUserID, githubAuthCode);
				}
			} catch (error) {
				monitoringCaptureError(error, "Store github user credentials in cloud");
			}
		},
		async githubLogin(githubAuthCode) {
			const integrationsStore = useIntegrationsStore();
			integrationsStore.createConnection({ type: "github", id: uuidv4() });

			try {
				await this.addLoginDetailsToCloud(githubAuthCode);
				const response = await getAuthUserDetails();
				this.setUserDetails(response.data);
				this.setGithubLogin(true);
			} catch (error) {
				monitoringCaptureError(error, "Github OAuth login");

				// @ts-ignore
				throw new Error(error);
			}
		},
	},
});
