
import { defineComponent } from "vue";
import { getUserByID } from "@/js/signaloidClient";
import SigCloudStorageFileList from "@/components/DataSources/SigCloudStorageFileList.vue";
import SigCloudStorageFileUploadDialog from "@/components/DataSources/SigCloudStorageFileUploadDialog.vue";

import { tiers, userGroupFromTokenPayload, groupCode, UserLimitsE } from "@/js/tierconfig";
import * as dsUtil from "@/components/DataSources/utilities";
import TooltipButton from "@/components/Common/TooltipButton.vue";

import { useUserStore } from "@/stores/user";
import { useRootStore } from "@/stores/root";

import { TierLimitEventTypeE } from "@/eventBus/tierLimitEventBus";
import { monitoringCaptureError } from "@/plugins/monitoring";

type ComponentData = {
	driveData: undefined;
	storageUsage: undefined | number;
	storageAllowance: undefined | number;
	showFilesDialog: boolean;
	showFileUploadDialog: boolean;
	fetchingUsage: boolean;
	errorFetchingUsage: boolean;
};

export default defineComponent({
	name: "SigCloudStorageCard",
	components: { SigCloudStorageFileList, SigCloudStorageFileUploadDialog, TooltipButton },
	props: {},
	data: (): ComponentData => ({
		driveData: undefined,
		storageUsage: undefined,
		storageAllowance: undefined,
		showFilesDialog: false,
		showFileUploadDialog: false,
		fetchingUsage: false,
		errorFetchingUsage: false,
	}),
	setup() {
		const userStore = useUserStore();
		const rootStore = useRootStore();
		return { userStore, rootStore, dataSourceIcon: dsUtil.dataSourceIcon };
	},
	computed: {
		usageBarColour() {
			if (this.usagePercentage >= 90) {
				return "danger";
			} else if (this.usagePercentage >= 80) {
				return "warning";
			} else if (this.usagePercentage >= 70) {
				return "primary";
			} else {
				return "accent";
			}
		},
		usagePercentage(): number {
			return this.storageUsage && this.storageAllowance ? (100 * this.storageUsage) / this.storageAllowance : 0;
		},
	},
	methods: {
		formatUsageString(usage: undefined | number) {
			if (usage !== undefined) {
				if (usage >= 1e9) {
					return `${(usage / 1e9).toFixed(2)} GB`;
				} else if (usage >= 1e6) {
					return `${(usage / 1e6).toFixed(2)} MB`;
				} else if (usage >= 1e3) {
					return `${(usage / 1e3).toFixed(2)} KB`;
				} else {
					return `${usage} B`;
				}
			} else {
				return "-- MB";
			}
		},

		async fetchUsage() {
			this.rootStore.tierLimitEventBus.emit({
				type: TierLimitEventTypeE.UsageChanged,
				affectedLimits: [UserLimitsE.CloudStorageBytes],
			});
			try {
				this.fetchingUsage = true;
				// get user usage
				const userID = await this.userStore.getCurrentUserID();
				const userDetails = await getUserByID(userID);
				this.storageUsage =
					userDetails?.data?.ResourceUsage?.CloudStorageBytes ??
					// @ts-ignore this is a legacy key that we should remove
					userDetails?.data?.ResourceUsage?.DataStorage ??
					undefined;

				// get user usage allowance
				const groups = await this.userStore.getCurrentUserGroups();
				const userSubscriptionTierCode = groupCode(userGroupFromTokenPayload(groups));
				this.storageAllowance =
					tiers.find((e) => e.code === userSubscriptionTierCode)?.allowance?.CloudStorageBytes ?? undefined;
			} catch (error) {
				monitoringCaptureError(error, "Fetch user quota usage");
			} finally {
				this.errorFetchingUsage = this.storageUsage === undefined || this.storageAllowance === undefined;
				this.fetchingUsage = false;
			}
		},
	},
	mounted() {
		this.fetchUsage();
	},
});
