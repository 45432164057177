
import { defineComponent } from "vue";

type ComponentData = {
	observer: null | IntersectionObserver;
	intersecting: boolean;
};
export default defineComponent({
	name: "IntersectionObserver",
	props: ["options"],
	data: (): ComponentData => ({
		observer: null,
		intersecting: false,
	}),
	mounted() {
		const options = this.options || {};
		this.observer = new IntersectionObserver(([entry]) => {
			if (entry && entry.isIntersecting) {
				this.$emit("intersect-enter");
			} else if (entry && !entry.isIntersecting) {
				this.$emit("intersect-exit");
			}
		}, options);
		this.observer.observe(this.$el);
	},
	destroyed() {
		if (this.observer !== null) {
			this.observer.disconnect();
		}
	},
});
