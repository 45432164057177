
import { defineComponent } from "vue";
import videojs from "video.js";

type ComponentData = { player: any };

export default defineComponent({
	name: "VideojsPlayer",
	props: {
		options: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data: (): ComponentData => ({ player: null }),
	mounted() {
		// @ts-ignore FIXME: need to figure out how to set the `videoPlayer` ref
		this.player = videojs(this.$refs.videoPlayer, this.options, () => {
			// this.player.log("onPlayerReady", this);
		});
	},
	beforeDestroy() {
		if (this.player) {
			this.player.dispose();
		}
	},
});
