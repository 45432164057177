
import { defineComponent, PropType } from "vue";
import { getUserByID, getThingByID, getBucketByID, getDriveByID } from "@/js/signaloidClient";
import { Auth } from "aws-amplify";
import { tiers, userGroupFromTokenPayload, groupCode } from "@/js/tierconfig";

import * as dsUtil from "@/components/DataSources/utilities";
import { DataSourceType } from "@/types/api/dataSources";
import { useUserStore } from "@/stores/user";
import { monitoringCaptureError } from "@/plugins/monitoring";

type ComponentData = {
	dataSourceData: any; //FIXME:
	showEditNameDialog: boolean;
	gatewayStatus: string;
	gatewayStatusIcon: any; //FIXME:
	gatewayStatusLoading: boolean;
	gatewayLastUpdated: undefined | number;
	signaloidCloudStorageLoading: boolean;
};

export default defineComponent({
	name: "DataSourcePickerCard",
	props: {
		dataSourceID: { type: String, required: true },
		dataSourceType: { type: String as PropType<DataSourceType>, required: true },
		setAsSource: { type: Boolean, default: false },
	},
	setup() {
		const userStore = useUserStore();
		return { userStore };
	},
	data: (): ComponentData => ({
		dataSourceData: undefined,
		showEditNameDialog: false,
		gatewayStatus: "Disconnected",
		gatewayStatusIcon: {},
		gatewayStatusLoading: false,
		gatewayLastUpdated: undefined,
		signaloidCloudStorageLoading: false,
	}),
	methods: {
		async getSourceInfo() {
			switch (this.dataSourceType) {
				case "Gateway":
					try {
						const resp = await getThingByID(this.dataSourceID);
						this.dataSourceData = resp.data;
						this.getGatewayStatus();
					} catch (error) {
						monitoringCaptureError(error, "Fetch data source info");
					}
					break;
				case "Bucket":
					try {
						const resp = await getBucketByID(this.dataSourceID);
						this.dataSourceData = resp.data;
					} catch (error) {
						monitoringCaptureError(error, "Fetch data source info");
					}
					break;
				case "Drive":
					try {
						const resp = await getDriveByID(this.dataSourceID);
						this.dataSourceData = resp.data;
					} catch (error) {
						monitoringCaptureError(error, "Fetch data source info");
					}
					break;
				case "SignaloidCloudStorage":
					try {
						this.dataSourceData = {
							Name: "Signaloid Cloud Storage",
							storageUsage: 0.0,
							storageAllowance: 1,
						};

						this.signaloidCloudStorageLoading = true;
						// get user usage
						//FIXME: The storage doesn't seem to update until manual refresh
						const userID = await this.userStore.getCurrentUserID();
						const userDetails = await getUserByID(userID);
						this.dataSourceData.storageUsage =
							userDetails?.data?.ResourceUsage?.CloudStorageBytes ??
							// @ts-ignore: FIXME: This is a legacy key which we should check for now
							userDetails?.data?.ResourceUsage?.DataStorage ??
							"--";

						// get user usage allowance
						const groups = await this.userStore.getCurrentUserGroups();
						const userSubscriptionTierCode = groupCode(userGroupFromTokenPayload(groups));
						this.dataSourceData.storageAllowance =
							tiers.find((e) => e.code === userSubscriptionTierCode)?.allowance?.CloudStorageBytes ??
							"--";
					} catch (error) {
						monitoringCaptureError(error, "Fetch data source info");
					} finally {
						this.signaloidCloudStorageLoading = false;
					}
					break;
				default:
					this.$emit("reset-data-source");
					throw "Error: Unexpected source type";
			}
		},
		setSourceBtnHandler() {
			this.setAsSource
				? this.$emit("reset-data-source")
				: this.$emit("set-data-source", {
						ResourceID: this.dataSourceID,
						ResourceType: this.dataSourceType,
				  });
		},
		setSourceBtnIcon(setAsSource: boolean, hover: boolean) {
			if (setAsSource && hover) {
				return { icon: "mdi-database-remove-outline", colour: "warning" };
			} else if (setAsSource && !hover) {
				return { icon: "mdi-database-check-outline", colour: "success" };
			} else {
				//  setAsSource is false
				return { icon: "mdi-database-plus-outline", colour: "success" };
			}
		},
		dataSourceIcon(dataSourceType: DataSourceType) {
			return dsUtil.dataSourceIcon(dataSourceType);
		},
		formatDateDayString(msSinceEpoch: number) {
			return dsUtil.formatDateDayString(1000 * msSinceEpoch);
		},
		formatDate(msSinceEpoch: number) {
			return dsUtil.formatDateStandardString(msSinceEpoch);
		},
		// * Gateway functions
		async getGatewayStatus() {
			this.gatewayStatusLoading = true;
			this.gatewayLastUpdated = await dsUtil.gatewayLastUpdated(this.dataSourceData);
			this.gatewayStatus = dsUtil.gatewayStatusFromLastUpdated(this.gatewayLastUpdated);
			this.gatewayStatusIcon = dsUtil.gatewayStatusIcon(this.gatewayStatus);
			if (this.gatewayStatusIcon) {
				this.gatewayStatusLoading = false;
			}
		},
		// * SignaloidCloudStorage functions
		formatUsageString(usageInBytes: number) {
			if (usageInBytes >= 1e9) {
				return `${(usageInBytes / 1e9).toFixed(2)} GB`;
			} else if (usageInBytes >= 1e6) {
				return `${(usageInBytes / 1e6).toFixed(2)} MB`;
			} else if (usageInBytes >= 1e3) {
				return `${(usageInBytes / 1e3).toFixed(2)} KB`;
			} else {
				return `${usageInBytes} B`;
			}
		},
	},
	computed: {
		// * Gateway functions
		gatewayIPAddress(): string {
			return dsUtil.gatewayIPAddressString(this.dataSourceData);
		},
		gatewayIPKnown(): boolean {
			return dsUtil.gatewayIPKnown(this.dataSourceData);
		},
		gatewayLastUpdatedString(): string {
			if (!this.gatewayLastUpdated) {
				return "---";
			}
			return dsUtil.formatDateDayString(1000 * this.gatewayLastUpdated);
		},

		// * Drive functions
		gatewayCount(): number {
			if (!Array.isArray(this.dataSourceData.DataSources)) {
				// log some error
				return 0;
			}
			// @ts-ignore FIXME:
			return this.dataSourceData.DataSources.filter((source) => {
				return source.ResourceType === "Gateway";
			}).length;
		},
		bucketCount(): number {
			if (!Array.isArray(this.dataSourceData.DataSources)) {
				// log some error
				return 0;
			}
			// @ts-ignore FIXME:
			return this.dataSourceData.DataSources.filter((source) => {
				return source.ResourceType === "Bucket";
			}).length;
		},
		connectedSourceCount(): number {
			return this.gatewayCount + this.bucketCount;
		},

		// * SignaloidCloudStorage functions
		usageBarColour() {
			if (this.usagePercentage >= 90) {
				return "danger";
			} else if (this.usagePercentage >= 80) {
				return "warning";
			} else if (this.usagePercentage >= 70) {
				return "primary";
			} else {
				return "accent";
			}
		},
		usagePercentage(): number {
			if (!this.dataSourceData) {
				return 0;
			}
			// @ts-ignore FIXME:
			return (100 * this.dataSourceData.storageUsage) / this.dataSourceData.storageAllowance;
		},
	},
	mounted() {
		this.getSourceInfo();
	},
});
