var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: {
        width: _vm.$vuetify.breakpoint.mobile ? "95%" : "900px",
        scrollable: "",
      },
      on: {
        "click:outside": _vm.closeDialog,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeDialog.apply(null, arguments)
        },
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pb-1", attrs: { "data-test": "core-selector-dialog" } },
        [
          _c(
            "v-card-title",
            { staticClass: "py-3 px-4" },
            [
              _vm._v("Select Active Core "),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "text--primary",
                  attrs: {
                    color: "white",
                    small: "",
                    outlined: "",
                    height: "36",
                    elevation: "0",
                  },
                  on: { click: _vm.closeDialog },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            {
              staticClass:
                "px-4 px-md-2 content-grid pb-0 my-2 my-md-0 py-md-0",
              attrs: { scrollable: "" },
            },
            [
              _c(
                "v-item-group",
                {
                  staticClass: "content-grid-core-list pr-2 pb-2 py-md-1",
                  attrs: { "data-test": "core-selector-menu" },
                },
                _vm._l(_vm.filteredVisibleCoresList, function (core) {
                  return _c(
                    "v-item",
                    {
                      key: core.CoreID,
                      staticClass: "align-center",
                      attrs: { "data-test": "core-selector-menu-item" },
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass:
                            "d-flex my-1 mx-1 px-3 py-2 text-body-2 font-weight-regular",
                          class: [core.Disabled ? "grey--text" : null],
                          attrs: {
                            color:
                              core.CoreID === _vm.selectedCore?.CoreID
                                ? "#EEEEEE"
                                : "default",
                            dense: "",
                            outlined: "",
                            rounded: "md",
                            "min-width": "fit-content",
                            "max-width": "250px",
                          },
                          on: {
                            click: function ($event) {
                              _vm.selectedCore = core
                            },
                          },
                        },
                        [
                          _vm._v(_vm._s(core.Name) + " "),
                          _c("v-spacer"),
                          core.Disabled
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "ml-2",
                                  attrs: {
                                    "x-small": "",
                                    color: "accent",
                                    outlined: "",
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { "x-small": "", left: "" } },
                                    [_vm._v(" mdi-chevron-double-up ")]
                                  ),
                                  [_vm._v(" Upgrade ")],
                                ],
                                2
                              )
                            : _vm._e(),
                          !core.Disabled && !_vm.satisfiesMinimumCore(core)
                            ? _c(
                                "v-icon",
                                {
                                  staticClass: "ml-3",
                                  attrs: {
                                    color: "warning",
                                    "x-small": "",
                                    left: "",
                                  },
                                },
                                [_vm._v(" mdi-alert-circle-outline ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _c("v-divider", {
                staticClass: "content-grid-grid-divider",
                attrs: {
                  inset: _vm.$vuetify.breakpoint.mdAndUp,
                  vertical: _vm.$vuetify.breakpoint.mdAndUp,
                },
              }),
              _c(
                "div",
                { staticClass: "px-0 px-4 content-grid-core-details" },
                [
                  _c("div", { staticClass: "text-h6 mt-2" }, [
                    _vm._v(" " + _vm._s(_vm.selectedCore?.Name) + " "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "inline-code black--text text-caption" },
                    [_vm._v(" " + _vm._s(_vm.selectedCore?.CoreID) + " ")]
                  ),
                  _c("v-simple-table", {
                    key: _vm.selectedCore?.CoreID,
                    staticClass: "mt-2",
                    staticStyle: { "scrollbar-gutter": "stable both-edges" },
                    attrs: { dense: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { staticClass: "text-left" }),
                                _c("th", { staticClass: "text-left" }, [
                                  _vm._v("Selected Core"),
                                ]),
                                _vm.minimumCore
                                  ? _c("th", { staticClass: "text-left" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-center justify-space-between",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "text-no-wrap" },
                                            [_vm._v("Recommended Core")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "ml-1 pb-1" },
                                            [
                                              _c("DocumentationIcon", {
                                                attrs: {
                                                  "tooltip-text":
                                                    "What is this?",
                                                  "documentation-link":
                                                    "https://docs.signaloid.io/docs/platform/user-interface/repositories/use-signaloid-yaml",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                            _vm.selectedCore != undefined
                              ? _c(
                                  "tbody",
                                  [
                                    _vm._l(_vm.fieldsToDisplay, function (key) {
                                      return [
                                        Object.hasOwnProperty.call(
                                          _vm.selectedCore,
                                          key
                                        )
                                          ? _c(
                                              "tr",
                                              {
                                                key: key,
                                                class: _vm
                                                  .coreRequirementWarnings[key]
                                                  ? "warning--text"
                                                  : "",
                                              },
                                              [
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatKeyString(key)
                                                    )
                                                  ),
                                                ]),
                                                _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      "min-width": "130px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.formatValueString(
                                                            key,
                                                            _vm.selectedCore[
                                                              key
                                                            ]
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _vm.minimumCore
                                                  ? _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-left",
                                                        staticStyle: {
                                                          "min-width": "130px",
                                                        },
                                                      },
                                                      [
                                                        _c("span", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.limitValuePrefix(
                                                                key
                                                              )
                                                            ),
                                                          },
                                                        }),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.formatValueString(
                                                                key,
                                                                _vm.minimumCore[
                                                                  key
                                                                ]
                                                              ) ?? "-"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("div", { staticClass: "mt-3" }, [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.coreDescription) + " "),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "mt-4" },
            [
              _c(
                "v-row",
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 mt-0",
                      attrs: { cols: "12", md: "auto" },
                    },
                    [
                      _c(
                        "v-fade-transition",
                        [
                          _vm.selectedCore &&
                          !_vm.satisfiesMinimumCore(_vm.selectedCore)
                            ? _c(
                                "v-alert",
                                {
                                  staticClass: "my-0 text-caption py-1",
                                  attrs: {
                                    dense: "",
                                    outlined: "",
                                    type: "warning",
                                    border: "left",
                                  },
                                },
                                [
                                  _vm._v(
                                    " This core does not meet the recommended core specification "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-end pt-0 mt-0",
                      attrs: { cols: "12", md: "auto" },
                    },
                    [
                      _vm.selectedCore?.Disabled
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                "data-test": "core-selector-set-core-button",
                                color: "accent",
                                elevation: "0",
                                width: "160px",
                                height: "34px",
                                to: "/billing",
                              },
                            },
                            [_vm._v(" Upgrade To Access ")]
                          )
                        : _c(
                            "v-btn",
                            {
                              attrs: {
                                "data-test": "core-selector-set-core-button",
                                color: "primary",
                                elevation: "0",
                                width: "160px",
                                height: "34px",
                                type: "submit",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.setCore(_vm.selectedCore?.CoreID)
                                },
                              },
                            },
                            [_vm._v(" Select Core ")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }