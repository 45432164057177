
import { defineComponent } from "vue";
import { getBucketByID, updateThingName, deleteBucketByID } from "@/js/signaloidClient";
import DataSourceConfigDialog from "@/components/DataSources/DataSourceConfigDialog.vue";
import * as dsUtil from "@/components/DataSources/utilities";
import TooltipButton from "@/components/Common/TooltipButton.vue";
import { AWSS3Bucket } from "@/types/api/dataSources";

type EditSourceData = {
	sourceType: "Bucket";
	sourceID: undefined | string;
	accountID: undefined | string;
	bucketName: undefined | string;
	readPermission: undefined | boolean;
	writePermission: undefined | boolean;
};

type ComponentData = {
	bucketData: undefined | AWSS3Bucket;
	editSourceData: undefined | EditSourceData;
	showEditDialog: boolean;
	bucketDisconnectionInProgress: boolean;
};

export default defineComponent({
	name: "BucketCard",
	components: { DataSourceConfigDialog, TooltipButton },
	props: {
		bucketID: { type: String, default: "" },
	},
	emits: {
		"bucket-disconnected": () => true,
	},
	data: (): ComponentData => ({
		bucketData: undefined,
		editSourceData: undefined,
		showEditDialog: false,
		bucketDisconnectionInProgress: false,
	}),
	methods: {
		formatDate(dtString) {
			return dsUtil.formatDateDayString(1000 * dtString);
		},
		getBucketInfo() {
			return getBucketByID(this.bucketID)
				.then((response) => {
					this.bucketData = response.data;
				})
				.catch((error) => console.log(error));
		},
		requestSourceEdit() {
			this.editSourceData = {
				sourceType: "Bucket",
				sourceID: this.bucketData?.BucketID,
				accountID: this.bucketData?.Account,
				bucketName: this.bucketData?.Name,
				readPermission: this.bucketData?.Read,
				writePermission: this.bucketData?.Write,
			};
			this.showEditDialog = true;
		},
		requestSourceDisconnect() {
			this.bucketDisconnectionInProgress = true;
			deleteBucketByID(this.bucketID)
				.then((response) => {
					this.$emit("bucket-disconnected");
				})
				.catch((error) => console.log(error));
		},
		// * setters
		async setBucketConfig(newBucketConfig) {
			updateThingName(this.bucketID, newBucketConfig)
				.then((response) => {
					this.getBucketInfo();
				})
				.catch((error) => console.log(error));
		},
		dataSourceIcon(dataSourceType) {
			return dsUtil.dataSourceIcon(dataSourceType);
		},
	},

	mounted() {
		this.getBucketInfo();
	},
});
