
import { MakeKeysOptional } from "@/types/utilities";
import { defineComponent } from "vue";

type ComponentData = {
	settingsNavItems: MakeKeysOptional<SettingsNavItem, "to" | "disabled" | "icon">[];
};
type SettingsNavItem = {
	type: string;
	text: string;
	to: {
		name: string;
	};
	icon: string;
	disabled: boolean;
};

export default defineComponent({
	name: "Settings",
	data: (): ComponentData => ({
		settingsNavItems: [
			//this valid routes should be specified in the router index
			{ type: "heading", text: "General" },
			{
				type: "nav",
				text: "Profile",
				to: { name: "Settings_profile" },
				icon: "mdi-account-circle-outline",
				disabled: false,
			},
			{
				type: "nav",
				text: "General",
				to: { name: "Settings_general" },
				icon: "mdi-cog-outline",
				disabled: false,
			},
			{
				type: "nav",
				text: "Account",
				to: { name: "Settings_account" },
				icon: "mdi-domain",
				disabled: false,
			},
			{
				type: "nav",
				text: "Cloud Engine API",
				to: { name: "Settings_cloudEngineAPI" },
				icon: "mdi-api",
				disabled: false,
			},
			// { type: "heading", text: "Access" },
			// {
			// 	type: "nav",
			// 	text: "Billing",
			// 	to: {name: "Settings_billing"},
			// 	icon: "mdi-credit-card-outline",
			// 	disabled: false,
			// },
			// {
			// 	type: "nav",
			// 	text: "Organisations",
			// 	to: { name: "Settings_organisations" },
			// 	icon: "mdi-domain",
			// 	disabled: true,
			// },
			// {
			// 	type: "nav",
			// 	text: "Integrations",
			// 	to: { name: "Settings_integrations" },
			// 	icon: "mdi-connection",
			// 	disabled: true,
			// },
		],
	}),
	computed: {
		settingsPageList(): Partial<SettingsNavItem>[] {
			//filter our the headings to get only the pages
			return this.settingsNavItems.filter((item) => {
				return item.type === "nav";
			});
		},
		selectedSettingsPage(): number | null {
			// TODO: Don't assign to drawerActiveItem, because it's a computed
			// property.
			const routePageIndex = this.settingsPageList.findIndex((page) =>
				page.to ? this.$route.name === page.to.name : false
			);
			return routePageIndex != -1 ? routePageIndex : null;
		},
	},
	methods: {},
});
