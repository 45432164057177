
import { defineComponent, PropType } from "vue";

// Libraries
import axios from "axios";

// Components
import TooltipButton from "@/components/Common/TooltipButton.vue";
import VideoPlayerComponent from "@/components/Common/VideoPlayerComponent.vue";
import LoadingCard from "@/components/LoadingCard.vue";
import AlertMessage from "@/components/Common/AlertMessage.vue";

// Utilities
import { getRepoData } from "@/js/githubClient";
import { generateGithubResponseErrorMessage } from "@/js/githubUtilities";

// Types
import { ApplicationSlideInfo } from "@/types/home";
import { Endpoints } from "@octokit/types";
import { RemoteAccessState } from "@/types/general";

type ComponentData = {
	repoData: any;
	loading: boolean;
	repoAccessState: RemoteAccessState;
};

export default defineComponent({
	name: "ApplicationDetailDialog",
	components: { TooltipButton, VideoPlayerComponent, LoadingCard, AlertMessage },
	props: {
		applicationInfo: { type: Object as PropType<ApplicationSlideInfo>, required: true },
		showDialog: { type: Boolean, default: false },
		initialRepoData: {
			type: Object as PropType<Endpoints["GET /repos/{owner}/{repo}"]["response"]["data"]>,
			required: false,
		},
	},
	data: (): ComponentData => ({
		repoData: {},
		loading: false,
		repoAccessState: { loading: false, error: false, message: "" },
	}),
	computed: {
		demoVideoURL(): string | undefined {
			return this.applicationInfo.demoVideoURL
				? new URL(this.applicationInfo.demoVideoURL, process.env.VUE_APP_ASSET_SERVER_URL).toString()
				: undefined;
		},
	},
	methods: {
		closeDialog() {
			this.$emit("update:showDialog", false);
		},
		getExampleInfoFromGithub() {},
		async getRepoData() {
			this.repoAccessState.loading = true;
			try {
				const applicationURL = new URL(this.applicationInfo.applicationURL);
				const resp = await getRepoData(applicationURL.pathname.slice(1));
				this.repoData = resp.data;
			} catch (error) {
				if (axios.isAxiosError(error)) {
					this.repoAccessState.error = true;
					this.repoAccessState.message = generateGithubResponseErrorMessage(error, false);
				}
			} finally {
				this.repoAccessState.loading = false;
			}
		},
	},
	watch: {
		// Watch for changes in the entire applicationInfo object
		applicationInfo: {
			handler(newVal, oldVal) {
				this.getRepoData();
			},
			deep: true,
			immediate: false,
		},
	},
	mounted() {
		if (this.initialRepoData) {
			this.repoData = this.initialRepoData;
		} else {
			this.getRepoData();
		}
	},
});
