
import { defineComponent } from "vue";

// Types

// Libraries

// Utilities

// Components

// Stores
import { mapState } from "pinia";
import { useRootStore } from "@/stores/root";

const defaultPlatformLogo = require("@/assets/images/signaloid-logo-v3.0-black-green-xHeight8.5pxPadding-200.png");

export default defineComponent({
	name: "NoticeCard",
	props: {
		showLogo: { type: Boolean, default: true },
	},
	setup() {
		return { defaultPlatformLogo };
	},
	computed: {
		...mapState(useRootStore, { platformLogo: "platformLogo" }),
	},
});
