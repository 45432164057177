
import { defineComponent, PropType } from "vue";

// Types

// Libraries

// Utilities

// Components
import { TierFeatureHighlight } from "@/js/tierconfig";

// Stores

// Global variables

export default defineComponent({
	name: "TierHighlightsList",
	props: {
		tierHighlightsToDisplay: {
			type: Array as PropType<TierFeatureHighlight[]>,
			default: () => [],
		},
	},
	methods: {
		pointIcon(iconName: string): string {
			switch (iconName) {
				case "checkmark":
					return "mdi-check";
				case "plus":
					return "mdi-plus";

				default:
					return "mdi-" + iconName;
			}
		},
	},
});
