var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "root mt-n2" },
    [
      _c("video-background", {
        staticClass: "video-background",
        staticStyle: { width: "100%", height: "100%", position: "fixed" },
        attrs: {
          poster: require("@/assets/exported-frame-sig-cut_1.3.1_1.3.1.jpg"),
          src: require("@/assets/sig_cut_1280_cr16.mp4"),
          loop: false,
        },
        on: { ended: _vm.videoEndHandler, error: _vm.videoErrorHandler },
      }),
      _c(
        "span",
        { staticClass: "mx-2" },
        [
          _c("v-fade-transition", [
            _vm.showWelcomeDialog
              ? _c(
                  "div",
                  { staticClass: "logout-button" },
                  [
                    _c("TooltipButton", {
                      attrs: { "btn-text": "Logout" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("sign-out")
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c(
            "v-fade-transition",
            { staticClass: "root", attrs: { group: "", "hide-on-leave": "" } },
            [
              _vm.showWelcomeDialog &&
              _vm.currentStep === _vm.WelcomeSteps.Intro
                ? _c("WelcomeSlide", {
                    key: _vm.WelcomeSteps.Intro,
                    on: { next: _vm.validateAndContinueToNextSection },
                  })
                : _vm._e(),
              _vm.showWelcomeDialog &&
              _vm.currentStep === _vm.WelcomeSteps.TierSelect
                ? _c("TierSelectionSlide", {
                    key: _vm.WelcomeSteps.TierSelect,
                    attrs: { initialSlide: "SubscribeToTrial" },
                    on: { slideEnd: _vm.validateAndContinueToNextSection },
                  })
                : _vm._e(),
              _vm.showWelcomeDialog &&
              _vm.currentStep === _vm.WelcomeSteps.ContinueToPlatform
                ? _c(
                    "NoticeCard",
                    {
                      key: _vm.WelcomeSteps.ContinueToPlatform,
                      attrs: { "show-logo": true },
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticStyle: { height: "300px" },
                          attrs: { justify: "center", align: "center" },
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  color: "accent",
                                  indeterminate: "",
                                  size: "64",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }