
import { toStringWithByteMagnitude, toStringWithSIMagnitude } from "@/js/utilities";
import { useCoresStore } from "@/stores/cores";
import { Core, CoreRequirementMatch } from "@/types/api/cores";
import { mapState } from "pinia";
import { defineComponent, h, PropType } from "vue";

// Types

// Libraries

// Utilities

// Components

// Stores

// Global variables

export default defineComponent({
	name: "ActiveCoreTooltip",
	props: {
		activeCore: {
			type: Object as PropType<Core>,
			default: null,
		},
		minimumCoreConfig: {
			type: Object as PropType<Partial<Core>>,
		},
	},
	setup() {
		const fieldsToDisplay = [
			"CoreID",
			"Name",
			"Class",
			"Precision",
			"MemorySize",
			"Microarchitecture",
			"CorrelationTracking",
		];
		return { fieldsToDisplay };
	},
	computed: {
		...mapState(useCoresStore, {
			coresSatisfyMinConfig: "coresSatisfyMinConfig",
			correlationTrackingMethodByCode: "correlationTrackingMethodByCode",
		}),
		coreRequirementWarnings(): Partial<CoreRequirementMatch> {
			const requirementMatch = {};
			for (const key in this.minimumCoreConfig) {
				if (typeof this.minimumCoreConfig[key] == "number") {
					// check if requirement satisfied for numerical constraints
					requirementMatch[key] = this.activeCore[key] < this.minimumCoreConfig[key];
				} else {
					// check if requirement satisfied for non numerical constraints
					if (["Class", "Microarchitecture", "CorrelationTracking"].includes(key)) {
						requirementMatch[key] = this.activeCore[key] !== this.minimumCoreConfig[key];
					}
				}
			}
			return requirementMatch;
		},
		allRequirementsMatched() {
			for (const key in this.coreRequirementWarnings) {
				if (Object.prototype.hasOwnProperty.call(this.coreRequirementWarnings, key)) {
					if (this.coreRequirementWarnings[key]) {
						return false;
					}
				}
			}
			return true;
		},
	},
	methods: {
		limitValuePrefix(key) {
			switch (key) {
				case "MemorySize":
				case "Precision":
					return `<i aria-hidden="true" style="font-size: 12px;" class="v-icon notranslate mdi mdi-greater-than-or-equal theme--light ${
						this.coreRequirementWarnings[key] ? "danger--text" : ""
					} "></i>`;
				default:
					return ``;
			}
		},
		formatValueString(key, value) {
			switch (key) {
				case "MemorySize":
					return toStringWithByteMagnitude(value);
				case "Precision":
					return toStringWithSIMagnitude(value, "");
				case "CorrelationTracking":
					return this.formatCorrelationString(value);
				default:
					return value;
			}
		},
		formatKeyString(key) {
			switch (key) {
				case "CoreID":
					return "Core ID";
				case "MemorySize":
					return "Memory Size";
				case "CorrelationTracking":
					return "Correlation Tracking";
				default:
					return key;
			}
		},
		formatCorrelationString(code) {
			return this.correlationTrackingMethodByCode(code)?.Name ?? "-";
		},
	},
});
