<template>
	<v-icon
		:class="'mr-3 ' + headwayWidgetSelector"
		v-click-outside="hide"
		>mdi-bell</v-icon
	>
	<!-- <div :class="headwayWidgetSelector">
    </div> -->
</template>

<script>
import { defineComponent } from "vue";
const HEADWAY_WIDGET_URL = "//cdn.headwayapp.co/widget.js";

export default defineComponent({
	name: "HeadwayWidget",
	data: () => ({
		widget: null,
		headwayAccountId: "JlO5Q7",
	}),
	props: {
		headwayWidgetSelector: {
			type: String,
			default: "defaultWidgetSelector",
		},
	},
	computed: {
		headwayConfig() {
			return {
				selector: "." + this.headwayWidgetSelector,
				account: this.headwayAccountId,
				callbacks: {
					onWidgetReady: (w) => {
						// console.log(w);
						this.widget = w;
					},
				},
			};
		},
	},
	methods: {
		hide() {
			this.widget?.hide();
		},
	},
	async mounted() {
		/*
		 *  From https://gist.github.com/codebryo/a845beaed67e4bb79210c20ca71c48db.
		 */

		/*
		 *  Check if script is already loaded.
		 */
		const headway = document.getElementById("headway-script");
		if (headway) {
			return;
		}

		const promise = new Promise((resolve, reject) => {
			const script = document.createElement("script");
			script.src = "//cdn.headwayapp.co/widget.js";
			script.id = "headway-script";
			script.setAttribute("async", true);
			document.head.appendChild(script);
			script.onload = resolve; //Resolve when loaded
			script.onerror = reject;
		});
		// console.log('Headway promise..');
		await promise;
		// console.log('Headway promise resolved.');

		// eslint-disable-next-line no-undef
		Headway.init(this.headwayConfig);
	},
	updated() {
		// console.log('updated');
		this.widget?.init(this.headwayConfig);
	},
});
</script>

<style>
#HW_badge_cont {
	position: absolute !important;
}

#HW_badge {
	left: 50%;
	top: 50%;
}
</style>
