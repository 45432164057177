var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "top-nav" },
    [
      [
        _c(
          "button",
          { staticClass: "btn-outline mr-3", on: { click: _vm.openDocs } },
          [
            _c(
              "svg",
              { staticClass: "mdi-icon", attrs: { viewBox: "0 0 24 24" } },
              [
                _c("path", {
                  attrs: {
                    d: "M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M13,9V3.5L18.5,9H13M16,18H8V16H16V18M16,14H8V12H16V14M13,10H8V8H13V10Z",
                  },
                }),
              ]
            ),
            _vm._v(" Documentation "),
          ]
        ),
      ],
      _c("HeadwayWidget", {
        attrs: { "bind:headwayWidgetSelector": "headway-selector" },
      }),
      _c(
        "v-menu",
        {
          attrs: {
            "content-class": "arrow-menu",
            left: "",
            bottom: "",
            "close-on-content-click": false,
            "offset-y": "",
            "nudge-bottom": "4px",
            origin: "center center",
            transition: "slide-y-transition",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-icon",
                    _vm._g(
                      _vm._b({ staticClass: "mr-3" }, "v-icon", attrs, false),
                      on
                    ),
                    [_vm._v("mdi-alert-circle")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _c(
                "v-card",
                { attrs: { tile: "", elevation: "0" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pt-2 text-body-2 font-weight-medium" },
                    [_vm._v("Signaloid Cloud Developer Platform")]
                  ),
                  _c("v-card-subtitle", { staticClass: "pb-3 text-menu" }, [
                    _vm._v("Version "),
                    _c("code", [_vm._v(_vm._s(_vm.appVersion))]),
                  ]),
                ],
                1
              ),
              _c("v-divider", { staticClass: "mb-2" }),
              _vm._l(_vm.legalLinks, function (legal) {
                return _c(
                  "v-list-item",
                  {
                    key: legal.title,
                    attrs: { link: "", target: "_blank", href: legal.url },
                  },
                  [
                    _c(
                      "v-list-item-icon",
                      { staticClass: "mr-0" },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "text-subtitle-1",
                            attrs: { color: "accent" },
                          },
                          [_vm._v(_vm._s(legal.icon))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-content",
                      { staticClass: "text-menu font-weight-medium" },
                      [_vm._v(" " + _vm._s(legal.title) + " ")]
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-menu",
        {
          attrs: {
            "content-class": "arrow-menu",
            left: "",
            bottom: "",
            "close-on-content-click": false,
            "offset-y": "",
            "nudge-bottom": "4px",
            origin: "center center",
            transition: "slide-y-transition",
            "max-width": "280px",
            "min-width": "220px",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-icon",
                    _vm._g(
                      _vm._b({ staticClass: "mr-3" }, "v-icon", attrs, false),
                      on
                    ),
                    [_vm._v("mdi-account")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _c(
                "v-card",
                { attrs: { tile: "", elevation: "0" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pt-2 text-body-2 font-weight-medium" },
                    [_vm._v(_vm._s(_vm.userFullname))]
                  ),
                  _c("v-card-subtitle", { staticClass: "pb-3 text-menu" }, [
                    _vm._v(_vm._s(_vm.userEmail)),
                  ]),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list-item",
                { staticClass: "mt-2" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-menu",
                      attrs: {
                        color: "secondary",
                        elevation: "0",
                        width: "100%",
                        outlined: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("sign-out")
                        },
                      },
                    },
                    [_vm._v("Sign Out")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }