import { defineStore } from "pinia";
import { getUserThings, getUserBuckets, getUserDrives } from "@/js/signaloidClient";
import { DataSourceTypeE, isLocalMountConfig, LocalMountConfig } from "@/types/api/dataSources";
import { useUserStore } from "./user";
import * as util from "@/js/utilities";
import { monitoringCaptureError } from "@/plugins/monitoring";
const fallbackDefaultMountConfig = null;

type StoreState = {
	driveList: string[];
	bucketList: string[];
	gatewayList: string[];
	defaultMountConfig: undefined | null | LocalMountConfig;
	fallbackDefaultMountConfig: undefined | null | LocalMountConfig;
};

export const useDataSourcesStore = defineStore("dataSources", {
	state: (): StoreState => ({
		driveList: [],
		bucketList: [],
		gatewayList: [],
		defaultMountConfig: undefined,
		fallbackDefaultMountConfig: undefined,
	}),
	getters: {
		getDefaultMountConfig: (state) => {
			//! Deprecated
			if (isLocalMountConfig(state.defaultMountConfig)) {
				return state.defaultMountConfig;
			} else {
				return fallbackDefaultMountConfig;
			}
		},
	},
	actions: {
		setDriveList(driveIDList: string[]) {
			this.driveList = driveIDList;
			// localStorage.setItem("drive-list", JSON.stringify(this.driveList));
		},
		setBucketList(bucketIDList: string[]) {
			this.bucketList = bucketIDList;
			// localStorage.setItem("bucket-list", JSON.stringify(this.bucketList));
		},
		setGatewayList(gatewayIDList: string[]) {
			this.gatewayList = gatewayIDList;
			// localStorage.setItem("gateway-list", JSON.stringify(this.gatewayList));
		},
		async fetchGatewayList() {
			try {
				const resp = await getUserThings();
				if (resp.data.thing_ids) {
					this.setGatewayList(resp.data.thing_ids);
				}
			} catch (error) {
				monitoringCaptureError(error, "Fetch user gateways list");
			}
		},
		async fetchDriveList() {
			try {
				const resp = await getUserDrives();
				if (resp.data.drive_ids) {
					this.setDriveList(resp.data.drive_ids);
				}
			} catch (error) {
				monitoringCaptureError(error, "Fetch user drives list");
			}
		},
		async fetchBucketList() {
			try {
				const resp = await getUserBuckets();
				if (resp.data.bucket_ids) {
					this.setBucketList(resp.data.bucket_ids);
				}
			} catch (error) {
				monitoringCaptureError(error, "Fetch user buckets list");
			}
		},
		async initialiseDataSourcesFromRemote() {
			this.fetchGatewayList();
			this.fetchDriveList();
			this.fetchBucketList();
			this.getFallbackMountConfig();
			this.getGlobalDefaultMountConfig();
		},

		async isValidMountConfig(mountConfig: undefined | null | LocalMountConfig): Promise<boolean> {
			if (mountConfig === null) {
				return true;
			}

			if (mountConfig && isLocalMountConfig(mountConfig)) {
				let resourceIDValid: boolean = true;

				if (mountConfig.ResourceType === DataSourceTypeE.SignaloidCloudStorage) {
					const authUserStore = useUserStore();
					const userID = await authUserStore.getCurrentUserID();
					resourceIDValid = mountConfig.ResourceID === `signaloid-cloud-storage:/${userID}` ? true : false;
				}
				return resourceIDValid;
			}
			return false;
		},
		async validateOrGetValidMountConfig(
			mountConfig: undefined | null | LocalMountConfig
		): Promise<null | LocalMountConfig> {
			if (mountConfig === null) {
				return null;
			}

			if (mountConfig && isLocalMountConfig(mountConfig)) {
				const isValidMountConfig = await this.isValidMountConfig(mountConfig);
				if (isValidMountConfig) {
					return mountConfig;
				}
			}

			// The input mount config is undefined...

			// Try to return global default
			const globalDefaultMountConfig = await this.getGlobalDefaultMountConfig();

			if (globalDefaultMountConfig) {
				return globalDefaultMountConfig;
			}

			// Try to return fallback
			const fallbackMountConfig = await this.getFallbackMountConfig();
			if (fallbackMountConfig) {
				return fallbackMountConfig;
			}

			// if all else fails return null
			return null;
		},

		async setDefaultMountConfig(mountConfig: null | LocalMountConfig, syncWithRemote: boolean) {
			if (isLocalMountConfig(mountConfig)) {
				// valid mount config
				this.defaultMountConfig = mountConfig;
			} else if (mountConfig === null) {
				// user has removed mounting
				this.defaultMountConfig = null;
			} else {
				// unknown mount config, set to default
				const dataSourcesStore = useDataSourcesStore();
				this.defaultMountConfig = await this.getFallbackMountConfig();
			}

			if (syncWithRemote) {
				const authUserStore = useUserStore();
				await authUserStore.syncUserPreferenceWithRemote({
					Execution_DefaultDataSources: JSON.stringify(mountConfig),
				});
			}
		},
		resetDefaultMountConfig() {
			this.setDefaultMountConfig(this.fallbackDefaultMountConfig ?? null, true);
		},
		removeDefaultMountConfig() {
			this.setDefaultMountConfig(null, true);
		},
		async getGlobalDefaultMountConfig() {
			if (this.defaultMountConfig !== undefined) {
				// i.e., the mount config is set or unset (=null)
				return this.defaultMountConfig;
			}
			try {
				const authUserStore = useUserStore();
				const preferencesFromDB = await authUserStore.getCurrentUserPreferences();
				const defaultMountConfigFromDB = preferencesFromDB?.Execution_DefaultDataSources;

				if (defaultMountConfigFromDB && typeof defaultMountConfigFromDB === "string") {
					// try to parse the string
					const parsedMountConfig = util.parseMountConfigString(defaultMountConfigFromDB);
					const isValidMountConfig = await this.isValidMountConfig(parsedMountConfig);
					if (isValidMountConfig) {
						// @ts-ignore
						this.setDefaultMountConfig(parsedMountConfig, false);
						return this.defaultMountConfig;
					} else {
						console.warn("Default mount config in DB is invalid. Resetting to fallback mount config.");
						const validMountConfig = await this.getFallbackMountConfig();
						this.setDefaultMountConfig(validMountConfig, true);
						return this.defaultMountConfig;
					}
				} else {
					// no data source set in DB, set to default mount config
					console.warn("No default mount config set in DB. Resetting to fallback mount config.");
					const validMountConfig = await this.getFallbackMountConfig();
					this.setDefaultMountConfig(validMountConfig, true);
					return this.defaultMountConfig;
				}
			} catch (error) {
				monitoringCaptureError(error, "Get user default mount config");
				return null;
			}
		},

		async getFallbackMountConfig() {
			/*
			 * this function should be called if there is no default set and
			 * you need to get a mount config to fallback onto. It will return
			 * a mount config for the cloud storage if possible, if not it will return `null`
			 */
			if (this.fallbackDefaultMountConfig !== undefined) {
				// i.e., the mount config is set or unset (=null)
				return this.fallbackDefaultMountConfig;
			}
			try {
				const authUserStore = useUserStore();
				const currentUserID = await authUserStore.getCurrentUserID();

				if (!currentUserID) {
					throw new Error("User ID invalid");
				}
				const fallbackMountConfig: LocalMountConfig = {
					Location: "sd0",
					ResourceID: `signaloid-cloud-storage:/${currentUserID}`,
					ResourceType: DataSourceTypeE.SignaloidCloudStorage,
				};

				this.fallbackDefaultMountConfig = fallbackMountConfig;
				return fallbackMountConfig;
			} catch (error) {
				monitoringCaptureError(error, "Get user fallback mount config");
				return null;
			}
		},
	},
});
