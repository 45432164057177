var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.$vuetify.breakpoint.mobile ? "95%" : "500px" },
      on: {
        "click:outside": _vm.closeDialog,
        keydown: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            )
              return null
            return _vm.closeDialog.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.connectToDataSource.apply(null, arguments)
          },
        ],
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pb-0" },
        [
          _c(
            "v-card-title",
            { staticClass: "py-3 px-4" },
            [
              _vm._v(_vm._s(_vm.dialogTitle) + " "),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "text--primary",
                  attrs: {
                    color: "white",
                    small: "",
                    outlined: "",
                    height: "36",
                    elevation: "0",
                  },
                  on: { click: _vm.closeDialog },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "py-3 px-4" },
            [
              _c(
                "v-form",
                {
                  ref: "dataSourceForm",
                  staticClass: "pt-5 px-4 px-sm-6",
                  model: {
                    value: _vm.formValid,
                    callback: function ($$v) {
                      _vm.formValid = $$v
                    },
                    expression: "formValid",
                  },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "mb-0", attrs: { align: "center" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.dataSourceTypes,
                          disabled: _vm.modifySourceConfigRequest,
                          label: "Data Source",
                          dense: "",
                        },
                        on: { change: _vm.setupDataSourceConfig },
                        model: {
                          value: _vm.chosenDataSource,
                          callback: function ($$v) {
                            _vm.chosenDataSource = $$v
                          },
                          expression: "chosenDataSource",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.chosenDataSource
                    ? _c("v-divider", { staticClass: "py-5 px-0" })
                    : _vm._e(),
                  _vm.chosenDataSource == "Bucket"
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-row",
                            { staticClass: "mb-2", attrs: { align: "center" } },
                            [
                              _c("v-text-field", {
                                staticClass: "mb-1",
                                attrs: {
                                  label: "AWS Account ID",
                                  hint: "Your 12-digit Account ID can be found in the top right-hand corner of your AWS console.",
                                  maxlength: "12",
                                  rules: [
                                    _vm.rules.required,
                                    _vm.rules.awsS3.accountIDNumeric,
                                    _vm.rules.awsS3.accountIDLength,
                                  ],
                                  disabled: _vm.modifySourceConfigRequest,
                                  dense: "",
                                },
                                model: {
                                  value: _vm.dataSourceConfig.accountID,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataSourceConfig,
                                      "accountID",
                                      $$v
                                    )
                                  },
                                  expression: "dataSourceConfig.accountID",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "S3 Bucket Name",
                                  placeholder: "bucket-name",
                                  rules: [
                                    _vm.rules.required,
                                    _vm.rules.awsS3.bucketNameNoARN,
                                    _vm.rules.awsS3.bucketNameStartValid,
                                    _vm.rules.awsS3.bucketNameEndValid,
                                    _vm.rules.awsS3.bucketNameLengthValid,
                                  ],
                                  disabled: _vm.modifySourceConfigRequest,
                                  dense: "",
                                },
                                model: {
                                  value: _vm.dataSourceConfig.bucketName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataSourceConfig,
                                      "bucketName",
                                      $$v
                                    )
                                  },
                                  expression: "dataSourceConfig.bucketName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-row", { staticClass: "mb-n4" }, [
                            _vm._v(" Permissions "),
                          ]),
                          _c(
                            "v-row",
                            {
                              attrs: {
                                justify: "space-around",
                                "no-gutters": "",
                              },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label: "Read",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value:
                                        _vm.dataSourceConfig.readPermission,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataSourceConfig,
                                          "readPermission",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dataSourceConfig.readPermission",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label: "Write",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value:
                                        _vm.dataSourceConfig.writePermission,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataSourceConfig,
                                          "writePermission",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dataSourceConfig.writePermission",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "mt-3",
                              attrs: { justify: "center", "no-gutters": "" },
                            },
                            [
                              _c("TooltipButton", {
                                attrs: {
                                  disabled: !_vm.dataSourceConfig.bucketName,
                                  width: "fit-content",
                                  btnClass: "text-caption",
                                },
                                on: { click: _vm.copyIAMPolicyToClipboard },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "btnContent",
                                      fn: function () {
                                        return [
                                          _vm._v(" Copy Suggested IAM Policy"),
                                          _c("sup", [_vm._v("1")]),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "pl-2",
                                              attrs: { small: "" },
                                            },
                                            [
                                              _vm._v(
                                                " mdi-clipboard-text-multiple-outline"
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "tooltipContent",
                                      fn: function () {
                                        return [
                                          _vm._v("Copy Suggested"),
                                          _c("sup", [_vm._v("1")]),
                                          _vm._v(
                                            " AWS IAM policy to clipboard"
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  4162317216
                                ),
                              }),
                              _c(
                                "v-snackbar",
                                {
                                  attrs: {
                                    color: "green lighten-1",
                                    absolute: "",
                                    bottom: "",
                                  },
                                  model: {
                                    value: _vm.showIAMCopyNotification,
                                    callback: function ($$v) {
                                      _vm.showIAMCopyNotification = $$v
                                    },
                                    expression: "showIAMCopyNotification",
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "pl-2",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v(" mdi-check")]
                                  ),
                                  _vm._v(
                                    " Copied AWS IAM policy to clipboard! "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-row", { staticClass: "mb-0" }, [
                            _c(
                              "div",
                              { staticClass: "text-caption text-justify" },
                              [
                                _vm._v(" Please make sure to grant "),
                                _c("em", [_vm._v("Signaloid Cloud")]),
                                _vm._v(
                                  " access to your S3 bucket by updating its IAM policy in the "
                                ),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      target: "_blank",
                                      href: `https://s3.console.aws.amazon.com/s3/bucket/${_vm.dataSourceConfig.bucketName}/property/policy/edit`,
                                    },
                                  },
                                  [
                                    _vm._v(" AWS console "),
                                    _c("v-icon", { attrs: { size: "12" } }, [
                                      _vm._v("mdi-open-in-new"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(". "),
                              ]
                            ),
                          ]),
                          _c("v-row", { staticClass: "mb-0" }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "0.625rem",
                                  "line-height": "1",
                                  "text-align": "justify",
                                },
                              },
                              [
                                _c("sup", [_vm._v("1")]),
                                _vm._v(
                                  " The policy is provided “as is”, without warranty of any kind, express or implied, including but not limited to the warranties of merchantability, fitness for a particular purpose and noninfringement. In no event shall Signaloid be liable for any claim, damages or other liability, whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software or the use or other dealings in the supplied policy. "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _vm.alert.show
                ? _c(
                    "v-alert",
                    {
                      staticClass: "my-0 text-body-2",
                      attrs: {
                        dense: "",
                        outlined: "",
                        type: _vm.alert.type,
                        border: "left",
                        width: "70%",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.alert.text) + " ")]
                  )
                : _vm._e(),
              _c("v-spacer"),
              _c("LimitableActionButton", {
                attrs: {
                  color: "primary",
                  disabled: !_vm.chosenDataSource,
                  tooltipText: "Connect to Repository",
                  "data-test": "connect-repo-button",
                  "limits-to-check": _vm.tierLimitsToCheck,
                  loading: _vm.connectingToDataSource,
                  "btn-text": _vm.modifySourceConfigRequest
                    ? "Update"
                    : "Connect",
                },
                on: { "valid-click": _vm.connectToDataSource },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }