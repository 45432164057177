var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticClass: "grid pa-2 application-card",
          attrs: { height: "125px", outlined: "", rounded: "lg" },
          on: {
            click: function ($event) {
              _vm.showDetailDialog = true
            },
          },
        },
        [
          _c("div", { staticClass: "text-subtitle-2 grid-title" }, [
            _vm._v(" " + _vm._s(_vm.slideInfo.titleText) + " "),
          ]),
          _vm.repoData
            ? _c(
                "div",
                { staticClass: "grid-tags" },
                _vm._l(_vm.repoData.topics, function (tag, i) {
                  return _c(
                    "v-chip",
                    {
                      key: i,
                      staticClass: "mr-1 px-2 mb-1",
                      attrs: { "x-small": "" },
                    },
                    [_vm._v(" " + _vm._s(tag) + " ")]
                  )
                }),
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "grid-actions" },
            [
              _c("TooltipButton", {
                attrs: {
                  color: "accent",
                  icon: true,
                  disabled: !_vm.slideInfo.applicationURL,
                  to: _vm.applicationConnectObject,
                  tooltipText: "Add to your account",
                  btnIcon: "mdi-book-plus",
                  btnClass: "float-right",
                },
              }),
              _c("TooltipButton", {
                attrs: {
                  icon: true,
                  disabled: !_vm.slideInfo.applicationURL,
                  btnIcon: _vm.applicationHostIcon,
                  btnClass: "float-right",
                  target: "_blank",
                  href: _vm.slideInfo.applicationURL,
                },
                scopedSlots: _vm._u([
                  {
                    key: "tooltipContent",
                    fn: function () {
                      return [
                        _vm._v(
                          " Open application on " +
                            _vm._s(_vm.applicationURL.hostname) +
                            " "
                        ),
                        _c("v-icon", { attrs: { small: "", color: "white" } }, [
                          _vm._v("mdi-open-in-new"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
      _vm.showDetailDialog
        ? _c("ApplicationDetailDialog", {
            attrs: {
              showDialog: _vm.showDetailDialog,
              applicationInfo: _vm.slideInfo,
              initialRepoData: _vm.repoData,
            },
            on: {
              "update:showDialog": function ($event) {
                _vm.showDetailDialog = $event
              },
              "update:show-dialog": function ($event) {
                _vm.showDetailDialog = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }