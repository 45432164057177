import router from "@/router";
import { v4 as uuidv4 } from "uuid";
import { Endpoints } from "@octokit/types";
import { signaloidApiClient } from "./signaloidClient";
import axios from "axios";

const github_oauth_app_id = process.env.VUE_APP_GITHUB_OAUTH_APP_ID;

export function initiateGithubLoginFlow(): void {
	const ghLoginState = uuidv4();
	const ghRedirectURL = new URL(router.resolve({ name: "GithubAuth" }).href, window.location.origin);
	const ghLoginPageURL = `https://github.com/login/oauth/authorize?client_id=${github_oauth_app_id}&scope=repo%20read:user&state=${ghLoginState}&redirect_uri=${ghRedirectURL.href}`;
	localStorage.setItem("gh-login-state", ghLoginState);
	window.location.href = ghLoginPageURL;
}

export function initiateGithubLoginRepositoryRetry(url: string): void {
	const ghLoginState = uuidv4();
	const ghRedirectURL = new URL(router.resolve({ name: "GithubAuth" }).href, window.location.origin);
	const params = new URLSearchParams(ghRedirectURL.search);

	params.append("connect", url);

	ghRedirectURL.search = params.toString();
	const ghLoginPageURL = `https://github.com/login/oauth/authorize?client_id=${github_oauth_app_id}&scope=repo%20read:user&state=${ghLoginState}&redirect_uri=${ghRedirectURL.href}`;

	localStorage.setItem("gh-login-state", ghLoginState);
	window.location.href = ghLoginPageURL;
}

// signaloidApiClient.interceptors.response.use(undefined, async (err) => {
// 	if (err?.response?.status === 401) {
// 		console.log("Intercepting '401 Unauthorized' response from GitHub...");
// 		try {
// 			initiateGithubLoginFlow();
// 			// return signaloidApiClient(err.config);
// 		} catch (reason) {
// 			console.error("User is not authenticated:", reason);
// 			throw new Error("Unauthorized");
// 		}
// 	}
// 	return Promise.reject(err);
// });

export function mockTimeout(ms: number, errors = false, msg: any = "") {
	return new Promise((resolve, reject) => {
		if (!msg) {
			msg = errors ? "some arbitrary error" : "request data";
		}
		if (errors) {
			setTimeout(reject, ms);
		} else {
			setTimeout(resolve, ms);
		}
	});
}

export function getRepoData(repoFullName: string) {
	return signaloidApiClient.get<Endpoints["GET /repos/{owner}/{repo}"]["response"]["data"]>(`proxy/github/repos/${repoFullName}`);
}

export function getRepoBranches(repoFullName: string, branchesPerRequest: number, branchPage: number) {
	return signaloidApiClient.get<Endpoints["GET /repos/{owner}/{repo}/branches"]["response"]["data"]>(
		`/proxy/github/repos/${repoFullName}/branches?per_page=${branchesPerRequest}&page=${branchPage}`
	);
}

export function getAuthUserDetails() {
	return signaloidApiClient.get<Endpoints["GET /user"]["response"]["data"]>(`/proxy/github/user`);
}


export async function getAllRepoBranches(repoFullName: string) {
	type BranchListType = Endpoints["GET /repos/{owner}/{repo}/branches"]["response"]["data"];

	//FIXME: Use implicit await promise return
	// return async (resolve, reject): Promise<typeof branchList> => {}

	// return new Promise<BranchListType>((resolve, reject) => {
	const branchesPerRequest = 100;
	let branchPage = 1;
	let fetchedAll = false;
	let branchList: BranchListType = [];

	while (!fetchedAll) {
		const response = await getRepoBranches(repoFullName, 100, branchPage);
		fetchedAll = response.data.length < branchesPerRequest;
		branchList = [...branchList, ...response.data];
		branchPage++;
	}
	return branchList;

	// });
}

export async function getRepoDirectories(repoFullName: string, path: string, branchName: string) {
	return signaloidApiClient.get<Endpoints["GET /repos/{owner}/{repo}/contents/{path}"]["response"]["data"]>(
		`/proxy/github/repos/${repoFullName}/contents/${path}`,
		{
			...(branchName !== "" && { params: { ref: branchName } }),
		}
	);
}

export async function getRepositoryTreeRecursive(repoFullName: string, shaName: string) {
	return signaloidApiClient.get<Endpoints["GET /repos/{owner}/{repo}/git/trees/{tree_sha}"]["response"]["data"]>(
		`/proxy/github/repos/${repoFullName}/git/trees/${shaName}`,
		{
			params: {
				recursive: 1,
			},
		}
	);
}

export async function getRepoConfigFile(
	repoFullName: string,
	branchName: string = "main",
	configFilePath: string = "signaloid.yaml"
) {
	return getRepoDirectories(repoFullName, configFilePath, branchName);
}

export async function detectSignaloidConfigFile(repositoryFullName: string, branch: string) {
	const acceptedConfigFilePaths = [
		/* In order of precedence */
		"signaloid.yaml",
		"signaloid.yml",
	];

	const response = await getRepoDirectories(repositoryFullName, "" /* Repository root */, branch);
	if (response.data instanceof Array) {
		const files = response.data.filter((e) => e.type === "file" && acceptedConfigFilePaths.includes(e.name));
		if (files.length === 0) {
			return null;
		}
		if (files.length === 1) {
			return files[0];
		}

		for (const name of acceptedConfigFilePaths) {
			const foundFile = files.find((f) => f.name === name);
			if (foundFile) {
				return foundFile;
			}
		}

		throw new Error("found configuration file and then lost it");
	} else if (response.data.type === "file") {
		/*
		 *	This could happen if the repository root only has a single file
		 */
		if (acceptedConfigFilePaths.includes(response.data.name)) {
			/*
			 *	There is a single file and it's a signaloid config file. Why though?
			 */
			return response.data;
		} else {
			return null;
		}
	} else {
		/*
		 *	This could happen if the repository root is empty (i.e. the repository is empty)
		 */
		return null;
	}
}
