
import { defineComponent } from "vue";
import UpgradeButton from "@/components/Common/UpgradeButton.vue";

// Stores
import { mapState } from "pinia";
import { useCoresStore } from "@/stores/cores";
import { useUserStore } from "@/stores/user";

// Types
import { tiers, groupCode, AccountGroupCode, compareTierCodes } from "@/js/tierconfig";

type Demo = {
	title: string;
	description: string;
	requiredCore: string;
	requiredTiers: AccountGroupCode[];
	atomicNetwork: string;
	prodDemoUrl: string;
	stagingDemoUrl: string;
	bannerImage: string;
	active: boolean;
};

const demos: Demo[] = [
	{
		title: "Precipitate Dislocation Model from Brown and Ham",
		description:
			"This example shows how uncertainties in empirical model parameters affect the uncertainty \
					distribution of the model's output, for a model of a physical process.",
		requiredCore: "C0Pro-XS",
		requiredTiers: ["developer-tier", "academic-research-tier", "pro-tier", "enterprise-tier"],
		atomicNetwork: "Materials Modeling",
		prodDemoUrl: "https://api-demo-mmmm-00.signaloid.io/",
		stagingDemoUrl: "https://api-demo-mmmm-00.signaloid.dev/",
		bannerImage: require("@/assets/Apps/api-demo-brown-ham.jpg"),
		active: false,
	},
	{
		title: "Bosch BME680 Calibration Routines",
		description:
			"Signaloid’s technology lets you quantify how measurement uncertainty and noise of sensor \
					transducer outputs affect your system’s calibrated outputs.",
		requiredCore: "C0Pro-M+",
		requiredTiers: ["developer-tier", "academic-research-tier", "pro-tier", "enterprise-tier"],
		atomicNetwork: "Sensor Manufacturing",
		prodDemoUrl: "https://api-demo-sicm-00.signaloid.io/",
		stagingDemoUrl: "https://api-demo-sicm-00.signaloid.dev/",
		bannerImage: require("@/assets/Apps/api-demo-bme680.jpg"),
		active: false,
	},
	{
		title: "Melexis MLX90640 Calibration Routines",
		description:
			"This example allows you to model the output uncertainty of the Melexis MLX90640 temperature sensor array,\
			given an uncertainty of measurand emissivity, and taking into account the ADC quantization error of the sensor.",
		requiredCore: "C0Pro-S",
		requiredTiers: ["developer-tier", "academic-research-tier", "pro-tier", "enterprise-tier"],
		atomicNetwork: "Sensor Manufacturing",
		prodDemoUrl: "https://api-demo-sicm-01.signaloid.io/",
		stagingDemoUrl: "https://api-demo-sicm-01.signaloid.dev/",
		bannerImage: require("@/assets/Apps/api-demo-mlx90640.jpg"),
		active: false,
	},
	{
		title: "Tax-Free Retirement Account",
		description:
			"This example allows you to specify arbitrary distributions for the uncertainty in future interest rates, \
			tax rates, and savings amounts and to see the effect of these on the uncertainty of your final withdrawal balance.",
		requiredCore: "C0Pro-Jupiter-S",
		requiredTiers: ["developer-tier", "academic-research-tier", "pro-tier", "enterprise-tier"],
		atomicNetwork: "Financial",
		prodDemoUrl: "https://api-demo-cfma-00.signaloid.io/",
		stagingDemoUrl: "https://api-demo-cfma-00.signaloid.dev/",
		bannerImage: require("@/assets/Apps/api-demo-retirement-account.jpg"),
		active: false,
	},
	{
		title: "Moonfire Ventures Portfolio Model",
		description: `This example implements a portfolio analysis model proposed by Farina et al. in the ArXiv article \
			      <a href="https://arxiv.org/pdf/2303.11013.pdf" target="_blank"> “Venture Capital Portfolio Construction” </a>. \
			      The example shows how you can use the Signaloid technology to avoid using the Monte Carlo simulations proposed in \
			      the original work.`,
		requiredCore: "C0Pro-M",
		requiredTiers: ["developer-tier", "academic-research-tier", "pro-tier", "enterprise-tier"],
		atomicNetwork: "Financial",
		prodDemoUrl: "https://api-demo-fsix-00.signaloid.io/",
		stagingDemoUrl: "https://api-demo-fsix-00.signaloid.dev/",
		bannerImage: require("@/assets/Apps/api-demo-finance.jpg"),
		active: false,
	},
	// {
	// 	title: "Icetime FPGA Timing Analysis Tool",
	// 	description:
	// 		"An implementation of the Icetime FPGA Timing Analysis Tool with \
	// 				Component-Level Timing Uncertainties.",
	// 	demoUrl: "https://api-demo-edaa-00.signaloid.io/",
	// 	requiredCore: "C0-XL",
	// 	requiredTier: "enterprise-tier",
	// 	atomicNetwork: "EDA Applications",
	// 	bannerImage: require("@/assets/Apps/api-demo-icetime.jpg"),
	// 	active: false,
	// },
	{
		title: "Battery State of Charge Estimation",
		description:
			"The state of charge of a battery expresses its remaining capacity \
			as a percentage of its total capacity. Accurately estimating the state of \
			charge is crucial in battery-powered systems. This example models the state \
			of charge of a Panasonic CGR-17500 cell given a noisy voltage measurement.",
		requiredCore: "C0Pro-M",
		requiredTiers: ["developer-tier", "academic-research-tier", "pro-tier", "enterprise-tier"],
		atomicNetwork: "Energy Storage",
		prodDemoUrl: "https://api-demo-bess-00.signaloid.io/",
		stagingDemoUrl: "https://api-demo-bess-00.signaloid.dev/",
		bannerImage: require("@/assets/Apps/api-demo-battery-estimation.jpg"),
		active: false,
	},
];

export default defineComponent({
	name: "Apps",
	components: { UpgradeButton },
	setup() {
		const coresStore = useCoresStore();
		const userStore = useUserStore();
		return { coresStore, userStore };
	},
	methods: {
		getActive(requiredTiers: AccountGroupCode[]) {
			if (this.userSubscriptionTierCode == "") {
				return false;
			}
			const userTierCode = this.userSubscriptionTierCode as AccountGroupCode;
			return requiredTiers.some((tierCode) => compareTierCodes(userTierCode, tierCode));
		},
	},
	computed: {
		...mapState(useCoresStore, {
			coresDisabledByTierCoreList: "coresFilteredCoresByUserTier",
			coresSatisfyMinConfig: "coresSatisfyMinConfig",
		}),
		activeDemos() {
			const activeDemos: Demo[] = [];
			for (let i = 0; i < demos.length; i++) {
				const demo = demos[i];
				demo.active = this.getActive(demo.requiredTiers);
				activeDemos.push(demo);
			}
			return activeDemos;
		},
		userSubscriptionTierCode(): "" | AccountGroupCode {
			return groupCode(this.userStore.currentUserSubscriptions?.Tier ?? "");
		},
		getUrlBasedOnEnv() {
			return (demo: Demo) => {
				const isProdEnv = process.env.NODE_ENV === "production";
				return isProdEnv ? demo.prodDemoUrl : demo.stagingDemoUrl;
			};
		},
	},
	created() {
		this.coresStore.getCoresFilteredCoresByUserTier();
	},
});
