var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      staticClass: "d-flex flex-column justify-space-between",
      attrs: {
        outlined: "",
        width: "100%",
        rounded: "lg",
        "max-width": "700px",
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "v-card",
            {
              staticClass: "px-2 px-sm-5 d-flex",
              attrs: {
                outlined: "",
                width: "100%",
                height: "64px",
                rounded: "t-lg b-0",
                color: "grey lighten-5",
              },
            },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-3 ml-sm-2 px-0",
                      attrs: { cols: "auto" },
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "pa-auto",
                          attrs: { rounded: "circle", outlined: "" },
                        },
                        [
                          _c("v-icon", { attrs: { size: 48 } }, [
                            _vm._v(" mdi-account-circle "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-col", { staticClass: "line-clamp-1" }, [
                    _c(
                      "div",
                      { staticClass: "line-clamp-1" },
                      [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(
                            _vm._s(
                              _vm.authenticatedUser?.attributes?.name ??
                                _vm.authenticatedUser?.attributes?.email
                            )
                          ),
                        ]),
                        _vm.$vuetify.breakpoint.smAndUp &&
                        _vm.authenticatedUser?.attributes?.name
                          ? [
                              _vm._v(
                                " (" +
                                  _vm._s(
                                    _vm.authenticatedUser?.attributes?.email
                                  ) +
                                  ") "
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c("div", { staticClass: "text-body-2 line-clamp-1" }, [
                      _vm._v(" " + _vm._s(_vm.usageCardSubtitle) + " "),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _vm.currentUserTierCode
                        ? _c("TierMarker", {
                            attrs: { tierCode: _vm.currentUserTierCode },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c("div", { staticClass: "px-4 px-sm-6 py-2 text-body-2" }, [
            _vm._v(
              " Your " +
                _vm._s(_vm.currentUserTierDetails?.title) +
                " tier includes: "
            ),
            _vm.currentUserTierDetails
              ? _c("div", { staticClass: "pl-5" }, [
                  _c(
                    "div",
                    { staticClass: "font-weight-regular" },
                    [
                      _c("v-icon", [_vm._v("mdi-circle-small")]),
                      _vm._v(
                        _vm._s(
                          _vm.toStringWithMagnitude(
                            _vm.currentUserTierDetails.allowance
                              .DynamicInstructionCount
                          )
                        ) +
                          " Signaloid C0 Processor Dynamic Instructions per month "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "font-weight-regular" },
                    [
                      _c("v-icon", [_vm._v("mdi-circle-small")]),
                      _vm._v(
                        _vm._s(
                          _vm.toStringWithByteMagnitude(
                            _vm.currentUserTierDetails.allowance
                              .CloudStorageBytes
                          )
                        ) + " Signaloid Cloud Storage "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "font-weight-regular" },
                    [
                      _c("v-icon", [_vm._v("mdi-circle-small")]),
                      _vm._v(
                        _vm._s(
                          _vm.toStringWithMagnitude(
                            _vm.currentUserTierDetails.allowance.CoreCount
                          )
                        ) +
                          " Signaloid custom C0 processor core" +
                          _vm._s(
                            _vm.currentUserTierDetails.allowance.CoreCount == 1
                              ? ""
                              : "s"
                          ) +
                          " "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "font-weight-regular" },
                    [
                      _c("v-icon", [_vm._v("mdi-circle-small")]),
                      _vm._v(
                        _vm._s(
                          _vm.toStringWithMagnitude(
                            _vm.currentUserTierDetails.allowance.PlotCount
                          )
                        ) + " Signaloid Distribution Plots "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "font-weight-regular" },
                    [
                      _c("v-icon", [_vm._v("mdi-circle-small")]),
                      _vm._v(
                        "Connect " +
                          _vm._s(
                            _vm.toStringWithMagnitude(
                              _vm.currentUserTierDetails.allowance
                                .RepositoryCount
                            )
                          ) +
                          " " +
                          _vm._s(
                            _vm.currentUserTierDetails.allowance
                              .RepositoryCount == 1
                              ? "repository"
                              : "repositories"
                          ) +
                          " "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "font-weight-regular" },
                    [
                      _c("v-icon", [_vm._v("mdi-circle-small")]),
                      _vm._v(
                        _vm._s(
                          _vm.toStringWithMagnitude(
                            _vm.currentUserTierDetails.allowance.DataDriveCount
                          )
                        ) +
                          " Signaloid Data Drive" +
                          _vm._s(
                            _vm.currentUserTierDetails.allowance
                              .DataDriveCount == 1
                              ? ""
                              : "s"
                          ) +
                          " "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "font-weight-regular" },
                    [
                      _c("v-icon", [_vm._v("mdi-circle-small")]),
                      _vm._v(
                        _vm._s(
                          _vm.toStringWithMagnitude(
                            _vm.currentUserTierDetails.allowance.GatewayCount
                          )
                        ) +
                          " Signaloid Sensor Gateway connection" +
                          _vm._s(
                            _vm.currentUserTierDetails.allowance.GatewayCount ==
                              1
                              ? ""
                              : "s"
                          ) +
                          " "
                      ),
                    ],
                    1
                  ),
                  _vm.currentUserTierDetails.allowance.BucketCount !== 0
                    ? _c(
                        "div",
                        { staticClass: "font-weight-regular" },
                        [
                          _c("v-icon", [_vm._v("mdi-circle-small")]),
                          _vm._v(
                            _vm._s(
                              _vm.toStringWithMagnitude(
                                _vm.currentUserTierDetails.allowance.BucketCount
                              )
                            ) +
                              " AWS S3 Bucket connection" +
                              _vm._s(
                                _vm.currentUserTierDetails.allowance
                                  .BucketCount == 1
                                  ? ""
                                  : "s"
                              ) +
                              " "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentUserTierDetails.allowance.ConcurrentTaskCount !== 1
                    ? _c(
                        "div",
                        { staticClass: "font-weight-regular" },
                        [
                          _c("v-icon", [_vm._v("mdi-circle-small")]),
                          _vm._v(
                            _vm._s(
                              _vm.toStringWithMagnitude(
                                _vm.currentUserTierDetails.allowance
                                  .ConcurrentTaskCount
                              )
                            ) +
                              " Concurrent Code Execution Task" +
                              _vm._s(
                                _vm.currentUserTierDetails.allowance
                                  .ConcurrentTaskCount == 1
                                  ? ""
                                  : "s"
                              ) +
                              " "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "float-bottom" },
        [
          _c("v-divider"),
          _c(
            "div",
            { staticClass: "px-2 px-sm-5 py-2 d-flex justify-end" },
            [
              _vm.userCanUpgrade
                ? _c("TooltipButton", {
                    attrs: {
                      btnText: "Upgrade",
                      btnIcon: "mdi-chevron-double-up",
                      width: "120px",
                      color: "accent",
                      to: { path: "/billing" },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }