var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      staticClass: "pa-2 pa-sm-3",
      attrs: {
        rounded: "lg",
        outlined: "",
        "data-test": "sig-cloud-storage-card",
      },
    },
    [
      _c(
        "v-row",
        { staticClass: "pb-2", attrs: { "no-gutters": "", align: "end" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("v-icon", { staticClass: "mr-1" }, [
                _vm._v(_vm._s(_vm.dataSourceIcon("SignaloidCloudStorage"))),
              ]),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "auto" } }, [
            _vm._v(" Signaloid Cloud Storage "),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "text-body-2 mt-1 ml-1",
          attrs: { align: "start", "no-gutters": "" },
        },
        [
          _c(
            "v-col",
            { staticClass: "ml-0 ml-sm-2" },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-2", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { small: "" } },
                        [_vm._v("mdi-chart-donut")]
                      ),
                      _vm._v("Usage: "),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "px-0", attrs: { cols: "2" } },
                    [
                      _c("v-progress-linear", {
                        attrs: {
                          rounded: "",
                          height: "6",
                          color: _vm.usageBarColour,
                          value: _vm.usagePercentage,
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.fetchingUsage
                    ? _c(
                        "v-col",
                        { staticClass: "text-caption" },
                        [
                          _vm._v(
                            _vm._s(_vm.formatUsageString(_vm.storageUsage)) +
                              " of " +
                              _vm._s(
                                _vm.formatUsageString(_vm.storageAllowance)
                              ) +
                              " "
                          ),
                          _vm.errorFetchingUsage
                            ? [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "", "open-delay": "500" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        size: "14",
                                                        color: "warning",
                                                      },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v("mdi-alert-outline")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2456155542
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "There was an error while loading your storage usage. Your data is not affected by this error. If this error persists, please contact Signaloid at developer-support@signaloid.com."
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pt-1 pt-sm-1",
              attrs: { cols: "12", "align-self": "end" },
            },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "", justify: "end", align: "end" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("TooltipButton", {
                        attrs: {
                          color: "accent",
                          width: "50px",
                          btnIcon: "mdi-folder-eye-outline",
                          tooltipText: "View Files",
                          "data-test": "view-files-button",
                        },
                        on: {
                          click: function ($event) {
                            _vm.showFilesDialog = true
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "mx-1" }),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("TooltipButton", {
                        attrs: {
                          color: "primary",
                          width: "50px",
                          btnIcon: "mdi-cloud-upload-outline",
                          tooltipText: "Upload Files",
                          "data-test": "upload-files-button",
                        },
                        on: {
                          click: function ($event) {
                            _vm.showFileUploadDialog = true
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showFilesDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                width: _vm.$vuetify.breakpoint.mobile ? "95%" : "500px",
                scrollable: "",
              },
              on: {
                "click:outside": function ($event) {
                  _vm.showFilesDialog = false
                },
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  )
                    return null
                  _vm.showFilesDialog = false
                },
              },
              model: {
                value: _vm.showFilesDialog,
                callback: function ($$v) {
                  _vm.showFilesDialog = $$v
                },
                expression: "showFilesDialog",
              },
            },
            [
              _c("SigCloudStorageFileList", {
                attrs: { showDialog: _vm.showFilesDialog },
                on: {
                  "update:showDialog": function ($event) {
                    _vm.showFilesDialog = $event
                  },
                  "update:show-dialog": function ($event) {
                    _vm.showFilesDialog = $event
                  },
                  "upload-successful": _vm.fetchUsage,
                  "delete-successful": _vm.fetchUsage,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showFileUploadDialog
        ? _c("SigCloudStorageFileUploadDialog", {
            attrs: { showDialog: _vm.showFileUploadDialog },
            on: {
              "update:showDialog": function ($event) {
                _vm.showFileUploadDialog = $event
              },
              "update:show-dialog": function ($event) {
                _vm.showFileUploadDialog = $event
              },
              "upload-successful": _vm.fetchUsage,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }