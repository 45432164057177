
import { defineComponent } from "vue";
export default defineComponent({
	name: "SourcePlaceholderCard",
	props: {
		minHeight: { type: String, default: "50px" },
		loading: { type: Boolean, default: false },
	},
	methods: {
		onClickHandler() {
			this.$emit("card-clicked");
		},
	},
});
