var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    { on: { click: _vm.clickHandler } },
    [_vm._t("clickTarget")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }