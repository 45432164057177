var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      staticClass: "text-center",
      attrs: {
        width: _vm.$vuetify.breakpoint.mobile ? "95%" : "500px",
        scrollable: "",
      },
      on: {
        "click:outside": _vm.closeDialog,
        keydown: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            )
              return null
            return _vm.closeDialog.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.validateAndCommitCore.apply(null, arguments)
          },
        ],
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { "data-test": "modify-core-dialog" } },
        [
          _c(
            "v-card-title",
            [
              _vm._v(_vm._s(_vm.formTitle) + " "),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "text--primary",
                  attrs: {
                    color: "white",
                    small: "",
                    outlined: "",
                    height: "36",
                    elevation: "0",
                    "data-test": "modify-core-dialog-close-button",
                  },
                  on: { click: _vm.closeDialog },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "pt-3" },
            [
              _c(
                "v-form",
                {
                  ref: "addCoreForm",
                  staticClass: "my-2",
                  attrs: { disabled: !_vm.isEditable },
                  model: {
                    value: _vm.formValid,
                    callback: function ($$v) {
                      _vm.formValid = $$v
                    },
                    expression: "formValid",
                  },
                },
                [
                  _vm.modifyCoreRequest
                    ? _c(
                        "v-row",
                        {
                          attrs: { justify: "space-between", align: "center" },
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0 my-0", attrs: { cols: "11" } },
                            [
                              _c("div", { staticClass: "my-1" }, [
                                _vm._v("Core ID"),
                              ]),
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  required: "",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.coreConfig.CoreID,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.coreConfig, "CoreID", $$v)
                                  },
                                  expression: "coreConfig.CoreID",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "py-0 pr-4 my-0 d-flex align-center justify-center",
                              attrs: { cols: "1" },
                            },
                            [
                              _c("CopyToClipboardButton", {
                                attrs: {
                                  "value-to-copy": _vm.coreConfig.CoreID,
                                  "value-name": "Core ID",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    {
                      staticClass: "pt-0 mt-n3",
                      attrs: { justify: "space-between", align: "end" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0 my-0 pr-0",
                          attrs: {
                            cols: "8",
                            "data-test": "core-name-input-container",
                          },
                        },
                        [
                          _c("div", { staticClass: "my-1" }, [
                            _vm._v("Core Name (required)"),
                          ]),
                          _c("v-text-field", {
                            staticClass: "mb-0",
                            attrs: {
                              outlined: "",
                              dense: "",
                              rules: [
                                (v) => !!v || "Core Name is required",
                                (v) =>
                                  v.length <= _vm.maxCoreNameLength ||
                                  `Core Name has to be less than ${_vm.maxCoreNameLength} characters long.`,
                                (v) =>
                                  !_vm.existingCoreNames.some((c) => {
                                    return c == v
                                  }) || "Core with this name already exists!",
                              ],
                              required: "",
                              autofocus: true,
                              "data-test": "core-name-input",
                            },
                            model: {
                              value: _vm.coreConfig.Name,
                              callback: function ($$v) {
                                _vm.$set(_vm.coreConfig, "Name", $$v)
                              },
                              expression: "coreConfig.Name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0 my-0", attrs: { cols: "4" } },
                        [
                          _c("div", { staticClass: "my-1" }, [
                            _vm._v("Core Class"),
                          ]),
                          _c("v-select", {
                            staticClass: "mb-0",
                            attrs: {
                              items: _vm.coresStore.availableCoreClasses,
                              "item-text": "Name",
                              "item-value": "Name",
                              "item-disabled": "!Enabled",
                              dense: "",
                              outlined: "",
                              "data-test": "core-class-input",
                            },
                            on: { input: _vm.coreClassChangeHandler },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(" " + _vm._s(item.Name) + " "),
                                        ]),
                                      ],
                                      1
                                    ),
                                    item.Experimental
                                      ? _c(
                                          "v-list-item-action",
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                attrs: {
                                                  "x-small": "",
                                                  color: "warning",
                                                },
                                              },
                                              [_vm._v(" Beta ")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.coreConfig.Class,
                              callback: function ($$v) {
                                _vm.$set(_vm.coreConfig, "Class", $$v)
                              },
                              expression: "coreConfig.Class",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-expand-transition",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mt-0 mb-2 pt-0 pb-0 mt-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "v-alert",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.availableSelectedCoreClass,
                                      expression: "!availableSelectedCoreClass",
                                    },
                                  ],
                                  staticClass:
                                    "text-body-2 pa-2 pl-5 mt-0 mb-4 mr-2",
                                  attrs: {
                                    icon: false,
                                    border: "left",
                                    type: "warning",
                                    outlined: "",
                                    elevation: "0",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "text-block" }, [
                                    _vm._v(
                                      " Selected Core Class is not available on " +
                                        _vm._s(_vm.currentUserTier) +
                                        " tier. "
                                    ),
                                  ]),
                                  _c("UpgradeButton"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 mt-n3 mb-1",
                          attrs: {
                            cols: "12",
                            md: "12",
                            "data-test":
                              "core-microarchitecture-input-container",
                          },
                        },
                        [
                          _c("div", { staticClass: "my-1" }, [
                            _vm._v("Microachitecture"),
                          ]),
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              items: _vm.microarchitecturesSupportedByCoreClass(
                                _vm.coreConfig.Class
                              ),
                              "item-text": "Name",
                              "item-value": "Name",
                              "item-disabled": "Disabled",
                              "data-test": "core-microarchitecture-input",
                            },
                            on: { input: _vm.microarchitectureChangeHandler },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(" " + _vm._s(item.Name) + " "),
                                        ]),
                                      ],
                                      1
                                    ),
                                    item.Disabled && !item.Restricted
                                      ? _c(
                                          "v-list-item-action",
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                attrs: {
                                                  "x-small": "",
                                                  color: "secondary",
                                                  outlined: "",
                                                },
                                              },
                                              [_vm._v(" Unavailable ")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.Restricted
                                      ? _c(
                                          "v-list-item-action",
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                attrs: {
                                                  "x-small": "",
                                                  color: "secondary",
                                                  outlined: "",
                                                },
                                              },
                                              [_vm._v(" Tier Restricted ")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.Experimental
                                      ? _c(
                                          "v-list-item-action",
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                attrs: {
                                                  "x-small": "",
                                                  color: "warning",
                                                },
                                              },
                                              [_vm._v(" Alpha release ")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      {
                                        attrs: {
                                          "data-test": `core-microarchitecture-${item.name}`,
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item",
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.name) +
                                                      " "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            item.disabled && !item.restricted
                                              ? _c(
                                                  "v-list-item-action",
                                                  [
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                          color: "secondary",
                                                          outlined: "",
                                                        },
                                                      },
                                                      [_vm._v(" Unavailable ")]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.restricted
                                              ? _c(
                                                  "v-list-item-action",
                                                  [
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                          color: "secondary",
                                                          outlined: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Tier Restricted "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.experimental
                                              ? _c(
                                                  "v-list-item-action",
                                                  [
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                          color: "warning",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Alpha release "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.coreConfig.Microarchitecture,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.coreConfig,
                                  "Microarchitecture",
                                  $$v
                                )
                              },
                              expression: "coreConfig.Microarchitecture",
                            },
                          }),
                          _c(
                            "v-expand-transition",
                            [
                              _c(
                                "v-alert",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.availableSelectedMicroarchitecture,
                                      expression:
                                        "!availableSelectedMicroarchitecture",
                                    },
                                  ],
                                  staticClass:
                                    "text-body-2 pa-2 pl-5 mb-0 mt-3 mr-2",
                                  attrs: {
                                    icon: false,
                                    border: "left",
                                    type: "warning",
                                    outlined: "",
                                    elevation: "0",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "text-block" }, [
                                    _vm._v(
                                      " Selected Microarchitecture not available on " +
                                        _vm._s(_vm.currentUserTier) +
                                        " tier. "
                                    ),
                                  ]),
                                  _c("UpgradeButton"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.coreSupportsCustomPrecision &&
                  _vm.coreSupportsSigmaEquivalent
                    ? [
                        _c(
                          "v-row",
                          {
                            attrs: {
                              "no-gutters": _vm.$vuetify.breakpoint.mobile,
                            },
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0 my-0",
                                attrs: {
                                  cols: "12",
                                  md: "12",
                                  "data-test":
                                    "core-mcequivalent-input-container",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "mt-1" },
                                  [
                                    _vm._v(" Monte Carlo Equivalent "),
                                    _c("DisclosureIcon", {
                                      attrs: {
                                        "disclosure-text":
                                          "For a given core representation size, the sigma (σ) value corresponds to the number of standard deviations of a Gaussian distribution that the Signaloid core can accurately represent. A sigma value of x means that the Signaloid core can accurately represent a Gaussian distribution within the domain [μ - xσ, μ + xσ], where μ is the mean and σ is the standard deviation of the Gaussian distribution. The higher the value, the more accurate the Signaloid core is at capturing the tails of distributions.",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("v-slider", {
                                  staticClass: "text-caption",
                                  attrs: {
                                    "tick-labels":
                                      _vm.sigmaStepsLabelWithFilter,
                                    max: _vm.sigmaSteps.length - 1,
                                    color: "warning",
                                    step: "1",
                                    "thumb-size": 36,
                                    "data-test": "core-mcequivalent-input",
                                  },
                                  on: { input: _vm.corePrecisionChangeHandler },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "thumb-label",
                                        fn: function ({ value }) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.sigmaStepsLabel[value]
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    562087
                                  ),
                                  model: {
                                    value: _vm.selectedPrecisionIndex,
                                    callback: function ($$v) {
                                      _vm.selectedPrecisionIndex = $$v
                                    },
                                    expression: "selectedPrecisionIndex",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.coreSupportsCustomPrecision
                    ? [
                        _c(
                          "v-row",
                          {
                            attrs: {
                              "no-gutters": _vm.$vuetify.breakpoint.mobile,
                            },
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0 my-0",
                                attrs: {
                                  cols: "12",
                                  md: "12",
                                  "data-test": "core-precision-input-container",
                                },
                              },
                              [
                                _c("div", { staticClass: "mt-1" }, [
                                  _vm._v("Signaloid Representation Size"),
                                ]),
                                _c("v-slider", {
                                  staticClass: "text-caption",
                                  attrs: {
                                    "tick-labels": _vm.filteredPrecisionSteps,
                                    max: _vm.precisionSteps.length - 1,
                                    color: "warning",
                                    step: "1",
                                    "thumb-size": 36,
                                    "data-test": "core-precision-input",
                                  },
                                  on: { input: _vm.corePrecisionChangeHandler },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "thumb-label",
                                        fn: function ({ value }) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.precisionSteps[value]
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    626489542
                                  ),
                                  model: {
                                    value: _vm.selectedPrecisionIndex,
                                    callback: function ($$v) {
                                      _vm.selectedPrecisionIndex = $$v
                                    },
                                    expression: "selectedPrecisionIndex",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-expand-transition",
                          [
                            _c(
                              "v-alert",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.availablePrecisionStep,
                                    expression: "!availablePrecisionStep",
                                  },
                                ],
                                staticClass:
                                  "text-body-2 pa-2 pl-5 mt-3 mb-4 mr-2",
                                attrs: {
                                  icon: false,
                                  border: "left",
                                  type: "warning",
                                  outlined: "",
                                  elevation: "0",
                                },
                              },
                              [
                                _c("div", { staticClass: "text-block" }, [
                                  _vm._v(
                                    " Selected Representation Size not available on " +
                                      _vm._s(_vm.currentUserTier) +
                                      " tier. "
                                  ),
                                ]),
                                _c("UpgradeButton"),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.coreConfig.Microarchitecture == "Reference"
                    ? [
                        _c(
                          "v-row",
                          {
                            attrs: {
                              "no-gutters": _vm.$vuetify.breakpoint.mobile,
                            },
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0 my-0",
                                attrs: {
                                  cols: "12",
                                  md: "12",
                                  "data-test": "core-precision-input-container",
                                },
                              },
                              [
                                _c("div", { staticClass: "mt-1" }, [
                                  _vm._v("Reference Quality Level"),
                                ]),
                                _c("v-slider", {
                                  staticClass: "text-caption",
                                  attrs: {
                                    "tick-labels":
                                      _vm.filteredReferencePrecisionSteps,
                                    max: _vm.referencePrecisionSteps.length - 1,
                                    color: "warning",
                                    step: "1",
                                    "thumb-size": 36,
                                    "data-test": "core-precision-input",
                                  },
                                  on: { input: _vm.corePrecisionChangeHandler },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "thumb-label",
                                        fn: function ({ value }) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm
                                                    .referencePrecisionStepsLabel[
                                                    value
                                                  ]
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3507817579
                                  ),
                                  model: {
                                    value: _vm.selectedReferencePrecisionIndex,
                                    callback: function ($$v) {
                                      _vm.selectedReferencePrecisionIndex = $$v
                                    },
                                    expression:
                                      "selectedReferencePrecisionIndex",
                                  },
                                }),
                                _c(
                                  "v-expand-transition",
                                  [
                                    _c(
                                      "v-alert",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.availablePrecisionStep,
                                            expression:
                                              "!availablePrecisionStep",
                                          },
                                        ],
                                        staticClass:
                                          "text-body-2 pa-2 pl-5 mb-2 mr-2",
                                        attrs: {
                                          icon: false,
                                          border: "left",
                                          type: "warning",
                                          outlined: "",
                                          elevation: "0",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "text-block" },
                                          [
                                            _vm._v(
                                              " Selected Reference Quality Level not available on " +
                                                _vm._s(_vm.currentUserTier) +
                                                " tier. "
                                            ),
                                          ]
                                        ),
                                        _c("UpgradeButton"),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": _vm.$vuetify.breakpoint.mobile } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0 my-0",
                          attrs: {
                            cols: "12",
                            md: "12",
                            "data-test": "core-memory-input-container",
                          },
                        },
                        [
                          _c("div", { staticClass: "mt-1" }, [
                            _vm._v("Memory Allocation"),
                          ]),
                          _c("v-slider", {
                            staticClass: "text-caption",
                            attrs: {
                              "tick-labels": _vm.filteredMemorySizeSteps,
                              max: _vm.memorySizeSteps.length - 1,
                              color: "warning",
                              step: "1",
                              "thumb-size": 42,
                              "data-test": "core-memory-input",
                            },
                            on: { input: _vm.updateCoreMemorySize },
                            scopedSlots: _vm._u([
                              {
                                key: "thumb-label",
                                fn: function ({ value }) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.memorySizeStepsLabel[value]
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.selectedMemorySizeIndex,
                              callback: function ($$v) {
                                _vm.selectedMemorySizeIndex = $$v
                              },
                              expression: "selectedMemorySizeIndex",
                            },
                          }),
                          _c(
                            "v-expand-transition",
                            [
                              _c(
                                "v-alert",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.availableMemorySizeStep,
                                      expression: "!availableMemorySizeStep",
                                    },
                                  ],
                                  staticClass:
                                    "text-body-2 pa-2 pl-5 mb-2 mr-2",
                                  attrs: {
                                    icon: false,
                                    border: "left",
                                    type: "warning",
                                    outlined: "",
                                    elevation: "0",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "text-block" }, [
                                    _vm._v(
                                      " Selected Memory Allocation not available on " +
                                        _vm._s(_vm.currentUserTier) +
                                        " tier. "
                                    ),
                                  ]),
                                  _c("UpgradeButton"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0 my-0",
                          attrs: {
                            cols: "12",
                            md: "12",
                            "data-test": "core-correlation-input-container",
                          },
                        },
                        [
                          _c("div", { staticClass: "mt-1" }, [
                            _vm._v("Correlation Tracking"),
                          ]),
                          _vm.supportedCorrelationTrackingMethods(
                            _vm.coreConfig.Microarchitecture
                          ).length > 0
                            ? _c("v-select", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "",
                                  items:
                                    _vm.supportedCorrelationTrackingMethods(
                                      _vm.coreConfig.Microarchitecture
                                    ),
                                  "item-text": "Name",
                                  "item-value": "Code",
                                  "data-test": "core-correlation-input",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function ({ item }) {
                                        return [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  " " + _vm._s(item.Name) + " "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          item.Experimental
                                            ? _c(
                                                "v-list-item-action",
                                                [
                                                  _c(
                                                    "v-chip",
                                                    {
                                                      attrs: {
                                                        "x-small": "",
                                                        color: "warning",
                                                      },
                                                    },
                                                    [_vm._v(" Alpha release ")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2779569762
                                ),
                                model: {
                                  value: _vm.coreConfig.CorrelationTracking,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.coreConfig,
                                      "CorrelationTracking",
                                      $$v
                                    )
                                  },
                                  expression: "coreConfig.CorrelationTracking",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end" },
            [
              _c("div", { staticClass: "my-3" }, [
                _vm._v(_vm._s(_vm.coreDescription)),
              ]),
              _c(
                "v-expand-transition",
                [
                  _c(
                    "v-alert",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.remoteErrorMessage,
                          expression: "remoteErrorMessage",
                        },
                      ],
                      staticClass: "text-body-2 pa-2 pl-5 mb-0 mr-2",
                      attrs: {
                        icon: false,
                        border: "left",
                        type: "warning",
                        outlined: "",
                        elevation: "0",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.remoteErrorMessage) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-expand-transition",
                [
                  _c(
                    "v-alert",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.modifyCoreRequest &&
                            _vm.customCoresLimitReached,
                          expression:
                            "!modifyCoreRequest && customCoresLimitReached",
                        },
                      ],
                      staticClass: "text-body-2 pa-2 pl-5 mb-0 mr-2",
                      attrs: {
                        icon: false,
                        border: "left",
                        type: "warning",
                        outlined: "",
                        elevation: "0",
                      },
                    },
                    [
                      _c("div", { staticClass: "text-block" }, [
                        _vm._v("Custom Core Limit Reached"),
                      ]),
                      _c("UpgradeButton"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.isEditable
                ? _c(
                    "div",
                    [
                      _c("v-spacer"),
                      _c("LimitableActionButton", {
                        attrs: {
                          loading: _vm.waitingForSeverResponse,
                          color: "primary",
                          width: "120px",
                          btnClass: "float-right",
                          "data-test": "commit-core-button",
                          disabled: _vm.submitButtonDisabled,
                          "limits-to-check": [
                            _vm.UserLimitsE.AllowedCoreClasses,
                          ],
                          "resource-usage-overrides": [_vm.coreConfig.Class],
                          "btn-text": _vm.submitButtonText,
                        },
                        on: { "valid-click": _vm.validateAndCommitCore },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }