
import { defineComponent } from "vue";

export default defineComponent({
	name: "DocumentationIcon",
	props: {
		tooltipText: { type: String, default: "" },
		documentationLink: { type: String, required: true, default: "https://docs.signaloid.io" },
	},
});
