
import { defineComponent, PropType } from "vue";
import { deleteUserFiles } from "@/js/signaloidClient";
import { FileTree } from "@/types/general";
import { monitoringCaptureError } from "@/plugins/monitoring";

type ComponentData = { deleteInProgress: boolean; recursiveOptionConfirmationText: string };

export default defineComponent({
	name: "SigCloudStorageFileDeleteDialog",
	props: {
		showDialog: { type: Boolean, default: false },
		deletePathData: { type: Object as PropType<FileTree>, required: true },
	},
	data: (): ComponentData => ({
		deleteInProgress: false,
		recursiveOptionConfirmationText: "",
	}),
	methods: {
		async commitDeletePath() {
			this.deleteInProgress = true;
			const isRecursiveAvailable = this.recursiveOptionConfirmationText == "permanently delete";
			try {
				await deleteUserFiles(
					this.deletePathData.path,
					this.deletePathData.type == "dir",
					isRecursiveAvailable
				);

				this.$emit("delete-successful");
				this.closeDialog();
			} catch (error) {
				monitoringCaptureError(error, "Delete file from user cloud storage");
			} finally {
				this.deleteInProgress = false;
			}
		},
		closeDialog() {
			this.$emit("close-dialog", false);
		},
		dirNotEmpty() {
			return this.deletePathData.type == "dir" && this.$props.deletePathData.children.length != 0;
		},
		deleteButtonDisabled() {
			const dirNotEmpty = this.deletePathData.type == "dir" && this.deletePathData.children.length != 0;
			const isRecursiveAvailable = this.recursiveOptionConfirmationText == "permanently delete";
			return dirNotEmpty && !isRecursiveAvailable;
		},
	},
});
