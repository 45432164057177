var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-container",
        {
          model: {
            value: _vm.dialogVisible,
            callback: function ($$v) {
              _vm.dialogVisible = $$v
            },
            expression: "dialogVisible",
          },
        },
        [
          _c(
            "v-dialog",
            {
              attrs: { width: "500px", height: "200px" },
              on: {
                "click:outside": _vm.closeSearchDialog,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  )
                    return null
                  return _vm.closeSearchDialog.apply(null, arguments)
                },
              },
              model: {
                value: _vm.searchBoxVisible,
                callback: function ($$v) {
                  _vm.searchBoxVisible = $$v
                },
                expression: "searchBoxVisible",
              },
            },
            [
              _c(
                "v-card",
                { attrs: { color: "white", rounded: "lg" } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "px-6 py-2" },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "", align: "baseline" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "append-outer-icon": "",
                                  label: "Search GitHub",
                                  type: "text",
                                  autofocus: true,
                                  "data-test": "github-search-input",
                                },
                                on: {
                                  keydown: _vm.searchBoxKeydownHandler,
                                  "click:append-outer": _vm.searchGithub,
                                },
                                model: {
                                  value: _vm.searchQuery,
                                  callback: function ($$v) {
                                    _vm.searchQuery = $$v
                                  },
                                  expression: "searchQuery",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-3 pa-4",
                                  attrs: {
                                    color: "primary",
                                    small: "",
                                    disabled: !_vm.searchQuery,
                                    height: "36",
                                    elevation: "1",
                                    "data-test": "github-search-button",
                                  },
                                  on: { click: _vm.searchGithub },
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-cloud-search-outline"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              staticClass: "mx-0 px-0",
              attrs: {
                width: _vm.$vuetify.breakpoint.smAndDown ? "95%" : "1000px",
                height: _vm.$vuetify.breakpoint.smAndDown ? "95%" : "80vh",
                scrollable: "",
              },
              on: {
                "click:outside": _vm.closeDialog,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  )
                    return null
                  return _vm.closeDialog.apply(null, arguments)
                },
              },
              model: {
                value: _vm.resultsBoxVisible,
                callback: function ($$v) {
                  _vm.resultsBoxVisible = $$v
                },
                expression: "resultsBoxVisible",
              },
            },
            [
              _c(
                "v-card",
                { attrs: { rounded: "lg" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "mx-0 pt-5" },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "px-md-6 px-1",
                          attrs: {
                            "justify-content": "space-between",
                            align: "center",
                            "align-content": "center",
                          },
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0 px-sm-1",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "text-sm-h6 font-weight-bold" },
                                [_vm._v("Search Results")]
                              ),
                            ]
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { staticClass: "pr-0", attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "text--primary",
                                  attrs: {
                                    color: "white",
                                    small: "",
                                    height: "36",
                                    elevation: "0",
                                    outlined: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.searchBoxVisible = true
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-magnify")])],
                                1
                              ),
                              _vm.githubStore.githubLoggedIn
                                ? [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "mx-2 px-1 text-center text-body-2 font-weight-medium",
                                        attrs: {
                                          color: "primary",
                                          label: "",
                                          outlined: "",
                                          width: _vm.$vuetify.breakpoint
                                            .smAndDown
                                            ? "50px"
                                            : undefined,
                                          "min-width": "0px",
                                        },
                                      },
                                      [
                                        !_vm.$vuetify.breakpoint.smAndDown
                                          ? _c("div", { staticClass: "mr-2" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.githubStore
                                                      .githubUsername
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _c(
                                          "v-avatar",
                                          {
                                            attrs: {
                                              "max-height": "60%",
                                              size: "25",
                                            },
                                          },
                                          [
                                            _vm.githubStore.githubUserDetails
                                              ? _c("img", {
                                                  attrs: {
                                                    contain: "true",
                                                    src: _vm.githubStore
                                                      .githubUserDetails
                                                      .avatar_url,
                                                    alt: _vm.githubStore
                                                      .githubUsername,
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "mx-2 px-2 text-center text-body-2 font-weight-medium",
                                        attrs: {
                                          color: "primary",
                                          elevation: "0",
                                          width: _vm.$vuetify.breakpoint.mobile
                                            ? "50px"
                                            : "100px",
                                          "min-width": "0px",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$emit("github-login")
                                          },
                                        },
                                      },
                                      [
                                        !_vm.$vuetify.breakpoint.mobile
                                          ? [_vm._v(" Login ")]
                                          : _vm._e(),
                                        _c(
                                          "v-icon",
                                          { staticClass: "ml-sm-2 ml-0" },
                                          [_vm._v("mdi-github")]
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                              _c(
                                "v-btn",
                                {
                                  staticClass: "text--primary",
                                  attrs: {
                                    color: "white",
                                    small: "",
                                    height: "36",
                                    elevation: "0",
                                    outlined: "",
                                    "data-test":
                                      "github-search-results-dialog-close-button",
                                  },
                                  on: { click: _vm.closeDialog },
                                },
                                [_c("v-icon", [_vm._v("mdi-close")])],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "px-md-6 px-1 py-0" },
                    [
                      _c(
                        "div",
                        [
                          _vm.showNoResultsBanner
                            ? _c(
                                "v-alert",
                                {
                                  staticClass:
                                    "d-flex justify-center my-5 mx-auto",
                                  attrs: {
                                    outlined: "",
                                    width: "80%",
                                    type: "warning",
                                    dense: "",
                                    border: "left",
                                  },
                                },
                                [
                                  _vm._v(
                                    " No repositories matching the query were found "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "transition-group",
                            {
                              attrs: {
                                name: "repositoryCardsListTransition",
                                tag: "div",
                                mode: "out-in",
                              },
                            },
                            [
                              !_vm.showNoResultsBanner
                                ? [
                                    _vm._l(
                                      _vm.displayRepoList,
                                      function (repo) {
                                        return _c("AddRepositoryCard", {
                                          key: repo.id,
                                          staticClass: "ma-2",
                                          attrs: { initialRepoData: repo },
                                          on: {
                                            "repo-connection-request":
                                              _vm.requestRepoConnect,
                                            "repository-disconnected":
                                              _vm.repoDisconnectionEventHandler,
                                          },
                                        })
                                      }
                                    ),
                                    _vm.endOfRepoList
                                      ? _c("EndOfRepoListMarker", {
                                          key: "endOfResultsBanner",
                                        })
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm.loadingRepos
                        ? _c("LoadingCard", {
                            attrs: { loadingText: "Fetching Repositories" },
                          })
                        : _vm._e(),
                      !_vm.endOfRepoList
                        ? _c("IntersectionObserver", {
                            on: { "intersect-enter": _vm.loadNextPage },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }