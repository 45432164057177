var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    _vm._b(
      {
        staticClass:
          "pa-2 pa-sm-3 placeholder-card text-body-2 font-weight-light",
        attrs: { rounded: "lg", outlined: "", "min-height": _vm.minHeight },
        on: { click: _vm.onClickHandler },
      },
      "v-card",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "v-row",
        {
          staticStyle: { height: "100%" },
          attrs: { justify: "center", "no-gutters": "", align: "center" },
        },
        [
          !_vm.loading
            ? [
                _c("v-col", { attrs: { cols: "auto" } }, [
                  _c("span", { staticClass: "mr-2" }, [_vm._t("icon")], 2),
                ]),
                _c("v-col", [_vm._t("buttonText")], 2),
              ]
            : [
                _c("v-progress-circular", {
                  attrs: {
                    indeterminate: "",
                    size: "24",
                    width: "2",
                    color: "rgb(170, 170, 170)",
                  },
                }),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }