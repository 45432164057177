var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      staticClass: "text-center",
      attrs: { width: _vm.$vuetify.breakpoint.mobile ? "95%" : "500px" },
      on: {
        "click:outside": _vm.closeDialog,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeDialog.apply(null, arguments)
        },
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "pa-2 pa-lg-2",
          attrs: { "data-test": "delete-core-dialog" },
        },
        [
          _c(
            "p",
            { staticClass: "mt-3 font-weight-medium text-h6 text-center" },
            [
              _vm._v(
                ' Would you like to delete the core "' +
                  _vm._s(_vm.deleteCoreData.Name) +
                  '"? '
              ),
            ]
          ),
          _c(
            "v-alert",
            {
              staticClass: "text-body-2 pa-2 pl-5 my-2 text-center mx-12",
              attrs: {
                icon: false,
                type: "warning",
                outlined: "",
                elevation: "0",
              },
            },
            [_c("strong", [_vm._v(" This operation can not be undone. ")])]
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-auto",
                  attrs: {
                    color: "primary",
                    elevation: "0",
                    width: "120px",
                    "data-test": "cancel-button",
                  },
                  on: { click: _vm.closeDialog },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-auto white--text",
                  attrs: {
                    color: "danger",
                    loading: _vm.waitingForSeverResponse,
                    elevation: "0",
                    width: "120px",
                    "data-test": "delete-button",
                  },
                  on: { click: _vm.commitDeleteCore },
                },
                [_vm._v(" Delete ")]
              ),
            ],
            1
          ),
          _c(
            "v-expand-transition",
            [
              _c(
                "v-alert",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showError,
                      expression: "showError",
                    },
                  ],
                  staticClass: "text-body-2 pa-2 pl-5 mb-0 mt-2",
                  attrs: {
                    icon: false,
                    border: "left",
                    type: "warning",
                    outlined: "",
                    elevation: "0",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }