var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      staticClass: "pa-2 pa-sm-3 mx-1 mx-sm-2",
      attrs: {
        rounded: "lg",
        outlined: "",
        "data-test": "add-repository-card",
      },
    },
    [
      _vm.repoData
        ? [
            _c(
              "v-row",
              { attrs: { align: "center", "no-gutters": "" } },
              [
                _c(
                  "v-col",
                  { staticClass: "text-truncate" },
                  [
                    _vm.repoData.visibility == "private"
                      ? _c(
                          "v-icon",
                          { staticClass: "mr-1", attrs: { color: "primary" } },
                          [_vm._v("mdi-book-lock")]
                        )
                      : _c("v-icon", { staticClass: "mr-1" }, [
                          _vm._v("mdi-book"),
                        ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "font-weight-regular text-sm-body-1 text-body-2",
                        staticStyle: { display: "inline" },
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.repoData.html_url,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(" " + _vm._s(_vm.repoData.full_name) + " "),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "", "open-delay": "250" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: _vm.repoData.html_url,
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "pl-1",
                                                      attrs: { small: "" },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v("mdi-open-in-new")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3515463831
                                ),
                              },
                              [_c("span", [_vm._v("View on Github")])]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                !_vm.$vuetify.breakpoint.mobile
                  ? _c(
                      "v-col",
                      { attrs: { cols: "auto" } },
                      [
                        _c(
                          "v-chip",
                          {
                            staticClass: "float-left mx-2",
                            attrs: { outlined: "", small: "" },
                          },
                          [_vm._v(_vm._s(_vm.repoData.visibility))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("v-hover", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ hover }) {
                          return [
                            _c(
                              "v-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.alreadyConnected(),
                                    expression: "alreadyConnected()",
                                  },
                                ],
                                staticClass: "mx-sm-2 mx-1",
                                attrs: { cols: "auto" },
                              },
                              [
                                _c(
                                  "v-fade-transition",
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "", "open-delay": "500" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c("v-progress-circular", {
                                                    staticStyle: {
                                                      position: "absolute",
                                                      top: "8px",
                                                      right: "16px",
                                                    },
                                                    attrs: {
                                                      indeterminate:
                                                        _vm.repoDisconnectStatus
                                                          .loading,
                                                      color: "danger",
                                                      width: _vm
                                                        .repoDisconnectStatus
                                                        .loading
                                                        ? 2
                                                        : 0,
                                                    },
                                                  }),
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "transition-slow-in-slow-out",
                                                          class:
                                                            hover &&
                                                            _vm.alreadyConnected()
                                                              ? "mx-0"
                                                              : "mx-1",
                                                          attrs: {
                                                            outlined: "",
                                                            size:
                                                              hover &&
                                                              _vm.alreadyConnected()
                                                                ? "24"
                                                                : "16",
                                                            color:
                                                              (hover ||
                                                                _vm
                                                                  .repoDisconnectStatus
                                                                  .loading) &&
                                                              _vm.alreadyConnected()
                                                                ? "danger"
                                                                : _vm.alreadyConnected()
                                                                ? "green"
                                                                : "grey",
                                                            "data-test":
                                                              "disconnect-repo-button",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.disconnectRepository,
                                                          },
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [_vm._v("mdi-connection ")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v("Disconnect")])]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2567996473
                  ),
                }),
              ],
              1
            ),
            _c(
              "v-row",
              {
                staticClass: "text-body-2 mt-1 ml-1",
                attrs: { "no-gutters": "" },
              },
              [
                _c(
                  "v-col",
                  { staticClass: "text-truncate text-sm-body-2 text-caption" },
                  [_vm._v(" " + _vm._s(_vm.repoData.description) + " ")]
                ),
              ],
              1
            ),
            _c(
              "v-row",
              {
                staticClass: "text-body-2 mt-1 ml-1",
                attrs: { "no-gutters": "" },
              },
              [
                _c(
                  "v-col",
                  { staticClass: "text-truncate" },
                  _vm._l(_vm.repoData.topics, function (topic) {
                    return _c(
                      "v-chip",
                      {
                        key: topic,
                        staticClass: "mx-0 mr-1",
                        attrs: { outlined: "", "x-small": "", color: "info" },
                      },
                      [_vm._v(_vm._s(topic))]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              {
                staticClass: "text-sm-body-2 mt-1 ml-1 text-caption",
                attrs: { "no-gutters": "", align: "center" },
              },
              [
                _c("v-col", [
                  _c("div", { staticClass: "d-inline-flex" }, [
                    _vm.repoData.license
                      ? _c(
                          "div",
                          { staticClass: "mr-sm-3 mr-2" },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("mdi-scale-balance"),
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.repoData.license.name) + " "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.repoData.forks_count
                      ? _c(
                          "div",
                          { staticClass: "mr-sm-3 mr-2" },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("mdi-source-fork"),
                            ]),
                            _vm._v(_vm._s(_vm.repoData.forks_count) + " "),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "mr-sm-3 mr-2" },
                      [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v("mdi-update"),
                        ]),
                        !_vm.$vuetify.breakpoint.mobile
                          ? [_vm._v(" Last updated: ")]
                          : _vm._e(),
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatDate(_vm.repoData.updated_at)) +
                            " "
                        ),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c(
                  "v-col",
                  { staticClass: "ml-2", attrs: { cols: "auto" } },
                  [
                    _c("LimitableActionButton", {
                      attrs: {
                        color: "accent",
                        width: _vm.$vuetify.breakpoint.mobile
                          ? "50px"
                          : "100px",
                        disabled: _vm.alreadyConnected(),
                        tooltipText: "Connect to Repository",
                        "data-test": "connect-repo-button",
                        "limits-to-check": [_vm.UserLimitsE.RepositoryCount],
                      },
                      on: { "valid-click": _vm.requestRepoConnect },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "btnContent",
                            fn: function () {
                              return [_c("v-icon", [_vm._v("mdi-book-plus")])]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2354172728
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _c("v-skeleton-loader", { attrs: { type: "list-item-avatar" } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }