
import { defineComponent, PropType } from "vue";
import { getThingByID, getBucketByID, getDriveByID } from "@/js/signaloidClient";
import * as dsUtil from "@/components/DataSources/utilities";
import TooltipButton from "@/components/Common/TooltipButton.vue";
import { AWSS3Bucket, DataDrive, DataSourceType } from "@/types/api/dataSources";
import { monitoringCaptureError } from "@/plugins/monitoring";

type ComponentData = {
	dataSourceData: undefined | DataSourceData;
	loadingData: boolean;
	markedForDisconnect: boolean;
};

type DataSourceData =
	| AWSS3Bucket
	| DataDrive
	| {
			Name: "Signaloid Cloud Storage";
	  };
export default defineComponent({
	name: "DataSourceInlineCard",
	components: {
		TooltipButton,
	},
	props: {
		dataSourceID: { type: String, required: true },
		dataSourceType: { type: String as PropType<DataSourceType>, required: true },
		mountLocation: { type: String, default: "" },
		disconnectTooltip: {
			type: Object,
			default: () => ({
				marked: "Keep Mounted",
				unmarked: "Mark for Unmounting",
			}),
		},
	},
	data: (): ComponentData => ({
		dataSourceData: undefined,
		loadingData: false,
		markedForDisconnect: false,
	}),
	methods: {
		async getSourceInfo() {
			this.loadingData = true;
			switch (this.dataSourceType) {
				case "Gateway":
					try {
						const resp = await getThingByID(this.dataSourceID);
						this.dataSourceData = resp.data;
					} catch (error) {
						monitoringCaptureError(error, "Fetch data source info");
					} finally {
						this.loadingData = false;
					}
					break;
				case "Bucket":
					try {
						const resp = await getBucketByID(this.dataSourceID);
						this.dataSourceData = resp.data;
					} catch (error) {
						monitoringCaptureError(error, "Fetch data source info");
					} finally {
						this.loadingData = false;
					}
					break;
				case "Drive":
					try {
						const resp = await getDriveByID(this.dataSourceID);
						this.dataSourceData = resp.data;
					} catch (error) {
						monitoringCaptureError(error, "Fetch data source info");
					} finally {
						this.loadingData = false;
					}
					break;
				case "SignaloidCloudStorage":
					try {
						this.dataSourceData = {
							Name: "Signaloid Cloud Storage",
						};
					} catch (error) {
						monitoringCaptureError(error, "Fetch data source info");
					} finally {
						this.loadingData = false;
					}
					break;
				default:
					this.$emit("reset-data-source");
					throw "Error: Unexpected source type";
			}
		},
		toggleSourceDisconnectMark() {
			if (this.markedForDisconnect) {
				this.markedForDisconnect = false;
				this.$emit("reset-source-disconnect-mark", this.dataSourceID);
			} else {
				this.markedForDisconnect = true;
				this.$emit("mark-source-for-disconnect", this.dataSourceID);
			}
		},
		dataSourceIcon(dataSourceType: DataSourceType) {
			return dsUtil.dataSourceIcon(dataSourceType);
		},
	},
	mounted() {
		this.getSourceInfo();
	},
});
