var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "px-1 px-sm-2", attrs: { flat: "" } },
    [
      _c(
        "v-card-title",
        {
          staticClass: "pb-3 pt-1 pt-sm-4 px-1 px-sm-4",
          staticStyle: { "min-height": "64px" },
        },
        [
          _c("div", { staticClass: "text-h6 font-weight-bold" }, [
            _vm._v("Connected Repositories"),
          ]),
          _c("v-spacer"),
          !_vm.isUserLoggedInToGithub
            ? [
                _c("TooltipButton", {
                  attrs: {
                    color: "primary",
                    width: _vm.$vuetify.breakpoint.mobile ? "45px" : "100px",
                    "min-width": "0px",
                    btnClass: "float-right",
                    tooltipText: "Login to GitHub",
                    "data-test": "github-login-button",
                  },
                  on: { click: _vm.githubLogin },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "btnContent",
                        fn: function () {
                          return [
                            !_vm.$vuetify.breakpoint.mobile
                              ? [_vm._v(" Login ")]
                              : _vm._e(),
                            _c("v-icon", { staticClass: "ml-0 ml-sm-2" }, [
                              _vm._v("mdi-github"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    812185816
                  ),
                }),
              ]
            : [
                _c("v-hover", {
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ hover }) {
                        return [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "mx-sm-2 mx-0 mr-1 px-2 text-center text-body-2 font-weight-medium",
                              class: { "on-hover": hover },
                              attrs: {
                                color: hover ? "danger" : "primary",
                                label: "",
                                outlined: "",
                                width: _vm.$vuetify.breakpoint.mobile
                                  ? "45px"
                                  : undefined,
                                "min-width": _vm.$vuetify.breakpoint.mobile
                                  ? "0px"
                                  : "120px",
                                "max-width": _vm.$vuetify.breakpoint.smAndDown
                                  ? "150px"
                                  : undefined,
                                loading: !_vm.githubStore.githubUserDetails,
                                "data-test": "github-user-button",
                              },
                              on: { click: _vm.githubLogout },
                            },
                            [
                              !_vm.$vuetify.breakpoint.mobile
                                ? _c("div", { staticClass: "mr-2" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.formattedGithubUsername) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "v-avatar",
                                { attrs: { "max-height": "60%", size: "25" } },
                                [
                                  _vm.githubStore.githubUserDetails
                                    ? _c("img", {
                                        attrs: {
                                          contain: "true",
                                          src: _vm.githubStore.githubUserDetails
                                            .avatar_url,
                                          alt: _vm.githubStore.githubUsername,
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "v-fade-transition",
                                [
                                  hover
                                    ? _c(
                                        "v-overlay",
                                        {
                                          staticClass:
                                            "transition-fast-in-fast-out my-n1 mx-n2 text-body-2 font-weight-medium danger--text",
                                          attrs: {
                                            absolute: "",
                                            opacity: "1",
                                            color: "white",
                                          },
                                        },
                                        [
                                          !_vm.$vuetify.breakpoint.mobile
                                            ? _c("div", [
                                                _vm._v("GitHub Logout"),
                                              ])
                                            : _c("v-icon", [
                                                _vm._v(" mdi-logout "),
                                              ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("TooltipButton", {
                  attrs: {
                    color: "accent",
                    width: _vm.$vuetify.breakpoint.smAndDown ? "45px" : "100px",
                    "min-width": "0px",
                    btnClass: "float-right",
                    btnIcon: "mdi-book-plus-multiple",
                    tooltipText: "Connect Private GitHub Repository",
                    "data-test": "connect-to-repositories-button",
                  },
                  on: { click: _vm.connectToRepo },
                }),
              ],
          _c("TooltipButton", {
            attrs: {
              color: "primary",
              width: _vm.$vuetify.breakpoint.smAndDown ? "45px" : "100px",
              "min-width": "0px",
              btnClass: "float-right ml-1 ml-sm-2",
              btnIcon: "mdi-cloud-search-outline",
              tooltipText: "Search Github",
              "data-test": "open-github-search-button",
            },
            on: { click: _vm.openGithubSearchDialog },
          }),
        ],
        2
      ),
      _c("v-divider", { staticClass: "mb-2 mb-sm-3 mx-1 mx-sm-4" }),
      _vm.connectedRepositories === undefined
        ? _c("LoadingCard", {
            attrs: { loadingText: "Fetching Connected Repositories" },
          })
        : _c(
            "v-container",
            {
              staticClass: "px-sm-4 px-1 py-sm-2 py-1 mx-auto card-grid",
              attrs: { fluid: true },
            },
            _vm._l(_vm.connectedRepositories, function (cr) {
              return _c("ConnectedRepositoryCard", {
                key: cr.RepositoryID,
                attrs: { repositoryID: cr.RepositoryID, repository: cr },
                on: {
                  "open-execution-log": _vm.openExecutionLog,
                  "repository-disconnected": _vm.repoDisconnectionEventHandler,
                },
              })
            }),
            1
          ),
      _c("GithubAuthRedirectDialog", {
        attrs: {
          dialogVisible: _vm.githubAuthRedirectDialogActive,
          dialogText: _vm.githubAuthRedirectDialogText,
        },
      }),
      _vm.githubRepoListDialogActive
        ? _c("GithubRepoListDialog", {
            attrs: { dialogVisible: _vm.githubRepoListDialogActive },
            on: {
              "close-dialog": _vm.closeGithubRepoListDialog,
              "repo-connection-request": _vm.repoConnectionRequestHandler,
              "repository-disconnected": _vm.repoDisconnectionEventHandler,
            },
          })
        : _vm._e(),
      _vm.searchGithubReposDialogActive
        ? _c("SearchGithubReposDialog", {
            attrs: { dialogVisible: _vm.searchGithubReposDialogActive },
            on: {
              "close-dialog": _vm.closeSearchGithubReposDialog,
              "repo-connection-request": _vm.repoConnectionRequestHandler,
              "repository-disconnected": _vm.repoDisconnectionEventHandler,
              "github-login": _vm.githubLogin,
            },
          })
        : _vm._e(),
      _vm.executionLogSheetActive
        ? _c("ExecutionResultsCard", {
            attrs: {
              isVisible: _vm.executionLogSheetActive,
              taskID: _vm.executionLogTaskID ?? undefined,
            },
            on: { "click-outside": _vm.closeExecutionLog },
          })
        : _vm._e(),
      _vm.repoConnectionValidationDialogActive && _vm.repoConnectionConfig
        ? _c("RepositoryConnectionValidationCard", {
            attrs: {
              dialogVisible: _vm.repoConnectionValidationDialogActive,
              connectionConfig: _vm.repoConnectionConfig,
            },
            on: {
              "repository-connected": _vm.repoConnectionSuccessHandler,
              "close-dialog": function ($event) {
                _vm.repoConnectionValidationDialogActive = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }