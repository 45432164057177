var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "d-flex flex-nowrap align-center my-0",
      staticStyle: { display: "inline" },
    },
    [
      _c(
        "code",
        {
          staticClass: "font-weight-regular py-0",
          staticStyle: { "font-size": "0.875em" },
          attrs: { "data-test": "task-id-value" },
        },
        [
          _c("router-link", { attrs: { to: _vm.link } }, [
            _vm._v(_vm._s(_vm.formattedTaskID)),
          ]),
        ],
        1
      ),
      _vm.showClipboardButton
        ? _c("CopyToClipboardButton", {
            attrs: { "value-to-copy": _vm.taskID, "value-name": "Task ID" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }