
import { defineComponent } from "vue";

import { toISODateString } from "@/js/dateUtils";

type ComponentData = {
	dateSelectionMenuVisible: boolean;
	datePickerPickedDate: string;
	textFieldDate: string;
};

export default defineComponent({
	name: "SignaloidDatePicker",
	props: {
		allowedDates: {
			type: Function,
			default: (val: string): boolean => {
				return true;
			},
		},
		date: {
			type: String,
			default: toISODateString(new Date()),
		},
	},
	emits: {
		"update:date": (v) => {
			if (!(v instanceof String)) {
				return false;
			}
			try {
				Date.parse(v as string);
				return true;
			} catch (error) {
				console.warn("Invalid chosen date!");
				return false;
			}
		},
	},
	data: (): ComponentData => ({
		dateSelectionMenuVisible: false,
		datePickerPickedDate: toISODateString(new Date()),
		textFieldDate: toISODateString(new Date()),
	}),
	computed: {
		datePickerPickedDateFormatted(): string {
			// this.datePickerPickedDate should always be in ISO8601 format
			const date = new Date(this.datePickerPickedDate);
			return date.toLocaleDateString();
		},
	},
	methods: {
		textFieldBlurHandler() {
			/*
			 *	This fires when the input is unfocused after the user manually
			 *	entered the date.
			 */

			if (!this.allowedDates(this.textFieldDate)) {
				// Date value is not allowed
				this.textFieldDate = this.datePickerPickedDateFormatted;
				return;
			}

			const handwrittenDate = new Date(this.textFieldDate);
			if (isNaN(handwrittenDate.getTime())) {
				// The date written is not valid, reset it to the previous value and do nothing
				this.textFieldDate = this.datePickerPickedDateFormatted;
				return;
			}

			// REMEMBER: v-date-picker only accepts ISO8601 strings in v-model!
			this.datePickerPickedDate = toISODateString(handwrittenDate);
			this.$emit("update:date", this.datePickerPickedDateFormatted);
		},
		textFieldInputHandler() {
			/*
			 *	When typing on the text field, close the date picker menu.
			 */
			this.dateSelectionMenuVisible = false;
		},
		datePickerInputHandler() {
			// console.log("datePickerInputHandler");

			this.textFieldDate = this.datePickerPickedDateFormatted;
			this.$emit("update:date", this.datePickerPickedDate); // Data should always use ISO8601 format
			this.dateSelectionMenuVisible = false;
		},
		parseDate(dateParameter: string): string {
			/*
			 *	Parse date from local into ISO8601. This is necessary to programmatically set the date selected in the
			 *	date picker because the date picker components needs the date in ISO format.
			 */
			const date = new Date(dateParameter);

			// Extract the year, month, and day
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month starts from 0 in JS
			const day = date.getDate().toString().padStart(2, "0");

			// Return the formatted string
			return `${year}-${month}-${day}`;
		},
	},
	mounted() {
		// console.log("SignaloidDatePicker.mounted");
		// console.log(this.date);
		if (this.date === "") {
			// console.log("SignaloidDatePicker.mounted:date=''");
			this.textFieldDate = this.datePickerPickedDateFormatted;
			this.$emit("update:date", this.datePickerPickedDateFormatted);
		} else {
			// console.log("SignaloidDatePicker.mounted:date!=''");
			// console.log(this.date);
			// console.log(new Date(this.date));
			// console.log(toISODateString(new Date(this.date)));
			// console.log(this.datePickerPickedDateFormatted);

			this.datePickerPickedDate = toISODateString(new Date(this.date));
			this.textFieldDate = this.datePickerPickedDateFormatted;

			// console.log("this.datePickerPickedDate=", this.datePickerPickedDate);
			// console.log("this.textFieldDate=", this.textFieldDate);
		}
	},
});
