var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "", width: "min-content" },
      on: {
        "click:outside": _vm.closeDialog,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeDialog.apply(null, arguments)
        },
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "pb-1",
          attrs: { "data-test": "build-directory-selector-dialog" },
        },
        [
          _c("v-card-title", { staticClass: "py-3 px-4" }, [
            _vm._v("Select Build Directory"),
          ]),
          _c("v-divider"),
          _c(
            "v-card-text",
            {
              staticClass: "py-2 px-4",
              staticStyle: {
                "max-height": "500px",
                "max-width": "600px",
                "min-width": "350px",
              },
            },
            [
              _vm.loading
                ? _c(
                    "v-flex",
                    { staticClass: "text-center" },
                    [
                      _c("v-progress-circular", {
                        staticClass: "my-6",
                        attrs: {
                          indeterminate: "",
                          color: "primary",
                          size: "40",
                        },
                      }),
                    ],
                    1
                  )
                : _c("v-treeview", {
                    staticClass: "text-body-2",
                    attrs: {
                      active: _vm.selectedItems,
                      open: _vm.openItems,
                      items: _vm.repositoryDirectories,
                      "item-key": "path",
                      "item-text": "name",
                      "expand-icon": "mdi-chevron-down",
                      activatable: "",
                      "multiple-active": false,
                      transition: "",
                      dense: "",
                    },
                    on: {
                      "update:active": function ($event) {
                        _vm.selectedItems = $event
                      },
                      "update:open": function ($event) {
                        _vm.openItems = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prepend",
                        fn: function ({ open }) {
                          return [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    open ? "mdi-folder-open" : "mdi-folder"
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
              _c(
                "AlertMessage",
                {
                  staticClass: "mx-0",
                  attrs: { "show-alert": _vm.showError, type: "error" },
                },
                [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", elevation: "0", width: "120px" },
                  on: { click: _vm.setBuildDirectoryHandler },
                },
                [_vm._v(" Set Directory ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }