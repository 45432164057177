var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    _vm._g(
      _vm._b(
        {
          staticClass: "notice-card-grid pa-8",
          attrs: {
            "min-height": "400px",
            width: _vm.$vuetify.breakpoint.mobile ? "95%" : "500px",
            rounded: "lg",
          },
        },
        "v-card",
        { ..._vm.$attrs },
        false
      ),
      { ..._vm.$listeners }
    ),
    [
      _c("div", { staticClass: "card-content" }, [_vm._t("default")], 2),
      _vm.showLogo
        ? _c("v-img", {
            staticClass: "platform-logo",
            attrs: {
              alt: "Platform Logo",
              contain: "",
              src: _vm.platformLogo ?? _vm.defaultPlatformLogo,
              transition: "scale-transition",
              "max-width": "150px",
              "max-height": "50px",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }