
import { defineComponent, ref } from "vue";

// Types
import { TraceVariable, TraceVariableWithTraceType } from "@/types/api/nodes";

// Libraries

// Utilities
import * as util from "@/js/utilities";
import { monitoringCaptureError } from "@/plugins/monitoring";

// Components
import ConnectedRepositoryCard from "@/components/Repositories/ConnectedRepositoryCard.vue";
import VariableViewer from "@/components/DevelopmentPlatform/VariableViewer.vue";
import Panel from "@/components/Panel.vue";

// Stores
import { useTasksStore } from "@/stores/tasks";
import { useRepositoriesStore } from "@/stores/repositories";
import { useGithubStore } from "@/stores/github";

// Global variables
import { defaultTabs } from "@/assets/defaultTabs";
import { ResultsPanelTab } from "@/types/general";
import { useOutputsStore } from "@/stores/outputs";
import { Repository } from "@/types/api/repositories";
import { storeToRefs } from "pinia";

type ComponentData = {
	results: null | ResultsPanelTab[];
	resultsLoading: boolean;
	breadcrumbs: any[];
	discoveredVariables: TraceVariableWithTraceType[];
	customVariableExpressions: TraceVariableWithTraceType[];
	repositoryVariableTraceList: TraceVariable[];
	variableDiscoveryInProgress: boolean;
};

export default defineComponent({
	name: "RepositoryDetail",
	components: { ConnectedRepositoryCard, VariableViewer, Panel },
	props: {
		repositoryID: { type: String },
	},
	data: (): ComponentData => ({
		results: null,
		resultsLoading: false,
		breadcrumbs: [
			{
				text: "Repositories",
				disabled: false,
				exact: true,
				to: { name: "Repositories" },
			},
			{
				text: "" /* Gets populated in created() */,
				disabled: true,
			},
		],
		discoveredVariables: [],
		customVariableExpressions: [],
		repositoryVariableTraceList: [],
		variableDiscoveryInProgress: false,
	}),
	setup() {
		const tasksStore = useTasksStore();
		const repositoriesStore = useRepositoriesStore();
		const githubStore = useGithubStore();
		const outputStore = useOutputsStore();
		/*
		 *	https://stackoverflow.com/a/71677026/7151170
		 *	Ideally we would have a direct reference to the repository we want and not to the list.
		 */
		const { connectedRepositories } = storeToRefs(repositoriesStore);

		const connectedRepositoryCard = ref<InstanceType<typeof ConnectedRepositoryCard>>();
		return {
			tasksStore,
			repositoriesStore,
			githubStore,
			connectedRepositoryCard,
			util,
			outputStore,
			connectedRepositories,
		};
	},
	computed: {
		taskID(): string {
			return this.connectedRepositoryCard?.activeTask?.TaskID ?? "";
		},
		repository(): Repository | undefined {
			/*
			 *	Ideally we would not need this and would have a direct reference to the store.
			 */
			return this.connectedRepositories?.find((r) => r.RepositoryID === this.repositoryID);
		},
		referenceCoreChosen(): boolean {
			if (this.connectedRepositoryCard?.chosenCore) {
				return this.connectedRepositoryCard?.chosenCore.Microarchitecture === "Reference" ;
			}
			return false;
		},
		repositoryHash(): string | undefined {
			return (
				(this.connectedRepositoryCard?.repository?.Commit ?? "") +
				(this.connectedRepositoryCard?.repository?.BuildDirectory ?? "")
			);
		},
		completeVariablesList(): any[] {
			return [...this.discoveredVariables, ...this.customVariableExpressions];
		},
		resultsPanelText(): readonly ResultsPanelTab[] {
			return this.results ?? defaultTabs;
		},
	},
	methods: {
		async repositoryExecutionResultsHandler(data: { taskID: string }) {
			if (data.taskID && !this.connectedRepositoryCard?.lastBuildForVariableDiscovery) {
				this.resultsLoading = true;
				try {
					const task = this.tasksStore.getTaskByID(this.taskID);
					await this.outputStore.loadTaskOutputs(task?.TaskID ?? "");
					await this.outputStore.loadBuildOutputs(task?.BuildID ?? "");
				} catch (error) {
					monitoringCaptureError(error, "Handle terminated repository task status change");
				}
			}
		},
		async discoverVariables() {
			this.variableDiscoveryInProgress = true;
			try {
				await this.connectedRepositoryCard?.discoverVariables();
			} catch (error) {
				monitoringCaptureError(error, "Discover repository variables");
			}
		},
		updateVariableTraceList(newList) {
			this.repositoryVariableTraceList = newList;
		},
		updateVariables(newVariables) {
			this.discoveredVariables = newVariables;
			this.variableDiscoveryInProgress = false;
		},
		switchToReferenceCore() {
			this.connectedRepositoryCard?.switchToReferenceCore();
		},
		addCustomTraceExpression(variableTrace) {
			this.customVariableExpressions.push(variableTrace);
		},
		removeCustomTraceExpression(variableTraceToRemove: TraceVariable) {
			const traceVariableIndex = this.customVariableExpressions.findIndex((variableTrace) => {
				return (
					variableTrace.Expression == variableTraceToRemove.Expression &&
					variableTrace.LineNumber == variableTraceToRemove.LineNumber &&
					variableTrace.File == variableTraceToRemove.File
				);
			});
			this.customVariableExpressions.splice(traceVariableIndex, 1);
		},
	},
	created() {
		this.breadcrumbs[1].text = this.repositoryID;
	},
});
