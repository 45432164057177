var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.$vuetify.breakpoint.mobile ? "95%" : "620px" },
      on: {
        "click:outside": _vm.closeDialog,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeDialog.apply(null, arguments)
        },
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-sheet",
        {
          staticClass: "pa-6 pa-lg-8",
          attrs: {
            "data-test": "delete-api-key-dialog-sheet",
            width: _vm.$vuetify.breakpoint.mobile ? "100%" : "620px",
            tile: _vm.$vuetify.breakpoint.mobile,
            elevation: !_vm.$vuetify.breakpoint.mobile ? "5" : "0",
            rounded: "",
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "d-flex justify-space-between" },
                [
                  _c(
                    "div",
                    { staticClass: "text-h5 pt-0 font-weight-medium" },
                    [_vm._v("Are you sure you want to delete this API key?")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text--primary",
                      attrs: {
                        color: "white",
                        small: "",
                        outlined: "",
                        height: "36",
                        elevation: "0",
                      },
                      on: { click: _vm.closeDialog },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "text-body-2 mt-4" }, [
                _c("span", [
                  _vm._v(
                    "After deleting the key, the Signaloid Cloud Compute Engine API will reject incoming requests that try to use it. "
                  ),
                ]),
              ]),
              _c("v-data-table", {
                staticClass: "outline",
                attrs: {
                  headers: _vm.headers,
                  items: [_vm.passedKey],
                  "hide-default-footer": "",
                  "sort-by": "Creation Date",
                  "no-data-text": "Could not display key.",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.Name`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "key-name",
                              attrs: { title: item.Name },
                            },
                            [_vm._v(" " + _vm._s(item.Name) + " ")]
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.KeyID`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "span",
                            { staticClass: "inline-code font-weight-medium" },
                            [_vm._v(_vm._s(_vm.formattedKey))]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "inline-code font-weight-medium",
                              staticStyle: {
                                "letter-spacing": "-0.2rem",
                                "margin-left": "-0.1rem",
                              },
                            },
                            [_vm._v("...")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _c(
                "div",
                { staticClass: "text-body-2 mt-4" },
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "text-body-2",
                      attrs: {
                        dense: "",
                        outlined: "",
                        icon: false,
                        type: "error",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "prepend",
                          fn: function () {
                            return undefined
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("strong", [_vm._v("Attention!")]),
                      _vm._v(
                        " Applications using this API key to make requests to the Signaloid Cloud Compute Engine "
                      ),
                      _c("strong", [_vm._v("may stop working!")]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "mt-2 pa-0" },
                [
                  !_vm.$vuetify.breakpoint.mobile
                    ? _c(
                        "v-alert",
                        {
                          staticClass: "text-body-2 mr-4 font-weight-medium",
                          attrs: {
                            value: _vm.errorText !== "",
                            dense: "",
                            transition: "v-expand-transition",
                            border: "left",
                            type: "error",
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.errorText))])]
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "error",
                        elevation: "0",
                        "data-test": "delete-api-key-button",
                        loading: _vm.confirmDeleteKeyButtonLoading,
                        width: "140px",
                      },
                      on: { click: _vm.confirmDeleteKey },
                    },
                    [_vm._v(" Confirm Deletion ")]
                  ),
                ],
                1
              ),
              _vm.$vuetify.breakpoint.mobile
                ? _c(
                    "v-alert",
                    {
                      staticClass: "text-body-2 mt-4 font-weight-medium",
                      attrs: {
                        value: _vm.errorText !== "",
                        dense: "",
                        transition: "v-expand-transition",
                        border: "left",
                        type: "error",
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.errorText))])]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }