var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "section",
      [
        _c("h4", { staticClass: "text-h7" }, [_vm._v("Editor")]),
        _c("v-divider"),
        _c("div", { staticClass: "mb-4 px-0 px-md-4" }, [
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "pb-3 font-weight-medium" }, [
              _vm._v("Theme"),
            ]),
            _c(
              "div",
              { attrs: { "data-test": "editor-theme-selector" } },
              [
                _c("v-select", {
                  attrs: {
                    "item-text": "name",
                    "item-value": "key",
                    items: _vm.editorThemeList,
                    outlined: "",
                    dense: "",
                    attach: "",
                    "hide-details": "",
                    "data-test": "editor-theme-selector",
                  },
                  model: {
                    value: _vm.editorTheme,
                    callback: function ($$v) {
                      _vm.editorTheme = $$v
                    },
                    expression: "editorTheme",
                  },
                }),
                _c(
                  "v-card",
                  {
                    staticClass: "mx-auto mt-2 editor-placeholder-container",
                    attrs: { outlined: "", width: "95%" },
                  },
                  [
                    _c("EditorPlaceholder", {
                      attrs: {
                        height: "175px",
                        theme: _vm.editorTheme ?? `dawn`,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-row flex-row-reverse px-0 px-md-4" },
              [
                _c(
                  "v-row",
                  { attrs: { justify: "end" } },
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-fade-transition",
                          [
                            _vm.editorStore.themeUpdateStatus.error
                              ? _c(
                                  "v-alert",
                                  {
                                    staticClass: "my-0 text-body-2 py-1",
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      type: "warning",
                                      border: "left",
                                      height: "36px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " Unable to save theme. " +
                                        _vm._s(
                                          _vm.editorStore.themeUpdateStatus
                                            .message
                                        ) +
                                        " Please try again later. "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "auto" } },
                      [
                        _c("TooltipButton", {
                          attrs: {
                            color: "primary",
                            loading: _vm.editorStore.themeUpdateStatus.loading,
                            btnText: "Save",
                            disabled:
                              _vm.editorTheme === _vm.editorStore.editorThemeID,
                            btnIcon: "mdi-content-save-outline",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.setEditorTheme(
                                _vm.editorTheme ?? "solarized-light"
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "pb-1 font-weight-medium" }, [
              _vm._v("Layout"),
            ]),
            _c(
              "span",
              [
                _c("v-switch", {
                  staticClass: "pl-1",
                  attrs: {
                    "data-test": "editor-variable-viewer-visibility-switch",
                    label: "Show variable viewer",
                    color: "primary",
                    loading:
                      _vm.editorStore.variableViewerVisibilityUpdateStatus
                        .loading,
                    inset: "",
                    dense: "",
                    "hide-details": "",
                  },
                  on: { change: _vm.toggleVariableViewerVisibility },
                  model: {
                    value: _vm.editorVariableViewer,
                    callback: function ($$v) {
                      _vm.editorVariableViewer = $$v
                    },
                    expression: "editorVariableViewer",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ],
      1
    ),
    _c(
      "section",
      [
        _c("h4", { staticClass: "text-h7" }, [_vm._v("Code Execution")]),
        _c("v-divider"),
        _c("div", { staticClass: "my-2 mb-4 px-0 px-md-4" }, [
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "two-col-grid" }, [
              _c(
                "div",
                { attrs: { "data-test": "default-core-selector" } },
                [
                  _c("div", { staticClass: "font-weight-medium" }, [
                    _vm._v("Default execution core"),
                  ]),
                  _c("v-select", {
                    attrs: {
                      "item-text": "Name",
                      "item-value": "CoreID",
                      items: _vm.filteredCores,
                      "item-disabled": _vm.disableItem,
                      loading: _vm.coresStore.defaultCoreUpdateStatus.loading,
                      outlined: "",
                      dense: "",
                      attach: "",
                      "hide-details": "",
                    },
                    on: { change: _vm.setDefaultCore },
                    model: {
                      value: _vm.coresChosenDefaultCoreID,
                      callback: function ($$v) {
                        _vm.coresChosenDefaultCoreID = $$v
                      },
                      expression: "coresChosenDefaultCoreID",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { attrs: { "data-test": "default-ref-core-selector" } },
                [
                  _c("div", { staticClass: "font-weight-medium" }, [
                    _vm._v("Default reference core"),
                  ]),
                  _c("v-select", {
                    attrs: {
                      "item-text": "Name",
                      "item-value": "CoreID",
                      items: _vm.coresStore.filteredVisibleReferenceCoresList,
                      loading:
                        _vm.coresStore.defaultRefCoreUpdateStatus.loading,
                      outlined: "",
                      dense: "",
                      attach: "",
                      "hide-details": "",
                    },
                    on: { change: _vm.setDefaultReferenceCore },
                    model: {
                      value: _vm.coresChosenDefaultReferenceCoreID,
                      callback: function ($$v) {
                        _vm.coresChosenDefaultReferenceCoreID = $$v
                      },
                      expression: "coresChosenDefaultReferenceCoreID",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "form-row" },
              [
                _c(
                  "div",
                  { staticClass: "pb-0 font-weight-medium" },
                  [
                    _vm._v(" Default Data Source Configuration "),
                    _c("TooltipButton", {
                      attrs: {
                        icon: true,
                        "x-small": "",
                        btnClass: "mx-2 px-0",
                        btnIcon: "mdi-restore",
                        tooltipText: "Reset to default",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.resetDefaultMountConfig()
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "section",
                  { staticClass: "mt-2" },
                  [
                    _vm.currentEditorDefaultMountConfig
                      ? _c("DataSourceInlineCard", {
                          staticClass: "mt-1",
                          attrs: {
                            dataSourceID:
                              _vm.currentEditorDefaultMountConfig.ResourceID,
                            dataSourceType:
                              _vm.currentEditorDefaultMountConfig.ResourceType,
                            mountLocation:
                              _vm.currentEditorDefaultMountConfig.Location,
                            disconnectTooltip: {
                              marked: "",
                              unmarked: "Remove Mount Point",
                            },
                          },
                          on: {
                            "mark-source-for-disconnect": function ($event) {
                              return _vm.removeDefaultMountConfig()
                            },
                          },
                        })
                      : _c("SourcePlaceholderCard", {
                          staticClass: "mt-2",
                          on: {
                            "card-clicked": function ($event) {
                              return _vm.openMountDataSourcesDialog("editor")
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "icon",
                              fn: function () {
                                return [
                                  _c("v-icon", { attrs: { size: "19" } }, [
                                    _vm._v("mdi-plus"),
                                  ]),
                                  _c("v-icon", [_vm._v("mdi-tray-arrow-down")]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "buttonText",
                              fn: function () {
                                return [
                                  _vm._v("Add Default Source Mount Point"),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                  ],
                  1
                ),
                _vm.mountDataSourcesDialogStatus.show
                  ? _c("MountDataSourcesDialog", {
                      attrs: {
                        showDialog: _vm.mountDataSourcesDialogStatus.show,
                      },
                      on: {
                        "close-dialog": _vm.closeMountDataSourcesDialog,
                        "set-data-sources": _vm.setDefaultMountConfig,
                        "reset-data-sources": _vm.resetDefaultMountConfig,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }