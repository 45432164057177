<template>
	<v-form
		v-model="formIsValid"
		@submit.prevent="onSubmit"
		ref="interestForm"
		action="/"
	>
		<h1 class="mb-5 text-center text-lg-left">Sign Up for Access</h1>
		<v-select
			v-model="formValues.plan"
			outlined
			required
			dense
			disabled
			:rules="formRules.plan"
			:label="labelValues.plan"
			:items="planSelectItems"
		/>
		<v-divider class="mb-4" />
		<h2 class="my-4">Your Details</h2>
		<v-text-field
			v-model="formValues.fullname"
			name="name"
			autocomplete="name"
			:rules="formRules.fullname"
			validate-on-blur
			outlined
			required
			dense
			:label="labelValues.fullname"
		/>
		<v-text-field
			v-model="formValues.email"
			type="email"
			name="email"
			autocomplete="email"
			:rules="formRules.email"
			required
			outlined
			validate-on-blur
			dense
			:label="labelValues.email"
		/>
		<v-text-field
			v-model="formValues.affiliation"
			outlined
			:rules="formRules.affiliation"
			dense
			:label="labelValues.affiliation"
		/>
		<v-textarea
			v-model="formValues.howDidYouHearAboutThis"
			outlined
			rows="4"
			auto-grow
			dense
			:rules="formRules.howDidYouHearAboutThis"
			:label="labelValues.howDidYouHearAboutThis"
		/>
		<v-textarea
			v-model="formValues.applicationDomain"
			outlined
			rows="4"
			auto-grow
			dense
			:rules="formRules.applicationDomain"
			:label="labelValues.applicationDomain"
			hint="We use this information in prioritizing your request for remote access to the Signaloid computing platform."
			persistent-hint
		/>

		<h2 class="my-4">Interest and Engangement</h2>

		<div
			v-for="(value, key) of labelValues.survey"
			:key="value"
			class="ma-0 pa-0"
		>
			<span class="text-body-1 font-weight-medium">{{ value }}</span>
			<v-radio-group
				v-model="formValues.survey[key]"
				class="mt-2 mb-8"
				hint="We use this information in prioritizing your request for remote access to the Signaloid computing platform."
				persistent-hint
				:rules="formRules.surveyRules"
				:row="!$vuetify.breakpoint.mobile"
			>
				<v-radio
					:ripple="false"
					class="mb-2 mt-1"
					label="Strongly Disagree"
					value="strongly-disagree"
				/>
				<v-radio
					:ripple="false"
					class="mb-2 mt-1"
					label="Disagree"
					value="disagree"
				/>
				<v-radio
					:ripple="false"
					class="mb-2 mt-1"
					label="Neutral"
					value="neutral"
				/>
				<v-radio
					:ripple="false"
					class="mb-2 mt-1"
					label="Agree"
					value="agree"
				/>
				<v-radio
					:ripple="false"
					class="mb-2 mt-1"
					label="Strongly Agree"
					value="strongly-agree"
				/>
			</v-radio-group>
		</div>

		<div class="text-center mt-10">
			<v-btn
				class="mx-auto"
				color="primary"
				:min-width="$vuetify.breakpoint.mobile ? '80%' : '20%'"
				:loading="submitButtonLoading"
				type="submit"
				>Submit</v-btn
			>
		</div>
		<div
			v-if="formPostSuccess"
			class="text-center mt-4"
		>
			<h3>Form submitted successfully.</h3>
		</div>
	</v-form>
</template>

<script>
import { defineComponent } from "vue";
import { Auth } from "aws-amplify";
import { joinWaitlist } from "@/js/signaloidClient";
import { monitoringCaptureError } from "@/plugins/monitoring";

export default defineComponent({
	name: "InterestForm",
	data: () => ({
		formIsValid: false,
		submitButtonLoading: false,
		formPostSuccess: false,
		formValues: {
			plan: "",
			fullname: "",
			email: "",
			affiliation: "",
			howDidYouHearAboutThis: "",
			applicationDomain: "",
			survey: {
				evaluatingCompetingSolutions: "",
			},
		},
		formRules: {
			plan: [(v) => v.length > 0 || "This field is required."],
			fullname: [(v) => v.length > 0 || "This field is required."],
			email: [(v) => v.length > 0 || /.+@.+\..+/.test(v) || "Please enter a valid email."],
			affiliation: [(v) => v.length > 0 || "This field is required."],
			howDidYouHearAboutThis: [(v) => v.length > 0 || "This field is required."],
			applicationDomain: [(v) => v.length > 0 || "This field is required."],
			surveyRules: [(v) => v.length > 0 || "This field is required."],
		},
		labelValues: {
			plan: "Plan you are interested in",
			fullname: "Full Name",
			email: "Email",
			affiliation: "Company/Institution Name",
			howDidYouHearAboutThis: "How did you hear about this?",
			applicationDomain: "What is your application domain?",
			survey: {
				evaluatingCompetingSolutions: "I am evaluating competing solutions",
			},
		},
		planSelectItems: ["Developer", "Developer+API", "Enterprise"],
	}),
	props: {
		passedFullname: { type: String, default: null },
		passedEmail: { type: String, default: null },
		passedPlan: { type: String, default: null },
	},
	watch: {
		passedPlan: function () {
			/*
			 *  `passedPlan` is the only prop that may change (for the same
			 *  Authentication Session).
			 */
			this.registerPassedProps();
		},
	},
	mounted() {
		this.registerPassedProps();
	},
	methods: {
		registerPassedProps() {
			this.formValues.fullname = this.passedFullname || "";
			this.formValues.email = this.passedEmail || "";
			if (this.planSelectItems.includes(this.passedPlan)) {
				this.formValues.plan = this.passedPlan || "";
			}
		},
		async onSubmit() {
			this.formPostSuccess = false;
			if (!this.$refs.interestForm.validate()) {
				// console.log("form invalid");
				return;
			}
			// console.log("valid");

			const message = {};
			message["interest-form"] = this.formValues;
			message["form-labels"] = this.labelValues;

			const planSelectItems = this.planSelectItems;
			message["form-choices"] = {
				planSelectItems,
			};

			/*
			 *  Build a pretty version of the message.
			 */
			let messagePretty = "";
			messagePretty += "A user has completed the Contact Us form.\n\n";

			for (const key in this.labelValues) {
				if (key === "survey") {
					messagePretty += "\n# Survey\n";
					for (const surveyKey in this.labelValues[key]) {
						if (Object.hasOwnProperty.call(this.labelValues[key], surveyKey)) {
							messagePretty += `${this.labelValues[key][surveyKey]} : ${this.formValues[key][surveyKey]}\n`;
						}
					}
				} else if (Object.hasOwnProperty.call(this.labelValues, key)) {
					messagePretty += `${this.labelValues[key]} : ${this.formValues[key]}\n`;
					if (key === "plan") {
						messagePretty += "\n# Personal Details\n";
					}
				}
			}

			try {
				const sess = await Auth.currentSession();
				// console.log(sess);
				messagePretty += "\n# User Metadata\n";
				messagePretty += `CognitoUser email: ${sess.idToken.payload["cognito:username"]}\n`;
				messagePretty += `CognitoUser sub: ${sess.idToken.payload["sub"]}\n`;
				messagePretty += `CognitoUser name: ${sess.idToken.payload["name"]}\n`;
				messagePretty += `CognitoUser groups: ${sess.idToken.payload["cognito:groups"]}\n`;
			} catch (error) {
				monitoringCaptureError(error, "Submit interest form");
			}

			try {
				this.submitButtonLoading = true;
				const response = await joinWaitlist(messagePretty);
				this.$refs.interestForm.reset();
				this.formPostSuccess = true;
				// @ts-ignore
				this.$posthog?.capture("submitted_interest_form");
			} catch (error) {
				monitoringCaptureError(error, "Submit interest form");
			} finally {
				this.submitButtonLoading = false;
			}
		},
	},
});
</script>

<style scoped></style>
