var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      attrs: { "min-height": "5em", "min-width": "20em", "max-width": "50em" },
    },
    [
      _c(
        "v-card-text",
        { staticClass: "py-1 px-0" },
        [
          _c("v-simple-table", {
            attrs: { dense: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function () {
                  return [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { staticClass: "text-left" }),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v("Active Core"),
                        ]),
                        _vm.minimumCoreConfig
                          ? _c("th", { staticClass: "text-left" }, [
                              _vm._v(" Required Core "),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      [
                        _vm._l(_vm.fieldsToDisplay, function (key) {
                          return [
                            Object.hasOwnProperty.call(_vm.activeCore, key)
                              ? _c(
                                  "tr",
                                  {
                                    key: key,
                                    class: _vm.coreRequirementWarnings[key]
                                      ? "danger--text"
                                      : "",
                                  },
                                  [
                                    _c("td", [
                                      _vm._v(_vm._s(_vm.formatKeyString(key))),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatValueString(
                                            key,
                                            _vm.activeCore[key]
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm.minimumCoreConfig
                                      ? _c("td", { staticClass: "text-left" }, [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.limitValuePrefix(key)
                                              ),
                                            },
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatValueString(
                                                  key,
                                                  _vm.minimumCoreConfig[key]
                                                ) ?? "-"
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }