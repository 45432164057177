var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _vm._t("clickTarget", function () {
        return [
          _c("TooltipButton", {
            attrs: {
              tooltipText: `Copy ${_vm.valueName} to clipboard`,
              icon: true,
              btnClass: "ml-sm-1 ml-n1",
            },
            on: { click: _vm.copyToClipboard },
            scopedSlots: _vm._u([
              {
                key: "btnContent",
                fn: function () {
                  return [
                    _c("v-icon", { attrs: { small: "" } }, [
                      _vm._v(" mdi-clipboard-text-multiple-outline"),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ]
      }),
      _c(
        "v-snackbar",
        {
          attrs: { color: "green lighten-1", bottom: "" },
          model: {
            value: _vm.showCopyNotification,
            callback: function ($$v) {
              _vm.showCopyNotification = $$v
            },
            expression: "showCopyNotification",
          },
        },
        [
          _c("v-icon", { staticClass: "pl-2", attrs: { small: "" } }, [
            _vm._v(" mdi-check"),
          ]),
          _vm._v(" Copied " + _vm._s(_vm.valueName) + " to clipboard! "),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }