var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-chip",
    { attrs: { color: _vm.tierColour, small: "", outlined: "" } },
    [_vm._v(" " + _vm._s(_vm.tierName) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }