var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.$vuetify.breakpoint.mobile ? "95%" : "500px" },
      on: {
        "click:outside": _vm.closeDialog,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeDialog.apply(null, arguments)
        },
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-sheet",
        {
          staticClass: "pa-10 pa-lg-10",
          attrs: {
            width: _vm.$vuetify.breakpoint.mobile ? "100%" : "500px",
            tile: _vm.$vuetify.breakpoint.mobile,
            elevation: !_vm.$vuetify.breakpoint.mobile ? "5" : "0",
            rounded: "",
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "d-flex justify-space-between" },
                [
                  _c(
                    "div",
                    { staticClass: "text-h5 pa-3 pt-0 font-weight-medium" },
                    [_vm._v("Remove Task")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text--primary",
                      attrs: {
                        color: "white",
                        small: "",
                        outlined: "",
                        height: "36",
                        elevation: "0",
                      },
                      on: { click: _vm.closeDialog },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "text-body-2 pa-3 mt-3" }, [
                _c("span", [
                  _vm._v(
                    "Please confirm that you want to permanently remove task "
                  ),
                ]),
                _vm.taskID != null
                  ? _c(
                      "code",
                      {
                        staticClass: "font-weight-regular mx-0",
                        staticStyle: { "font-size": "0.875em" },
                        attrs: { "data-test": "task-card-task-id-value" },
                      },
                      [_vm._v(_vm._s(_vm.taskID))]
                    )
                  : _vm._e(),
              ]),
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "mt-4",
                          attrs: { cols: "12", md: "12" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "text-body-1 font-weight-medium auth-button",
                              attrs: {
                                color: "secondary",
                                depressed: "",
                                width: "100%",
                                loading: _vm.buttonIsLoading,
                                type: "submit",
                                "data-test": "task-remove-button",
                              },
                              on: { click: _vm.removeTask },
                            },
                            [_vm._v("Remove Task")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.showSupportMessage
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "v-alert",
                                {
                                  staticStyle: { "text-align": "left" },
                                  attrs: {
                                    dense: "",
                                    outlined: "",
                                    type: "warning",
                                    border: "left",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.getSupportMessage) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "text-center" }, [
                        _c("span", { staticClass: "text-body-2" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: { click: _vm.closeDialog },
                            },
                            [_vm._v("Go back")]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }