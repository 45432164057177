
import { PropType, defineComponent } from "vue";

// Types
import { UserKeyFormatted, UserKey } from "@/types/api/keys";

// Libraries
import * as signaloidClient from "@/js/signaloidClient";
import axios from "axios";

// Utilities
import * as Sentry from "@sentry/vue";

// Stores
import { useUserStore } from "@/stores/user";

type VDataTableHeader = {
	text: string;
	value: string;
	align?: "start" | "center" | "end";
	sortable?: boolean;
	filterable?: boolean;
	groupable?: boolean;
	divider?: boolean;
	class?: string | string[];
	cellClass?: string | string[];
	width?: string | number;
	filter?: (value: any, search: string, item: any) => boolean;
	sort?: (a: any, b: any) => number;
};

type ComponentData = {
	headers: VDataTableHeader[];
	confirmDeleteKeyButtonLoading: boolean;
	errorText: string;
};

function formatKey(keyID: string): string {
	return "scce_" + keyID.substring(0, 8);
}

export default defineComponent({
	name: "DeleteAPIKey",
	components: {},
	emits: ["update:showDialog", "keyDeleted"],
	data: (): ComponentData => ({
		confirmDeleteKeyButtonLoading: false,
		errorText: "",
		headers: [
			{
				text: "Name",
				align: "start",
				sortable: false,
				value: "Name",
			},
			{
				text: "Key ID",
				align: "start",
				value: "KeyID",
				sortable: false,
			},
			{
				text: "Creation Date",
				align: "start",
				value: "FormattedCreatedAt",
				sortable: false,
			},
			{
				text: "Valid Until",
				align: "start",
				value: "FormattedValidUntil",
				sortable: false,
			},
		],
	}),
	props: {
		showDialog: { type: Boolean, default: false },
		passedKey: {
			type: Object as PropType<UserKeyFormatted>,
			default: () => {},
		},
	},
	computed: {
		formattedKey(): string {
			return formatKey(this.passedKey.KeyID);
		},
	},
	setup() {
		const userStore = useUserStore();
		return { userStore };
	},
	methods: {
		closeDialog() {
			this.$emit("update:showDialog", false);
		},
		async confirmDeleteKey() {
			this.confirmDeleteKeyButtonLoading = true;
			this.errorText = "";
			const keyID = (this.passedKey as UserKeyFormatted).KeyID;

			try {
				await signaloidClient.deleteUserAPIKeyByID(keyID);
				this.closeDialog();
				this.$emit("keyDeleted", keyID);
			} catch (error) {
				// Can fail with: 400, 404, 500
				let severityLevel: Sentry.SeverityLevel = "error";
				if (axios.isAxiosError(error)) {
					if (error.response) {
						/*
						 *	This API can only return: 400, 404, 500, 401, 404 (UserID), 500
						 */
						switch (error.response.status) {
							case 500:
								severityLevel = "fatal"; // HTTP 500 means a Lambda is failing!
								this.errorText =
									`Our system encountered a server error deleting the API key (HTTP Code ${error.response.status}).` +
									" Please try refreshing the page. If this error persists, please contact support at developer-support@signaloid.com.";
								break;
							case 401:
								this.errorText =
									"Our system encountered an authorization error while deleting the API key." +
									" Please try refreshing the page. Signing out and back in will ensure you are properly authorized." +
									" If this error persists, please contact support at developer-support@signaloid.com.";
								break;
							case 404:
							case 400:
								severityLevel = "fatal"; // Why is it even in the list if it doesn't exist?
								this.errorText =
									"Our system encountered an error while deleting this API key." +
									" Our team will receive a high priority notification for this issue." +
									" If this error persists, please contact support at developer-support@signaloid.com.";
								break;

							default:
								break;
						}
					} else if (error.request) {
						// no response  (this also fires when client is offline)
						this.errorText =
							"A request to delete the API key was initiated but there was no response." +
							" Please ensure that you have an active Internet connection." +
							" If this error persists, please contact support at developer-support@signaloid.com.";
					} else {
						// no request made
						this.errorText =
							"A browser error ocurred when trying to make the network request to delete the API key." +
							" Please ensure that you have an active Internet connection." +
							" If this error persists, please contact support at developer-support@signaloid.com.";
					}
				} else {
					// other error
					this.errorText =
						"The network request could not be made due to an unexpected error" +
						" If this error persists, please contact support at developer-support@signaloid.com.";
				}
				console.log(error);
				Sentry.captureException(error, {
					level: severityLevel,
					extra: { userMessage: this.errorText },
					tags: { service: "delete-api-key" },
				});
				console.log(error);
			} finally {
				this.confirmDeleteKeyButtonLoading = false;
			}
		},
	},
});
