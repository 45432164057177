var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      on: {
        "click:outside": _vm.closeDialog,
        keydown: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            )
              return null
            return _vm.closeDialog.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.setValue.apply(null, arguments)
          },
        ],
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pb-1" },
        [
          _c("v-card-title", { staticClass: "py-3 px-4" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "py-2 px-4", staticStyle: { "min-width": "350px" } },
            [
              _c(
                "v-form",
                {
                  ref: "mainForm",
                  model: {
                    value: _vm.formValid,
                    callback: function ($$v) {
                      _vm.formValid = $$v
                    },
                    expression: "formValid",
                  },
                },
                [
                  _c("v-text-field", {
                    staticClass: "pt-2 mb-n2",
                    attrs: {
                      placeholder: "valueHint",
                      maxlength: "50",
                      rules: [_vm.rules.required, _vm.rules.counter],
                      counter: 50,
                      autofocus: "",
                      outlined: "",
                      dense: "",
                    },
                    model: {
                      value: _vm.newValue,
                      callback: function ($$v) {
                        _vm.newValue = $$v
                      },
                      expression: "newValue",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    elevation: "0",
                    width: "160px",
                    disabled: !_vm.formValid,
                  },
                  on: { click: _vm.setValue },
                },
                [_vm._v(" Set " + _vm._s(_vm.valueHint) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }