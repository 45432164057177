
import { defineComponent } from "vue";
import TierMarker from "@/components/Common/TierMarker.vue";
import TooltipButton from "@/components/Common/TooltipButton.vue";
import moment, { Moment } from "moment";
import { toStringWithMagnitude, toStringWithByteMagnitude, numberLessThanTenToWords } from "@/js/utilities";
import { mapState } from "pinia";
import { useUserStore } from "@/stores/user";
import { AWSAuthenticatedUserObject } from "@/types/user";

type ComponentData = {
	authenticatedUser: undefined | null | AWSAuthenticatedUserObject;
};

export default defineComponent({
	name: "UserUsageAllowanceCard",
	components: { TierMarker, TooltipButton },
	props: {},
	data: (): ComponentData => ({
		authenticatedUser: undefined,
	}),
	computed: {
		usageResetDay(): undefined | Moment {
			if (!this.currentUserResourceUsage) {
				return undefined;
			}
			return moment(this.currentUserResourceUsage?.ResetsAt * 1000);
		},
		usageResetDayString(): string {
			return this.usageResetDay?.isValid() ? this.usageResetDay.fromNow() : "";
		},
		usageResetDate(): string {
			return this.usageResetDay?.isValid() ? this.usageResetDay.format("MMM Do") : "";
		},
		usageCardSubtitle(): string {
			if (this.usageResetDay && this.usageResetDay.isValid() && this.usageResetDay?.isSameOrAfter(moment())) {
				return `Usage quota resets ${this.usageResetDay.fromNow()} on ${this.usageResetDate}.`;
			} else {
				return "Upgrade to increase your monthly quota.";
			}
		},
		...mapState(useUserStore, {
			user: "currentUser",
			currentUserResourceUsage: "currentUserResourceUsage",
			currentUserTierDetails: "currentUserTierDetails",
			currentUserTierCode: "currentUserTierCode",
		}),
		userCanUpgrade():boolean{
			return  this.currentUserTierCode != "enterprise-tier"
		}
	},
	setup() {
		// Note: gets called after beforeCreate in vue2
		const userStore = useUserStore();
		return { userStore };
	},
	methods: {
		toStringWithMagnitude(value) {
			if (value == undefined) {
				return "Unlimited";
			}
			if (value < 10) {
				const word = numberLessThanTenToWords(value);
				// Make first char uppercase
				return word[0].toUpperCase() + word.slice(1);
			}
			return toStringWithMagnitude(value);
		},
		toStringWithByteMagnitude(value) {
			return toStringWithByteMagnitude(value);
		},
	},
	async created() {
		this.authenticatedUser = await this.userStore.getCurrentAuthenticatedUserObject();
		this.userStore.getCurrentUserTierDetails();
	},
});
