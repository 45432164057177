
import { defineComponent } from "vue";

// Types

// Libraries

// Utilities

// Components
import TooltipButton from "@/components/Common/TooltipButton.vue";

// Stores

// Global variables

export default defineComponent({
	name: "CopyToClipboardButton",
	components: { TooltipButton },
	props: {
		valueToCopy: {
			type: String,
			default: "",
		},
		valueName: {
			type: String,
			default: "",
		},
	},
	data: (): { showCopyNotification: boolean } => ({
		showCopyNotification: false,
	}),

	methods: {
		copyToClipboard() {
			if (this.valueToCopy) {
				navigator.clipboard.writeText(this.valueToCopy);
				this.showCopyNotification = true;
			}
		},
	},
});
