
import { defineComponent, PropType } from "vue";
import { mapState } from "pinia";
import { useUserStore } from "@/stores/user";
import UpgradeButton from "@/components/Common/UpgradeButton.vue";
import { toStringWithSIMagnitude } from "@/js/utilities";
import { TierLimitDisplayObject } from "@/js/tierconfig";

export default defineComponent({
	name: "UsageDetailCard",
	components: { UpgradeButton },
	props: {
		title: { type: String, default: "" },
		subtitle: {
			type: Object,
			default: () => ({
				default: "",
				warning: "",
				error: "",
			}),
		},
		icon: { type: String },
		iconSize: { type: Number, default: 36 },
		usageQuotas: { type: Array as PropType<TierLimitDisplayObject[]>, default: () => [] },
		warningThreshold: { type: Number, default: 80 },
		colourful: { type: Boolean, default: false },
		showHeader: { type: Boolean, default: true },
	},
	expose: ["showUpgradeButton"],
	computed: {
		someQuotasOverThreshold(): boolean {
			return this.usageQuotas.some((v) => {
				return this.usagePercentage(v.usage, v.limit) > this.warningThreshold;
			});
		},
		noResourcesInClass(): boolean {
			return this.usageQuotas.every((v) => {
				return v.limit === 0 || v.limit === null;
			});
		},

		iconColor(): string {
			return this.someQuotasOverThreshold ? "warning" : "primary";
		},
		subtitleToDisplay(): string {
			if (this.noResourcesInClass) {
				return this.subtitle?.error ?? "Upgrade to access more features.";
			} else if (this.someQuotasOverThreshold) {
				return this.subtitle.warning;
			} else {
				return this.subtitle.default;
			}
		},
		...mapState(useUserStore, {
			currentUserTierCode: "currentUserTierCode",
		}),
		showUpgradeButton(): boolean {
			const noTierToUpgradeTo = this.currentUserTierCode == "enterprise-tier";
			if (noTierToUpgradeTo) {
				return false;
			}
			return this.noResourcesInClass || this.someQuotasOverThreshold;
		},
	},
	methods: {
		toStringWithSIMagnitude(value) {
			return toStringWithSIMagnitude(value);
		},
		usageBarColour(usage, limit) {
			// If there is no limit
			if (limit === undefined) {
				return "primary";
			}

			// If resource is not allowed
			if (limit === null) {
				return "grey";
			}

			if (!this.validNumericalLimit(usage, limit)) {
				return "grey";
			}
			const usagePercentage = this.usagePercentage(usage, limit);
			if (this.colourful) {
				if (usagePercentage >= 100) {
					return "danger";
				} else if (usagePercentage >= this.warningThreshold) {
					return "warning";
				} else if (usagePercentage >= 20) {
					return "primary";
				} else {
					return "accent";
				}
			} else {
				const overTheLimit = usagePercentage >= 100;
				if (overTheLimit) {
					return "danger";
				}
				const overThresholdLimit = usagePercentage >= this.warningThreshold;
				if (overThresholdLimit) {
					return "warning";
				}
				return "primary";
			}
		},
		usagePercentage(usage, limit) {
			if (!this.validNumericalLimit(usage, limit)) return 0;
			return this.validNumericalLimit(usage, limit) ? (100 * usage) / limit : 100.0;
		},
		validNumericalLimit(usage, limit) {
			return !(
				limit === undefined ||
				limit === null ||
				isNaN(usage) ||
				isNaN(limit) ||
				(usage == 0 && limit == 0)
			);
		},
		suffix(quotaSuffix, usage, limit) {
			if (!quotaSuffix || quotaSuffix.length == 0) {
				return;
			}

			const isSingular = this.validNumericalLimit(usage, limit) ? limit == 1 : usage == 1;

			if (typeof quotaSuffix == "string") {
				return isSingular ? quotaSuffix : quotaSuffix + "s";
			} else if (Array.isArray(quotaSuffix)) {
				if (quotaSuffix.length > 1) {
					return isSingular ? quotaSuffix[0] : quotaSuffix[1];
				} else {
					console.warn("unexpected suffix structure");
					return isSingular ? quotaSuffix[0] : quotaSuffix[0] + "s";
				}
			}
			console.warn("unexpected suffix type");
			return;
		},
	},
});
