
import { defineComponent } from "vue";

// Components
import UsageLineChart from "@/components/Home/UsageLineChart.vue";
import UsageDetailCard from "@/components/Usage/UsageDetailCard.vue";
import UserUsageAllowanceCard from "@/components/Usage/UserUsageAllowanceCard.vue";

// Libraries
import moment from "moment";

// Stores
import { mapState } from "pinia";
import { useDataSourcesStore } from "@/stores/dataSources";
import { useUserStore } from "@/stores/user";
import { useCoresStore } from "@/stores/cores";
import { useRepositoriesStore } from "@/stores/repositories";
import { AccountTierDetail, TierLimitDisplayObject, UserLimitsE } from "@/js/tierconfig";
import { UserResourceUsage } from "@/types/user";

type ComponentData = {
	usageHistoryAvailable: boolean;
	computeUsage: TierLimitDisplayObject[];
	storageUsage: TierLimitDisplayObject[];
	connectionsUsage: TierLimitDisplayObject[];
	apiUsage: TierLimitDisplayObject[];
	usageResetDay: undefined | moment.Moment;
};

export default defineComponent({
	name: "Usage",
	components: { UsageDetailCard, UsageLineChart, UserUsageAllowanceCard },
	data: (): ComponentData => ({
		usageHistoryAvailable: false,
		computeUsage: [
			{
				name: "Dynamic Instructions",
				id: UserLimitsE.DynamicInstructionCount,
				usage: 0,
				limit: 0,
				unit: "",
				quotaSuffix: "",
			},
			{
				name: "Concurrent Tasks",
				id: UserLimitsE.ConcurrentTaskCount,
				usage: 0,
				limit: 0,
				unit: "",
				quotaSuffix: "",
			},
			{
				name: "Custom Cores",
				id: UserLimitsE.CoreCount,
				usage: 0,
				limit: 0,
				unit: "",
				quotaSuffix: "",
			},
			{
				name: "Distribution Plots",
				id: UserLimitsE.PlotCount,
				usage: 0,
				limit: 0,
				unit: "",
				quotaSuffix: "",
			},
		],
		storageUsage: [
			{
				name: "Cloud Storage",
				id: UserLimitsE.CloudStorageBytes,
				usage: 0,
				limit: 0,
				unit: "B",
			},
			{
				name: "Data Drives",
				id: UserLimitsE.DataDriveCount,
				usage: 0,
				limit: 0,
				unit: "",
				quotaSuffix: "",
			},
		],
		connectionsUsage: [
			{
				name: "Repositories",
				id: UserLimitsE.RepositoryCount,
				usage: 0,
				limit: 0,
				unit: "",
				quotaSuffix: "",
			},
			{
				name: "Sensor Gateways",
				id: UserLimitsE.GatewayCount,
				usage: 0,
				limit: 0,
				hasNoLimit: false,
				unit: "",
				quotaSuffix: "",
			},
			{
				name: "S3 Buckets",
				id: UserLimitsE.BucketCount,
				usage: 0,
				limit: 0,
				hasNoLimit: false,
				unit: "",
				quotaSuffix: "",
			},
		],
		apiUsage: [
			{
				name: "API Requests",
				id: UserLimitsE.APIAccessCount,
				usage: 0,
				limit: 0,
				unit: "",
				quotaSuffix: "",
			},
		],
		usageResetDay: undefined,
	}),
	setup() {
		// Note: gets called after beforeCreate in vue2
		const dataSourcesStore = useDataSourcesStore();
		const userStore = useUserStore();
		const repositoriesStore = useRepositoriesStore();
		return { dataSourcesStore, userStore, repositoriesStore };
	},
	computed: {
		usageResetDayString(): string {
			return this.usageResetDay && this.usageResetDay.isValid() ? this.usageResetDay.fromNow() : "";
		},
		usageResetDate(): string {
			return this.usageResetDay && this.usageResetDay.isValid() ? this.usageResetDay.format("MMM Do") : "";
		},
		usageCardSubtitle(): { default: string; warning: string } {
			this.currentUserResourceUsage;
			if (this.usageResetDay && this.usageResetDay.isValid() && this.usageResetDay?.isSameOrAfter(moment())) {
				return {
					default: `Usage quota resets ${this.usageResetDay.fromNow()} on ${this.usageResetDate}.`,
					warning: `Usage resets ${this.usageResetDay.fromNow()}. Upgrade to increase your monthly quota.`,
				};
			} else {
				return {
					default: "Upgrade to increase your monthly quota.",
					warning: "Upgrade to increase your monthly quota.",
				};
			}
		},
		...mapState(useCoresStore, {
			localCoresList: "localCoresList",
		}),
		...mapState(useDataSourcesStore, {
			driveList: "driveList",
			bucketList: "bucketList",
			gatewayList: "gatewayList",
		}),
		...mapState(useUserStore, {
			user: "currentUser",
			currentUserResourceUsage: "currentUserResourceUsage",
			currentUserTierDetails: "currentUserTierDetails",
		}),
	},
	watch: {
		currentUserResourceUsage: {
			handler: function (newUsage: UserResourceUsage, oldUsage) {
				if (newUsage) {
					this.usageResetDay = moment(newUsage.ResetsAt * 1000);
					this.computeUsage = this.computeUsage.map((v: TierLimitDisplayObject) => {
						v.usage = newUsage[v.id] ?? v.usage;
						return v;
					});
					this.storageUsage = this.storageUsage.map((v: TierLimitDisplayObject) => {
						v.usage = newUsage[v.id] ?? v.usage;
						return v;
					});
					this.connectionsUsage = this.connectionsUsage.map((v: TierLimitDisplayObject) => {
						v.usage = newUsage[v.id] ?? v.usage;
						return v;
					});
					this.apiUsage = this.apiUsage.map((v: TierLimitDisplayObject) => {
						v.usage = newUsage[v.id] ?? v.usage;
						return v;
					});
				}
			},
			deep: true,
			immediate: true,
		},
		driveList: {
			handler: function (newValue, oldValue) {
				this.storageUsage[this.storageUsage.findIndex((v) => v.id == UserLimitsE.DataDriveCount)].usage =
					newValue.length;
			},
			immediate: true,
		},
		gatewayList: {
			handler: function (newValue, oldValue) {
				this.connectionsUsage[this.connectionsUsage.findIndex((v) => v.id == UserLimitsE.GatewayCount)].usage =
					newValue.length;
			},
			immediate: true,
		},
		bucketList: {
			handler: function (newValue, oldValue) {
				this.connectionsUsage[this.connectionsUsage.findIndex((v) => v.id == UserLimitsE.BucketCount)].usage =
					newValue.length;
			},
			immediate: true,
		},
		currentUserTierDetails: {
			handler: function (newLimits: undefined | AccountTierDetail, oldLimits) {
				if (newLimits) {
					const allowance = newLimits.allowance;

					this.computeUsage = this.computeUsage.map((v) => {
						v.limit = allowance[v.id];
						return v;
					});

					this.storageUsage = this.storageUsage.map((v) => {
						v.limit = allowance[v.id];
						return v;
					});

					this.connectionsUsage = this.connectionsUsage.map((v) => {
						v.limit = allowance[v.id];
						return v;
					});
					this.apiUsage = this.apiUsage.map((v) => {
						v.limit = allowance[v.id];
						return v;
					});
				}
			},
			deep: true,
			immediate: true,
		},
	},

	async created() {
		this.userStore.fetchCurrentUserObjectFromDB();
		this.userStore.getCurrentUserTierDetails();
		await Promise.allSettled([
			this.dataSourcesStore.fetchGatewayList(),
			this.dataSourcesStore.fetchDriveList(),
			this.dataSourcesStore.fetchBucketList(),
		]);
	},
});
