
import { defineComponent } from "vue";

// Types
import { UserLimitsE } from "@/js/tierconfig";

// Libraries

// Components
import TaskListDisplay from "@/components/TaskManager/TaskListDisplay.vue";
import LoadingCard from "@/components/LoadingCard.vue";

// Stores
import { useTasksStore } from "@/stores/tasks";

// Global variables

// Local Types

export default defineComponent({
	name: "TaskManager",
	components: { LoadingCard, TaskListDisplay },
	setup() {
		const tasksStore = useTasksStore();
		return { tasksStore, UserLimitsE };
	},
	methods: {
		async loadMoreTasks() {
			await this.tasksStore.fetchNextTaskBatchFromRemote(true);
		},
		async loadMoreActiveTasks() {
			// Currently not being used
			await this.tasksStore.fetchNextActiveTaskBatchFromRemote(true);
		},
	},
});
