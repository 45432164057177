<template>
	<v-dialog
		v-model="showDialog"
		:width="$vuetify.breakpoint.mobile ? '95%' : '900px'"
		@click:outside="closeDialog"
		@keydown.esc="closeDialog"
		@keydown.enter="setValue"
	>
		<VideoPlayerComponent
			v-if="videoURL"
			:videoURL="videoURL"
		></VideoPlayerComponent>
	</v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import VideoPlayerComponent from "@/components/Common/VideoPlayerComponent.vue";

export default defineComponent({
	name: "VideoDialog",
	components: {
		VideoPlayerComponent,
	},
	props: {
		videoURL: {
			type: String,
			required: true,
		},
		showDialog: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({}),
	methods: {
		closeDialog() {
			this.$emit("update:showDialog", false);
		},
	},
});
</script>

<style scoped>
.video {
	width: 100%;
	object-fit: cover;
	aspect-ratio: 16/9;
}
</style>
