
import { defineComponent, PropType } from "vue";

// Types
import { DataSourceTypeE, isLocalMountConfig, LocalMountConfig } from "@/types/api/dataSources";

// Components
import DataSourceListDialog from "@/components/DataSources/DataSourceListDialog.vue";
import DataSourcePickerCard from "@/components/DataSources/DataSourcePickerCard.vue";

type ComponentData = {
	mountConfig: Partial<LocalMountConfig>;
	showMountDataSourcesDialog: boolean;
};

export default defineComponent({
	name: "MountDataSourcesDialog",
	components: { DataSourcePickerCard, DataSourceListDialog },
	props: {
		showDialog: { type: Boolean, default: false },
		initialMountConfig: { type: Object as PropType<Partial<LocalMountConfig>> },
		dataSourceExcludeList: { type: Array, default: () => [] },
	},
	emits: {
		"set-data-sources": (mountConfig: Partial<LocalMountConfig>) => true,
		"remove-data-sources": (data: null) => true,
		"reset-data-sources": (mountConfig: undefined) => true,
		"close-dialog": () => true,
	},
	data: (): ComponentData => ({
		mountConfig: {
			Location: undefined,
			ResourceID: undefined,
			ResourceType: undefined,
		},
		showMountDataSourcesDialog: false,
	}),
	methods: {
		closeDialog() {
			this.$emit("close-dialog");
		},
		setDataSource(dataSource: LocalMountConfig) {
			this.mountConfig.ResourceID = dataSource.ResourceID;
			this.mountConfig.ResourceType = dataSource.ResourceType;

			if (
				this.mountConfig.ResourceType === DataSourceTypeE.Drive &&
				(this.mountConfig.Location === "" || this.mountConfig.Location === undefined)
			) {
				this.mountConfig.Location = ".";
			}
		},
		unselectDataSource() {
			this.mountConfig.ResourceID = undefined;
			this.mountConfig.ResourceType = undefined;
		},
		saveMountConfig() {
			if (this.validConfig) {
				this.$emit("set-data-sources", this.mountConfig);
			}
		},
		removeMountConfig() {
			this.$emit("remove-data-sources", null);
		},
		resetMountConfig() {
			this.$emit("reset-data-sources", undefined);
		},
	},
	computed: {
		validConfig(): boolean {
			return isLocalMountConfig(this.mountConfig) && this.validMountLocation;
		},
		validMountLocation(): boolean {
			if (!this.mountConfig.ResourceID) {
				return true;
			}

			if (this.mountConfig.Location !== undefined) {
				if (this.mountConfig.Location === "") {
					return false;
				}

				if (this.mountConfig.Location.includes("..")) {
					return false;
				}

				if (this.mountConfig.Location.includes("\\")) {
					return false;
				}

				if (this.mountConfig.Location.startsWith("/")) {
					return false;
				}

				if (this.mountConfig.ResourceType !== DataSourceTypeE.Drive) {
					if (this.mountConfig.Location.includes(".")) {
						return false;
					} else {
						return true;
					}
				}
				return true;
			}
			return false;
		},
	},
	mounted() {
		if (isLocalMountConfig(this.initialMountConfig)) {
			this.mountConfig = { ...this.initialMountConfig };
		} else {
			this.mountConfig = {
				Location: "",
				ResourceID: undefined,
				ResourceType: undefined,
			};
		}
	},
});
