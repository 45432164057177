var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          staticClass: "mx-0 px-0",
          attrs: {
            width: _vm.$vuetify.breakpoint.smAndDown ? "95%" : "1000px",
            height: _vm.$vuetify.breakpoint.smAndDown ? "95%" : "80vh",
            scrollable: "",
          },
          on: {
            "click:outside": _vm.closeDialog,
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.closeDialog.apply(null, arguments)
            },
          },
          model: {
            value: _vm.dialogVisible,
            callback: function ($$v) {
              _vm.dialogVisible = $$v
            },
            expression: "dialogVisible",
          },
        },
        [
          _c(
            "v-card",
            {
              attrs: {
                color: "white",
                rounded: "lg",
                "min-height": _vm.fullRepoList.length > 0 ? "90vh" : undefined,
              },
            },
            [
              _c(
                "template",
                { slot: "progress" },
                [
                  _c("v-progress-linear", {
                    attrs: { color: "primary", height: "5", indeterminate: "" },
                  }),
                ],
                1
              ),
              _c(
                "v-card-title",
                { staticClass: "mx-0 pt-5" },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "px-md-6 px-1",
                      attrs: {
                        "justify-content": "space-between",
                        align: "center",
                        "align-content": "center",
                      },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 px-sm-1",
                          attrs: {
                            cols: _vm.$vuetify.breakpoint.smAndDown
                              ? "5"
                              : "auto",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-truncate text-sm-h6 font-weight-bold",
                            },
                            [_vm._v("Repositories on GitHub")]
                          ),
                        ]
                      ),
                      _c("v-spacer"),
                      !_vm.$vuetify.breakpoint.mobile
                        ? _c(
                            "v-col",
                            {
                              staticClass: "mb-0 py-0 px-0",
                              attrs: {
                                "align-self": "end",
                                cols: "4",
                                sm: "3",
                                md: "5",
                              },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "prepend-icon": "mdi-magnify",
                                  label: "Search",
                                  autofocus: true,
                                  loading: _vm.searchingRepos,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.searchUserRepositories()
                                  },
                                },
                                model: {
                                  value: _vm.searchQuery,
                                  callback: function ($$v) {
                                    _vm.searchQuery = $$v
                                  },
                                  expression: "searchQuery",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        { staticClass: "ml-0 pr-0", attrs: { cols: "auto" } },
                        [
                          _vm.$vuetify.breakpoint.mobile
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    height: "36",
                                    elevation: "0",
                                    color: _vm.showSearchBarEnabled
                                      ? "primary"
                                      : "grey",
                                    outlined: "",
                                    icon: "",
                                  },
                                  on: { click: _vm.toggleSearchBar },
                                },
                                [_c("v-icon", [_vm._v("mdi-magnify")])],
                                1
                              )
                            : _vm._e(),
                          !_vm.$vuetify.breakpoint.mobile
                            ? _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "mr-2 px-sm-2 px-0 text-center text-body-2 font-weight-medium",
                                  attrs: {
                                    color: "primary",
                                    label: "",
                                    outlined: "",
                                  },
                                },
                                [
                                  !_vm.$vuetify.breakpoint.smAndDown
                                    ? _c("div", { staticClass: "mr-2" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.githubStore.githubUsername
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "v-avatar",
                                    {
                                      attrs: {
                                        "max-height": "60%",
                                        size: "25",
                                      },
                                    },
                                    [
                                      _vm.githubStore.githubUserDetails
                                        ? _c("img", {
                                            attrs: {
                                              contain: "true",
                                              src: _vm.githubStore
                                                .githubUserDetails.avatar_url,
                                              alt: _vm.githubStore
                                                .githubUsername,
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-btn",
                            {
                              staticClass: "text--primary",
                              attrs: {
                                color: "white",
                                small: "",
                                height: "36",
                                elevation: "0",
                                outlined: "",
                              },
                              on: { click: _vm.closeDialog },
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.showSearchBar
                        ? _c(
                            "v-col",
                            {
                              staticClass: "mt-0 mb-0 mx-auto py-0",
                              attrs: { "align-self": "center", cols: "11" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "prepend-icon": "mdi-magnify",
                                  label: "Search",
                                  dense: "",
                                  autofocus: true,
                                  loading: _vm.searchingRepos,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.searchUserRepositories()
                                  },
                                },
                                model: {
                                  value: _vm.searchQuery,
                                  callback: function ($$v) {
                                    _vm.searchQuery = $$v
                                  },
                                  expression: "searchQuery",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c("v-card-text", { staticClass: "px-md-6 px-1 py-0 py-sm-1" }, [
                _c(
                  "div",
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "transition",
                          { attrs: { name: "noResultBannerTransition" } },
                          [
                            _vm.showNoResultsBanner
                              ? _c(
                                  "v-alert",
                                  {
                                    staticClass:
                                      "d-flex justify-center my-5 mx-auto",
                                    attrs: {
                                      outlined: "",
                                      width: "80%",
                                      type: "warning",
                                      dense: "",
                                      border: "left",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " No repositories matching the query were found "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "transition-group",
                          {
                            attrs: {
                              name: "repositoryCardsListTransition",
                              tag: "div",
                              mode: "out-in",
                            },
                          },
                          [
                            !_vm.showNoResultsBanner
                              ? [
                                  _vm._l(_vm.displayRepoList, function (repo) {
                                    return _c("AddRepositoryCard", {
                                      key: repo.id,
                                      staticClass: "ma-2",
                                      attrs: { initialRepoData: repo },
                                      on: {
                                        "repo-connection-request":
                                          _vm.requestRepoConnect,
                                        "repository-disconnected":
                                          _vm.repoDisconnectionEventHandler,
                                      },
                                    })
                                  }),
                                  _vm.endOfRepoList
                                    ? _c("EndOfRepoListMarker", {
                                        key: "endOfResultsBanner",
                                      })
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm.loadingRepos
                      ? _c("LoadingCard", {
                          attrs: { loadingText: "Fetching Repositories" },
                        })
                      : _vm._e(),
                    !_vm.endOfRepoList
                      ? _c("IntersectionObserver", {
                          on: { "intersect-enter": _vm.loadNextPage },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("v-divider"),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }