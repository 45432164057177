
import { defineComponent, PropType } from "vue";
import ApplicationDetailDialog from "@/components/Home/ApplicationDetailDialog.vue";
import TooltipButton from "@/components/Common/TooltipButton.vue";
import { getRepoData } from "@/js/githubClient";
import { Endpoints } from "@octokit/types";

import { ApplicationConnectObject, ApplicationSlideInfo } from "@/types/home";
import { monitoringCaptureError } from "@/plugins/monitoring";

export default defineComponent({
	name: "ApplicationSlide",
	components: { TooltipButton, ApplicationDetailDialog },
	props: {
		slideInfo: { type: Object as PropType<ApplicationSlideInfo>, required: true },
	},
	data: (): {
		showDetailDialog: boolean;
		repoData: undefined | Endpoints["GET /repos/{owner}/{repo}"]["response"]["data"];
		loading: boolean;
	} => ({
		showDetailDialog: false,
		repoData: undefined,
		loading: false,
	}),
	computed: {
		applicationURL(): URL {
			return new URL(this.slideInfo.applicationURL);
		},
		applicationHostIcon(): string {
			switch (this.applicationURL.hostname) {
				case "github.com":
					return "mdi-github";
			}
			return "";
		},
		applicationConnectObject(): ApplicationConnectObject | "" {
			switch (this.applicationURL.hostname) {
				case "github.com":
					return {
						name: "Repositories",
						query: { connect: this.applicationURL.href },
					};
			}
			return "";
		},
		demoVideoURL(): URL | undefined {
			return this.slideInfo.demoVideoURL
				? new URL(this.slideInfo.demoVideoURL, process.env.VUE_APP_ASSET_SERVER_URL)
				: undefined;
		},
	},
	methods: {
		async getRepoData() {
			this.loading = true;
			try {
				const resp = await getRepoData(this.applicationURL.pathname.slice(1));
				this.repoData = resp.data;
			} catch (error) {
				// monitoringCaptureError(error, "Get application repository details");
			} finally {
				this.loading = false;
			}
		},
	},
	watch: {
		// Watch for changes in the entire applicationInfo object
		slideInfo: {
			handler(newVal, oldVal) {
				this.getRepoData();
			},
			deep: true,
			immediate: false,
		},
	},
	mounted() {
		this.getRepoData();
	},
});
