import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import DevelopmentPlatform from "../views/DevelopmentPlatform.vue";
import Cores from "../views/Cores.vue";
import DataSources from "../views/DataSources.vue";
import GithubAuth from "../views/GithubAuth.vue";
import Usage from "../views/Usage.vue";
import Home from "../views/Home.vue";

import Repositories from "../views/Repositories.vue";
import Apps from "../views/Apps.vue";

/*
 *Disabled till api migration for pipelines is done
 // import Pipelines from "../views/Pipelines.vue";
 // import PipelineDetail from "../views/PipelineDetail.vue";
 */
import TaskManager from "../views/TaskManager.vue";
import TaskDetail from "../views/TaskDetail.vue";
import RepositoryDetail from "@/components/Repositories/RepositoryDetail.vue";

import Settings_Profile from "@/components/Settings/Profile.vue";
import Settings_General from "@/components/Settings/General.vue";
import Settings_Account from "@/components/Settings/Account.vue";
import Settings_CloudEngineAPI from "@/components/Settings/CloudEngineAPI.vue";
import Settings from "@/views/Settings.vue";
import WelcomePage from "@/views/WelcomePage.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		/*
		 *  Redirects to the default route.
		 */
		path: "/",
		redirect: "/home",
		beforeEnter: (to, from, next) => {
			/*
			 *  Check for history-fallback redirect.
			 *  Source: https://stackoverflow.com/a/61571528
			 */
			if (sessionStorage.getItem("redirect") != null) {
				console.log("Redirect navigation guard activated.");
				console.log("Redirect:", sessionStorage.redirect);
				const redirect = sessionStorage.redirect;
				delete sessionStorage.redirect;
				console.log("Go to:", redirect);
				next({ path: redirect });
			} else {
				next("/home");
			}
		},
	},
	{
		path: "/home",
		name: "Home",
		meta: {
			requiresAuth: true,
		},
		component: Home,
	},
	{
		path: "/welcome",
		name: "Welcome",
		meta: {
			requiresAuth: true,
		},
		component: WelcomePage,
	},
	{
		path: "/launch",
		name: "CodeEditor",
		meta: {
			requiresAuth: true,
		},
		component: DevelopmentPlatform,
	},
	{
		path: "/cores",
		name: "Cores",
		meta: {
			requiresAuth: true,
		},
		component: Cores,
	},
	{
		path: "/datasources",
		name: "DataSources",
		meta: {
			requiresAuth: true,
		},
		component: DataSources,
	},
	{
		path: "/repositories",
		name: "Repositories",
		meta: {
			requiresAuth: true,
		},
		// component: () => import(/* webpackChunkName: "repositories" */ "@/views/Repositories.vue"),
		component: Repositories,
	},
	{
		path: "/repositories/detail",
		name: "RepositoryDetail",
		meta: {
			requiresAuth: true,
		},
		props: (route) => ({ repositoryID: route.query.repositoryID as string }),
		// component: () =>
		// 	import(/* webpackChunkName: "repositories" */ "@/components/Repositories/RepositoryDetail.vue"),
		component: RepositoryDetail,
	},
	{
		path: "/apps",
		name: "Apps",
		meta: {
			requiresAuth: true,
		},
		// component: () => import(/* webpackChunkName: "repositories" */ "@/views/Repositories.vue"),
		component: Apps,
	},
	/*
	  * Disabled till api migration for pipelines is done
	// {
	// 	path: "/pipelines",
	// 	name: "Pipelines",
	// 	meta: {
	// 		requiresAuth: true,
	// 	},
	// 	component: Pipelines,
	// },
	// {
	// 	path: "/pipelines/:pipeline",
	// 	name: "PipelineDetail",
	// 	meta: {
	// 		requiresAuth: true,
	// 	},
	// 	component: PipelineDetail,
	// },
	*/
	{
		path: "/tasks",
		name: "TaskManager",
		meta: {
			requiresAuth: true,
		},
		// component: () => import(/* webpackChunkName: "tasks" */ "@/views/TaskManager.vue"),
		component: TaskManager,
	},
	{
		path: "/tasks/:id",
		name: "TaskDetail",
		meta: {
			requiresAuth: true,
		},
		props: (route) => ({
			id: route.params.id,
			initialTaskData: route.params.initialTaskData,
		}),
		// component: () => import(/* webpackChunkName: "tasks" */ "@/views/TaskDetail.vue"),
		component: TaskDetail,
		beforeEnter: (from, to, next) => {
			return next();
		},
	},
	{
		path: "/usage",
		name: "Usage",
		meta: {
			requiresAuth: true,
		},
		component: Usage,
	},
	{
		path: "/billing",
		name: "Billing",
		meta: {
			requiresAuth: true,
		},
		component: () => import(/* webpackChunkName: "billing" */ "@/views/Billing.vue"),
		children: [
			{
				path: "",
				component: () => import(/* webpackChunkName: "billing-panel" */ "@/components/BillingPanel.vue"),
			},
			{
				path: "subscribe",
				name: "Subscribe",
				meta: {
					requiresAuth: true,
				},
				component: () => import(/* webpackChunkName: "subscribe" */ "@/components/Subscription.vue"),
				beforeEnter: (from, to, next) => {
					return next();
				},
			},
		],
	},
	{
		path: "/subscribe",
		redirect: "/billing",
	},
	{
		path: "/external/oauth/github/auth",
		name: "GithubAuth",
		meta: {
			requiresAuth: true,
		},
		component: GithubAuth,
		props: (route) => ({
			code: route.query.code,
			connect: route.query.connect,
			state: route.query.state,
			error: route.query.error,
			errorDescription: route.query.error_description,
		}),
	},
	{
		path: "/settings",
		meta: {
			requiresAuth: true,
		},
		children: [
			{
				path: "",
				name: "Settings",
				redirect: { name: "Settings_profile" },

				// component: () => import(/* webpackChunkName: "settings" */ "@/components/Settings/Profile.vue"),
			},
			{
				path: "profile",
				name: "Settings_profile",
				// component: () => import(/* webpackChunkName: "settings" */ "@/components/Settings/Profile.vue"),
				component: Settings_Profile,
			},
			{
				path: "general",
				name: "Settings_general",
				// component: () => import(/* webpackChunkName: "settings" */ "@/components/Settings/General.vue"),
				component: Settings_General,
			},
			{
				path: "account",
				name: "Settings_account",
				// component: () => import(/* webpackChunkName: "settings" */ "@/components/Settings/Account.vue"),
				component: Settings_Account,
			},
			{
				path: "api",
				name: "Settings_cloudEngineAPI",
				// component: () => import(/* webpackChunkName: "settings" */ "@/components/Settings/CloudEngineAPI.vue"),
				component: Settings_CloudEngineAPI,
			},
			// {
			// 	path: "billing",
			// 	name: "Settings_billing",
			// 	component: () => import(/* webpackChunkName: "settings" */ "@/components/Settings/Billing.vue"),
			// },
			// {
			// 	path: "integrations",
			// 	name: "Settings_integrations",
			// 	component: () => import(/* webpackChunkName: "settings" */ "@/components/Settings/Integrations.vue"),
			// },
			{
				path: "*",
				redirect: { name: "Settings" },
			},
		],

		// component: () => import(/* webpackChunkName: "settings" */ "@/views/Settings.vue"),
		component: Settings,
	},
	{
		/*
		 *  Wildcard route that redirects to the default route.
		 *  TODO: Consider redirecting to a 404 Not Found page.
		 */
		path: "*",
		redirect: "/",
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

/*
 * Setup router hooks
 */

//*  Send page view to analytics on load
router.afterEach((to, from) => {
	// window.analytics.page(to.name);
	// nextTick(() => {
	// 	posthog?.capture("$pageview", {
	// 		$current_url: to.fullPath,
	// 	});
	// });
});

export default router;
