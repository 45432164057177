
import { defineComponent } from "vue";

// Types
import { ObjectValues } from "@/types/utilities";

// Libraries
import VideoBackground from "vue-responsive-video-background-player";

// Components
import WelcomeSlide from "@/components/WelcomeFlow/WelcomeSlide.vue";
import NoticeCard from "@/components/Common/NoticeCard.vue";
import TierSelectionSlide from "@/components/WelcomeFlow/TierSelectionSlide.vue";

import TooltipButton from "@/components/Common/TooltipButton.vue";
import { defaultDocument } from "@vueuse/core";

// Stores

// Global variables

// Local Types

const WelcomeSteps = {
	Intro: "Intro",
	TierSelect: "TierSelect",
	ContinueToPlatform: "ContinueToPlatform",
} as const;

type ComponentData = {
	showWelcomeDialog: boolean;
	currentStep: ObjectValues<typeof WelcomeSteps>;
	previousOverflow: string | undefined;
};
export default defineComponent({
	name: "WelcomePage",
	components: {
		WelcomeSlide,
		NoticeCard,
		TierSelectionSlide,
		VideoBackground,
		TooltipButton,
	},
	data: (): ComponentData => ({
		showWelcomeDialog: false,
		currentStep: WelcomeSteps.Intro,
		previousOverflow: undefined,
	}),
	emits: {
		"sign-out": () => true,
	},
	setup() {
		const backgroundImage = `${process.env.VUE_APP_ASSET_SERVER_URL}/assets/exported-frame-sig-cut_1.3.1_1.3.1.jpg`;
		return { backgroundImage, WelcomeSteps };
	},
	methods: {
		videoEndHandler() {
			setTimeout(() => {
				this.showWelcomeDialog = true;
			}, 500);
		},
		videoErrorHandler() {
			this.videoEndHandler();
		},
		validateAndContinueToNextSection() {
			switch (this.currentStep) {
				case "Intro":
					this.currentStep = "TierSelect";
					console.log("continue welcome tour ");
					break;
				case "TierSelect":
					this.currentStep = "ContinueToPlatform";
					this.continueToPlatform();
					console.log("continue welcome tour ");
					break;
			}
		},
		continueToPlatform() {
			window.location.href = "/";
		},
	},
	mounted() {
		// this.tasksStore.fetchTaskHistory();
		document.body.classList.add("hide-scrollbar");
		document.getElementById("app")?.classList.add("welcome-page-background");
		this.previousOverflow = document.body.style.overflow;
		document.documentElement.style.setProperty("overflow", "hidden", "important");
		document.body.style.overflow = "hidden";

		// Backup for if video doesn't play ball
		setTimeout(() => {
			this.showWelcomeDialog = true;
		}, 5000);
	},
	beforeDestroy() {
		document.body.classList.remove("hide-scrollbar");
		document.getElementById("app")?.classList.remove("welcome-page-background");
		document.body.style.overflow = this.previousOverflow!;
		this.previousOverflow = undefined;
	},
});
