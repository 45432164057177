<template>
	<v-card
		class="pa-2 pa-sm-3"
		rounded="lg"
		outlined
	>
		<template v-if="driveConfig">
			<!-- TITLE -->
			<v-row no-gutters>
				<v-col align-self="baseline">
					<v-icon class="mr-1">{{ dataSourceIcon("Drive") }}</v-icon>
					<v-tooltip
						bottom
						:allow-overflow="true"
					>
						<template v-slot:activator="{ on, attrs }">
							<span
								v-bind="attrs"
								v-on="on"
							>
								{{ driveConfig.Name }}
							</span>
						</template>
						<div>Unique Source ID: {{ driveConfig.DriveID }}</div>
					</v-tooltip>
				</v-col>
				<v-hover v-slot="{ hover }">
					<v-col
						cols="auto"
						class="mx-2"
					>
						<v-fade-transition>
							<v-tooltip
								top
								open-delay="500"
							>
								<template v-slot:activator="{ on, attrs }">
									<!-- FIXME: get rid of absolute positioning and match center with icon-->
									<v-progress-circular
										:indeterminate="driveDisconnectionInProgress"
										color="danger"
										style="position: relative; top: 0px; right: -28px"
										:width="driveDisconnectionInProgress ? 2 : 0"
									></v-progress-circular>
									<v-icon
										outlined
										:size="hover ? '24' : '16'"
										:color="hover || driveDisconnectionInProgress ? 'danger' : 'green'"
										class="transition-slow-in-slow-out"
										:class="hover ? 'mx-0' : 'mx-1'"
										v-bind="attrs"
										v-on="on"
										data-test="data-drive-disconnect"
										@click="requestDriveDisconnect"
										>mdi-connection</v-icon
									>
								</template>
								<span>Disconnect</span>
							</v-tooltip>
						</v-fade-transition>
					</v-col>
				</v-hover>
			</v-row>
			<!-- Subtitle  -->
			<v-row
				class="text-body-2 mt-1 ml-1"
				no-gutters
			>
				<v-col class="ml-0 ml-sm-2">
					<div>
						<v-icon
							class="mr-1"
							small
							>mdi-tray-arrow-down</v-icon
						>
						<template v-if="connectedSourceCount > 0"
							>Source Mount Points:
							<template v-if="gatewayCount != 0">
								<v-icon
									class="ml-1"
									small
									>{{ dataSourceIcon("Gateway") }}</v-icon
								>
								{{ gatewayCount }} {{ gatewayCount == 1 ? "Gateway" : "Gateways" }}
							</template>
							<template v-if="bucketCount != 0">
								<v-icon
									class="ml-1"
									small
									>{{ dataSourceIcon("Bucket") }}</v-icon
								>
								{{ bucketCount }} {{ bucketCount == 1 ? "S3 Bucket" : "S3 Buckets" }}
							</template>
							<template v-if="signaloidCloudStorageConnected">
								<v-icon
									class="ml-1"
									small
									>{{ dataSourceIcon("SignaloidCloudStorage") }}</v-icon
								>
								Signaloid Cloud Storage
							</template>
						</template>
						<template v-else>No Source Mount Points Set!</template>
					</div>
					<div
						v-if="!$vuetify.breakpoint.mobile"
						class="mr-3"
					>
						<v-icon
							class="mr-1"
							small
							>mdi-checkbox-marked-circle-plus-outline</v-icon
						>Created:
						{{ formatDate(driveConfig.CreatedAt) }}
					</div>
					<div class="mr-3">
						<v-icon
							class="mr-1"
							small
							>mdi-update</v-icon
						>Last Updated:
						{{ formatDate(driveConfig.UpdatedAt) }}
					</div>
				</v-col>
				<v-col
					:cols="$vuetify.breakpoint.mobile ? '12' : 'auto'"
					class="pt-1 pt-sm-0"
					align-self="end"
				>
					<v-row
						no-gutters
						justify="end"
					>
						<!-- <v-col
							class="mx-1"
							cols="auto"
						>
							<v-tooltip
								bottom
								open-delay=250
							>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										v-bind="attrs"
										v-on="on"
										color="accent"
										elevation="0"
										width="50px"
										disabled
									>
										<v-icon>mdi-folder-eye-outline</v-icon>
									</v-btn>
								</template>
								<span>View Drive</span>
							</v-tooltip>
						</v-col> -->

						<v-col cols="auto">
							<TooltipButton
								@click="showDriveConfigDialog = true"
								color="primary"
								width="50px"
								btnIcon="mdi-cog-outline"
								tooltipText="Edit Drive Configuration"
							></TooltipButton>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</template>
		<v-skeleton-loader
			v-else
			type="list-item-avatar"
		></v-skeleton-loader>

		<DriveConfigDialog
			v-if="showDriveConfigDialog"
			:showDialog.sync="showDriveConfigDialog"
			:driveConfigToUpdate="driveConfig"
			@drive-config-updated="getDriveInfo"
		></DriveConfigDialog>
	</v-card>
</template>

<script>
import { defineComponent } from "vue";
import DriveConfigDialog from "@/components/DataSources/DriveConfigDialog.vue";
import { cloneDeep } from "lodash";
import { getDriveByID, deleteDriveByID } from "@/js/signaloidClient";
import * as dsUtil from "@/components/DataSources/utilities";
import TooltipButton from "@/components/Common/TooltipButton.vue";
import { monitoringCaptureError } from "@/plugins/monitoring";

export default defineComponent({
	name: "DriveCard",
	components: { DriveConfigDialog, TooltipButton },
	props: {
		driveID: {
			type: String,
			required: true,
		},
		initialDriveConfig: {
			type: Object,
			default: undefined,
		},
	},
	emits: {
		"drive-disconnected": () => true,
	},
	data: () => ({
		showDriveConfigDialog: false,
		driveDisconnectionInProgress: false,
		driveConfig: undefined,
	}),
	methods: {
		formatDate(dtString) {
			return dsUtil.formatDateDayString(1000 * dtString);
		},
		async getDriveInfo() {
			// TODO:
			try {
				const resp = await getDriveByID(this.driveID);
				if (resp.data) {
					this.driveConfig = resp.data;
				} else {
					throw new Error("Bad response");
				}
			} catch (error) {
				monitoringCaptureError(error, "Fetch data drive config");
			}
		},
		async requestDriveDisconnect() {
			this.driveDisconnectionInProgress = true;
			try {
				const resp = await deleteDriveByID(this.driveID);
				this.$emit("drive-disconnected");
			} catch (error) {
				monitoringCaptureError(error, "Delete data drive");
			} finally {
				this.driveDisconnectionInProgress = false;
			}
		},
		dataSourceIcon(dataSourceType) {
			return dsUtil.dataSourceIcon(dataSourceType);
		},
	},

	computed: {
		gatewayCount() {
			if (!Array.isArray(this.driveConfig.DataSources)) {
				// log some error
				return 0;
			}
			return this.driveConfig.DataSources.filter((source) => {
				return source.ResourceType === "Gateway";
			}).length;
		},
		bucketCount() {
			if (!Array.isArray(this.driveConfig.DataSources)) {
				// log some error
				return 0;
			}
			return this.driveConfig.DataSources.filter((source) => {
				return source.ResourceType === "Bucket";
			}).length;
		},
		signaloidCloudStorageConnected() {
			return this.driveConfig.DataSources.find((source) => {
				return source.ResourceType === "SignaloidCloudStorage";
			});
		},
		connectedSourceCount() {
			return this.driveConfig.DataSources.length;
		},
	},
	mounted() {
		if (!this.initialDriveConfig) {
			this.getDriveInfo();
		} else {
			this.driveConfig = cloneDeep(this.initialDriveConfig);
		}
	},
});
</script>

<style></style>
