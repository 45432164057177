
import { PropType, defineComponent } from "vue";
import SpotlightSlide from "@/components/Home/SpotlightSlide.vue";
import { SpotlightSlideInfo } from "@/types/home";

type ComponentData = {
	activeSlide: number;
};

export default defineComponent({
	name: "SpotlightCarousel",
	components: { SpotlightSlide },
	props: {
		spotlightSlides: { type: Array as PropType<SpotlightSlideInfo[]>, required: false },
	},
	data: (): ComponentData => ({
		activeSlide: 0,
	}),
	computed: {
		validatedSpotlightSlides(): SpotlightSlideInfo[] {
			if (!this.spotlightSlides) {
				return [];
			}

			return this.spotlightSlides.filter((v) => {
				return this.validSlide(v);
			});
		},
	},
	methods: {
		nextSlide() {
			this.activeSlide = this.activeSlide + 1 === this.validatedSpotlightSlides.length ? 0 : this.activeSlide + 1;
		},
		previousSlide() {
			this.activeSlide =
				this.activeSlide - 1 < 0 ? this.validatedSpotlightSlides.length - 1 : this.activeSlide - 1;
		},
		validSlide(slideInfo) {
			// Ensure that there is a fallback image url if a video link is provided
			if (slideInfo.videoURL) {
				// FIXME: potentially throws .videoURL.includes is not a function
				// if the video isnt from vimeo or if the fallback image is not provided...
				if (!slideInfo.videoURL.includes("vimeo.com") && !slideInfo.imageURL) {
					return false;
				}
			}

			switch (slideInfo.type) {
				case "application":
					return (
						slideInfo.titleText && (slideInfo.imageURL || slideInfo.videoURL) && slideInfo.applicationURL
					);
				default:
					return slideInfo.titleText && (slideInfo.imageURL || slideInfo.videoURL);
			}
		},
	},
});
