var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.useIntersection
        ? _c("IntersectionObserver", {
            on: { "intersect-enter": _vm.intersectionHandler },
          })
        : _vm._e(),
      _vm.imageLoading
        ? [
            _c(
              "v-row",
              {
                staticClass: "my-4",
                attrs: { align: _vm.justify, justify: _vm.justify },
              },
              [
                _c(
                  "v-col",
                  { attrs: { cols: _vm.loaderCols } },
                  [
                    _c("v-progress-linear", {
                      attrs: { indeterminate: "", height: "3" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : [
            _vm.imageUrl
              ? _c("v-img", {
                  attrs: {
                    src: _vm.imageUrl,
                    "lazy-src": _vm.imageUrl,
                    "max-height": _vm.imageMaxHeight,
                    "max-width": _vm.imageMaxWidth,
                    contain: "",
                    eager: true,
                  },
                  on: {
                    error: function ($event) {
                      return _vm.$emit("image-load-error")
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "placeholder",
                        fn: function () {
                          return [
                            _c(
                              "v-row",
                              {
                                staticClass: "fill-height ma-0",
                                attrs: { align: "center", justify: "center" },
                              },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: _vm.loaderCols } },
                                  [
                                    _c("v-progress-linear", {
                                      attrs: { indeterminate: "", height: "3" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    141866198
                  ),
                })
              : _c(
                  "v-alert",
                  {
                    staticClass: "mb-0 text-body-2",
                    attrs: { width: _vm.alertWidth },
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.plotErrorMessage) + " "),
                    _vm.apiErrorMessage
                      ? _c("div", [
                          _vm._v(
                            "API error message: " + _vm._s(_vm.apiErrorMessage)
                          ),
                        ])
                      : _vm._e(),
                    _vm.tierLimitError
                      ? _c(
                          "div",
                          { staticClass: "mt-2" },
                          [
                            _c("UpgradeButton", {
                              attrs: { "btn-text": "Upgrade Access" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }