
import Vue, { PropType, defineComponent } from "vue";
import * as monaco from "monaco-editor";

import screenfull from "screenfull";
import { StyleValue } from "vue/types/jsx";
import { EditorTheme, themes as themeList } from "@/js/editor";

export const kDefaultTheme: EditorTheme = "solarized-light";
export const kDefaultDarkTheme: EditorTheme = "solarized-dark";

type UxHwDefinition = {
	returnType: string;
	paramList: string[];
	prototype: string;
	functionName: string;
	documentation: string;
	documentationUrl?: string;
};

const uxhwDefFile = require("@/assets/uxhw_definitions.json") as UxHwDefinition;

type UxHwTypeSense = {
	label: string;
	functionPrototype: string;
	functionName: string;
	kind: number; // enum CompletionItemKind
	insertText: string;
	insertTextRules: number; // enum CompletionItemInsertTextRule
	documentation: string;
	range;
};

var uxhwCompletion: UxHwTypeSense[] = [];

// function uxhwCompletionProposals(range) {
// 	return;
// }

function loadUxHwDefs(uxhwDefFile) {
	return uxhwDefFile.map((x) => {
		let parsedParamString = "";
		if (x.paramList.length > 0) {
			x.paramList.forEach((value, index, array) => {
				parsedParamString += "${" + (parseInt(index) + 1) + ":" + value + "}";
				if (index < array.length - 1) {
					parsedParamString += ",";
				}
			});
		}
		return {
			label: x.functionName,
			functionPrototype: x.prototype,
			functionName: x.functionName,
			kind: monaco.languages.CompletionItemKind.Function,
			insertText: `${x.functionName}(${parsedParamString})`,
			insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
			documentation: `${x.documentation}` + (x.documentationUrl ? `\n\n Read more: ${x.documentationUrl}` : ""),
		};
	});
}

type ComponentData = {
	editor: monaco.editor.IStandaloneCodeEditor | undefined;
	elementObserverBinding: ResizeObserver | undefined;
	loadedThemes: string[];
	uxhwCompletionItemProvider: monaco.IDisposable | undefined;
	uxhwHoverProvider: monaco.IDisposable | undefined;
};

export default defineComponent({
	name: "MonacoEditor",
	data: (): ComponentData => ({
		editor: undefined,
		elementObserverBinding: undefined,
		loadedThemes: [],
		uxhwCompletionItemProvider: undefined,
		uxhwHoverProvider: undefined,
	}),
	props: {
		diff: { type: Boolean, default: false },
		original: String, // For diff-mode
		width: { type: String, default: "100%" },
		height: { type: String, default: "100%" },
		value: { type: String, value: "" },
		language: { type: String, default: "c" },
		showUxHwSuggestions: { type: Boolean, default: true },
		theme: { type: Object as PropType<EditorTheme>, default: kDefaultTheme },
		options: { type: Object, default: () => ({}) },
	},
	watch: {
		value(newValue) {
			if (this.editor) {
				const previousValue = this.editor.getValue();
				if (newValue !== previousValue) {
					this.editor.setValue(newValue);
				}
			}
		},
		theme(newTheme: EditorTheme) {
			this.setTheme(newTheme);
		},
	},
	model: {
		// prop: value
	},
	computed: {
		style(): StyleValue {
			return {
				width: !/^\d+$/.test(this.width) ? this.width : `${this.width}px`,
				height: !/^\d+$/.test(this.height) ? this.height : `${this.height}px`,
			};
		},
	},
	async beforeMount() {
		this.loadTheme(kDefaultTheme);
		this.loadTheme(kDefaultDarkTheme);

		if (this.theme) {
			this.setTheme(this.theme);
		}
	},
	mounted() {
		const options: monaco.editor.IStandaloneEditorConstructionOptions = {
			//start with default values
			value: this.value,
			language: this.language,
			fontFamily: '"Roboto Mono", "Courier New", Courier, monospace',
			fontSize: 13.6,
			theme: this.theme,
			mouseWheelZoom: true,
			tabCompletion: "on",
			minimap: {
				enabled: !this.$vuetify.breakpoint.mobile,
			},
			selectOnLineNumbers: true,
			// automaticLayout: true, //HACK: to force resize on layout change, might affect performance.
			padding: { top: 5, bottom: 200 },
			scrollBeyondLastLine: false,

			scrollbar: { alwaysConsumeMouseWheel: false },
			// append/override with prop values
			...this.options,
		};

		this.editor = monaco.editor.create(this.$el as HTMLElement, options);

		// @ts-ignore
		window.codeEditor = this.editor;

		const monacoEditorComponent = this;
		const editor = this.editor;

		const monacoBuildAndRunAction: monaco.editor.IActionDescriptor = {
			id: "signaloid-build-run-code",
			label: "Signaloid: Compile and Run",
			keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter],
			contextMenuGroupId: "navigation",
			contextMenuOrder: 1.5,
			run: function () {
				monacoEditorComponent.$emit("request-build");
			},
		};
		this.editor.addAction(monacoBuildAndRunAction);

		const monacoAddLineBelowAction = {
			id: "InsertLineAfter",
			label: "Insert Line Below",
			keybindings: [monaco.KeyMod.Shift | monaco.KeyCode.Enter],
			run: function () {
				editor.getAction("editor.action.insertLineAfter").run();
			},
		};
		this.editor.addAction(monacoAddLineBelowAction);

		uxhwCompletion = loadUxHwDefs(uxhwDefFile);

		if (this.showUxHwSuggestions === true) {
			// FIXME: language "*" = Hack to merge both custom suggestions and uxhw suggestions.
			// But this makes functions show up twice if they are already being used in the file.
			this.uxhwCompletionItemProvider = monaco.languages.registerCompletionItemProvider("*", {
				provideCompletionItems: (model, position, context, token) => {
					var word = model.getWordUntilPosition(position);
					var range = {
						startLineNumber: position.lineNumber,
						endLineNumber: position.lineNumber,
						startColumn: word.startColumn,
						endColumn: word.endColumn,
					};
					return {
						suggestions: uxhwCompletion.map((x) => {
							x.range = range;
							return x;
						}),
					};
				},
			});

			this.uxhwHoverProvider = monaco.languages.registerHoverProvider("c", {
				provideHover: (model, position): monaco.languages.ProviderResult<monaco.languages.Hover> => {
					const wordAtHoverPosition = model.getWordAtPosition(position)?.word;
					if (!wordAtHoverPosition) {
						return null;
					}
					const hoverValueDict = uxhwCompletion.filter((x) => {
						return x.label.includes(wordAtHoverPosition);
					});
					if (hoverValueDict.length == 1) {
						return {
							contents: [
								{ value: `**${hoverValueDict[0].functionPrototype}**` },
								{ value: hoverValueDict[0].documentation },
							],
						};
					}
					return null;
				},
			});
		}

		/*
		 *	Add resize listener. Monaco editor doesn't auto-resize. Must keep
		 *	the binding reference to be able to remove the handler in
		 *	beforeDestroy.
		 */
		// this.resizeHandlerBinding = this.resizeHandler.bind(this);
		// window.addEventListener("resize", this.resizeHandlerBinding);

		if (this.elementObserverBinding == undefined) {
			this.elementObserverBinding = new ResizeObserver(this.resizeHandler);
		}

		this.elementObserverBinding.observe(this.$el);

		/*
		 *	Add editor content change listener to propagate change through
		 *	v-model.
		 */
		this.editor.onDidChangeModelContent((e) => {
			const value = this.editor?.getValue();
			if (this.value !== value) {
				this.$emit("input", value, e);
				this.$emit("change", value, e);
			}
		});

		if (this.theme) {
			this.setTheme(this.theme);
		} else {
			this.setTheme(kDefaultTheme);
		}
	},
	updated() {
		// FIX: type error. Does this do anything at all?
		// @ts-ignore
		this.editor?.trigger();
	},
	beforeDestroy() {
		if (this.showUxHwSuggestions === true) {
			this.uxhwCompletionItemProvider?.dispose();
			this.uxhwHoverProvider?.dispose();
		}
		this.editor?.dispose();

		// if (this.resizeHandlerBinding) {
		// 	window.removeEventListener("resize", this.resizeHandlerBinding);
		// 	this.resizeHandlerBinding = undefined;
		// }
		if (this.elementObserverBinding) {
			this.elementObserverBinding.disconnect();
		}
	},
	methods: {
		resizeHandler() {
			this.editor?.layout();
		},
		loadTheme(key: EditorTheme) {
			if (!(key in themeList)) {
				throw new Error(`theme ${key} is not available`);
			}
			if (this.loadedThemes.includes(key)) {
				/*
				 *	No-op if already loaded
				 */
				return;
			}
			const filename = themeList[key];
			const theme = require(`@/assets/editor-themes/${filename}.json`);
			monaco.editor.defineTheme(key, theme);
			this.loadedThemes.push(key);
		},
		/**
		 * Switches the editor theme to a new theme. It loads the new theme if
		 * it's not already loaded.
		 * @param newTheme The theme to change the editor to
		 */
		setTheme(newTheme: EditorTheme) {
			this.loadTheme(newTheme);
			monaco.editor.setTheme(newTheme);
		},
		goFullscreen() {
			if (screenfull.isEnabled) {
				screenfull
					.request(this.$el)
					.then(() => {
						this.resizeHandler();
					})
					.catch((e) => {
						console.log(e);
					});
			}
		},
	},
});
