var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "px-1 px-sm-2", attrs: { flat: "" } },
    [
      _c(
        "v-card-title",
        {
          staticClass: "pb-3 pt-1 pt-sm-4 px-1 px-sm-4",
          staticStyle: { "min-height": "64px" },
        },
        [
          _c("div", { staticClass: "text-h6 font-weight-bold" }, [
            _vm._v("Task Manager"),
          ]),
        ]
      ),
      _c("v-divider", { staticClass: "mb-2 mb-sm-3 mx-1 mx-sm-4" }),
      _vm.tasksStore.taskHistory === undefined
        ? _c("LoadingCard", {
            attrs: {
              "data-test": "task-history-loader",
              loadingText: "Fetching Tasks",
            },
          })
        : _c(
            "v-container",
            {
              staticClass: "px-sm-4 px-1 py-sm-2 py-1 mx-auto",
              attrs: { fluid: true },
            },
            [
              _c("TaskListDisplay", {
                attrs: {
                  "data-test": "active-tasks-list",
                  sectionTitle: "Active Tasks",
                  sectionDescription:
                    "Tasks with status 'Accepted', 'Compiling', 'Building', or 'In Progress' will show up here.",
                  tasksLoadingMessage: "Loading Active Tasks",
                  noTasksMessage: "No tasks currently active",
                  taskList: _vm.tasksStore.activeTasks,
                  taskUpdateStatus: _vm.tasksStore.activeTaskUpdateStatus,
                  canLoadMore: false,
                },
              }),
              _c("v-divider", { staticClass: "my-5 mx-10" }),
              _c("TaskListDisplay", {
                attrs: {
                  "data-test": "inactive-tasks-list",
                  sectionTitle: "Completed Tasks",
                  sectionDescription:
                    "Tasks with status 'Completed', 'Cancelled', or 'Stopped' will show up here.",
                  tasksLoadingMessage: "Loading Completed Tasks",
                  noTasksMessage: "No completed tasks found",
                  taskList: _vm.tasksStore.inactiveTasks,
                  taskUpdateStatus: _vm.tasksStore.taskHistoryUpdateStatus,
                  continuationKey: _vm.tasksStore.taskListContinuationKey,
                  canLoadMore: true,
                },
                on: { "load-more-tasks": _vm.loadMoreTasks },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }