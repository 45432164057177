import { initiateGithubLoginFlow } from "@/js/githubClient";
import { defineStore } from "pinia";

type Integration = {
	type: string;
	name: string;
	logo: {
		name: string;
		type: "mdi";
	};
	disconnectFunction: undefined | Function;
	connectFunction: undefined | Function;
	connectionLimit: number;
};
type StoreState = {
	availableIntegrations: Integration[];
	connectedIntegrations: Integration[];
};

export const useIntegrationsStore = defineStore("integrations", {
	state: (): StoreState => ({
		availableIntegrations: [
			{
				type: "github",
				name: "Github",
				logo: {
					name: "mdi-github",
					type: "mdi",
				},
				disconnectFunction: undefined,
				connectFunction: initiateGithubLoginFlow,
				connectionLimit: 1,
			},
			// {
			// 	name: "Gitlab",
			// 	logo: {
			// 		name: "mdi-gitlab",
			// 		type: "mdi",
			// 	},
			// 	onClick: "",
			// 	connectionLimit: 1,
			// },
			// {
			// 	name: "Bitbucket",
			// 	logo: {
			// 		name: "mdi-bitbucket",
			// 		type: "mdi",
			// 	},
			// 	onClick: "",
			// },
			// {
			// 	name: "AWS S3",
			// 	logo: {
			// 		name: "mdi-aws",
			// 		type: "mdi",
			// 	},
			// 	onClick: "",
			// },
		],
		connectedIntegrations: [],
	}),
	getters: {
		integrationDetails: (state) => (type) => {
			return state.availableIntegrations.find((v) => {
				return v.type == type;
			});
		},
		connectionsAllowed: (state) => (integration) => {
			return (
				state.connectedIntegrations.filter((v) => {
					return v.type == integration.type;
				}).length < integration.connectionLimit
			);
		},
	},
	actions: {
		createConnection(connection) {
			this.connectedIntegrations.push(connection);
		},
		removeConnection(id) {
			console.log("remove connection :>> ", id);
		},
		updateConnection(id) {
			console.log("update connection:>> ", id);
		},
	},
});
