import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import { Auth, Amplify } from "aws-amplify";
import { AwsConfig } from "@/js/awsconfig";
import { segmentInit } from "./plugins/segment";
import * as util from "@/js/utilities";

Amplify.configure(AwsConfig);
Vue.config.productionTip = false;

import "@/assets/global.css";

/*
 * Segment Setup
 */

// segmentInit();

/*
 * Posthog Setup
 */

import posthogPlugin from "@/plugins/posthog"; //import the plugin.
Vue.use(posthogPlugin); //install the plugin

/*
 * Hotjar Setup
 */

import Hotjar from "@hotjar/browser";
if (process.env?.NODE_ENV === "production") {
	const siteId = 3501471;
	const hotjarVersion = 6;
	Hotjar.init(siteId, hotjarVersion);
}

/*
 * Sentry Setup
 */

import * as Sentry from "@sentry/vue";

Sentry.init({
	Vue,
	dsn: process.env.VUE_APP_SENTRY_DSN,
	environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
	release: `${process.env.VUE_APP_VERSION}+${process.env.VUE_APP_COMMIT_HASH ? process.env.VUE_APP_COMMIT_HASH.slice(0, 7) : "_"
		}`,
	integrations: [
		new Sentry.BrowserTracing({
			// @ts-ignore
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
		}),
		new Sentry.Replay(),
	],
	tracePropagationTargets: ["localhost", /^\//, process.env.VUE_APP_BACKEND_API_URL as string],
	tracesSampleRate: 1.0,
	logErrors: true,
	tunnel: process.env.VUE_APP_PROXY_GATEWAY_URL ? process.env.VUE_APP_PROXY_GATEWAY_URL + "/sentry" : undefined,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

/*
 * Userflow Setup
 */

import userflow from "userflow.js";

if (process.env.VUE_APP_USERFLOW_TOKEN) {
	userflow.init(process.env.VUE_APP_USERFLOW_TOKEN);
}

/*
 * Pinia Setup
 */

// vue gets stuck in a loop if this goes in stores/root.js
import { createPinia, PiniaVuePlugin } from "pinia";
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

/*
 * Signaloid Client Setup
 */

import { registerTokens, registerRefreshTokenCallbacks } from "./js/signaloidClient";
import { useRootStore } from "./stores/root";

//*	Register token refreshing interceptors for axios instances
registerTokens(
	Auth.currentSession().then((sess) => sess.getAccessToken().getJwtToken()),
	Auth.currentSession().then((sess) => sess.getIdToken().getJwtToken())
);
registerRefreshTokenCallbacks(
	async () => {
		const currentSession = await Auth.currentSession();
		return currentSession.getAccessToken();
	},
	async () => {
		const currentSession = await Auth.currentSession();
		return currentSession.getIdToken();
	}
);

/*
 * Vue Router Setup
 */

//These are here because Auth is not initiated in the router file.
//*  Check authentication for user
router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		Auth.currentAuthenticatedUser()
			.then((user) => {
				return next();
			})
			.catch((err) => {
				console.log("Nav guard - No Authenticated user. Redirecting to login");
				util.clearLocalState();
				location.href = "/login";
			});
	} else {
		return next();
	}
});

//* Check subscription status for user
router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		Auth.currentAuthenticatedUser()
			.then((user) => {
				// if the user is trying to go to billing, let them
				if (to.path.startsWith("/billing") || to.path.startsWith("/welcome")) {
					return next();
				}

				//*  Check user groups.
				// console.log('Checking user groups.');
				const accessToken = user.getSignInUserSession().getAccessToken();
				if (Object.prototype.hasOwnProperty.call(accessToken?.payload, "cognito:groups")) {
					const groups = accessToken.payload["cognito:groups"];
					if (
						groups.includes("FreeTier") ||
						groups.includes("AcademicTier") ||
						groups.includes("DeveloperTier") ||
						groups.includes("ProTier") ||
						groups.includes("EnterpriseTier") ||
						groups.includes("VirtualServerTier") ||
						groups.includes("Administrator")
					) {
						// console.log('User is subscribed.');
						return next();
					}
				}
				console.log("User is authenticated but not subscribed. Initiating onboarding flow");
				return next("/welcome");
			})
			.catch((err) => {
				console.log("Nav guard - User subscription check failed. Redirecting to login");
				util.clearLocalState();
				location.href = "/login";
			});
	} else {
		return next();
	}
});

/*
 * Initialise Vue
 */
new Vue({
	router,
	vuetify,
	pinia,
	render: (h) => h(App),
}).$mount("#app");
