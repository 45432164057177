var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      class: ["mx-auto", _vm.isRestrictedCore && "card-disabled"],
      attrs: {
        width: _vm.$vuetify.breakpoint.mobile ? "100%" : undefined,
        rounded: "lg",
        elevation: "0",
        outlined: "",
        "data-test": "core-card",
      },
    },
    [
      _c("v-card-text", { staticClass: "pa-2 pa-sm-3 fill-height" }, [
        _c(
          "div",
          { staticClass: "fill-height fill-width" },
          [
            _c(
              "v-row",
              {
                staticClass: "mt-0 d-flex justify-space-between",
                attrs: { "no-gutters": "" },
              },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "4" } },
                  [
                    _c("v-img", {
                      staticClass: "pa-0 ma-0 rounded-0",
                      style: _vm.imageStyle,
                      attrs: {
                        contain: "",
                        src:
                          _vm.coreConfig.Owner === `*`
                            ? require("@/assets/Cores/signaloid-08.2023-graphics-CxChip-green_0.25xZoom.png")
                            : require("@/assets/Cores/signaloid-08.2023-graphics-CxChip-multicolor_0.25xZoom.png"),
                        "max-width": _vm.$vuetify.breakpoint.mobile
                          ? "180px"
                          : "240px",
                      },
                    }),
                  ],
                  1
                ),
                _c("v-col", { staticStyle: { "min-width": "140px" } }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-space-between align-center mb-2 ml-2",
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "", "allow-overflow": true },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "span",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "text-subtitle-1 font-weight-bold line-clamp-1",
                                              attrs: {
                                                "data-test": "core-name-label",
                                              },
                                            },
                                            "span",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.coreConfig.Name) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c(
                                "div",
                                [
                                  _vm._v(
                                    " Core Class: " +
                                      _vm._s(_vm.coreConfig.Class)
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " Memory Allocation: " +
                                      _vm._s(_vm.coreMemorySizeString)
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " Signaloid Representation Size: " +
                                      _vm._s(_vm.coreConfig.Precision)
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " Microachitecture: " +
                                      _vm._s(_vm.coreConfig.Microarchitecture)
                                  ),
                                  _c("br"),
                                  _vm.correlationTrackingMethodPrettyName
                                    ? [
                                        _vm._v(
                                          " Correlation Tracking: " +
                                            _vm._s(
                                              _vm.correlationTrackingMethodPrettyName
                                            ) +
                                            " "
                                        ),
                                        _c("br"),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                          _vm.isEditableLocalCore
                            ? [
                                _vm.syncError
                                  ? _c("TooltipButton", {
                                      attrs: {
                                        icon: true,
                                        tooltipText:
                                          "An error occurred while synchronising. Click to try again.",
                                      },
                                      on: { click: _vm.syncCoreWithCloud },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "btnContent",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      color: "danger",
                                                    },
                                                  },
                                                  [_vm._v("mdi-cloud-alert")]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        3255876567
                                      ),
                                    })
                                  : _vm.syncingCore
                                  ? _c("v-progress-circular", {
                                      staticClass: "ml-2",
                                      attrs: {
                                        indeterminate: true,
                                        color: "warning",
                                        size: "16",
                                        width: 2,
                                      },
                                    })
                                  : _c("TooltipButton", {
                                      attrs: {
                                        icon: true,
                                        tooltipText:
                                          "Core not synced with Signaloid Cloud. Click to synchronise.",
                                      },
                                      on: { click: _vm.syncCoreWithCloud },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "btnContent",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      color: "warning",
                                                    },
                                                  },
                                                  [_vm._v("mdi-cloud-refresh")]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        2008791381
                                      ),
                                    }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm.isRestrictedCore && _vm.isSupportedCore
                        ? _c("UpgradeButton", {
                            attrs: { "btn-text": "Upgrade access" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-wrap justify-end mb-2" },
                    [
                      _c(
                        "v-chip",
                        {
                          staticClass: "ma-1 py-0 font-weight-medium",
                          attrs: {
                            color: "primary",
                            small: "",
                            outlined: "",
                            disabled: _vm.isRestrictedCore,
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(" mdi-memory "),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.coreMicroarchitectureStringFormat) +
                              " "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-chip",
                        {
                          staticClass: "ma-1 py-0 font-weight-medium",
                          attrs: {
                            color: "grey darken-4",
                            small: "",
                            outlined: "",
                            disabled: _vm.isRestrictedCore,
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(" mdi-cog-play-outline "),
                          ]),
                          _vm._v(" " + _vm._s(_vm.coreConfig.Class) + " "),
                        ],
                        1
                      ),
                      _c(
                        "v-chip",
                        {
                          staticClass: "ma-1 py-0 font-weight-medium",
                          attrs: {
                            color: "grey darken-4",
                            small: "",
                            outlined: "",
                            disabled: _vm.isRestrictedCore,
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(" mdi-chip "),
                          ]),
                          _vm._v(" " + _vm._s(_vm.coreMemorySizeString) + " "),
                        ],
                        1
                      ),
                      _c(
                        "v-chip",
                        {
                          staticClass: "ma-1 py-0 font-weight-medium",
                          attrs: {
                            color: _vm.correlationTrackingChipStyle.color,
                            small: "",
                            outlined: "",
                            disabled: _vm.isRestrictedCore,
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.correlationTrackingChipStyle.icon) +
                                " "
                            ),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.correlationTrackingChipStyle.text) +
                              " "
                          ),
                        ],
                        1
                      ),
                      !_vm.isSupportedCore
                        ? _c(
                            "v-chip",
                            {
                              staticClass: "ma-1 py-0 font-weight-medium",
                              attrs: {
                                color: "error",
                                small: "",
                                outlined: "",
                                disabled: _vm.isRestrictedCore,
                              },
                            },
                            [
                              _c("v-icon", { attrs: { small: "", left: "" } }, [
                                _vm._v(" mdi-minus-circle "),
                              ]),
                              _vm._v(" Deprecated "),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex justify-space-between align-center" },
              [
                _c(
                  "div",
                  {
                    staticClass: "d-flex justify-end",
                    staticStyle: { "align-items": "center" },
                  },
                  [
                    _c("div", { staticClass: "font-weight-bold" }, [
                      _vm._v("Core ID:"),
                    ]),
                    _c(
                      "code",
                      {
                        staticClass:
                          "text-subtitle-2 font-weight-regular truncated",
                        staticStyle: {
                          display: "inline",
                          "margin-left": "8px",
                        },
                        attrs: { "data-test": "task-card-task-id-value" },
                      },
                      [_vm._v(_vm._s(_vm.coreConfig.CoreID))]
                    ),
                    _c("CopyToClipboardButton", {
                      staticClass: "ml-0",
                      attrs: {
                        "value-to-copy": _vm.coreConfig.CoreID,
                        "value-name": "Core ID",
                      },
                    }),
                  ],
                  1
                ),
                _vm.coreConfig.Owner !== "*"
                  ? _c(
                      "div",
                      [
                        _vm.isSupportedCore
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "ma-0 py-0 font-weight-medium",
                                attrs: {
                                  color: "accent",
                                  small: "",
                                  elevation: "0",
                                  "text-color": "white",
                                  "data-test": "edit-core-button",
                                },
                                on: { click: _vm.editCoreRequest },
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { small: "", left: "" } },
                                  [_vm._v(" mdi-pencil ")]
                                ),
                                _vm._v(" Edit "),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-btn",
                          {
                            staticClass:
                              "ma-0 ml-1 mr-2 py-0 font-weight-medium",
                            attrs: {
                              color: "danger",
                              small: "",
                              "min-width": "30px",
                              width: "30px",
                              elevation: "0",
                              "data-test": "delete-core-button",
                              outlined: "",
                            },
                            on: { click: _vm.deleteCoreRequest },
                          },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v(" mdi-delete "),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "v-btn",
                      {
                        staticClass: "ma-0 py-0 font-weight-medium",
                        attrs: {
                          color: "primary",
                          small: "",
                          elevation: "0",
                          "text-color": "white",
                          "data-test": "details-core-button",
                        },
                        on: { click: _vm.editCoreRequest },
                      },
                      [
                        _c("v-icon", { attrs: { small: "", left: "" } }, [
                          _vm._v(" mdi-text "),
                        ]),
                        _vm._v(" Details "),
                      ],
                      1
                    ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }