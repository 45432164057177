var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      staticClass: "d-flex flex-column justify-space-between",
      attrs: { rounded: "lg", height: "100%", outlined: "" },
    },
    [
      _c(
        "v-sheet",
        {
          staticStyle: {
            "border-top": "none",
            "border-left": "none",
            "border-right": "none",
          },
          attrs: { rounded: "t-lg", outlined: "" },
        },
        [
          _c(
            "v-tabs",
            {
              staticClass: "rounded-lg",
              attrs: { height: "2.5em", "show-arrows": "" },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            _vm._l(_vm.resultText, function (stream, i) {
              return _c(
                "v-tab",
                {
                  key: stream.code,
                  attrs: {
                    disabled: stream.disabled,
                    "data-test": `${stream.code}-output-tab-header`,
                  },
                },
                [
                  stream.code_tag
                    ? _c(
                        "v-badge",
                        {
                          attrs: {
                            value: _vm.tabBadgeState[i],
                            color: "red",
                            "offset-y": "5px",
                            dot: "",
                          },
                        },
                        [
                          _c("v-icon", {
                            domProps: { textContent: _vm._s(stream.icon) },
                          }),
                          _vm._v(" "),
                          _c("code", { staticClass: "inline-code" }, [
                            _vm._v(_vm._s(stream.name)),
                          ]),
                        ],
                        1
                      )
                    : _c(
                        "v-badge",
                        {
                          attrs: {
                            value: _vm.tabBadgeState[i],
                            color: "red",
                            "offset-y": "5px",
                            dot: "",
                          },
                        },
                        [
                          _c("v-icon", {
                            domProps: { textContent: _vm._s(stream.icon) },
                          }),
                          _vm._v(" " + _vm._s(stream.name) + " "),
                        ],
                        1
                      ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "v-sheet",
        {
          staticClass: "flex-grow-1",
          staticStyle: { "overflow-y": "scroll" },
          attrs: { "min-height": "0" },
        },
        [
          _vm.tab == 5
            ? [
                _c(
                  "v-row",
                  {
                    staticClass: "mb-5",
                    attrs: { align: "center", justify: "start" },
                  },
                  [
                    _c(
                      "v-col",
                      [
                        _c("SigCloudStorageFileList", {
                          attrs: {
                            showTitle: false,
                            flatCard: true,
                            minWidth: "100%",
                            inEditor: true,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : [
                _vm.isLoading || _vm.chunkIsLoading
                  ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
                  : _vm._e(),
                _c(
                  "div",
                  {
                    ref: "resultsContainer",
                    staticClass: "mt-0 mb-0 px-3 v-text-field results-div",
                    attrs: {
                      id: `results-sheet-id-${_vm.tab}`,
                      "data-test": `${_vm.resultText[
                        _vm.tab
                      ].code.toLowerCase()}-output-tab-text`,
                    },
                  },
                  [
                    _vm._l(_vm.transformedContents, function (elem, index) {
                      return _c(
                        "span",
                        { key: index },
                        [
                          elem.type === "image"
                            ? [
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "accent--text plot-digit px-0",
                                      on: {
                                        click: function ($event) {
                                          return _vm.openPlotDialog(elem.body)
                                        },
                                        mouseenter: function ($event) {
                                          _vm.$vuetify.breakpoint.mobile
                                            ? null
                                            : _vm.onHoverEnter(
                                                elem.body,
                                                $event
                                              )
                                        },
                                        mouseleave: function ($event) {
                                          return _vm.onHoverLeave()
                                        },
                                      },
                                    },
                                    [
                                      !elem.body.Value ||
                                      elem.body.Value.length === 0
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass: "px-0 mx-0 mb-1",
                                              attrs: {
                                                color: "accent",
                                                size: "20",
                                              },
                                            },
                                            [_vm._v(" mdi-chart-histogram ")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        {
                                          class: _vm.autoPlotEnabled
                                            ? "accent--text plot-digit"
                                            : "text-decoration-underline",
                                          staticStyle: {
                                            "text-decoration-style":
                                              "dotted !important",
                                          },
                                          attrs: {
                                            "data-test": "result-digit-value",
                                          },
                                        },
                                        [_vm._v(_vm._s(elem.body.Value))]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.autoPlotEnabled
                                    ? _c("LazyFigure", {
                                        attrs: {
                                          imageURL: elem.body.ImageURL,
                                          taskID: _vm.taskID,
                                          valueID: elem.body.ValueID,
                                          uxString: elem.body.UxString,
                                          imageMaxWidth: "25em",
                                          alertWidth: "30em",
                                          loaderCols: 3,
                                        },
                                        on: {
                                          "update:imageURL": function ($event) {
                                            return _vm.$set(
                                              elem.body,
                                              "ImageURL",
                                              $event
                                            )
                                          },
                                          "update:image-u-r-l": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              elem.body,
                                              "ImageURL",
                                              $event
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                              ]
                            : _c("div", {
                                staticClass: "black--text",
                                staticStyle: { display: "inline" },
                                domProps: { textContent: _vm._s(elem.body) },
                              }),
                        ],
                        2
                      )
                    }),
                    _vm.hoveredItem
                      ? _c(
                          "div",
                          {
                            staticClass: "hover-overlay",
                            style: {
                              top: _vm.overlayTop + "px",
                              left: _vm.overlayLeft + "px",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              {
                                attrs: {
                                  "min-height": "5em",
                                  "min-width": "20em",
                                },
                              },
                              [
                                _c(
                                  "v-card-text",
                                  { staticClass: "py-3 px-0" },
                                  [
                                    _vm.hoveredItem
                                      ? _c("LazyFigure", {
                                          attrs: {
                                            imageURL: _vm.hoveredItem.ImageURL,
                                            taskID: _vm.taskID,
                                            valueID: _vm.hoveredItem.ValueID,
                                            uxString: _vm.hoveredItem.UxString,
                                            loaderCols: 7,
                                            imageMaxHeight: "20em",
                                            imageMaxWidth: "40em",
                                            alertWidth: "30em",
                                            justify: "center",
                                          },
                                          on: {
                                            "update:imageURL": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                _vm.hoveredItem,
                                                "ImageURL",
                                                $event
                                              )
                                            },
                                            "update:image-u-r-l": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                _vm.hoveredItem,
                                                "ImageURL",
                                                $event
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ],
        ],
        2
      ),
      _vm.tab != 5
        ? _c(
            "v-sheet",
            {
              staticStyle: {
                "border-bottom": "none",
                "border-left": "none",
                "border-right": "none",
              },
              attrs: { "min-height": "50px", rounded: "b-lg", outlined: "" },
            },
            [
              _c(
                "v-container",
                { staticClass: "fill-height py-0 px-2", attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center", "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          ["Stdout", "Stderr"].includes(
                            _vm.resultText[_vm.tab].code
                          )
                            ? _c("TooltipButton", {
                                attrs: {
                                  color: _vm.autoPlotEnabled
                                    ? "primary"
                                    : "grey",
                                  outlined: true,
                                  icon: false,
                                  width: "fit-content",
                                  tooltipText: "Automatically show plots",
                                  btnIcon: "mdi-chart-box-outline",
                                },
                                on: { click: _vm.toggleAutoplot },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _vm.showClearButton
                        ? [
                            _c(
                              "v-col",
                              { attrs: { cols: "auto" } },
                              [
                                _c("TooltipButton", {
                                  attrs: {
                                    btnClass:
                                      "font-weight-medium text-body-2 copy-btn float-right px-3",
                                    color: "primary",
                                    "min-width": "50px",
                                    "max-width": "100px",
                                    tooltipText: "Clear Output",
                                    btnIcon: "mdi-delete-outline",
                                    btnText: "Clear",
                                  },
                                  on: { click: _vm.requestClearTextArea },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm.showCopyButton
                        ? [
                            _c(
                              "v-col",
                              { staticClass: "ml-2", attrs: { cols: "auto" } },
                              [
                                _c("TooltipButton", {
                                  attrs: {
                                    btnClass:
                                      "font-weight-medium text-body-2 copy-btn float-right  px-3",
                                    color: "primary",
                                    "min-width": "50px",
                                    "max-width": "100px",
                                    tooltipText: "Copy Output",
                                    btnIcon: "mdi-content-copy",
                                    btnText: "Copy",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyOutputToClipboard(_vm.tab)
                                    },
                                  },
                                }),
                                _c(
                                  "v-snackbar",
                                  {
                                    attrs: {
                                      color: "green lighten-1",
                                      bottom: "",
                                    },
                                    model: {
                                      value: _vm.showCopyNotification,
                                      callback: function ($$v) {
                                        _vm.showCopyNotification = $$v
                                      },
                                      expression: "showCopyNotification",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "pl-2",
                                        attrs: { small: "" },
                                      },
                                      [_vm._v(" mdi-check")]
                                    ),
                                    _vm._v(" Copied to clipboard! "),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { width: _vm.$vuetify.breakpoint.mobile ? "95%" : "500px" },
          on: {
            "click:outside": _vm.closePlotDialog,
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.closePlotDialog.apply(null, arguments)
            },
          },
          model: {
            value: _vm.showPlotDialog,
            callback: function ($$v) {
              _vm.showPlotDialog = $$v
            },
            expression: "showPlotDialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { "min-height": "5em" } },
            [
              _c(
                "v-card-text",
                { staticClass: "py-3 px-4" },
                [
                  _vm.showPlotDialog && _vm.dataForPlotDialog
                    ? _c("LazyFigure", {
                        attrs: {
                          useIntersection: false,
                          imageURL: _vm.dataForPlotDialog.ImageURL,
                          taskID: _vm.taskID,
                          valueID: _vm.dataForPlotDialog?.ValueID,
                          uxString: _vm.dataForPlotDialog.UxString,
                          justify: "center",
                          "data-test": "result-plot-figure",
                        },
                        on: {
                          "update:imageURL": function ($event) {
                            return _vm.$set(
                              _vm.dataForPlotDialog,
                              "ImageURL",
                              $event
                            )
                          },
                          "update:image-u-r-l": function ($event) {
                            return _vm.$set(
                              _vm.dataForPlotDialog,
                              "ImageURL",
                              $event
                            )
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("OutputFailedDialog", {
        attrs: { "show-dialog": _vm.showTaskFailedDialog, taskID: _vm.taskID },
        on: {
          "update:showDialog": function ($event) {
            _vm.showTaskFailedDialog = $event
          },
          "update:show-dialog": function ($event) {
            _vm.showTaskFailedDialog = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }