
import { defineComponent, PropType } from "vue";

// Stores
import { mapState } from "pinia";
import { useDataSourcesStore } from "@/stores/dataSources";
import { useUserStore } from "@/stores/user";

// Utilities
import { createBucket, editBucketByID } from "@/js/signaloidClient";
import { monitoringCaptureError } from "@/plugins/monitoring";

// Components
import TooltipButton from "@/components/Common/TooltipButton.vue";
import LimitableActionButton from "@/components/Common/LimitableActionButton.vue";

// Libraries
import axios from "axios";

// Types
import { UserLimitsE } from "@/js/tierconfig";

type ComponentData = {
	formValid: boolean;
	dataSourceTypes: DataSourceTypeListItem[];
	dataSourceConfig: DataSourceConfig;
	connectingToDataSource: boolean;
	chosenDataSource: undefined | string;
	alert: Alert;
	modifySourceConfigRequest: boolean;
	showIAMCopyNotification: boolean;
};

type Alert = {
	show: boolean;
	text: string;
	type: string;
};

type DataSourceTypeListItem = {
	text: string;
	value: string;
	disabled: boolean;
};

type DataSourceConfig = {
	sourceID?: string;
	sourceType: string;
	accountID: string;
	bucketName: string;
	readPermission: boolean;
	writePermission: boolean;
};

export default defineComponent({
	name: "DataSourceConfigDialog",
	components: { TooltipButton, LimitableActionButton },
	props: {
		showDialog: { type: Boolean, default: false },
		editSourceData: { type: Object as PropType<DataSourceConfig>, default: undefined },
	},
	emits: {
		"update:showDialog": (data: boolean) => true,
		"bucket-config-updated": () => true,
		"bucket-connected": () => true,
	},
	data: (): ComponentData => ({
		formValid: false,
		dataSourceTypes: [
			{ text: "S3 Bucket", value: "Bucket", disabled: false },
			{ text: "Signaloid D0", value: "D0", disabled: true },
			{ text: "Signaloid Sensor Gateway", value: "Gateway", disabled: true },
			{ text: "API", value: "API", disabled: true },
		],
		// @ts-ignore FIXME: add correct DataSourceConfig type
		dataSourceConfig: {},
		connectingToDataSource: false,
		chosenDataSource: undefined,
		alert: { show: false, text: "", type: "warning" },
		modifySourceConfigRequest: false,
		showIAMCopyNotification: false,
	}),
	setup() {
		const userStore = useUserStore();
		const dataSourcesStore = useDataSourcesStore();
		return { userStore, dataSourcesStore, UserLimitsE };
	},
	methods: {
		charIsAlphaNumeric(value: string | number) {
			if (typeof value == "string") {
				return value.toLowerCase().charCodeAt(0) >= 97 && value.toLowerCase().charCodeAt(0) <= 122;
			} else if (typeof value == "number") {
				return isFinite(value);
			} else {
				return false;
			}
		},
		closeDialog() {
			this.$emit("update:showDialog", false);
			this.chosenDataSource = "";
			this.alert = { show: false, text: "", type: "warning" };
		},
		async connectToDataSource() {
			this.alert = { show: false, text: "", type: "warning" };
			//@ts-ignore
			this.$refs.dataSourceForm.validate();
			if (this.formValid) {
				if (this.dataSourceConfig.sourceType == "Bucket") {
					this.connectingToDataSource = true;
					try {
						if (this.modifySourceConfigRequest && this.dataSourceConfig.sourceID) {
							const resp = await editBucketByID(this.dataSourceConfig.sourceID, {
								Read: this.dataSourceConfig.readPermission,
								Write: this.dataSourceConfig.writePermission,
							});
							this.$emit("bucket-config-updated");
						} else {
							const resp = await createBucket(
								this.dataSourceConfig.bucketName,
								this.dataSourceConfig.accountID,
								{
									Read: this.dataSourceConfig.readPermission,
									Write: this.dataSourceConfig.writePermission,
								}
							);
							this.$emit("bucket-connected");
						}
						this.closeDialog();
					} catch (error) {
						monitoringCaptureError(error, "Connect to data source");

						if (axios.isAxiosError(error)) {
							const isRestrictedError = error.response?.status == 403;
							this.alert = isRestrictedError
								? {
										show: true,
										text: "Connected Buckets limit reached.",
										type: "warning",
								  }
								: {
										show: true,
										text: `${error?.response?.data?.error}: ${error?.response?.data?.message}`,
										type: "error",
								  };
						}
					} finally {
						this.connectingToDataSource = false;
					}
				}
			}
		},
		setupDataSourceConfig(sourceType: string) {
			switch (sourceType) {
				case "Bucket":
					this.dataSourceConfig = {
						sourceType: sourceType,
						accountID: "",
						bucketName: "",
						readPermission: true,
						writePermission: false,
					};
					break;
				default:
					// @ts-ignore FIXME: add correct DataSourceConfig type
					this.dataSourceConfig = {};
					break;
			}
		},
		copyIAMPolicyToClipboard() {
			navigator.clipboard.writeText(JSON.stringify(this.iamPolicy, null, 4));
			this.showIAMCopyNotification = true;
		},
	},
	computed: {
		...mapState(useDataSourcesStore, {
			bucketList: "bucketList",
		}),
		tierLimitsToCheck() {
			switch (this.dataSourceConfig.sourceType) {
				case "Bucket":
					return [UserLimitsE.BucketCount];
				default:
					return [];
			}
		},

		// @ts-ignore FIXME: add correct types
		rules(): any {
			return {
				required: (value) => !!value || "Required.",
				counter: (value) => value.length <= 50 || "Max 50 characters",
				awsS3: {
					accountIDLength: (value) => value.length == 12 || "Account ID must be 12 characters long",
					accountIDNumeric: (value) => !isNaN(value) || "Account ID must be a number",
					bucketNameNoARN: (value) =>
						!value.toLowerCase().startsWith("arn") || "Please enter the S3 Bucket name not ARN",
					bucketNameLengthValid: (value) =>
						(value.length >= 3 && value.length <= 63) ||
						"The S3 Bucket name must be between 3 and 64 characters long",
					bucketNameStartValid: (value) =>
						(this.charIsAlphaNumeric(value.charAt(0)) && !value.startsWith("xn--")) ||
						"The S3 Bucket name must start with a letter or a number and also not 'xn--'",
					bucketNameEndValid: (value) =>
						(this.charIsAlphaNumeric(value.charAt(value.length - 1)) && !value.endsWith("-s3alias")) ||
						"The S3 Bucket name must end with a letter or a number and also not '-s3alias'",
					// bucketNameCharactersvalid: (value) => //TODO: || "Please enter a valid S3 Bucket Name",
				},
			};
		},
		// @ts-ignore FIXME: add correct types
		iamPolicy(): any {
			const iamPolicy = {
				Version: "2012-10-17",
				Statement: [
					{
						Sid: "SignaloidCloudBucketPermissionsList",
						Effect: "Allow",
						Principal: {
							AWS: `arn:aws:iam::${process.env.VUE_APP_ENV_AWS_ACCOUNT_ID}:root`,
						},
						Action: ["s3:GetBucketLocation", "s3:ListBucket"],
						Resource: `arn:aws:s3:::${this.dataSourceConfig.bucketName}`,
					},
				],
			};

			if (this.dataSourceConfig.readPermission) {
				iamPolicy.Statement.push({
					Sid: "SignaloidCloudBucketPermissionsRead",
					Effect: "Allow",
					Principal: {
						AWS: `arn:aws:iam::${process.env.VUE_APP_ENV_AWS_ACCOUNT_ID}:root`,
					},
					Action: ["s3:GetObject"],
					Resource: `arn:aws:s3:::${this.dataSourceConfig.bucketName}/*`,
				});
			}

			if (this.dataSourceConfig.writePermission) {
				iamPolicy.Statement.push({
					Sid: "SignaloidCloudBucketPermissionsWrite",
					Effect: "Allow",
					Principal: {
						AWS: `arn:aws:iam::${process.env.VUE_APP_ENV_AWS_ACCOUNT_ID}:root`,
					},
					Action: ["s3:PutObject", "s3:DeleteObject"],
					Resource: `arn:aws:s3:::${this.dataSourceConfig.bucketName}/*`,
				});
			}

			return iamPolicy;
		},
		dialogTitle(): string {
			return this.modifySourceConfigRequest ? "Modify Data Source" : "Add a Data Source";
		},
	},
	async created() {
		await this.userStore.getCurrentUserTierDetails();
		await this.dataSourcesStore.fetchBucketList();
	},
	mounted() {
		if (this.editSourceData) {
			this.modifySourceConfigRequest = true;
			this.chosenDataSource = this.editSourceData.sourceType;
			this.dataSourceConfig = this.editSourceData;
		}
	},
});
