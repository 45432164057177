var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "section",
      [
        _c("h4", { staticClass: "text-h7" }, [_vm._v("Profile Settings")]),
        _c("v-divider"),
        _c(
          "div",
          { staticClass: "grid px-0 px-md-4" },
          [
            _c("div", { staticClass: "logo-grid" }),
            _c("v-form", { staticClass: "form-grid" }, [
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c("div", { staticClass: "font-weight-medium mb-2" }, [
                    _vm._v("UserID"),
                  ]),
                  _c("v-text-field", {
                    staticClass:
                      "grey lighten-3 font-weight-medium disabled-text-field",
                    attrs: {
                      disabled: "",
                      outlined: "",
                      "hide-details": "",
                      dense: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append",
                        fn: function () {
                          return [
                            _c("CopyToClipboardButton", {
                              staticClass: "mr-n3 mt-n2 align-self-center",
                              attrs: {
                                "value-to-copy":
                                  _vm.userStore.currentUser.userID,
                                "value-name": "User ID",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.userStore.currentUser.userID,
                      callback: function ($$v) {
                        _vm.$set(_vm.userStore.currentUser, "userID", $$v)
                      },
                      expression: "userStore.currentUser.userID",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c("div", { staticClass: "font-weight-medium mb-2" }, [
                    _vm._v("E-mail"),
                  ]),
                  _c("v-text-field", {
                    staticClass:
                      "grey lighten-3 font-weight-medium disabled-text-field",
                    attrs: {
                      disabled: "",
                      outlined: "",
                      "hide-details": "",
                      dense: "",
                    },
                    model: {
                      value: _vm.userStore.currentUser.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.userStore.currentUser, "email", $$v)
                      },
                      expression: "userStore.currentUser.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c("div", { staticClass: "font-weight-medium mb-2" }, [
                    _vm._v("Name"),
                  ]),
                  _c("v-text-field", {
                    staticClass:
                      "grey lighten-3 font-weight-medium disabled-text-field",
                    attrs: {
                      disabled: "",
                      outlined: "",
                      "hide-details": "",
                      dense: "",
                    },
                    model: {
                      value: _vm.userStore.currentUser.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.userStore.currentUser, "name", $$v)
                      },
                      expression: "userStore.currentUser.name",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }