
import { defineComponent } from "vue";

// Types

// Libraries

// Utilities

// Components

// Stores
import { useRootStore } from "@/stores/root";
import { mapState } from "pinia";

// Global variables

export default defineComponent({
	name: "HelpButton",
	data: (): { showMenu: boolean; menuColour: string } => ({
		showMenu: false,
		menuColour: "accent",
	}),
	computed: {
		...mapState(useRootStore, { userflowLoaded: "userflowLoaded", showHelpButton: "showHelpButton" }),
	},
});
