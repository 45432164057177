<template>
	<v-dialog
		v-model="showDialog"
		class="text-center"
		:width="$vuetify.breakpoint.mobile ? '95%' : '500px'"
		@click:outside="closeDialog"
		@keydown.esc="closeDialog"
	>
		<v-card
			class="pa-2 pa-lg-2"
			data-test="delete-core-dialog"
		>
			<p class="mt-3 font-weight-medium text-h6 text-center">
				Would you like to delete the core "{{ deleteCoreData.Name }}"?
			</p>
			<v-alert
				:icon="false"
				class="text-body-2 pa-2 pl-5 my-2 text-center mx-12"
				type="warning"
				outlined
				elevation="0"
				><strong> This operation can not be undone. </strong>
			</v-alert>
			<v-card-actions>
				<v-btn
					color="primary"
					elevation="0"
					class="mx-auto"
					width="120px"
					@click="closeDialog"
					data-test="cancel-button"
				>
					Cancel
				</v-btn>
				<v-btn
					color="danger"
					:loading="waitingForSeverResponse"
					elevation="0"
					class="mx-auto white--text"
					width="120px"
					@click="commitDeleteCore"
					data-test="delete-button"
				>
					Delete
				</v-btn>
			</v-card-actions>
			<v-expand-transition>
				<v-alert
					v-show="showError"
					:icon="false"
					border="left"
					class="text-body-2 pa-2 pl-5 mb-0 mt-2"
					type="warning"
					outlined
					elevation="0"
				>
					{{ errorMessage }}
				</v-alert>
			</v-expand-transition>
		</v-card>
	</v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import { deleteCoreByID } from "@/js/signaloidClient";
import { useCoresStore } from "@/stores/cores";
import { monitoringCaptureError } from "@/plugins/monitoring";

export default defineComponent({
	name: "DeleteCoreDialog",
	props: { showDialog: Boolean, deleteCoreData: Object },
	data: () => ({
		waitingForSeverResponse: false,
		errorMessage: "unknown error",
		showError: false,
	}),
	setup() {
		const coresStore = useCoresStore();
		return { coresStore };
	},
	computed: {
		isLocalCore() {
			return !this.deleteCoreData.CoreID.includes("cor_");
		},
	},
	methods: {
		async commitDeleteCore() {
			this.errorMessage = undefined;
			this.showError = false;
			try {
				this.waitingForSeverResponse = true;
				if (this.isLocalCore) {
					this.coresStore.removeCoreFromLocalCoresList(this.deleteCoreData);
					this.$emit("core-deleted", this.deleteCoreData);
					this.closeDialog();
				} else {
					const response = await deleteCoreByID(this.deleteCoreData.CoreID);

					this.$emit("core-deleted", this.deleteCoreData);
					this.closeDialog();
				}
			} catch (error) {
				monitoringCaptureError(error, "Delete core");
				if (error.response) {
					//non 2xx response
					this.errorMessage = "An error occurred while deleting the core.";
					this.showError = true;
				} else if (error.request) {
					//no response
					this.errorMessage =
						"An error occurred while deleting the core. Could not connect to Signaloid servers.";
					this.showError = true;
				} else {
					//making request failed
					this.errorMessage =
						"An error occurred while deleting the core. Please check your internet connection.";
					this.showError = true;
				}
			} finally {
				this.waitingForSeverResponse = false;
			}
		},
		closeDialog() {
			this.$emit("close-dialog");
		},
	},
});
</script>

<style></style>
