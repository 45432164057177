var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.videoURL.includes("vimeo.com")
        ? [
            _c("iframe", {
              staticClass: "video",
              staticStyle: { display: "block" },
              attrs: {
                src: `${_vm.videoURL}&badge=0&autopause=0&player_id=0&app_id=58479`,
                frameborder: "0",
                allow: "autoplay; fullscreen; picture-in-picture",
                allowfullscreen: "",
              },
            }),
          ]
        : _c("VideojsPlayer", {
            staticClass: "video",
            attrs: { options: _vm.videoOptions },
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }