var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "outline",
        attrs: {
          headers: _vm.headers,
          loading: _vm.keyListLoading,
          items: _vm.keysToList,
          "hide-default-footer": "",
          "sort-by": "CreatedAt",
          "sort-desc": "",
          dense: "",
          "mobile-breakpoint": _vm.$vuetify.breakpoint.sm ? 900 : 1075,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "no-data",
              fn: function () {
                return [_vm._v(" " + _vm._s(_vm.noDataText) + " ")]
              },
              proxy: true,
            },
            {
              key: `header.FormattedCreatedAt`,
              fn: function ({ header }) {
                return [
                  _vm._v(" " + _vm._s(header.text)),
                  _c(
                    "v-icon",
                    { staticClass: "text--disabled", attrs: { small: "" } },
                    [_vm._v("mdi-arrow-down-thin")]
                  ),
                ]
              },
            },
            {
              key: `item.Name`,
              fn: function ({ item }) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "key-name",
                      attrs: {
                        title: item.Name,
                        "data-test": "keys-list-key-entry",
                        "data-test-value": item.KeyID,
                      },
                    },
                    [_vm._v(" " + _vm._s(item.Name) + " ")]
                  ),
                ]
              },
            },
            {
              key: `item.FormattedCreatedAt`,
              fn: function ({ item }) {
                return [
                  _vm._v(" " + _vm._s(item.FormattedCreatedAt) + " "),
                  _vm.daysDiff(item.CreatedAt) <= -90
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "", "open-delay": "250" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "align-self-center",
                                            attrs: {
                                              small: "",
                                              color: "warning",
                                            },
                                          },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-alert-outline ")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "This key is " +
                                _vm._s(-_vm.daysDiff(item.CreatedAt)) +
                                " days old."
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.FormattedValidUntil`,
              fn: function ({ item }) {
                return [
                  _vm._v(" " + _vm._s(item.FormattedValidUntil) + " "),
                  _vm.daysDiff(item.ValidUntil) < 0
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "", "open-delay": "250" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "align-self-center",
                                            attrs: {
                                              small: "",
                                              color: "error",
                                            },
                                          },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-alert-circle-outline ")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("This key has expired.")])]
                      )
                    : _vm.daysDiff(item.ValidUntil) < 7
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "", "open-delay": "250" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "align-self-center",
                                            attrs: {
                                              small: "",
                                              color: "warning",
                                            },
                                          },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-alert-outline ")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "This key will expire in " +
                                _vm._s(_vm.daysDiff(item.ValidUntil)) +
                                " day" +
                                _vm._s(
                                  _vm.daysDiff(item.ValidUntil) == 1 ? "" : "s"
                                ) +
                                "."
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.FormattedKeyID`,
              fn: function ({ item }) {
                return [
                  _c(
                    "span",
                    { staticClass: "inline-code font-weight-medium" },
                    [_vm._v(_vm._s(item.FormattedKeyID))]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "inline-code font-weight-medium",
                      staticStyle: {
                        "letter-spacing": "-0.2rem",
                        "margin-left": "-0.1rem",
                      },
                    },
                    [_vm._v("...")]
                  ),
                ]
              },
            },
            {
              key: `item.actions`,
              fn: function ({ item }) {
                return [
                  _c("TooltipButton", {
                    attrs: {
                      color: "grey",
                      icon: true,
                      "x-small": "",
                      tooltipText: "Delete API key",
                      "data-test": "keys-list-delete-key-button",
                      btnIcon: "mdi-delete",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleDeleteKey(item)
                      },
                    },
                  }),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _vm.deleteKeyDialog
        ? _c("DeleteAPIKey", {
            attrs: {
              "show-dialog": _vm.deleteKeyDialog,
              passedKey: _vm.deleteKeyDialogConfig,
            },
            on: {
              "update:showDialog": function ($event) {
                _vm.deleteKeyDialog = $event
              },
              "update:show-dialog": function ($event) {
                _vm.deleteKeyDialog = $event
              },
              keyDeleted: _vm.refreshList,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }