var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-tooltip",
    {
      attrs: { top: "", "max-width": "500px" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-icon",
                _vm._g(
                  _vm._b(
                    { staticClass: "ml-1", attrs: { small: "" } },
                    "v-icon",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v("mdi-information-outline ")]
              ),
            ]
          },
        },
      ]),
    },
    [_vm._v(" " + _vm._s(_vm.disclosureText) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }