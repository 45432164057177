import { defineStore } from "pinia";
import * as util from "@/js/utilities";

import { useCoresStore } from "@/stores/cores";
import { useUserStore } from "@/stores/user";
import { isLocalMountConfig, LocalMountConfig } from "@/types/api/dataSources";

import { base64Decode, base64Encode } from "@/js/utilities";
import { useDataSourcesStore } from "./dataSources";
import { RemoteAccessState } from "@/types/general";
import { monitoringCaptureError } from "@/plugins/monitoring";

import { EditorTheme, themes as monacoThemes } from "@/js/editor";

const fallbackEditorCode = require("@/assets/example_loadFromFile.c.js").default;
const fallbackEditorTheme: EditorTheme = "solarized-light";
const fallbackEditorLayout = "vertical";
const fallbackVariableViewerVisibility = false;
const fallbackExecutionCoreID = "cor_b21e4de9927158c1a5b603c2affb8a09"; /* C0-S+ (one of the default cores) */
const maxArgLength = 100_000;

/*
 * mountConfig can be undefined | null | LocalMountConfig
 * `undefined` => `mountConfig` hasn't been set
 * 	- should fallback to default.
 *  - If default not defined, fallback to `null` ?
 * `null` => user has removed the mount config
 * 	- honour `null` and run code without data source mounting
 * `LocalMountConfig` => user (or system) has set the mount config
 * 	- honour `LocalMountConfig` and run code without data source mounting
 */

type StoreState = {
	editorThemeID: undefined | string;
	savedEditorThemeID: undefined | string;
	variableViewerVisibility: boolean;
	editorSourceCode: undefined | string;
	editorCore: undefined | string;
	coreUpdateStatus: RemoteAccessState;
	variableViewerVisibilityUpdateStatus: RemoteAccessState;
	themeUpdateStatus: RemoteAccessState;
	dataSourceUpdateStatus: RemoteAccessState;
	runArgumentsUpdateStatus: RemoteAccessState;
	mountConfig: undefined | null | LocalMountConfig;
	codeSyncFrequency: number; //How often to check for code changes before synchronising with remote
	editorRunArgs: string;
	codeLanguage: string;
	lastBuildID: undefined | string;
	editorLayout: "vertical" | "horizontal";
};

export const useEditorStore = defineStore("editor", {
	state: (): StoreState => ({
		editorThemeID: undefined,
		savedEditorThemeID: undefined,
		variableViewerVisibility: fallbackVariableViewerVisibility,
		editorSourceCode: undefined,
		editorRunArgs: "",
		editorCore: undefined,
		coreUpdateStatus: { loading: false, error: false, message: "" },
		variableViewerVisibilityUpdateStatus: { loading: false, error: false, message: "" },
		themeUpdateStatus: { loading: false, error: false, message: "" },
		dataSourceUpdateStatus: { loading: false, error: false, message: "" },
		runArgumentsUpdateStatus: { loading: false, error: false, message: "" },
		mountConfig: undefined,
		codeSyncFrequency: 5_000,
		codeLanguage: "",
		lastBuildID: undefined,
		editorLayout: "vertical",
	}),
	getters: {
		editorCoreObject: (state) => {
			if (!state.editorCore) {
				return undefined;
			}
			const coresStore = useCoresStore();

			let core = coresStore.getCoreByID(state.editorCore, coresStore.filteredCoresList);
			if (!core) {
				core = coresStore.defaultCore;
				console.warn(
					`(Editor initialisation) Specified editor core not found: ${state.editorCore}. returning default: ${core.CoreID}`
				);
			}

			return core;
		},
		themeList: (state) => {
			// eslint-disable-next-line prefer-const
			let monacoThemesList = new Array(Object.keys(monacoThemes).length);
			let index = 0;
			for (const key in monacoThemes) {
				if (Object.hasOwnProperty.call(monacoThemes, key)) {
					const element = monacoThemes[key];
					monacoThemesList[index++] = {
						name: element,
						key: key,
					};
				}
			}
			return monacoThemesList;
		},
		rawThemeList: (state) => monacoThemes,
		getLastBuild: (state) => {
			if (state.lastBuildID){
				return state.lastBuildID
			}
			return localStorage.getItem("signaloid-editor-last-build") ?? undefined
		}
	},
	actions: {
		/*
		 * Editor theme
		 */
		setLastBuild(buildID:string){
			localStorage.setItem("signaloid-editor-last-build", buildID);
			this.lastBuildID = buildID;
		},
		resetLastBuild(){
			localStorage.removeItem("signaloid-editor-last-build");
			this.lastBuildID = undefined;
		},
		async syncPreferredTheme() {
			const authUserStore = useUserStore();
			const userPreferences = await authUserStore.getCurrentUserPreferences();
			this.savedEditorThemeID = userPreferences.Editor_Theme ?? fallbackEditorTheme;
		},
		async setPreferredTheme(themeID: undefined | string) {
			this.savedEditorThemeID = themeID;
			await this.setEditorTheme(themeID, true);
		},
		setToPreferredTheme() {
			this.setEditorTheme(this.savedEditorThemeID, true);
		},
		setThemeToDefault(syncWithRemote: boolean) {
			this.setEditorTheme(fallbackEditorTheme, syncWithRemote);
		},
		async setEditorTheme(themeID: undefined | null | string, syncWithRemote: boolean) {
			this.themeUpdateStatus.loading = true;

			// check if theme ID is valid
			this.editorThemeID = themeID && monacoThemes[themeID] ? themeID : fallbackEditorTheme;
			// localStorage.setItem("signaloid-editor-theme", this.editorThemeID);

			if (syncWithRemote) {
				const authUserStore = useUserStore();
				await authUserStore.syncUserPreferenceWithRemote({
					Editor_Theme: this.editorThemeID,
				});
			}
			this.themeUpdateStatus.loading = false;
		},

		/*
		 * Editor Layout
		 */
		toggleEditorLayout(syncWithRemote: boolean) {
			if (this.editorLayout === "vertical") {
				this.setEditorLayout("horizontal", syncWithRemote);
			} else {
				this.setEditorLayout("vertical", syncWithRemote);
			}
		},
		async setEditorLayout(layout: string, syncWithRemote: boolean) {
			// check if theme ID is valid
			this.editorLayout = layout === "vertical" || layout === "horizontal" ? layout : fallbackEditorLayout;

			// localStorage.setItem("editor-layout", this.editorLayout);
			// localStorage.setItem("editor-layout-ratio", this.editorLayout);

			if (syncWithRemote) {
				const authUserStore = useUserStore();
				await authUserStore.syncUserPreferenceWithRemote({
					Editor_Layout: this.editorLayout,
				});
			}
		},

		/*
		 * Variable Viewer
		 */
		toggleVariableViewerVisibility(syncWithRemote) {
			this.setVariableViewerVisibility(!this.variableViewerVisibility, syncWithRemote);
		},
		async setVariableViewerVisibility(visibility: boolean, syncWithRemote: boolean) {
			this.variableViewerVisibilityUpdateStatus.loading = true;
			this.variableViewerVisibility = visibility ?? fallbackVariableViewerVisibility;

			if (syncWithRemote) {
				const authUserStore = useUserStore();
				await authUserStore.syncUserPreferenceWithRemote({
					Editor_Layout_VariableViewer: String(this.variableViewerVisibility),
				});
			}
			this.variableViewerVisibilityUpdateStatus.loading = false;
		},

		/*
		 * Editor Core
		 */
		async setEditorCore(coreID: string, syncWithRemote: boolean) {
			// try to find the given core in the coresStore
			this.coreUpdateStatus.loading = true;
			// HACK:
			const coresStore = useCoresStore();

			await util.waitFor(() => coresStore.loadingCores === false);
			const coreInCoresStore = coresStore.getCoreByID(coreID, coresStore.filteredCoresList);
			if (coreInCoresStore) {
				this.editorCore = coreInCoresStore.CoreID;
			} else {
				console.warn(`Specified editor core not found: ${coreID}. Resetting to default: `);
				this.editorCore = coresStore.defaultCore.CoreID;
			}

			// localStorage.setItem("signaloid-editor-core", this.editorCore);

			if (syncWithRemote) {
				const authUserStore = useUserStore();
				await authUserStore.syncUserPreferenceWithRemote({
					Editor_Execution_Core: this.editorCore,
				});
			}
			this.coreUpdateStatus.loading = false;
		},

		/*
		 * Editor Cmd Line Arguments
		 */
		async setEditorArgs(args: string, syncWithRemote: boolean) {
			this.runArgumentsUpdateStatus.loading = true;
			this.editorRunArgs = args.slice(0, maxArgLength);

			// localStorage.setItem("editor-runArguments", args);
			if (syncWithRemote) {
				const authUserStore = useUserStore();
				await authUserStore.syncUserPreferenceWithRemote({
					Editor_Execution_Arguments: this.editorRunArgs,
				});
			}
			this.runArgumentsUpdateStatus.loading = false;
		},

		/*
		 * Editor Mount Configs
		 */
		async setEditorMountConfig(mountConfig: null | undefined | LocalMountConfig, syncWithRemote: boolean) {
			this.dataSourceUpdateStatus.loading = true;

			if (isLocalMountConfig(mountConfig)) {
				// valid mount config
				this.mountConfig = mountConfig;
			} else if (mountConfig === null) {
				// user has removed mounting
				this.mountConfig = null;
			} else {
				// unknown mount config, set to default
				const dataSourcesStore = useDataSourcesStore();
				this.mountConfig =
					(await dataSourcesStore.getGlobalDefaultMountConfig()) ??
					(await dataSourcesStore.getFallbackMountConfig());
			}

			// localStorage.setItem("signaloid-editor-mountConfig", JSON.stringify(this.mountConfig));
			if (syncWithRemote) {
				const authUserStore = useUserStore();
				await authUserStore.syncUserPreferenceWithRemote({
					Editor_Execution_DataSources: JSON.stringify(this.mountConfig),
				});
			}
			this.dataSourceUpdateStatus.loading = false;
		},

		/*
		 * Editor Source Code
		 */
		async resetEditorSourceCode() {
			this.editorSourceCode = fallbackEditorCode;

			const encodedCodeString = base64Encode(this.editorSourceCode);
			localStorage.setItem("signaloid-editor-code", encodedCodeString);
			const authUserStore = useUserStore();
			await authUserStore.syncUserPreferenceWithRemote({
				Editor_SourceCode: encodedCodeString,
			});
		},
		async setEditorSourceCode(code: string, syncWithRemote = false) {
			// TODO: currently remote sync is not supported
			// syncWithRemote = false;

			this.editorSourceCode = code;

			// Base 64 encode the string before storing it
			const encodedCodeString = base64Encode(this.editorSourceCode);
			localStorage.setItem("signaloid-editor-code", encodedCodeString);
			if (syncWithRemote) {
				const authUserStore = useUserStore();
				await authUserStore.syncUserPreferenceWithRemote({
					Editor_SourceCode: encodedCodeString,
				});
			}
		},

		/*
		 * Initialise state
		 */
		async initialiseEditorStateFromRemote() {
			const authUserStore = useUserStore();
			const preferencesFromDB = await authUserStore.getCurrentUserPreferences();

			if (preferencesFromDB) {
				this.coreUpdateStatus.loading = true;
				this.runArgumentsUpdateStatus.loading = true;
				this.dataSourceUpdateStatus.loading = true;

				//* Editor Source Code
				const editorCode = preferencesFromDB?.Editor_SourceCode;

				if (editorCode) {
					this.setEditorSourceCode(this.parseSourceCode(editorCode));
				} else {
					console.warn("Invalid editor code from remote. Resetting to default.");
					this.setEditorSourceCode(fallbackEditorCode);
				}

				//* Editor Theme
				let themeID = preferencesFromDB.Editor_Theme ?? this.editorThemeID;
				themeID = themeID && monacoThemes[themeID] ? themeID : fallbackEditorTheme;

				// set theme and sync if theme different to whats in db (parsing error or non existent key)
				this.setEditorTheme(themeID, themeID !== preferencesFromDB?.Editor_Theme);

				//* Editor Command line args
				const editorArgsFromDB = preferencesFromDB?.Editor_Execution_Arguments ?? "";
				// set CLI args and sync if theme different to whats in db (parsing error or non existent key)
				this.setEditorArgs(
					editorArgsFromDB,
					editorArgsFromDB !== preferencesFromDB?.Editor_Execution_Arguments
				);
				this.runArgumentsUpdateStatus.loading = false;

				//* Editor Layout
				const editorLayoutFromDB = preferencesFromDB?.Editor_Layout ?? "vertical";
				// set editor layout and sync if theme different to whats in db (parsing error or non existent key)
				this.setEditorLayout(editorLayoutFromDB, editorLayoutFromDB !== preferencesFromDB?.Editor_Layout);

				//* Variable viewer visibility
				const variableViewerVisibilityFromDB = preferencesFromDB?.Editor_Layout_VariableViewer;
				if (variableViewerVisibilityFromDB) {
					// we got something from  the DB
					this.setVariableViewerVisibility(variableViewerVisibilityFromDB === "true", false);
				} else {
					console.log(
						`Error parsing variable viewer state.  Resetting to default - ${fallbackVariableViewerVisibility}.`
					);
					this.setVariableViewerVisibility(fallbackVariableViewerVisibility, true);
				}

				//* Editor Core
				const editorCoreIDFromDB = preferencesFromDB?.Editor_Execution_Core;
				const coresStore = useCoresStore();
				// if the cores havent been fetched from DB, wait
				await util.waitFor(() => coresStore.remoteCoresList !== undefined);
				if (editorCoreIDFromDB && typeof editorCoreIDFromDB === "string") {
					const editorCoreId = await coresStore.resetEditorCoreIfRestricted(
						editorCoreIDFromDB,
						fallbackExecutionCoreID
					);
					this.setEditorCore(editorCoreId, true);
				} else if (
					preferencesFromDB?.Execution_DefaultCore &&
					typeof preferencesFromDB?.Execution_DefaultCore === "string"
				) {
					console.warn("Invalid editor core ID from remote. Trying to set user default...");
					const editorCoreId = await coresStore.resetEditorCoreIfRestricted(
						preferencesFromDB?.Execution_DefaultCore,
						fallbackExecutionCoreID
					);
					this.setEditorCore(editorCoreId, true);
				} else {
					console.warn(
						"Invalid editor core ID from remote and no user default code. Resetting to global default."
					);
					this.setEditorCore(fallbackExecutionCoreID, true);
				}
				this.coreUpdateStatus.loading = false;

				//* Editor Data Source Config
				const editorDataSourcesFromDB = preferencesFromDB?.Editor_Execution_DataSources;
				const dataSourcesStore = useDataSourcesStore();
				if (editorDataSourcesFromDB && typeof editorDataSourcesFromDB === "string") {
					// try to parse the string
					const parsedMountConfig = util.parseMountConfigString(editorDataSourcesFromDB);
					const isValidMountConfig = await dataSourcesStore.isValidMountConfig(parsedMountConfig);
					if (isValidMountConfig) {
						this.setEditorMountConfig(parsedMountConfig, false);
					} else {
						console.warn("Editor mount config in DB is invalid. Resetting to default config.");
						const validMountConfig = await dataSourcesStore.validateOrGetValidMountConfig(
							parsedMountConfig
						);

						this.setEditorMountConfig(validMountConfig, true);
					}
				} else {
					// no data source set in DB, set to default mount config
					console.warn("No editor mount config set in DB. Resetting to first time mount config.");
					const validMountConfig = await dataSourcesStore.validateOrGetValidMountConfig(undefined);

					this.setEditorMountConfig(validMountConfig, true);
				}
				this.dataSourceUpdateStatus.loading = false;
			}
		},
		parseSourceCode(editorCode) {
			try {
				if (editorCode === undefined || editorCode === "" || editorCode === null) {
					throw "Invalid editor code from local storage. Resetting to default.";
				}

				return base64Decode(editorCode);
			} catch (error) {
				monitoringCaptureError(error, "Validate code editor source");
				return fallbackEditorCode;
			}
		},
	},
});
