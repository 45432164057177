export default String.raw`#include <stdio.h>
#include <math.h>
#include <stdlib.h>

/*
 *	Minimal example that demonstrates the effect of uncertainty in
 *	resistance and circuit voltage on the power dissipation of a resistor.
 */
int
main(int argc, char *  argv[])
{
	double			R;	/* Resistance (Ohms) */
	double			V;	/* Voltage (Volts)   */
	double			P;	/* Power (Watts)     */

	FILE *			fp;
	const char *	fileLocation = "sd0/Signaloid/example-loadDistFromFile/example.csd";

	/*
	 *	Load the resistance R and the circuit voltage V from a file.
	 *
	 *	The file 'example.csd' is a comma-separated distribution (.csd) file that
	 *	contains two distributions represented as comma-separated Ux hexadecimal strings.
	 *	You can find more details about the Ux hexadecimal data format at
	 *	https://docs.signaloid.io/docs/hardware-api/ux-data-format.
	 *
	 *	The first Ux hexadecimal string in 'example.csd' initializes the resistance R as
	 *	a uniform distribution with a nominal resistance value of 1000 Ω and a gold band
	 *	tolerance of ±5%.
	 *
	 *	The second Ux hexadecimal string in 'example.csd' initializes the circuit voltage
	 *	V as a Gaussian distribution with a nominal voltage value of 3.3 V and a standard
	 *	deviation of 0.01 V.
	 */
	fp = fopen(fileLocation, "r");
	if (fp == NULL)
	{
		fprintf(stderr, "Could not open %s.\n", fileLocation);
		exit(EXIT_FAILURE);
	}

	fscanf(fp, "%lf, %lf\n", &R, &V);
	printf("R = %lf Ω\n", R);
	printf("V = %lf V\n", V);

	/*
	 *	The power dissipation equation, P = V^2 / R.
	 *
	 *	The Signaloid Cloud Compute Engine computes the distribution of
	 *	P by deterministic computation on the distributions of V and R.
	 */
	P = pow(V, 2) / R;
	printf("P = %lf W\n", P);

	return 0;
}
`;
