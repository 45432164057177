var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-tooltip",
    {
      attrs: {
        top: "",
        "open-delay": "150",
        "nudge-bottom": "5px",
        disabled: !_vm.tooltipText && !_vm.$slots["tooltipContent"],
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  "v-btn",
                  _vm._g(
                    _vm._b(
                      {
                        class: _vm.btnClass,
                        attrs: { width: _vm.width, elevation: "0" },
                      },
                      "v-btn",
                      { ..._vm.$attrs, ...attrs },
                      false
                    ),
                    { ..._vm.$listeners, ...on }
                  ),
                  [
                    _vm._t("btnContent", function () {
                      return [
                        _vm.btnIcon
                          ? [
                              _c("v-icon", { class: _vm.btnText && "mr-1" }, [
                                _vm._v(_vm._s(_vm.btnIcon)),
                              ]),
                            ]
                          : _vm._e(),
                        _vm._v(_vm._s(_vm.btnText) + " "),
                      ]
                    }),
                  ],
                  2
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
    },
    [
      _vm._t("tooltipContent", function () {
        return [_vm._v(_vm._s(_vm.tooltipText))]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }