var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "section",
      [
        _c("h4", { staticClass: "text-h7" }, [_vm._v("Account Settings")]),
        _c("v-divider"),
        _c(
          "div",
          { staticClass: "mb-4 px-0 px-md-4" },
          [
            _c(
              "div",
              { staticClass: "form-row" },
              [
                _c(
                  "div",
                  { staticClass: "font-weight-medium mb-2 secondary--text" },
                  [_vm._v("Delete Account")]
                ),
                _c(
                  "v-alert",
                  {
                    staticClass: "mx-3",
                    attrs: { color: "danger", outlined: "" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "font-weight-medium text-subtitle-2" },
                      [
                        _vm._v(
                          ' WARNING! Pressing the "Delete Account" button will permanently delete: '
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "ml-4" }, [
                      _c(
                        "div",
                        { staticClass: "font-weight-regular text-subtitle-2" },
                        [
                          _c(
                            "v-icon",
                            { attrs: { small: "", color: "danger" } },
                            [_vm._v("mdi-circle-medium")]
                          ),
                          _vm._v(
                            " Personal information associated with your account. "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "font-weight-regular text-subtitle-2" },
                        [
                          _c(
                            "v-icon",
                            { attrs: { small: "", color: "danger" } },
                            [_vm._v("mdi-circle-medium")]
                          ),
                          _vm._v(
                            " Build artefacts, outputs, and metadata of tasks you have run. "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "font-weight-regular text-subtitle-2" },
                        [
                          _c(
                            "v-icon",
                            { attrs: { small: "", color: "danger" } },
                            [_vm._v("mdi-circle-medium")]
                          ),
                          _vm._v(" The C0-Cloud cores you have created. "),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "font-weight-regular text-subtitle-2" },
                        [
                          _c(
                            "v-icon",
                            { attrs: { small: "", color: "danger" } },
                            [_vm._v("mdi-circle-medium")]
                          ),
                          _vm._v(" Files in your Signaloid Cloud Storage. "),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "font-weight-regular text-subtitle-2" },
                        [
                          _c(
                            "v-icon",
                            { attrs: { small: "", color: "danger" } },
                            [_vm._v("mdi-circle-medium")]
                          ),
                          _vm._v(
                            " Access permissions for any API integrations you have created. "
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "mt-5 mx-8 font-weight-regular text-subtitle-2",
                      },
                      [
                        _vm._v(
                          " You will not be able to access the Signaloid Cloud Development Platform or the Compute Engine API afterwards unless you make a new account. "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "mt-5 d-flex justify-space-between align-start",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "ml-7 font-weight-medium text-subtitle",
                          },
                          [_vm._v("This action is irreversible!")]
                        ),
                        _c("TooltipButton", {
                          attrs: {
                            btnText: "Delete Account",
                            width: "150px",
                            color: "secondary",
                          },
                          on: { click: _vm.openDeleteAccountDialog },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _c("DeleteAccountDialog", {
              attrs: {
                "show-dialog": _vm.deleteAccountDialog,
                passedEmail: _vm.authenticatedUser?.attributes.email,
              },
              on: { closeDeleteDialog: _vm.closeDeleteAccountDialog },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }