var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "section",
      [
        _c("h4", { staticClass: "text-h7" }, [
          _vm._v("Generate a new API key"),
        ]),
        _c("GenerateAPIKey", {
          staticClass: "mt-2",
          on: { keyCreated: _vm.handleNewAPIKeyCreated },
        }),
      ],
      1
    ),
    _c(
      "section",
      { staticClass: "mt-4" },
      [
        _c("h4", { staticClass: "text-h7 mb-1" }, [_vm._v("Current API keys")]),
        _c("ListAPIKeys", { ref: "listAPIKeysComponent" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }