var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-form",
    {
      ref: "interestForm",
      attrs: { action: "/" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.formIsValid,
        callback: function ($$v) {
          _vm.formIsValid = $$v
        },
        expression: "formIsValid",
      },
    },
    [
      _c("h1", { staticClass: "mb-5 text-center text-lg-left" }, [
        _vm._v("Sign Up for Access"),
      ]),
      _c("v-select", {
        attrs: {
          outlined: "",
          required: "",
          dense: "",
          disabled: "",
          rules: _vm.formRules.plan,
          label: _vm.labelValues.plan,
          items: _vm.planSelectItems,
        },
        model: {
          value: _vm.formValues.plan,
          callback: function ($$v) {
            _vm.$set(_vm.formValues, "plan", $$v)
          },
          expression: "formValues.plan",
        },
      }),
      _c("v-divider", { staticClass: "mb-4" }),
      _c("h2", { staticClass: "my-4" }, [_vm._v("Your Details")]),
      _c("v-text-field", {
        attrs: {
          name: "name",
          autocomplete: "name",
          rules: _vm.formRules.fullname,
          "validate-on-blur": "",
          outlined: "",
          required: "",
          dense: "",
          label: _vm.labelValues.fullname,
        },
        model: {
          value: _vm.formValues.fullname,
          callback: function ($$v) {
            _vm.$set(_vm.formValues, "fullname", $$v)
          },
          expression: "formValues.fullname",
        },
      }),
      _c("v-text-field", {
        attrs: {
          type: "email",
          name: "email",
          autocomplete: "email",
          rules: _vm.formRules.email,
          required: "",
          outlined: "",
          "validate-on-blur": "",
          dense: "",
          label: _vm.labelValues.email,
        },
        model: {
          value: _vm.formValues.email,
          callback: function ($$v) {
            _vm.$set(_vm.formValues, "email", $$v)
          },
          expression: "formValues.email",
        },
      }),
      _c("v-text-field", {
        attrs: {
          outlined: "",
          rules: _vm.formRules.affiliation,
          dense: "",
          label: _vm.labelValues.affiliation,
        },
        model: {
          value: _vm.formValues.affiliation,
          callback: function ($$v) {
            _vm.$set(_vm.formValues, "affiliation", $$v)
          },
          expression: "formValues.affiliation",
        },
      }),
      _c("v-textarea", {
        attrs: {
          outlined: "",
          rows: "4",
          "auto-grow": "",
          dense: "",
          rules: _vm.formRules.howDidYouHearAboutThis,
          label: _vm.labelValues.howDidYouHearAboutThis,
        },
        model: {
          value: _vm.formValues.howDidYouHearAboutThis,
          callback: function ($$v) {
            _vm.$set(_vm.formValues, "howDidYouHearAboutThis", $$v)
          },
          expression: "formValues.howDidYouHearAboutThis",
        },
      }),
      _c("v-textarea", {
        attrs: {
          outlined: "",
          rows: "4",
          "auto-grow": "",
          dense: "",
          rules: _vm.formRules.applicationDomain,
          label: _vm.labelValues.applicationDomain,
          hint: "We use this information in prioritizing your request for remote access to the Signaloid computing platform.",
          "persistent-hint": "",
        },
        model: {
          value: _vm.formValues.applicationDomain,
          callback: function ($$v) {
            _vm.$set(_vm.formValues, "applicationDomain", $$v)
          },
          expression: "formValues.applicationDomain",
        },
      }),
      _c("h2", { staticClass: "my-4" }, [_vm._v("Interest and Engangement")]),
      _vm._l(_vm.labelValues.survey, function (value, key) {
        return _c(
          "div",
          { key: value, staticClass: "ma-0 pa-0" },
          [
            _c("span", { staticClass: "text-body-1 font-weight-medium" }, [
              _vm._v(_vm._s(value)),
            ]),
            _c(
              "v-radio-group",
              {
                staticClass: "mt-2 mb-8",
                attrs: {
                  hint: "We use this information in prioritizing your request for remote access to the Signaloid computing platform.",
                  "persistent-hint": "",
                  rules: _vm.formRules.surveyRules,
                  row: !_vm.$vuetify.breakpoint.mobile,
                },
                model: {
                  value: _vm.formValues.survey[key],
                  callback: function ($$v) {
                    _vm.$set(_vm.formValues.survey, key, $$v)
                  },
                  expression: "formValues.survey[key]",
                },
              },
              [
                _c("v-radio", {
                  staticClass: "mb-2 mt-1",
                  attrs: {
                    ripple: false,
                    label: "Strongly Disagree",
                    value: "strongly-disagree",
                  },
                }),
                _c("v-radio", {
                  staticClass: "mb-2 mt-1",
                  attrs: {
                    ripple: false,
                    label: "Disagree",
                    value: "disagree",
                  },
                }),
                _c("v-radio", {
                  staticClass: "mb-2 mt-1",
                  attrs: { ripple: false, label: "Neutral", value: "neutral" },
                }),
                _c("v-radio", {
                  staticClass: "mb-2 mt-1",
                  attrs: { ripple: false, label: "Agree", value: "agree" },
                }),
                _c("v-radio", {
                  staticClass: "mb-2 mt-1",
                  attrs: {
                    ripple: false,
                    label: "Strongly Agree",
                    value: "strongly-agree",
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c(
        "div",
        { staticClass: "text-center mt-10" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mx-auto",
              attrs: {
                color: "primary",
                "min-width": _vm.$vuetify.breakpoint.mobile ? "80%" : "20%",
                loading: _vm.submitButtonLoading,
                type: "submit",
              },
            },
            [_vm._v("Submit")]
          ),
        ],
        1
      ),
      _vm.formPostSuccess
        ? _c("div", { staticClass: "text-center mt-4" }, [
            _c("h3", [_vm._v("Form submitted successfully.")]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }