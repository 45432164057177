var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      staticClass: "pa-2",
      attrs: { rounded: "lg", width: "100%", outlined: "" },
    },
    [
      !_vm.loadingData
        ? [
            [
              _c(
                "v-row",
                {
                  staticClass: "px-1 ma-0",
                  attrs: {
                    "no-gutters": "",
                    justify: "space-between",
                    "align-content": "space-around",
                    align: "center",
                  },
                },
                [
                  _c(
                    "v-fade-transition",
                    [
                      _vm.markedForDisconnect
                        ? _c("v-sheet", {
                            staticStyle: { position: "absolute", top: "20px" },
                            attrs: {
                              height: "2",
                              width: "85%",
                              color: "danger",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-truncate",
                      attrs: {
                        cols: _vm.$vuetify.breakpoint.mobile ? "auto" : "5",
                      },
                    },
                    [
                      _vm.dataSourceData
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    top: "",
                                    "open-delay": "50",
                                    disabled: !_vm.$vuetify.breakpoint.mobile,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                _vm._b({}, "div", attrs, false),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "mr-1" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.dataSourceIcon(
                                                          _vm.dataSourceType
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.dataSourceData.Name
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2023939682
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.dataSourceData.Name)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c(
                                "v-alert",
                                {
                                  staticClass:
                                    "d-flex justify-center ma-0 pa-0 px-1 text-sm-body-2 text-caption",
                                  attrs: {
                                    width: "100%",
                                    outlined: "",
                                    type: "warning",
                                    dense: "",
                                    icon: _vm.dataSourceIcon(
                                      _vm.dataSourceType
                                    ),
                                  },
                                },
                                [
                                  _c("strong", [
                                    _vm._v("Data source not found!"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                    ]
                  ),
                  _vm.mountLocation
                    ? [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "px-2 px-sm-2 ml-0 ml-sm-0" },
                              [_vm._v(" mdi-transfer-right ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-truncate",
                            attrs: {
                              cols: _vm.$vuetify.breakpoint.mobile ? "" : "5",
                            },
                          },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  top: "",
                                  "open-delay": "50",
                                  disabled: !_vm.$vuetify.breakpoint.mobile,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "div",
                                            _vm._g(
                                              _vm._b({}, "div", attrs, false),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "mr-1" },
                                                [
                                                  _vm._v(
                                                    "mdi-folder-download-outline"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                " ./" +
                                                  _vm._s(_vm.mountLocation) +
                                                  "/ "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  330485688
                                ),
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "./" + _vm._s(_vm.mountLocation) + "/"
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "v-alert",
                          {
                            staticClass:
                              "d-flex justify-center ma-0 pa-0 px-1 text-sm-body-2 text-caption",
                            attrs: {
                              width: "100%",
                              outlined: "",
                              type: "warning",
                              dense: "",
                              icon: "mdi-folder-download-outline",
                            },
                          },
                          [_c("strong", [_vm._v("Mount Location not found!")])]
                        ),
                      ],
                  _c("v-hover", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ hover }) {
                            return [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-fade-transition",
                                    [
                                      _c("TooltipButton", {
                                        attrs: {
                                          btnClass:
                                            "ml-1 pa-0 my-n3 mr-n2 transition-slow-in-slow-out",
                                          icon: true,
                                          color:
                                            hover || _vm.markedForDisconnect
                                              ? "danger"
                                              : "accent",
                                          tooltipText: _vm.markedForDisconnect
                                            ? _vm.disconnectTooltip.marked
                                            : _vm.disconnectTooltip.unmarked,
                                        },
                                        on: {
                                          click: _vm.toggleSourceDisconnectMark,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "btnContent",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          hover ||
                                                            _vm.markedForDisconnect
                                                            ? "mdi-database-remove-outline"
                                                            : "mdi-database-check-outline"
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2621795221
                    ),
                  }),
                ],
                2
              ),
            ],
          ]
        : _c("v-progress-linear", {
            attrs: { color: "accent lighten-2", stream: "", indeterminate: "" },
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }