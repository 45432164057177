<template>
	<v-dialog
		v-model="showDialog"
		:width="$vuetify.breakpoint.mobile ? '95%' : '500px'"
		@click:outside="closeDialog"
		@keydown.esc="closeDialog"
	>
		<v-card
			class="pb-1"
			data-test="sig-cloud-storage-create-directory-dialog"
		>
			<v-card-title class="py-3 px-4">Create Directory</v-card-title>
			<v-divider></v-divider>
			<v-card-text class="py-2 px-4">
				<v-form
					ref="mainForm"
					v-model="formValid"
				>
					<v-text-field
						placeholder="Directory Name"
						v-model="directoryName"
						class="pt-2 mb-n2"
						:rules="[rules.required, rules.noSpace, rules.maxSize, rules.noRestrictedCharacters]"
						autofocus
						outlined
						dense
						@keydown.enter.prevent="createDirectory"
						data-test="directory-name-input"
					></v-text-field>
				</v-form>
				<div
					class="text-center"
					v-if="formValid"
				>
					The new directory will be created at <br />
					<em>./{{ absolutePath }}</em>
				</div>

				<v-expand-transition>
					<v-alert
						v-show="showError"
						:icon="false"
						border="left"
						class="text-body-2 pa-2 pl-3 mb-0 mt-2"
						:type="alertType"
						outlined
						elevation="0"
					>
						{{ errorMessage }}
					</v-alert>
				</v-expand-transition>
			</v-card-text>
			<v-divider></v-divider>
			<v-card-actions>
				<v-row
					align-content="center"
					justify="end"
					no-gutters
				>
					<v-col cols="auto">
						<v-btn
							color="primary"
							@click="createDirectory"
							elevation="0"
							width="160px"
							:disabled="!formValid"
							:loading="uploadInProgress"
							data-test="create-directory-button"
						>
							Create Directory
						</v-btn>
					</v-col>
				</v-row>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import { putCreateDirectory } from "@/js/signaloidClient";
import * as dsUtil from "@/components/DataSources/utilities";
import { monitoringCaptureError } from "@/plugins/monitoring";

export default defineComponent({
	name: "SigCloudStorageCreateDirectoryDialog",
	props: {
		showDialog: {
			type: Boolean,
			default: false,
		},
		parentDir: {
			type: String,
			default: "",
		},
	},
	data: () => ({
		directoryName: "",
		formValid: false,
		uploadInProgress: false,
		alertType: "info",
		showError: false,
		errorMessage: "",
		uploadTaskStatus: "",
	}),
	computed: {
		absolutePath() {
			return this.parentDir ? `${this.parentDir}/${this.directoryName.trim()}` : `${this.directoryName.trim()}`;
		},
		rules() {
			return {
				required: (value) => !!value || "Required.",
				counter: (value) => value.length <= 50 || "Directory path length should be less than 50 characters",
				maxSize: (value) =>
					value.length < dsUtil.awsS3MaxPathSizeBytes ||
					`Directory path length should be less than ${dsUtil.awsS3MaxPathSizeBytes} Bytes`,
				noSpace: (value) => value.indexOf(" ") == -1 || "Directory path cannot have spaces",
				noRestrictedCharacters: (value) => {
					return (
						dsUtil.awsS3RestrictedCharacters.every((character) => {
							return value.indexOf(character) == -1;
						}) ||
						`Directory path cannot contain ${dsUtil.awsS3RestrictedCharacters.reduce((t, c, idx) => {
							return idx == dsUtil.awsS3RestrictedCharacters.length - 1
								? t + " , or " + c
								: t + " , " + c;
						})}`
					);
				},
			};
		},
	},
	methods: {
		containsS3RestrictedCharacters(value) {
			return this.awsS3RestrictedCharacters.every((character) => {
				return value.indexOf(character) == -1;
			});
		},
		closeDialog() {
			this.$emit("update:showDialog", false);
		},
		async createDirectory() {
			if (this.formValid) {
				this.uploadInProgress = true;
				try {
					const response = await putCreateDirectory(`${this.absolutePath}/`);
					this.$emit("upload-successful");
					this.closeDialog();
				} catch (error) {
					monitoringCaptureError(error, "Create directory in user cloud storage");
					this.uploadInProgress = false;

					if (error.response) {
						//non 2xx response
						this.errorMessage = error.response.data.error;
					} else if (error.request) {
						//no response
						this.errorMessage = "Please check your internet connection";
					} else {
						//making request failed
						this.errorMessage = error.message;
					}
					this.showError = true;
					this.alertType = "warning";
				}
			}
		},
	},
	mounted() {
		this.newValue = this.value;
	},
});
</script>

<style lang="scss" scoped></style>
