var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.slideData
    ? _c(
        "v-card",
        {
          staticClass: "carousel__slide",
          attrs: { outlined: "", rounded: "lg" },
        },
        [
          _c(
            "div",
            { staticClass: "carousel__slide__image-container" },
            [
              _vm.slideVideoURL
                ? [
                    _vm.showVideoDialog
                      ? _c("VideoDialog", {
                          attrs: {
                            showDialog: _vm.showVideoDialog,
                            videoURL: _vm.slideVideoURL.href,
                          },
                          on: {
                            "update:showDialog": function ($event) {
                              _vm.showVideoDialog = $event
                            },
                            "update:show-dialog": function ($event) {
                              _vm.showVideoDialog = $event
                            },
                          },
                        })
                      : _vm._e(),
                    _c(
                      "v-btn",
                      {
                        staticClass: "play-button",
                        attrs: { icon: "" },
                        on: {
                          click: function ($event) {
                            _vm.showVideoDialog = true
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { size: "4em" } }, [
                          _vm._v("mdi-play"),
                        ]),
                      ],
                      1
                    ),
                    _c("div", [
                      _c("img", {
                        staticClass: "carousel__image pb-0 mb-n2",
                        staticStyle: { cursor: "pointer" },
                        attrs: { width: "100%", src: _vm.slideImageURL },
                        on: {
                          click: function ($event) {
                            _vm.showVideoDialog = true
                          },
                        },
                      }),
                    ]),
                  ]
                : _vm.slideImageURL
                ? _c("img", {
                    staticClass: "carousel__image pb-0 mb-n2",
                    attrs: { src: _vm.slideImageURL },
                  })
                : _vm._e(),
            ],
            2
          ),
          _c(
            "v-card",
            {
              staticClass: "px-3 rounded-0 slide-footer",
              staticStyle: {
                "border-left": "none",
                "border-right": "none",
                "border-bottom": "none",
              },
              attrs: { flat: "", rounded: "b-lg", width: "100%" },
            },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { staticStyle: { display: "inline" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "slide-footer-grid-title text-md-h6 text-subtitle-2",
                      },
                      [_vm._v(" " + _vm._s(_vm.slideData.titleText) + " ")]
                    ),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _vm.slideTag
                        ? _c(
                            "v-chip",
                            {
                              attrs: { color: _vm.slideTag.colour, small: "" },
                            },
                            [_vm._v(" " + _vm._s(_vm.slideTag.text) + " ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "pt-1", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "text-caption text-md-body-2 slide-footer-grid-description",
                    },
                    [_vm._v(" " + _vm._s(_vm.slideData.descriptionText) + " ")]
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto", "align-self": "end" } },
                    [
                      _vm.slideData.type == "application"
                        ? [
                            _c(
                              "div",
                              [
                                _c("TooltipButton", {
                                  attrs: {
                                    color: "accent",
                                    icon: true,
                                    disabled: !_vm.slideData.applicationURL,
                                    to: _vm.applicationConnectObject,
                                    tooltipText: "Add to your account",
                                    btnIcon: "mdi-book-plus",
                                    btnClass: "float-right",
                                  },
                                }),
                                _c("TooltipButton", {
                                  attrs: {
                                    icon: true,
                                    disabled: !_vm.slideData.applicationURL,
                                    btnIcon: _vm.applicationHostIcon,
                                    btnClass: "float-right",
                                    target: "_blank",
                                    href: _vm.applicationURL?.href ?? "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "tooltipContent",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " Open application " +
                                                _vm._s(
                                                  _vm.applicationURL?.hostname
                                                    ? `on ${_vm.applicationURL.hostname}`
                                                    : ""
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  small: "",
                                                  color: "white",
                                                },
                                              },
                                              [_vm._v("mdi-open-in-new")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    3674119589
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm.slideData.actions
                        ? _vm._l(
                            _vm.slideData.actions.slice(0, 1),
                            function (action, i) {
                              return _c(
                                "div",
                                { key: i },
                                [
                                  _c("TooltipButton", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: action.btnText && action.href,
                                        expression:
                                          "action.btnText && action.href",
                                      },
                                    ],
                                    attrs: {
                                      target: "_blank",
                                      href: action.href,
                                      btnClass:
                                        "font-weight-medium text-body-2 float-right",
                                      color: "primary",
                                      width: "100%",
                                      small: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "btnContent",
                                          fn: function () {
                                            return [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    small: "",
                                                    color: "white",
                                                  },
                                                },
                                                [_vm._v(_vm._s(action.btnIcon))]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(action.btnText) +
                                                  " "
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        action.tooltipText
                                          ? {
                                              key: "tooltipContent",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        action.tooltipText
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            }
                                          : null,
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              )
                            }
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }