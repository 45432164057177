
import { defineComponent, PropType } from "vue";

// Libraries
import moment from "moment";
import axios from "axios";

// Utilities
import { monitoringCaptureError } from "@/plugins/monitoring";

// Stores
import { useRepositoriesStore } from "@/stores/repositories";
import { useUserStore } from "@/stores/user";

// Types
import { Endpoints } from "@octokit/types";
import { RemoteAccessState, RepoConnectionConfig } from "@/types/general";
import { UserLimitsE } from "@/js/tierconfig";

// Components
import LimitableActionButton from "@/components/Common/LimitableActionButton.vue";
import { signaloidApiClient } from "@/js/signaloidClient";

type ComponentData = {
	repoData: undefined | Endpoints["GET /user/repos"]["response"]["data"][0];
	connectedRepositoryID: undefined | string;
	repoDisconnectStatus: RemoteAccessState;
};

export default defineComponent({
	name: "AddRepositoryCard",
	components: { LimitableActionButton },
	props: {
		initialRepoData: { type: Object as PropType<undefined | Endpoints["GET /user/repos"]["response"]["data"][0]> },
		repoFullName: { type: String },
	},
	emits: {
		"repo-connection-request": (data: RepoConnectionConfig) => true,
		"repository-disconnected": (data: { repositoryID: string }) => true,
	},
	setup() {
		const repositoriesStore = useRepositoriesStore();
		const userStore = useUserStore();
		return { repositoriesStore, userStore, UserLimitsE };
	},
	data: (): ComponentData => ({
		repoData: undefined,
		connectedRepositoryID: undefined,
		repoDisconnectStatus: { loading: false, error: false, message: "" },
	}),
	methods: {
		formatDate(dateString) {
			return moment(dateString).format("DD MMM YYYY");
		},
		getRepoData(repoFullName) {
			return signaloidApiClient
				.get(`/proxy/github/repos/${repoFullName}`)
				.then((response) => {
					this.repoData = response.data;
				})
				.catch((error) => console.log(error));
		},
		requestRepoConnect() {
			if (this.repoData?.html_url) {
				this.$emit("repo-connection-request", { remoteURL: new URL(this.repoData.html_url) });
			}
		},
		async disconnectRepository() {
			if (this.alreadyConnected() && this.connectedRepositoryID) {
				this.repoDisconnectStatus.loading = true;
				try {
					await this.repositoriesStore.disconnectFromRepository(this.connectedRepositoryID);
					setTimeout(async () => {
						this.repoDisconnectStatus.loading = false;
					}, 1000);
				} catch (error) {
					monitoringCaptureError(error, "Disconnect repository");

					if (axios.isAxiosError(error)) {
						this.repoDisconnectStatus.error = true;
						this.repoDisconnectStatus.message =
							"An error occurred while disconnecting from the repository.";
					}
					this.repoDisconnectStatus.loading = false;
				}
				this.$emit("repository-disconnected", { repositoryID: this.connectedRepositoryID });
			} else {
				console.log("not connected");
			}
		},
		alreadyConnected() {
			let val: any = undefined;
			if (this.repositoriesStore.connectedRepositories) {
				val = this.repositoriesStore.connectedRepositories.find((currentValue, index, arr) => {
					return currentValue.RemoteURL == this.repoData?.html_url;
				});
			}
			if (val) {
				this.connectedRepositoryID = val.RepositoryID;
				return true;
			} else {
				return false;
			}
		},
	},
	created() {
		this.userStore.getCurrentUserTierDetails();
	},
	mounted() {
		//HACK: I dont know why this doesnt work when it is initially set in data()
		this.repoData = structuredClone(this.initialRepoData);

		if (!this.repoData && this.repoFullName) {
			this.getRepoData(this.repoFullName);
		}
	},
});
