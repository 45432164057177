
import { defineComponent } from "vue";
import CopyToClipboardButton from "@/components/Common/CopyToClipboardButton.vue";
import * as util from "@/js/utilities";

export default defineComponent({
	name: "BuildIDDisplay",
	components: {
		CopyToClipboardButton,
	},
	props: {
		buildID: { type: String },
		showClipboardButton: { type: Boolean, default: false },
	},
	computed: {
		formattedBuildID(): string {
			return this.buildID ? util.shortenID(this.buildID) : "";
		},
	},
});
