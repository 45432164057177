var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-list",
    _vm._g(
      _vm._b(
        { attrs: { dense: "", subheader: "" } },
        "v-list",
        { ..._vm.$attrs },
        false
      ),
      { ..._vm.$listeners }
    ),
    _vm._l(_vm.tierHighlightsToDisplay, function (point) {
      return _c(
        "v-list-item",
        { key: point.text, staticClass: "my-n3" },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "ml-2 mr-4" },
            [
              point.icon
                ? _c("v-icon", { attrs: { color: "success" } }, [
                    _vm._v(_vm._s(_vm.pointIcon(point.icon))),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c("v-list-item-content", {
            domProps: { innerHTML: _vm._s(point.text) },
          }),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }