var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "end-of-results-banner" },
    [
      _c("p", { staticClass: "footer-hline" }),
      _c("v-icon", { staticClass: "footer-icon" }, [
        _vm._v("mdi-flag-checkered"),
      ]),
      _c("p", { staticClass: "footer-hline" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }