
import { defineComponent, PropType } from "vue";

// Libraries

// Utilities

// Components

// Stores

// Global variables

// Types
type AlertTypes = "success" | "info" | "warning" | "error";

export default defineComponent({
	name: "AlertMessage",
	props: {
		showAlert: { type: Boolean, default: false },
		type: { type: String as PropType<AlertTypes | "danger"> },
	},
	data: (): { alertType: AlertTypes; alertOutlined: boolean; alertClasses: String } => ({
		alertType: "info",
		alertOutlined: false,
		alertClasses: "mt-5 text-body-2",
	}),
	beforeMount() {
		switch (this.type) {
			case "success":
				this.alertType = "success";
				break;
			case "info":
				this.alertType = "info";
				break;
			case "warning":
				this.alertType = "warning";
				this.alertOutlined = true;
				break;
			case "error":
				this.alertType = "error";
				this.alertClasses += " font-weight-medium";
				break;
			case "danger":
				this.alertOutlined = true;
				this.alertType = "error";
				break;
			default:
				this.alertType = "info";
				break;
		}

		// @ts-ignore
		this.alertOutlined = this.$attrs.outlined ?? this.alertOutlined;
	},
});
