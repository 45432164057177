var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", {
    ref: "editor",
    staticClass: "editor-placeholder-container",
    style: _vm.style,
    attrs: { id: "editor-placeholder-container" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }