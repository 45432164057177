var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.$vuetify.breakpoint.mobile ? "95%" : "500px" },
      on: {
        "click:outside": _vm.closeDialog,
        keydown: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            )
              return null
            return _vm.closeDialog.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.setArguments.apply(null, arguments)
          },
        ],
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pb-1" },
        [
          _c(
            "v-card-title",
            { staticClass: "py-3 px-4" },
            [
              _vm._v("Set command line arguments "),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "text--primary",
                  attrs: {
                    color: "white",
                    small: "",
                    outlined: "",
                    height: "36",
                    elevation: "0",
                  },
                  on: { click: _vm.closeDialog },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.setArguments.apply(null, arguments)
                },
              },
              model: {
                value: _vm.formIsValid,
                callback: function ($$v) {
                  _vm.formIsValid = $$v
                },
                expression: "formIsValid",
              },
            },
            [
              _c(
                "v-card-text",
                { staticClass: "py-2 px-4" },
                [
                  _c("v-textarea", {
                    staticClass: "pt-2 mb-n2 text-body-2",
                    attrs: {
                      "textarea-narrowLineHeight": "",
                      outlined: "",
                      dense: "",
                      counter: "",
                      rules: _vm.argumentRules,
                    },
                    model: {
                      value: _vm.runArguments,
                      callback: function ($$v) {
                        _vm.runArguments = $$v
                      },
                      expression: "runArguments",
                    },
                  }),
                  _c("div", { staticClass: "px-1" }, [
                    _vm._v(
                      " Our platform will pass the command line arguments specified above to your application when you run it. "
                    ),
                  ]),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        elevation: "0",
                        width: "140px",
                        type: "submit",
                        disabled: !_vm.formIsValid,
                      },
                    },
                    [_vm._v(" Set Arguments ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }