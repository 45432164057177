
import { defineComponent, PropType } from "vue";

// Components
import TooltipButton from "@/components/Common/TooltipButton.vue";
import CopyToClipboardButton from "@/components/Common/CopyToClipboardButton.vue";
// Stores
import { mapState } from "pinia";
import { useUserStore } from "@/stores/user";

// Global variables

export default defineComponent({
	name: "OutputFailedDialog",
	components: { TooltipButton, CopyToClipboardButton },
	props: {
		showDialog: { type: Boolean, default: false },
		showTaskDetail: { type: Boolean, default: false },
		taskID: { type: String, default: "" },
	},
	setup() {
		const userStore = useUserStore();
		return { userStore };
	},
	computed: {
		...mapState(useUserStore, {
			currentUserResourceUsage: "currentUserResourceUsage",
			currentUserTierDetails: "currentUserTierDetails",
			currentUserTierCode: "currentUserTierCode",
		}),
		sideImageURL() {
			const imagePath = "images/overviewVideoThumbnail_abstract.png";
			return new URL(imagePath, process.env.VUE_APP_ASSET_SERVER_URL).toString();
		},
		title(): string {
			return "Failed to Fetch Task Output";
		},
		description(): string {
			return `\n The Signaloid Cloud Developer Platform failed to retrieve the output for this task. \n Our team will receive a high priority notification of this incident.\n`;
		},
		extraDescription(): string {
			return this.showTaskDetail
				? `\n`
				: `\n The “Task Details” button at the bottom of this pop-up message will take you to the details of the task and will retry to get the output. \n`;
		},
		upgradeButtonStyling() {
			return { text: "Task Details", icon: "mdi-format-list-group" };
		},
	},
	async created() {
		await this.userStore.getCurrentUserTierDetails();
	},
	methods: {
		closeDialog() {
			this.$emit("update:showDialog", false);
		},
		goToTaskManagerPage() {
			this.closeDialog();
			this.$router.push({ path: `/tasks/${this.taskID}` });
		},
	},
});
