var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.rootStore.platformGlobalState === "Ready" ||
    _vm.rootStore.platformGlobalState === "OnboardingMode"
    ? _c(
        "v-app",
        [
          _vm.rootStore.platformGlobalState == "Ready"
            ? [
                _c(
                  "v-app-bar",
                  {
                    staticStyle: {
                      "border-bottom":
                        "1px solid rgba(0, 0, 0, 0.12) !important",
                    },
                    attrs: {
                      app: "",
                      id: "appbar",
                      color: "white",
                      height: _vm.$vuetify.breakpoint.smAndDown
                        ? "57px"
                        : "65px",
                      "elevate-on-scroll": "",
                    },
                  },
                  [
                    _vm.$vuetify.breakpoint.mobile
                      ? [
                          _c(
                            "v-col",
                            { staticClass: "px-0", attrs: { cols: "auto" } },
                            [
                              _c("v-img", {
                                attrs: {
                                  id: "logo",
                                  alt: "Platform Logo",
                                  contain: "",
                                  src: _vm.platformLogo,
                                  transition: "scale-transition",
                                  width: "35",
                                  "max-height": "35",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-app-bar-nav-icon", {
                            on: {
                              click: function ($event) {
                                _vm.drawer = !_vm.drawer
                              },
                            },
                          }),
                        ]
                      : _vm._e(),
                    _c("v-spacer"),
                    _c("TopNav", { on: { "sign-out": _vm.awsSignOut } }),
                  ],
                  2
                ),
                _c(
                  "v-navigation-drawer",
                  {
                    attrs: {
                      app: "",
                      width: _vm.$vuetify.breakpoint.mobile ? "220" : "230",
                      permanent: !_vm.$vuetify.breakpoint.mobile,
                      "mini-variant": _vm.$vuetify.breakpoint.sm,
                      id: "navbar",
                      color: "grey lighten-5",
                    },
                    model: {
                      value: _vm.drawer,
                      callback: function ($$v) {
                        _vm.drawer = $$v
                      },
                      expression: "drawer",
                    },
                  },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-sheet",
                          {
                            staticClass: "d-flex align-center justify-start",
                            class: _vm.$vuetify.breakpoint.mobile ? "py-2" : "",
                            attrs: {
                              color: "rgba(0,0,0,0)",
                              height: _vm.$vuetify.breakpoint.sm ? "32" : "40",
                            },
                          },
                          [
                            _c("v-img", {
                              staticClass: "mx-md-2",
                              attrs: {
                                id: "logo",
                                alt: "Platform Logo",
                                src: _vm.platformLogo,
                                contain: "",
                                position: "center start",
                                transition: "scale-transition",
                                width: _vm.$vuetify.breakpoint.sm ? "32" : "",
                                "max-height": _vm.$vuetify.breakpoint.sm
                                  ? "32"
                                  : "40",
                                "max-width": "150px",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.drawer = !_vm.drawer
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-divider"),
                    _c(
                      "v-list",
                      { staticClass: "pt-4", attrs: { nav: "", dense: "" } },
                      [
                        _c(
                          "v-list-item-group",
                          {
                            attrs: {
                              value: _vm.drawerActiveItem,
                              mandatory: "",
                            },
                          },
                          _vm._l(_vm.drawerItems, function (item, i) {
                            return _c(
                              "router-link",
                              {
                                key: i,
                                attrs: {
                                  to: item.to,
                                  disabled: item.disabled,
                                  "data-test": `navbar-item-${item.text.toLowerCase()}`,
                                },
                              },
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    staticClass: "mx-0 mx-md-2",
                                    attrs: {
                                      disabled: item.disabled,
                                      "active-class": "primary--text",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      {
                                        staticClass: "mr-2",
                                        staticStyle: {
                                          "justify-content": "center",
                                        },
                                      },
                                      [
                                        _c("v-icon", {
                                          staticClass: "text-h6",
                                          attrs: { disabled: item.disabled },
                                          domProps: {
                                            textContent: _vm._s(item.icon),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", {
                                          staticClass:
                                            "font-weight-medium text-body-2",
                                          domProps: {
                                            textContent: _vm._s(item.text),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "v-main",
            { staticClass: "mt-2 mt-lg-3 mx-lg-4" },
            [_c("router-view", { on: { "sign-out": _vm.awsSignOut } })],
            1
          ),
          _c("TierUpgradeHintDialog", {
            attrs: {
              triggeredLimit: _vm.exceededTierLimitsForHintDialog[0],
              "show-dialog": _vm.showLimitHintDialog,
            },
            on: {
              "update:showDialog": function ($event) {
                _vm.showLimitHintDialog = $event
              },
              "update:show-dialog": function ($event) {
                _vm.showLimitHintDialog = $event
              },
            },
          }),
          _c("HelpButton"),
        ],
        2
      )
    : _c(
        "v-app",
        [
          _c(
            "v-overlay",
            { attrs: { opacity: "1", value: "overlay", color: "white" } },
            [
              _vm.fatalErrorInitialising
                ? [
                    _c(
                      "v-card",
                      {
                        attrs: {
                          width: _vm.$vuetify.breakpoint.mobile
                            ? "95%"
                            : "500px",
                          color: "white",
                        },
                      },
                      [
                        _c("v-alert", { attrs: { type: "error" } }, [
                          _vm._v(
                            " An error occurred while loading your account details. Please refresh the page and try again. " +
                              _vm._s(_vm.util.ContactSignaloidSupportMessage) +
                              " "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                : _c("v-progress-circular", {
                    attrs: { color: "accent", indeterminate: "", size: "64" },
                  }),
            ],
            2
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }