var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.$vuetify.breakpoint.mobile ? "98%" : "500px" },
      on: {
        "click:outside": _vm.closeDialog,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeDialog.apply(null, arguments)
        },
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "pb-1",
          attrs: { "data-test": "data-source-list-dialog" },
        },
        [
          _c(
            "v-card-title",
            { staticClass: "py-3 px-4" },
            [
              _vm._v("Data Sources "),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "text--primary",
                  attrs: {
                    color: "white",
                    small: "",
                    outlined: "",
                    height: "36",
                    elevation: "0",
                  },
                  on: { click: _vm.closeDialog },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "px-2 py-0 px-sm-4 py-sm-1" },
            [
              _vm.loadingSources
                ? _c("LoadingCard", {
                    attrs: { loadingText: "Loading Data Sources" },
                  })
                : [
                    _vm.dataSourceList.length > 0
                      ? _vm._l(_vm.dataSourceList, function (dataSource) {
                          return _c("DataSourcePickerCard", {
                            key: dataSource.ResourceID,
                            staticClass: "my-2",
                            attrs: {
                              dataSourceID: dataSource.ResourceID,
                              dataSourceType: dataSource.ResourceType,
                            },
                            on: { "set-data-source": _vm.setDataSource },
                          })
                        })
                      : [
                          _c(
                            "v-alert",
                            {
                              staticClass: "my-2 text-justify text-body-2",
                              attrs: {
                                dense: "",
                                outlined: "",
                                type: "warning",
                                border: "left",
                                icon: "mdi-cable-data",
                              },
                            },
                            [
                              _vm._v(
                                " No Data sources or Data Drives found! Please use the "
                              ),
                              _c(
                                "em",
                                [
                                  _c(
                                    "router-link",
                                    { attrs: { to: { name: "DataSources" } } },
                                    [_vm._v("Data and Sensors")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(
                                " page to connect to data sources and create Data Drives. "
                              ),
                            ]
                          ),
                        ],
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }