var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.$vuetify.breakpoint.mobile ? "95%" : "500px" },
      on: {
        "click:outside": _vm.closeDialog,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeDialog.apply(null, arguments)
        },
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pb-0" },
        [
          _c(
            "v-card-title",
            { staticClass: "py-3 px-4" },
            [
              _vm._v(_vm._s(_vm.dialogTitle) + " "),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "text--primary",
                  attrs: {
                    color: "white",
                    small: "",
                    outlined: "",
                    height: "36",
                    elevation: "0",
                  },
                  on: { click: _vm.closeDialog },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "py-3 px-4" },
            [
              _c(
                "v-form",
                {
                  ref: "driveConfigForm",
                  staticClass: "pt-5 px-4 px-sm-6",
                  model: {
                    value: _vm.formValid,
                    callback: function ($$v) {
                      _vm.formValid = $$v
                    },
                    expression: "formValid",
                  },
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mb-0 mx-2 mx-sm-5",
                      attrs: { align: "center" },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          maxlength: "100",
                          rules: [_vm.rules.required],
                          label: "Data Drive Name",
                          dense: "",
                          "data-test": "data-drive-name-input",
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.preventDefault()
                            return _vm.saveDriveConfig.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.driveConfig.Name,
                          callback: function ($$v) {
                            _vm.$set(_vm.driveConfig, "Name", $$v)
                          },
                          expression: "driveConfig.Name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider", { staticClass: "mb-3" }),
              _vm._l(_vm.driveConfig.DataSources, function (dataSource) {
                return _c("DataSourceInlineCard", {
                  key: dataSource.ResourceID,
                  staticClass: "mt-1",
                  attrs: {
                    dataSourceID: dataSource.ResourceID,
                    dataSourceType: dataSource.ResourceType,
                    mountLocation: dataSource.Location,
                  },
                  on: {
                    "mark-source-for-disconnect": _vm.addSourceToDisconnectList,
                    "reset-source-disconnect-mark":
                      _vm.removeSourceFromDisconnectList,
                  },
                })
              }),
              _c("SourcePlaceholderCard", {
                staticClass: "mt-2",
                on: { "card-clicked": _vm.openMountDataSourcesDialog },
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("v-icon", { attrs: { size: "19" } }, [
                          _vm._v("mdi-plus"),
                        ]),
                        _c("v-icon", [_vm._v("mdi-tray-arrow-down")]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "buttonText",
                    fn: function () {
                      return [_vm._v("New Data Source Mount Point")]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            2
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _vm.alert.show
                ? _c(
                    "v-alert",
                    {
                      staticClass: "my-0 text-body-2",
                      attrs: {
                        dense: "",
                        outlined: "",
                        type: _vm.alert.type,
                        border: "left",
                        width: "70%",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.alert.text) + " ")]
                  )
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    loading: _vm.savingDriveConfig,
                    elevation: "0",
                    width: "100px",
                    disabled: !_vm.formValid,
                  },
                  on: { click: _vm.saveDriveConfig },
                },
                [_vm._v(" Save ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showMountDataSourcesDialog
        ? _c("MountDataSourcesDialog", {
            attrs: {
              showDialog: _vm.showMountDataSourcesDialog,
              initialMountConfig: _vm.mountConfigToUpdate,
              dataSourceExcludeList: [
                ..._vm.connectedDataSources,
                ..._vm.driveIDList,
              ],
            },
            on: {
              "close-dialog": _vm.closeMountDataSourcesDialog,
              "set-data-sources": _vm.addDataSource,
              "reset-data-sources": _vm.closeMountDataSourcesDialog,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }