var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "px-1 px-sm-2", attrs: { flat: "" } },
    [
      _c(
        "v-card-title",
        {
          staticClass: "pb-3 pt-1 pt-sm-4 px-1 px-sm-4",
          staticStyle: { "min-height": "64px" },
        },
        [
          _c("div", { staticClass: "text-h6 font-weight-bold" }, [
            _vm._v("Data and Sensors"),
          ]),
          _c("v-spacer"),
        ],
        1
      ),
      _c("v-divider", { staticClass: "mb-2 mb-sm-3 mx-1 mx-sm-4" }),
      _c(
        "v-container",
        {
          staticClass: "px-1 px-sm-4 py-sm-2 py-1 mx-auto",
          attrs: { fluid: "" },
        },
        [
          _c(
            "div",
            { staticClass: "text-h7 pt-1 font-weight-bold" },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "", "max-width": "500px" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "div",
                                _vm._g(_vm._b({}, "div", attrs, false), on),
                                [
                                  _vm._v(" Data Drives "),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("mdi-information-outline")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _vm._v(
                        " Data Drives are collections of data sources with associated mount points. They allow you to mount multiple data sources for the same execution so that applications running on Signaloid Cloud can access their data. "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "px-0 pt-1 pb-2" },
            [
              _vm.loadingDrives
                ? _c("LoadingCard", {
                    attrs: { loadingText: "Loading Data Drive Configurations" },
                  })
                : [
                    _c(
                      "div",
                      { staticClass: "card-grid" },
                      [
                        _vm._l(_vm.driveList, function (driveID) {
                          return _c("DriveCard", {
                            key: driveID,
                            attrs: { driveID: driveID },
                            on: {
                              "drive-disconnected":
                                _vm.driveDisconnectionHandler,
                            },
                          })
                        }),
                        _c("LimitableActionValidator", {
                          attrs: {
                            "limits-to-check": [_vm.UserLimitsE.DataDriveCount],
                          },
                          on: { "valid-click": _vm.addDrive },
                          scopedSlots: _vm._u([
                            {
                              key: "clickTarget",
                              fn: function () {
                                return [
                                  _c("SourcePlaceholderCard", {
                                    attrs: {
                                      "min-height": "80px",
                                      height: "100%",
                                      "data-test": "data-drive-add-button",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "icon",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "19" } },
                                              [_vm._v("mdi-plus")]
                                            ),
                                            _c("v-icon", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.dataSourceIcon("Drive")
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "buttonText",
                                        fn: function () {
                                          return [_vm._v("New Data Drive")]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                      ],
                      2
                    ),
                  ],
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "text-h7 pt-1 font-weight-bold" },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "", "max-width": "500px" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "div",
                                _vm._g(_vm._b({}, "div", attrs, false), on),
                                [
                                  _vm._v(" Connected Sensor Gateways "),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("mdi-information-outline")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("em", [_vm._v("Signaloid Sensor Gateways")]),
                      _vm._v(
                        " connect to different sensor modules and stream data from them to Signaloid Cloud. "
                      ),
                      _c("em", [_vm._v("Signaloid Sensor Gateways")]),
                      _vm._v(
                        " connected to your account will appear here once you set them up. Please refer to the initial configuration instructions you received with the gateway. "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "px-0 pt-1 pb-2" },
            [
              _vm.loadingGateways
                ? _c("LoadingCard", {
                    attrs: { loadingText: "Loading Sensor Gateways" },
                  })
                : [
                    _c(
                      "div",
                      { staticClass: "card-grid" },
                      [
                        _vm.gatewayList.length > 0
                          ? _vm._l(_vm.gatewayList, function (gateway) {
                              return _c("GatewayCard", {
                                key: gateway,
                                attrs: { gatewayID: gateway },
                              })
                            })
                          : [
                              _c("SourcePlaceholderCard", {
                                attrs: { "min-height": "80px", disabled: true },
                                scopedSlots: _vm._u([
                                  {
                                    key: "icon",
                                    fn: function () {
                                      return [
                                        _c("v-icon", { staticClass: "ml-5" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataSourceIcon("Gateway")
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "buttonText",
                                    fn: function () {
                                      return [
                                        _vm._v("No Sensor Gateways available"),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                      ],
                      2
                    ),
                  ],
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "text-h7 pt-1 font-weight-bold" },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "", "max-width": "500px" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "div",
                                _vm._g(_vm._b({}, "div", attrs, false), on),
                                [
                                  _vm._v(" Data Sources "),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("mdi-information-outline")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _vm._v(
                        " Adding a data source will allow your applications to have access to persistent storage to read and write from. "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "px-0 pt-1 pb-2" },
            [
              _vm.loadingBuckets
                ? _c("LoadingCard", {
                    attrs: { loadingText: "Loading Data Sources" },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "card-grid" },
                [
                  _c("SigCloudStorageCard"),
                  _vm._l(_vm.dataSourceList, function (dataSourceID) {
                    return _c("BucketCard", {
                      key: dataSourceID,
                      attrs: { bucketID: dataSourceID },
                      on: {
                        "bucket-disconnected": _vm.bucketDisconnectionHandler,
                      },
                    })
                  }),
                  _c("SourcePlaceholderCard", {
                    attrs: { disabled: _vm.dataSourceLimitReached },
                    on: { "card-clicked": _vm.addDataSource },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "icon",
                          fn: function () {
                            return [
                              _c("v-icon", { attrs: { size: "19" } }, [
                                _vm._v("mdi-plus"),
                              ]),
                              _c("v-icon", [
                                _vm._v(
                                  _vm._s(
                                    _vm.dataSourceIcon(
                                      _vm.DataSourceTypeE.DataSource
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                        !_vm.dataSourceLimitReached
                          ? {
                              key: "buttonText",
                              fn: function () {
                                return [_vm._v("New Data Source")]
                              },
                              proxy: true,
                            }
                          : {
                              key: "buttonText",
                              fn: function () {
                                return [_vm._v("Data Source limit reached")]
                              },
                              proxy: true,
                            },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
      _c("DataSourceConfigDialog", {
        attrs: { showDialog: _vm.showDataSourceConfigDialog },
        on: {
          "update:showDialog": function ($event) {
            _vm.showDataSourceConfigDialog = $event
          },
          "update:show-dialog": function ($event) {
            _vm.showDataSourceConfigDialog = $event
          },
          "bucket-connected": _vm.bucketConnectionHandler,
        },
      }),
      _vm.showDriveConfigDialog
        ? _c("DriveConfigDialog", {
            attrs: { showDialog: _vm.showDriveConfigDialog },
            on: {
              "update:showDialog": function ($event) {
                _vm.showDriveConfigDialog = $event
              },
              "update:show-dialog": function ($event) {
                _vm.showDriveConfigDialog = $event
              },
              "drive-config-updated": _vm.fetchDriveList,
              "drive-created": _vm.driveCreationHandler,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }