
export function isToday(date: Date): boolean {
    const today = new Date();

    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    );
}

export function isThisYear(date: Date): boolean {
    const today = new Date();

    return date.getFullYear() === today.getFullYear();
}

export function withinNextSevenDays(date: Date): boolean {
    const today = new Date();
    return true
}

export function isFutureDateString(val: string): boolean {
    return Date.parse(val) >= Date.now();
}

export function formatTime(date: Date): string {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
}

export function formatDateWithoutYear(date: Date): string {
    return date.toLocaleDateString("default", {
        day: "numeric",
        month: "short",
    });
}

export function formatDate(date: Date): string {
    return date.toLocaleDateString("default", {
        day: "numeric",
        month: "short",
        year: "numeric",
    });
}

export function formatValidUntil(ts: number | null): string {
    if (ts === null) {
        return "Forever";
    }
    const validUntilDate = new Date(ts * 1000);
    if (isToday(validUntilDate)) {
        return "Today"; // formatTime(validUntilDate);
    } else if (isThisYear(validUntilDate)) {
        return formatDateWithoutYear(validUntilDate);
    }
    return formatDate(validUntilDate);
}

export function formatCreatedAt(ts: number): string {
    const createdAtDate = new Date(ts * 1000);
    if (isToday(createdAtDate)) {
        return "Today, " + formatTime(createdAtDate);
    } else if (isThisYear(createdAtDate)) {
        return formatDateWithoutYear(createdAtDate);
    }
    return formatDate(createdAtDate);
}

export function toISODateString(date: Date) {
    return date.toISOString().substring(0, 10);
}
