var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      staticClass: "pa-1 pa-sm-3",
      attrs: {
        rounded: "lg",
        width: "100%",
        outlined: "",
        "data-test": "data-source-picker-card",
      },
    },
    [
      _vm.dataSourceData
        ? [
            _c(
              "v-row",
              { attrs: { "no-gutters": "" } },
              [
                _c(
                  "v-col",
                  {
                    attrs: {
                      "align-self": "baseline",
                      "data-test": "data-source-picker-card-title",
                    },
                  },
                  [
                    _c("v-icon", { staticClass: "mr-1" }, [
                      _vm._v(_vm._s(_vm.dataSourceIcon(_vm.dataSourceType))),
                    ]),
                    _vm._v(" " + _vm._s(_vm.dataSourceData.Name) + " "),
                  ],
                  1
                ),
                _vm.dataSourceType == "Gateway"
                  ? _c(
                      "v-col",
                      {
                        staticClass: "mx-2",
                        attrs: { cols: "auto", "align-self": "start" },
                      },
                      [
                        _vm.gatewayStatusLoading
                          ? [
                              _c("v-progress-circular", {
                                staticClass: "mb-1",
                                attrs: {
                                  indeterminate: "",
                                  color: "primary",
                                  size: 20,
                                  width: 2,
                                },
                              }),
                            ]
                          : [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "", "open-delay": "500" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "mb-1 mt-0 pt-0",
                                                    attrs: {
                                                      outlined: "",
                                                      size: "20",
                                                      color:
                                                        _vm.gatewayStatusIcon
                                                          .colour,
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.gatewayStatusIcon.icon
                                                    )
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1146934220
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.gatewayStatus) + " "),
                                    _c("br"),
                                    _vm._v(
                                      " Last active: " +
                                        _vm._s(_vm.gatewayLastUpdatedString)
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "", "open-delay": "500" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "div",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "text-body-2",
                                              staticStyle: {
                                                display: "inline",
                                              },
                                            },
                                            "div",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.gatewayIPAddress) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3958817312
                            ),
                          },
                          [_c("span", [_vm._v("Gateway Local IP address")])]
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-row",
              {
                staticClass: "text-body-2 mt-1 ml-1",
                attrs: { "no-gutters": "", align: "end" },
              },
              [
                _c("v-col", { staticClass: "ml-2" }, [
                  _vm.dataSourceType == "Bucket"
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { small: "" } },
                            [_vm._v("mdi-shield-account-outline")]
                          ),
                          _vm._v("Permissions: "),
                          _c(
                            "v-icon",
                            {
                              staticClass: "ml-1 mr-1",
                              attrs: {
                                small: "",
                                color: _vm.dataSourceData.Read
                                  ? "accent"
                                  : "warning",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.dataSourceData.Read
                                    ? "mdi-check"
                                    : "mdi-cancel"
                                )
                              ),
                            ]
                          ),
                          _vm._v("Read "),
                          _c(
                            "v-icon",
                            {
                              staticClass: "ml-2 mr-1",
                              attrs: {
                                small: "",
                                color: _vm.dataSourceData.Write
                                  ? "accent"
                                  : "warning",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.dataSourceData.Write
                                    ? "mdi-check"
                                    : "mdi-cancel"
                                )
                              ),
                            ]
                          ),
                          _vm._v("Write "),
                        ],
                        1
                      )
                    : _vm.dataSourceType == "Drive"
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { small: "" } },
                            [_vm._v("mdi-tray-arrow-down")]
                          ),
                          _vm.connectedSourceCount > 0
                            ? [
                                _vm._v(" Source Mount Points: "),
                                _vm.gatewayCount != 0
                                  ? [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "ml-1",
                                          attrs: { small: "" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataSourceIcon("Gateway")
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.gatewayCount) +
                                          " " +
                                          _vm._s(
                                            _vm.gatewayCount == 1
                                              ? "Gateway"
                                              : "Gateways"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm.bucketCount != 0
                                  ? [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "ml-1",
                                          attrs: { small: "" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.dataSourceIcon("Bucket"))
                                          ),
                                        ]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.bucketCount) +
                                          " " +
                                          _vm._s(
                                            _vm.bucketCount == 1
                                              ? "S3 Bucket"
                                              : "S3 Buckets"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  : _vm._e(),
                              ]
                            : [_vm._v("No Source Mount Points Set!")],
                        ],
                        2
                      )
                    : _vm.dataSourceType == "SignaloidCloudStorage"
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pr-2",
                                  attrs: { cols: "auto" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("mdi-chart-donut")]
                                  ),
                                  _vm._v("Usage: "),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "px-0", attrs: { cols: "2" } },
                                [
                                  _c("v-progress-linear", {
                                    attrs: {
                                      rounded: "",
                                      height: "6",
                                      color: _vm.usageBarColour,
                                      value: _vm.usagePercentage,
                                    },
                                  }),
                                ],
                                1
                              ),
                              !_vm.signaloidCloudStorageLoading
                                ? _c("v-col", { staticClass: "text-caption" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatUsageString(
                                          _vm.dataSourceData.storageUsage
                                        )
                                      ) +
                                        " of " +
                                        _vm._s(
                                          _vm.formatUsageString(
                                            _vm.dataSourceData.storageAllowance
                                          )
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dataSourceType != "SignaloidCloudStorage"
                    ? _c(
                        "div",
                        { staticClass: "mr-3" },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { small: "" } },
                            [_vm._v("mdi-update")]
                          ),
                          _vm._v(
                            "Last Updated: " +
                              _vm._s(
                                _vm.formatDateDayString(
                                  _vm.dataSourceData.UpdatedAt
                                )
                              ) +
                              " "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c("v-hover", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ hover }) {
                          return [
                            _c(
                              "v-col",
                              { attrs: { cols: "auto" } },
                              [
                                _c(
                                  "v-fade-transition",
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          bottom: "",
                                          "open-delay": "250",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "transition-slow-in-slow-out",
                                                          attrs: {
                                                            elevation: "0",
                                                            width: "50px",
                                                            color:
                                                              _vm.setAsSource &&
                                                              hover
                                                                ? "danger"
                                                                : "accent",
                                                            "data-test":
                                                              "set-data-source-button",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.setSourceBtnHandler,
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.setSourceBtnIcon(
                                                              _vm.setAsSource,
                                                              hover
                                                            ).icon
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.setAsSource
                                                ? "Unmount Data Source"
                                                : "Mount Data Source"
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3542689544
                  ),
                }),
              ],
              1
            ),
          ]
        : _c("v-skeleton-loader", { attrs: { type: "list-item-avatar" } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }