var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "px-1 px-sm-2", attrs: { flat: "" } },
    [
      _c("v-breadcrumbs", {
        staticClass: "pa-1 pl-2 ma-0",
        attrs: { items: _vm.breadcrumbs },
      }),
      _c(
        "v-container",
        {
          staticClass: "px-sm-4 px-1 py-1 py-sm-2 mx-auto",
          attrs: { fluid: true },
        },
        [
          _vm.taskID
            ? _c("TaskCard", {
                attrs: {
                  taskData: _vm.taskData ?? undefined,
                  buildData: _vm.buildData ?? undefined,
                  taskID: _vm.taskID,
                  "show-delete-task-button": true,
                },
                on: { removeTaskSuccess: _vm.getBackToTaskList },
              })
            : _vm._e(),
          _c(
            "v-sheet",
            { staticClass: "mt-2", attrs: { width: "100%", height: "70vh" } },
            [
              _c("Panel", {
                attrs: {
                  taskID: _vm.taskID,
                  showCopyButton: false,
                  showClearButton: false,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }