
import CopyToClipboardButton from "@/components/Common/CopyToClipboardButton.vue";

import { defineComponent } from "vue";
// import UpdatePassword from "@/components/UpdatePassword.vue";
// import TooltipButton from "@/components/Common/TooltipButton.vue";
import { useUserStore } from "@/stores/user";
import { AWSAuthenticatedUserObject } from "@/types/user";

type ComponentData = {
	avatarRadius: string;
	editingUserSettings: boolean;
	editButtonLoading: boolean;
	saveButtonLoading: boolean;
	changePasswordDialog: boolean;
	authenticatedUser: undefined | null | AWSAuthenticatedUserObject;
};

export default defineComponent({
	name: "SettingsProfile",
	props: {},
	components: {
		CopyToClipboardButton,
	},
	data: (): ComponentData => ({
		avatarRadius: "10rem",
		editingUserSettings: false,
		editButtonLoading: false,
		saveButtonLoading: false,
		changePasswordDialog: false,
		authenticatedUser: undefined,
	}),
	setup() {
		const userStore = useUserStore();
		return { userStore };
	},
	methods: {
		requestChangePassword() {
			this.changePasswordDialog = true;
		},
		handleUpdatePassword(newValue: string) {
			switch (newValue) {
				case "cancel":
					this.changePasswordDialog = false;
					break;

				default:
					break;
			}
		},
	},
	async created() {
		this.authenticatedUser = await this.userStore.getCurrentAuthenticatedUserObject();
	},
});
