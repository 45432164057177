var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("LimitableActionValidator", {
    attrs: {
      "limits-to-check": _vm.limitsToCheck,
      "validation-threshold-overrides": _vm.validationThresholdOverrides,
      "resource-usage-overrides": _vm.resourceUsageOverrides,
      "error-on-undefined-usage": _vm.errorOnUndefinedUsage,
    },
    on: {
      "valid-click": _vm.validClickHandler,
      "invalid-click": _vm.invalidClickHandler,
    },
    scopedSlots: _vm._u([
      {
        key: "clickTarget",
        fn: function () {
          return [
            _c(
              "TooltipButton",
              _vm._g(
                _vm._b(
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "btnContent",
                          fn: function () {
                            return [_vm._t("btnContent")]
                          },
                          proxy: true,
                        },
                        {
                          key: "tooltipContent",
                          fn: function () {
                            return [_vm._t("tooltipContent")]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  "TooltipButton",
                  { ..._vm.$attrs },
                  false
                ),
                { ..._vm.$listeners }
              )
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }