
import { defineComponent } from "vue";

export default defineComponent({
	name: "GithubAuthRedirectDialog",
	props: {
		dialogVisible: { type: Boolean, default: false },
		dialogText: { type: String, default: "Authenticating with GitHub..." },
	},
});
