import { convertLocalCoreObjectToRemoteFormat } from "@/js/utilities";
import { defineStore } from "pinia";

export const usePipelinesStore = defineStore("pipelines", {
	state: () => ({ pipelineList: [] }),
	getters: {
		getPipelineByPipelineID: (state) => (pipelineID) => {
			return state.pipelineList.find((x) => x.pipelineID === pipelineID);
		},
	},
	actions: {
		setPipelineList(pipelineList) {
			this.pipelineList = pipelineList;
			localStorage.setItem("pipeline-list", JSON.stringify(this.pipelineList));
		},
		addToPipelineList(pipeline) {
			// assert non existence
			if (!this.pipelineList.find((x) => x.pipelineID === pipeline.pipelineID)) {
				this.pipelineList.push(pipeline);
				localStorage.setItem("pipeline-list", JSON.stringify(this.pipelineList));
			} else {
				console.warn("Already connected to " + pipeline.pipelineID);
			}
		},
		removeFromPipelineList(pipeline) {
			this.pipelineList.splice(
				this.pipelineList.findIndex((x) => x.pipelineID === pipeline.pipelineID),
				1
			);
			localStorage.setItem("pipeline-list", JSON.stringify(this.pipelineList));
		},
		updatePipelineList(pipeline) {
			pipeline.updated_at = new Date().toISOString();
			this.pipelineList.splice(
				this.pipelineList.findIndex((x) => x.pipelineID === pipeline.pipelineID),
				1,
				pipeline
			);
			localStorage.setItem("pipeline-list", JSON.stringify(this.pipelineList));
		},
		updatePipelineListSilent(pipeline) {
			this.pipelineList.splice(
				this.pipelineList.findIndex((x) => x.pipelineID === pipeline.pipelineID),
				1,
				pipeline
			);
			localStorage.setItem("pipeline-list", JSON.stringify(this.pipelineList));
		},

		initialisePipelinesFromLocalStorage() {
			let pipelineList;
			try {
				pipelineList = JSON.parse(localStorage.getItem("pipeline-list"));
				pipelineList = pipelineList ?? require("@/assets/_pipelineList.json");
				pipelineList = pipelineList.filter((r) => {
					return r?.pipelineID;
				});

				pipelineList.map((pipelineConfig) => {
					pipelineConfig.nodes = pipelineConfig.nodes.map((node) => {
						node.config.executionCore = convertLocalCoreObjectToRemoteFormat(node.config.executionCore);
						return node;
					});
					return pipelineConfig;
				});
			} catch {
				console.log("FAILED INITIALIZATION");
				pipelineList = require("@/assets/_pipelineList.json");
			}
			this.setPipelineList(pipelineList);
		},
	},
});
