
import { defineComponent } from "vue";

// Types
import { DataSourceType, DataSourceTypeE } from "@/types/api/dataSources";

// Libraries

// Utilities
import { getUserThings } from "@/js/signaloidClient";
import * as dsUtil from "@/components/DataSources/utilities";
import { monitoringCaptureError } from "@/plugins/monitoring";

// Components
import GatewayCard from "@/components/DataSources/GatewayCard.vue";
import BucketCard from "@/components/DataSources/BucketCard.vue";
import DriveCard from "@/components/DataSources/DriveCard.vue";
import DataSourceConfigDialog from "@/components/DataSources/DataSourceConfigDialog.vue";
import DriveConfigDialog from "@/components/DataSources/DriveConfigDialog.vue";
import LoadingCard from "@/components/LoadingCard.vue";
import SigCloudStorageCard from "@/components/DataSources/SigCloudStorageCard.vue";
import SourcePlaceholderCard from "@/components/DataSources/SourcePlaceholderCard.vue";
import LimitableActionValidator from "@/components/Common/LimitableActionValidator.vue";

// Stores
import { mapState } from "pinia";
import { useDataSourcesStore } from "@/stores/dataSources";
import { useUserStore } from "@/stores/user";
import { UserLimitsE } from "@/js/tierconfig";
import { TierLimitEventTypeE } from "@/eventBus/tierLimitEventBus";
import { useRootStore } from "@/stores/root";

// Global variables

type ComponentData = {
	loadingGateways: boolean;
	loadingDrives: boolean;
	loadingBuckets: boolean;
	showDataSourceConfigDialog: boolean;
	showDriveConfigDialog: boolean;
};

export default defineComponent({
	name: "DataSources",
	components: {
		GatewayCard,
		BucketCard,
		SigCloudStorageCard,
		LoadingCard,
		DataSourceConfigDialog,
		DriveCard,
		DriveConfigDialog,
		SourcePlaceholderCard,
		LimitableActionValidator,
	},
	data: (): ComponentData => ({
		loadingGateways: false,
		loadingDrives: false,
		loadingBuckets: false,
		showDataSourceConfigDialog: false,
		showDriveConfigDialog: false,
	}),
	setup() {
		// Note: gets called after beforeCreate in vue2
		const userStore = useUserStore();
		const dataSourcesStore = useDataSourcesStore();
		const rootStore = useRootStore();
		return { dataSourcesStore, DataSourceTypeE, userStore, rootStore, UserLimitsE };
	},
	methods: {
		addDataSource() {
			this.showDataSourceConfigDialog = true;
		},
		addDrive() {
			this.showDriveConfigDialog = true;
		},
		async fetchGatewayList() {
			this.loadingGateways = true;
			try {
				const resp = await getUserThings();
				this.gatewayList = resp.data.thing_ids;
				this.dataSourcesStore.setGatewayList(this.gatewayList);
			} catch (error) {
				monitoringCaptureError(error, "Fetch gateway list");
			} finally {
				this.loadingGateways = false;
			}
		},
		// Handle drive events
		async driveCreationHandler() {
			this.rootStore.tierLimitEventBus.emit({
				type: TierLimitEventTypeE.UsageChanged,
				affectedLimits: [UserLimitsE.DataDriveCount],
			});
			this.fetchDriveList();
		},
		async driveDisconnectionHandler() {
			this.rootStore.tierLimitEventBus.emit({
				type: TierLimitEventTypeE.UsageChanged,
				affectedLimits: [UserLimitsE.DataDriveCount],
			});
			this.fetchDriveList();
		},
		async fetchDriveList() {
			this.loadingDrives = true;
			await this.dataSourcesStore.fetchDriveList();
			this.loadingDrives = false;
		},
		// Handle bucket events
		async bucketDisconnectionHandler() {
			this.rootStore.tierLimitEventBus.emit({
				type: TierLimitEventTypeE.UsageChanged,
				affectedLimits: [UserLimitsE.BucketCount],
			});
			this.fetchBucketList();
		},
		async bucketConnectionHandler() {
			this.rootStore.tierLimitEventBus.emit({
				type: TierLimitEventTypeE.UsageChanged,
				affectedLimits: [UserLimitsE.BucketCount],
			});
			this.fetchBucketList();
		},
		async fetchBucketList() {
			this.loadingBuckets = true;
			await this.dataSourcesStore.fetchBucketList();
			this.loadingBuckets = false;
		},
		dataSourceIcon(dataSourceType: DataSourceType) {
			return dsUtil.dataSourceIcon(dataSourceType);
		},
	},
	computed: {
		dataSourceList(): string[] {
			return [...this.bucketList];
		},
		...mapState(useDataSourcesStore, {
			driveList: "driveList",
			bucketList: "bucketList",
			gatewayList: "gatewayList",
		}),
		dataSourceLimitReached(): boolean {
			const totalDataSources = this.dataSourceList.length;
			const thisUserDataSourceLimit = this.userStore.currentUserTierDetails?.allowance.DataSourceCount;
			if (!thisUserDataSourceLimit) {
				return false;
			}
			return totalDataSources >= thisUserDataSourceLimit;
		},
	},
	async created() {
		this.userStore.getCurrentUserTierDetails();

		if (this.gatewayList.length == 0) {
			this.loadingGateways = true;
			this.dataSourcesStore.fetchGatewayList().then(() => {
				this.loadingGateways = false;
			});
		}

		if (this.driveList.length == 0) {
			this.loadingDrives = true;
			await this.dataSourcesStore.fetchDriveList().then(() => {
				this.loadingDrives = false;
			});
		}

		if (this.bucketList.length == 0) {
			this.loadingBuckets = true;
			await this.dataSourcesStore.fetchBucketList().then(() => {
				this.loadingBuckets = false;
			});
		}
	},
});
