<template>
	<v-dialog
		v-model="showDialog"
		:width="$vuetify.breakpoint.mobile ? '95%' : '500px'"
		@click:outside="closeDialog"
		@keydown.esc="closeDialog"
	>
		<v-sheet
			class="pa-10 pa-lg-10"
			:width="$vuetify.breakpoint.mobile ? '100%' : '500px'"
			:tile="$vuetify.breakpoint.mobile"
			:elevation="!$vuetify.breakpoint.mobile ? '5' : '0'"
			rounded
		>
			<div>
				<div class="d-flex justify-space-between">
					<div class="text-h5 pa-3 pt-0 font-weight-medium">Remove Task</div>
					<v-btn
						color="white"
						class="text--primary"
						small
						outlined
						height="36"
						elevation="0"
						@click="closeDialog"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>

				<div class="text-body-2 pa-3 mt-3">
					<span>Please confirm that you want to permanently remove task </span>
					<code
						v-if="taskID != null"
						class="font-weight-regular mx-0"
						style="font-size: 0.875em"
						data-test="task-card-task-id-value"
						>{{ taskID }}</code
					>
				</div>
				<v-container>
					<v-row>
						<v-col
							class="mt-4"
							cols="12"
							md="12"
						>
							<v-btn
								color="secondary"
								class="text-body-1 font-weight-medium auth-button"
								depressed
								width="100%"
								:loading="buttonIsLoading"
								type="submit"
								@click="removeTask"
								data-test="task-remove-button"
								>Remove Task</v-btn
							>
						</v-col>
					</v-row>
					<v-row v-if="showSupportMessage">
						<v-col class="text-center">
							<v-alert
								dense
								outlined
								type="warning"
								border="left"
								style="text-align: left"
							>
								{{ getSupportMessage }}
							</v-alert>
						</v-col>
					</v-row>
					<v-row>
						<v-col class="text-center">
							<span class="text-body-2"
								><a
									href="#"
									@click="closeDialog"
									>Go back</a
								></span
							>
						</v-col>
					</v-row>
				</v-container>
			</div>
		</v-sheet>
	</v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import { DeleteTaskMetadataById } from "@/js/signaloidClient";
import { useTasksStore } from "@/stores/tasks";

const SupportMessage = `Could not remove this item.\n If this issue persists, please contact Signaloid Support at\n developer-support@signaloid.com. `;

export default defineComponent({
	name: "TasksRemoveDialog",
	data: () => ({
		buttonIsLoading: false,
		showSupportMessage: false,
	}),
	props: {
		showDialog: { type: Boolean, default: false },
		taskID: {
			type: String,
			default: null,
		},
	},
	computed: {
		getSupportMessage() {
			return SupportMessage;
		},
	},
	setup() {
		const tasksStore = useTasksStore();
		return {
			tasksStore,
		};
	},
	methods: {
		async removeTask() {
			this.buttonIsLoading = true;
			try {
				await DeleteTaskMetadataById(this.$props.taskID);

				this.buttonIsLoading = false;
				this.closeDialog();
				this.tasksStore.removeTaskByIdFromLocal(this.$props.taskID);
				this.$emit("removeTaskSuccess");
			} catch (e) {
				this.buttonIsLoading = false;
				this.showSupportMessage = true;
			}
		},
		closeDialog() {
			this.$emit("closeRemoveTaskDialog");
		},
	},
});
</script>

<style>
.password-field .v-text-field__details {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
</style>
