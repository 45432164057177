var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.$vuetify.breakpoint.mobile ? "95%" : "650px" },
      on: {
        "click:outside": _vm.closeDialog,
        keydown: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            )
              return null
            return _vm.closeDialog.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.initiateUpgrade.apply(null, arguments)
          },
        ],
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "card-grid pa-6",
          attrs: { "min-height": "400px", "data-test": "upgrade-hint-dialog" },
        },
        [
          _c("div", { staticClass: "card-grid-title" }, [
            _c("div", { staticClass: "text-h5" }, [
              _vm._v(" " + _vm._s(_vm.title) + " "),
            ]),
            _vm.subtitle
              ? _c("div", { staticClass: "text-body font-italic mt-1" }, [
                  _vm._v(" " + _vm._s(_vm.subtitle) + " "),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "card-grid-side-image" }, [
            _c("img", {
              staticClass: "side-image",
              attrs: { src: _vm.sideImageURL, height: "100%" },
            }),
          ]),
          _c(
            "v-card-text",
            {
              staticClass: "px-1 card-grid-details d-flex flex-column",
              staticStyle: { "min-width": "300px" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "text-subtitle-2 font-weight-regular text--primary",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.description) +
                      " " +
                      _vm._s(_vm.upgradeBenefitsText) +
                      " "
                  ),
                ]
              ),
              _c("div", { staticClass: "text-subtitle-1 mt-3 text--primary" }, [
                _vm._v(" " + _vm._s(_vm.nextTierIntroText) + " "),
              ]),
              _c("TierHighlightsList", {
                staticClass: "mt-3",
                attrs: { tierHighlightsToDisplay: _vm.tierHighlightsToDisplay },
              }),
              _vm.quickCheckout
                ? _c("span", { staticClass: "mt-2 mx-auto text-caption" }, [
                    _c("a", { on: { click: _vm.goToBillingPage } }, [
                      _vm._v(" Compare Account Tier Features."),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "card-grid-side-actions" },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("TooltipButton", {
                        attrs: {
                          color: "accent",
                          width: "200px",
                          "btn-icon": _vm.upgradeButtonStyling.icon,
                          "tooltip-text": _vm.upgradeButtonStyling.text,
                          "btn-text": _vm.upgradeButtonStyling.text,
                        },
                        on: { click: _vm.initiateUpgrade },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("stripe-checkout", {
        ref: "checkoutRef",
        attrs: {
          pk: _vm.stripePublishableKey,
          "session-id": _vm.stripeSessionId,
          locale: "en-GB",
        },
      }),
      _c(
        "v-dialog",
        {
          staticClass: "text-center",
          attrs: { width: _vm.$vuetify.breakpoint.mobile ? "100%" : "700px" },
          model: {
            value: _vm.showInterestFormDialog,
            callback: function ($$v) {
              _vm.showInterestFormDialog = $$v
            },
            expression: "showInterestFormDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-4 pa-lg-10" },
            [
              _c("InterestFrom", {
                attrs: {
                  passedPlan: _vm.interestFormProps.plan,
                  passedEmail: _vm.interestFormProps.email,
                  passedFullname: _vm.interestFormProps.fullname,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }