<template>
	<v-card
		class="px-1 px-sm-2"
		flat
	>
		<!-- CUSTOM CORES -->
		<template v-if="userCustomCores.length > 0">
			<v-card-title class="pb-3 pt-1 pt-sm-4 px-1 px-sm-4">
				<div class="text-h6 font-weight-bold">Custom Cores</div>
				<v-spacer />
				<LimitableActionButton
					@valid-click="addCore"
					color="accent"
					btnClass="float-right"
					tooltipText="Add Core"
					data-test="add-core-button"
					:limits-to-check="[UserLimitsE.CoreCount]"
				>
					<template #btnContent>
						<v-icon size="19">mdi-plus</v-icon>
						<v-icon class="ml-1">mdi-memory</v-icon>
					</template>
				</LimitableActionButton>
			</v-card-title>
			<v-divider class="mb-2 mb-sm-3 mx-1 mx-sm-4" />
			<v-container
				fluid
				class="px-sm-4 px-1 py-sm-2 py-1 mx-auto mb-5"
			>
				<v-row dense>
					<v-col
						cols="12"
						sm="12"
						md="6"
						lg="4"
						xl="3"
						v-for="executionCore in userCustomCores"
						:key="executionCore.CoreID"
					>
						<CoreCard
							:coreConfig="executionCore"
							@edit-core="editCore"
							@delete-core="deleteCore"
							@core-synced="updateCoreList"
							class="fill-height ma-0 ma-sm-0"
							outlined
							tile
						/>
					</v-col>
					<LoadingCard
						v-if="coresStore.loadingCores"
						loadingText="Loading Cores"
					/>
				</v-row>
			</v-container>
		</template>

		<!-- SIGNALOID DEFAULT CORES -->
		<v-card-title class="pb-3 pt-1 pt-sm-4 px-1 px-sm-4">
			<div class="text-h6 font-weight-bold">Signaloid Cores</div>
			<v-spacer />
			<LimitableActionButton
				@valid-click="addCore"
				color="accent"
				btnClass="float-right"
				tooltipText="Add Core"
				data-test="add-core-button"
				:limits-to-check="[UserLimitsE.CoreCount]"
				v-if="userCustomCores.length === 0"
			>
				<template #btnContent>
					<v-icon size="19">mdi-plus</v-icon>
					<v-icon class="ml-1">mdi-memory</v-icon>
				</template>
			</LimitableActionButton>
		</v-card-title>
		<v-divider class="mb-2 mb-sm-3 mx-1 mx-sm-4" />
		<v-container
			fluid
			class="px-sm-4 px-1 py-sm-2 py-1 mx-auto"
		>
			<v-row dense>
				<v-col
					cols="12"
					sm="12"
					md="6"
					lg="4"
					xl="3"
					v-for="executionCore in defaultCores"
					:key="executionCore.CoreID"
				>
					<CoreCard
						:coreConfig="executionCore"
						@edit-core="editCore"
						@delete-core="deleteCore"
						@core-synced="updateCoreList"
						class="fill-height ma-0 ma-sm-0"
						outlined
						tile
					/>
				</v-col>
				<LoadingCard
					v-if="coresStore.loadingCores"
					loadingText="Loading Cores"
				/>
			</v-row>
		</v-container>

		<!-- SIGNALOID PRO CORES -->
		<template v-if="defaultProCores.length > 0">
			<v-card-title class="pb-3 pt-1 pt-sm-4 px-1 px-sm-4">
				<div class="text-h6 font-weight-bold">Signaloid Pro Cores</div>
			</v-card-title>
			<v-divider class="mb-2 mb-sm-3 mx-1 mx-sm-4" />
			<v-container
				fluid
				class="px-sm-4 px-1 py-sm-2 py-1 mx-auto"
			>
				<v-row dense>
					<v-col
						cols="12"
						sm="12"
						md="6"
						lg="4"
						xl="3"
						v-for="executionCore in defaultProCores"
						:key="executionCore.CoreID"
					>
						<CoreCard
							:coreConfig="executionCore"
							@edit-core="editCore"
							@delete-core="deleteCore"
							@core-synced="updateCoreList"
							class="fill-height ma-0 ma-sm-0"
							outlined
							tile
						/>
					</v-col>
				</v-row>
				<LoadingCard
					v-if="coresStore.loadingCores"
					loadingText="Loading Cores"
				/>
			</v-container>
		</template>

		<ModifyCoreDialog
			v-if="showModifyCoreDialog"
			:showDialog="showModifyCoreDialog"
			:editCoreData="editCoreData"
			@close-dialog="showModifyCoreDialog = false"
			@core-created="updateCoreList"
			@core-updated="updateCoreList"
		/>
		<DeleteCoreDialog
			v-if="showDeleteCoreDialog"
			:showDialog="showDeleteCoreDialog"
			:deleteCoreData="deleteCoreData"
			@close-dialog="showDeleteCoreDialog = false"
			@core-deleted="coreSuccessfullyDeleted"
		/>
	</v-card>
</template>
<script>
import { defineComponent } from "vue";

// Components
import CoreCard from "@/components/Cores/CoreCard.vue";
import ModifyCoreDialog from "@/components/Cores/ModifyCoreDialog.vue";
import DeleteCoreDialog from "@/components/Cores/DeleteCoreDialog.vue";
import LimitableActionButton from "@/components/Common/LimitableActionButton.vue";
import LoadingCard from "@/components/LoadingCard.vue";

// Stores
import { useCoresStore } from "@/stores/cores";
import { useUserStore } from "@/stores/user";
import { useRootStore } from "@/stores/root";
import { mapState } from "pinia";

// Types
import { TierLimitEventTypeE } from "@/eventBus/tierLimitEventBus";
import { UserLimitsE } from "@/js/tierconfig";

export default defineComponent({
	name: "Cores",
	components: { CoreCard, ModifyCoreDialog, DeleteCoreDialog, LimitableActionButton, LoadingCard },
	data: () => ({
		showModifyCoreDialog: false,
		showDeleteCoreDialog: false,
		editCoreData: undefined,
		deleteCoreData: undefined,
		coresDisabledByUserTier: [],
	}),
	setup() {
		const coresStore = useCoresStore();
		const userStore = useUserStore();
		const rootStore = useRootStore();
		return { coresStore, userStore, rootStore, UserLimitsE };
	},
	methods: {
		addCore() {
			//@ts-ignore
			this.$posthog?.capture("create_core_button_clicked");

			this.editCoreData = undefined;
			this.showModifyCoreDialog = true;
		},
		async updateCoreList() {
			this.rootStore.tierLimitEventBus.emit({
				type: TierLimitEventTypeE.UsageChanged,
				affectedLimits: [UserLimitsE.CoreCount],
			});
			await this.coresStore.fetchUserCoresFromRemote();
			this.coresDisabledByUserTier = await this.coresStore.getCoresFilteredCoresByUserTier();
		},
		editCore(coreInfo) {
			this.editCoreData = coreInfo;
			this.showModifyCoreDialog = true;
		},
		deleteCore(coreInfo) {
			this.deleteCoreData = coreInfo;
			this.showDeleteCoreDialog = true;
		},
		async coreSuccessfullyDeleted() {
			//@ts-ignore
			this.$posthog?.capture("custom_core_deleted");

			await this.updateCoreList();
		},
	},
	computed: {
		...mapState(useCoresStore, {
			filteredVisibleCoresList: "filteredVisibleCoresList",
		}),
		defaultCores() {
			return this.filteredVisibleCoresList.filter((obj) => {
				return obj.Owner === "*" && obj.Class == "C0";
			});
		},
		defaultProCores() {
			return this.filteredVisibleCoresList.filter((obj) => {
				return obj.Owner === "*" && obj.Class == "C0Pro";
			});
		},
		userCustomCores() {
			return this.filteredVisibleCoresList.filter((obj) => {
				return obj.Owner !== "*";
			});
		},
	},
	created() {
		this.coresStore.getCoresFilteredCoresByUserTier();
	},
});
</script>

<style lang="scss" scoped></style>
