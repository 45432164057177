
import { defineComponent } from "vue";

// Types
import { TierLimitEventTypeE } from "@/eventBus/tierLimitEventBus";
import { UserLimitsE } from "@/js/tierconfig";

// Libraries

// Utilities
import { initiateGithubLoginFlow, mockTimeout } from "@/js/githubClient";

// Components
import ConnectedRepositoryCard from "@/components/Repositories/ConnectedRepositoryCard.vue";
import GithubRepoListDialog from "@/components/Repositories/GithubRepoListDialog.vue";
import ExecutionResultsCard from "@/components/Repositories/ExecutionResultsCard.vue";
import SearchGithubReposDialog from "@/components/Repositories/SearchGithubReposDialog.vue";
import GithubAuthRedirectDialog from "@/components/Repositories/GithubAuthRedirectDialog.vue";
import RepositoryConnectionValidationCard from "@/components/Repositories/RepositoryConnectionValidationCard.vue";
import TooltipButton from "@/components/Common/TooltipButton.vue";
import LoadingCard from "@/components/LoadingCard.vue";

// Stores
import { mapState, storeToRefs } from "pinia";
import { useCoresStore } from "@/stores/cores";
import { useUserStore } from "@/stores/user";
import { useRepositoriesStore } from "@/stores/repositories";
import { useRootStore } from "@/stores/root";

import { useGithubStore } from "@/stores/github";
import { useDataSourcesStore } from "@/stores/dataSources";
import { RepoConnectionConfig, TaskBusEvent } from "@/types/general";
import { TaskOutput } from "@/types/api/tasks";
import { Repository } from "@/types/api/repositories";

// Global variables

// Local Types

type ComponentData = {
	fetchingRepositories: boolean;
	githubRepoListDialogActive: boolean;
	searchGithubReposDialogActive: boolean;
	githubAuthRedirectDialogActive: boolean;
	githubAuthRedirectDialogText: string;
	repoConnectionValidationDialogActive: boolean;
	executionLogSheetActive: boolean;
	executionLogTaskID: null | string;
	repoConnectionConfig: undefined | RepoConnectionConfig;
};

export default defineComponent({
	name: "Repositories",
	components: {
		ConnectedRepositoryCard,
		GithubRepoListDialog,
		ExecutionResultsCard,
		SearchGithubReposDialog,
		GithubAuthRedirectDialog,
		RepositoryConnectionValidationCard,
		TooltipButton,
		LoadingCard,
	},
	data: (): ComponentData => ({
		fetchingRepositories: false,
		githubRepoListDialogActive: false,
		searchGithubReposDialogActive: false,
		githubAuthRedirectDialogActive: false,
		githubAuthRedirectDialogText: "",
		repoConnectionValidationDialogActive: false,
		executionLogSheetActive: false,
		executionLogTaskID: null,
		repoConnectionConfig: undefined,
	}),
	setup() {
		const coresStore = useCoresStore();
		const repositoriesStore = useRepositoriesStore();
		const githubStore = useGithubStore();
		const userStore = useUserStore();
		const rootStore = useRootStore();

		return { coresStore, repositoriesStore, githubStore, userStore, rootStore };
	},
	computed: {
		...mapState(useDataSourcesStore, { defaultRepoMountConfig: "defaultMountConfig" }),
		formattedGithubUsername(): string {
			const githubUsername = this.githubStore.githubUsername;

			if (!githubUsername) {
				return "-";
			}

			const smMaxLength = 12;
			const mdMaxLength = 24;
			const maxLength = this.$vuetify.breakpoint.smAndDown ? smMaxLength : mdMaxLength;

			return githubUsername?.slice(0, maxLength) + (githubUsername?.length > maxLength ? "..." : "");
		},
		connectedRepositories() {
			const repositoriesStore = useRepositoriesStore();
			return repositoriesStore.connectedRepositories;
		},
		isUserLoggedInToGithub(): boolean {
			const githubStore = useGithubStore();
			return githubStore.userGithubLogin;
		},
	},
	methods: {
		connectToRepo() {
			//@ts-ignore
			this.$posthog?.capture("search_private_repos_button_clicked");

			if (!this.githubStore.githubLoggedIn) {
				this.githubLogin();
			} else {
				this.githubRepoListDialogActive = true;
			}
		},
		closeGithubRepoListDialog() {
			this.githubRepoListDialogActive = false;
		},
		closeSearchGithubReposDialog() {
			this.searchGithubReposDialogActive = false;
		},
		githubLogin() {
			//@ts-ignore
			this.$posthog?.capture("github_login_initiated");

			this.githubAuthRedirectDialogText = "Authenticating with GitHub...";
			this.githubAuthRedirectDialogActive = true;
			mockTimeout(500).then(() => {
				initiateGithubLoginFlow();
			});
		},
		async githubLogout() {
			// TODO: Test
			this.githubAuthRedirectDialogText = "Disconnecting from GitHub...";
			this.githubAuthRedirectDialogActive = true;

			await this.githubStore.githubLogout();
			mockTimeout(500).then(() => {
				window.location.reload();
			});
		},
		repoConnectionSuccessHandler(repoData: RepoConnectionConfig) {
			// @ts-ignore
			this.$posthog?.capture("repository_connected", {
				repoURL: repoData.remoteURL,
			});

			this.repositoriesStore.fetchRemoteRepositories();
			this.repoConnectionConfig = undefined;
		},
		async repoConnectionRequestHandler(config: RepoConnectionConfig) {
			this.repoConnectionConfig = config;
			this.repoConnectionValidationDialogActive = true;
		},
		repoDisconnectionEventHandler(data: { repositoryID: string }) {
			// @ts-ignore
			this.$posthog?.capture("repository_disconnected");

			this.repositoriesStore.fetchRemoteRepositories();
			this.rootStore.tierLimitEventBus.emit({
				type: TierLimitEventTypeE.UsageChanged,
				affectedLimits: [UserLimitsE.RepositoryCount],
			});
		},
		buildAndRun() {},
		openExecutionLog(data: { taskID: string }) {
			if (!this.executionLogSheetActive) {
				this.executionLogTaskID = data.taskID;
				this.executionLogSheetActive = true;
			}
		},
		closeExecutionLog() {
			this.executionLogTaskID = null;
			this.executionLogSheetActive = false;
		},
		openGithubSearchDialog() {
			//@ts-ignore
			this.$posthog?.capture("search_public_repos_button_clicked");

			this.searchGithubReposDialogActive = true;
		},
	},
	async mounted() {
		// Deal with repo connection with `?connect` query param
		if (this.$route.query?.connect && typeof this.$route.query?.connect === "string") {
			const urlOfRepoToConnect = new URL(this.$route.query.connect);
			if (urlOfRepoToConnect.host === "github.com") {
				const repoConnectionConfig = {
					remoteURL: urlOfRepoToConnect,
					full_name: urlOfRepoToConnect.pathname,
				};
				this.repoConnectionRequestHandler(repoConnectionConfig);
			} else {
				console.log(`Repository not from Github:  ${urlOfRepoToConnect}`);
			}
			this.$router.push({ name: "Repositories" });
		}

		// wait until till github details are fetched before fetching repositories
		await this.repositoriesStore.fetchRemoteRepositories();
	},
});
