
import { PropType, defineComponent } from "vue";

// Stores
import { mapState } from "pinia";
import { useCoresStore } from "@/stores/cores";

import { coreDescriptionString, toStringWithByteMagnitude, toStringWithSIMagnitude } from "@/js/utilities";
import { Core, CoreRequirementMatch } from "@/types/api/cores";
import DocumentationIcon from "./Common/DocumentationIcon.vue";

type ComponentData = {
	//TODO: Change this to Core
	selectedCore: Core | undefined;
};

export default defineComponent({
	name: "SelectCoreDialog",
	components: { DocumentationIcon },
	props: {
		showDialog: { type: Boolean, default: false },
		minimumCore: { type: Object as PropType<Core | undefined>, default: undefined },
		activeCore: { type: Object as PropType<Core | undefined>, default: undefined },
	},
	data: (): ComponentData => ({
		selectedCore: undefined,
	}),
	setup() {
		const fieldsToDisplay = [
			// "CoreID",
			// "Name",
			"Class",
			"Precision",
			"MemorySize",
			"Microarchitecture",
			"CorrelationTracking",
		];
		const coresStore = useCoresStore();
		return {
			coresStore,
			fieldsToDisplay,
		};
	},
	methods: {
		closeDialog() {
			this.$emit("close-dialog");
		},
		setCore(coreID) {
			this.$emit("set-core", coreID);
		},
		limitValuePrefix(key) {
			switch (key) {
				case "MemorySize":
				case "Precision":
					return `<i aria-hidden="true" style="font-size: 12px;" class="v-icon notranslate mdi mdi-greater-than-or-equal theme--light ${
						this.coreRequirementWarnings[key] ? "warning--text" : ""
					} "></i>`;
				default:
					return ``;
			}
		},
		formatValueString(key, value) {
			switch (key) {
				case "MemorySize":
					return toStringWithByteMagnitude(value);
				case "Precision":
					return toStringWithSIMagnitude(value, "");
				case "CorrelationTracking":
					return this.formatCorrelationString(value);
				default:
					return value;
			}
		},
		formatKeyString(key) {
			switch (key) {
				case "CoreID":
					return "Core ID";
				case "MemorySize":
					return "Memory Size";
				case "CorrelationTracking":
					return "Correlation Tracking";
				default:
					return key;
			}
		},
		formatCorrelationString(code) {
			return this.correlationTrackingMethodByCode(code)?.Name ?? "-";
		},
		satisfiesMinimumCore(core): boolean {
			if (!this.minimumCore) {
				return true;
			}
			if (this.coresSatisfyMinConfig(this.minimumCore)[core.CoreID]) {
				return true;
			} else {
				return false;
			}
		},
	},
	mounted() {
		this.selectedCore = this.activeCore ?? this.filteredVisibleCoresList[0];
	},
	computed: {
		...mapState(useCoresStore, {
			coresSatisfyMinConfig: "coresSatisfyMinConfig",
			correlationTrackingMethodByCode: "correlationTrackingMethodByCode",
		}),
		...mapState(useCoresStore, {
			filteredVisibleCoresList: "filteredVisibleCoresList",
			coresSatisfyMinConfig: "coresSatisfyMinConfig",
		}),
		coreRequirementWarnings(): Partial<CoreRequirementMatch> {
			const requirementMatch = {};
			if (!this.selectedCore) {
				return requirementMatch;
			}
			for (const key in this.minimumCore) {
				if (typeof this.minimumCore[key] == "number") {
					// check if requirement satisfied for numerical constraints
					requirementMatch[key] = this.selectedCore[key] < this.minimumCore[key];
				} else {
					// check if requirement satisfied for non numerical constraints
					if (["Class", "Microarchitecture", "CorrelationTracking"].includes(key)) {
						requirementMatch[key] = this.selectedCore[key] !== this.minimumCore[key];
					}
				}
			}
			return requirementMatch;
		},
		coreDescription() {
			if (this.selectedCore && this.selectedCore.Owner == "*") {
				const description = coreDescriptionString(this.selectedCore.Name, false);
				return description;
			} else {
				return "";
			}
		},
	},
	created() {
		this.coresStore.getCoresFilteredCoresByUserTier();
	},
});
