var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.$vuetify.breakpoint.mobile ? "95%" : "500px" },
      on: {
        "click:outside": _vm.closeDialog,
        keydown: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            )
              return null
            return _vm.closeDialog.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.saveMountConfig.apply(null, arguments)
          },
        ],
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "pb-0",
          attrs: { "data-test": "mount-data-source-dialog" },
        },
        [
          _c(
            "v-card-title",
            { staticClass: "py-3 px-4" },
            [
              _vm._v("Mount Data Source "),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "text--primary",
                  attrs: {
                    color: "white",
                    small: "",
                    outlined: "",
                    height: "36",
                    elevation: "0",
                    "data-test": "close-mount-data-source-dialog-button",
                  },
                  on: { click: _vm.closeDialog },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "py-1 px-4" },
            [
              _c("div", { staticClass: "mb-2 mt-1" }, [_vm._v("Source")]),
              _c(
                "v-row",
                { staticClass: "mx-0 mx-sm-2", attrs: { justify: "center" } },
                [
                  !_vm.mountConfig || !_vm.mountConfig?.ResourceID
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "my-2",
                          attrs: {
                            color: "primary",
                            elevation: "0",
                            "data-test": "select-data-source-button",
                          },
                          on: {
                            click: function ($event) {
                              _vm.showMountDataSourcesDialog = true
                            },
                          },
                        },
                        [_vm._v(" Select Data Source ")]
                      )
                    : _c("DataSourcePickerCard", {
                        staticClass: "my-2",
                        attrs: {
                          setAsSource: true,
                          dataSourceID: _vm.mountConfig.ResourceID,
                          dataSourceType: _vm.mountConfig.ResourceType,
                        },
                        on: { "reset-data-source": _vm.unselectDataSource },
                      }),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c("v-icon", { staticClass: "mb-0 mt-0 mb-sm-1 mt-sm-2" }, [
                    _vm._v(" mdi-transfer-down "),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "my-2" }, [_vm._v("Destination")]),
              _c("v-text-field", {
                staticClass: "mb-n2 mx-0 mx-sm-2",
                attrs: {
                  maxlength: "500",
                  placeholder: "Mount location",
                  hint: !_vm.mountConfig.ResourceID
                    ? `First choose a data source to be mounted at ${_vm.mountConfig.Location}/`
                    : `Data source will be mounted at ${_vm.mountConfig.Location}/`,
                  "error-messages": !_vm.validMountLocation
                    ? `Mounting at ${_vm.mountConfig.Location}/ not allowed. Please set a valid path.`
                    : "",
                  outlined: "",
                  dense: "",
                  "data-test": "data-source-mount-destination",
                },
                model: {
                  value: _vm.mountConfig.Location,
                  callback: function ($$v) {
                    _vm.$set(_vm.mountConfig, "Location", $$v)
                  },
                  expression: "mountConfig.Location",
                },
              }),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "text--primary",
                  attrs: {
                    color: "white",
                    height: "36",
                    elevation: "0",
                    outlined: "",
                  },
                  on: { click: _vm.resetMountConfig },
                },
                [_vm._v(" Reset to Default ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "text--primary",
                  attrs: {
                    color: "white",
                    height: "36",
                    elevation: "0",
                    outlined: "",
                    "data-test": "remove-mount-config-button",
                  },
                  on: { click: _vm.removeMountConfig },
                },
                [_vm._v(" Remove Mount ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    elevation: "0",
                    disabled: !_vm.validConfig,
                    "data-test": "save-mount-config-button",
                  },
                  on: { click: _vm.saveMountConfig },
                },
                [_vm._v(" Save Mount ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showMountDataSourcesDialog
        ? _c("DataSourceListDialog", {
            attrs: {
              showDialog: _vm.showMountDataSourcesDialog,
              dataSourceExcludeList: _vm.dataSourceExcludeList,
            },
            on: {
              "close-dialog": function ($event) {
                _vm.showMountDataSourcesDialog = false
              },
              "set-data-source": _vm.setDataSource,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }