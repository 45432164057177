var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-alert",
    {
      staticClass: "mx-auto",
      attrs: {
        icon: false,
        border: "top",
        "colored-border": "",
        type: _vm.alertColour,
        elevation: "2",
        width: "95%",
        "max-width": "700px",
      },
    },
    [
      _c("div", { staticClass: "text-center text-sm-h4 text-h5 pa-3 mb-5" }, [
        _vm._v("Connecting to your GitHub account..."),
      ]),
      _c(
        "div",
        [
          _c(
            "v-slide-y-transition",
            { staticClass: "py-0", attrs: { group: "", tag: "v-list" } },
            [
              _vm._l(_vm.authTasks, function (authTask, i) {
                return [
                  _c(
                    "v-list-item",
                    { key: `${i}-${authTask.text}` },
                    [
                      _c("div", {
                        staticClass: "ml-4",
                        class:
                          (authTask.done &&
                            !authTask.success &&
                            "error--text") ||
                          (authTask.done &&
                            authTask.success &&
                            "success--text") ||
                          "primary--text",
                        domProps: { textContent: _vm._s(authTask.text) },
                      }),
                      _c("v-spacer"),
                      _c(
                        "v-scale-transition",
                        { attrs: { "hide-on-leave": "" } },
                        [
                          authTask.done && authTask.success
                            ? _c("v-icon", { attrs: { color: "success" } }, [
                                _vm._v(" mdi-check "),
                              ])
                            : _vm._e(),
                          authTask.done && !authTask.success
                            ? _c("v-icon", { attrs: { color: "error" } }, [
                                _vm._v(" mdi-close "),
                              ])
                            : _c("v-progress-circular", {
                                attrs: {
                                  width: 3,
                                  size: 25,
                                  color: "primary",
                                  indeterminate: "",
                                },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showError,
                  expression: "showError",
                },
              ],
              staticClass: "mx-5 mt-2 mb-0 py-3",
              attrs: { type: "error", elevation: "2", icon: false },
            },
            [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }