
import { defineComponent, PropType } from "vue";

// Types
import { UserLimitsE } from "@/js/tierconfig";
import { TraceVariable, TraceVariableWithTraceType } from "@/types/api/nodes";

// Components
import SourcePlaceholderCard from "@/components/DataSources/SourcePlaceholderCard.vue";
import TooltipButton from "@/components/Common/TooltipButton.vue";
import LimitableActionButton from "@/components/Common/LimitableActionButton.vue";
import LimitableActionValidator from "@/components/Common/LimitableActionValidator.vue";
import DisclosureIcon from "@/components/Common/DisclosureIcon.vue";

type ComponentData = {
	variableIDsToTrace: number[];
	lastCodeHash: undefined | string;
	showCustomVariableTraceDialog: boolean;
	rules: {
		required: CallableFunction;
		isNumber: CallableFunction;
	};
	customVariableTraceToAdd: Partial<TraceVariableWithTraceType>;
	customVariableTraceFormValid: false;
	traceDialogAlert: { show: boolean; text: string; type: "warning" | "error" };
};

export default defineComponent({
	name: "VariableViewer",
	components: {
		SourcePlaceholderCard,
		TooltipButton,
		LimitableActionValidator,
		LimitableActionButton,
		DisclosureIcon,
	},
	props: {
		height: { type: String, default: "100%" },
		buildAndRunLoading: { type: Boolean, default: false },
		variablesList: { type: Array as PropType<TraceVariableWithTraceType[]>, default: () => [] },
		currentCodeHash: { type: String, default: undefined },
		variableDiscoveryInProgress: { type: Boolean, default: false },
		referenceCoreChosen: { type: Boolean },
		disableRequestPanelClose: { type: Boolean, default: false },
	},
	setup() {
		return { UserLimitsE };
	},

	data: (): ComponentData => ({
		variableIDsToTrace: [],
		lastCodeHash: undefined,
		showCustomVariableTraceDialog: false,
		rules: {
			required: (value) => !!value || "Required.",
			isNumber: (value) => {
				const number = parseInt(value);
				if (number && number > 0) {
					return true;
				} else {
					return "Should be a positive number.";
				}
			},
		},
		customVariableTraceToAdd: {
			Expression: undefined,
			Type: undefined,
			File: undefined,
			LineNumber: undefined,
		},
		customVariableTraceFormValid: false,
		traceDialogAlert: { show: false, text: "", type: "warning" },
	}),
	watch: {
		variablesList: {
			handler() {
				this.lastCodeHash = this.currentCodeHash;
			},
		},
		variableIDsToTrace: {
			handler() {
				this.$emit("update-variable-trace-list", this.getTraceVariables());
			},
		},
	},
	computed: {
		staleVariableList(): boolean {
			return this.currentCodeHash != this.lastCodeHash;
		},
		discoverVariablesDisabled(): boolean {
			return false;
			// temporarily disabled
			// return !(this.staleVariableList || (this.variablesList.length === 0 && this.staleVariableList));
		},
		showVariablePlaceholderButton(): boolean {
			// this.lastCodeHash is used as a proxy to determine if discover variables has been run at least once before.
			// to prevent the placeholder button replacing the "no variables found banner"
			return this.variablesList.length === 0 && this.staleVariableList && this.lastCodeHash === undefined;
		},
		showCodeUpdatedBanner(): boolean {
			// Show the banner if the variable list is stale and if discover variables has been run at least once before.
			return this.staleVariableList && this.lastCodeHash !== undefined;
		},
		showSwitchToRefCoreBanner(): boolean {
			// Show the banner if a ref core is not chosen, but no variables chosen to be traced
			return !this.referenceCoreChosen && this.variableIDsToTrace.length > 0;
		},
		showPickVariablesBanner(): boolean {
			// Show the banner if a ref core is chosen, variables have been discovered, but no variables chosen to be traced
			return this.referenceCoreChosen && this.variableIDsToTrace.length == 0 && this.variablesList.length > 0;
		},
	},
	methods: {
		getTraceVariables(): TraceVariable[] {
			const varList: TraceVariable[] = [];
			for (let idx = 0; idx < this.variableIDsToTrace.length; idx++) {
				const variableIDToTrace = this.variableIDsToTrace[idx];
				if (variableIDToTrace < this.variablesList.length) {
					const v = this.variablesList[variableIDToTrace];
					if (v.Expression && v.File && v.LineNumber) {
						varList.push({
							Expression: v.Expression,
							File: v.File,
							LineNumber: v.LineNumber,
						});
					}
				}
			}
			return varList;
		},
		resetVariableMarks() {
			this.variableIDsToTrace = [];
		},
		requestVariableDiscovery() {
			//@ts-ignore
			this.$posthog?.capture("discover_variables_button_clicked");

			this.$emit("discover-variables");
			this.resetVariableMarks();
			this.lastCodeHash = this.currentCodeHash;
		},
		requestPanelClose() {
			this.$emit("close-panel");
		},
		requestDefaultReferenceCore() {
			this.$emit("switch-to-ref-core");
		},
		variableIcon(kind) {
			switch (kind) {
				case "Variable":
					return "mdi-variable";
				case "FormalParameter":
					return "mdi-abugida-devanagari";
				case "UserExpression":
					return "mdi-account-details";
				default:
					return "mdi-progress-question";
			}
		},
		openCustomVariableTraceDialog(templateVariable: void | TraceVariableWithTraceType) {
			this.customVariableTraceToAdd = {
				Expression: templateVariable?.Expression ?? "",
				Type: "UserExpression",
				File: templateVariable?.File ?? "",
				LineNumber: templateVariable?.LineNumber ?? 1,
			};
			this.showCustomVariableTraceDialog = true;
		},
		closeCustomVariableTraceDialog() {
			this.showCustomVariableTraceDialog = false;
			// @ts-ignore FIXME:
			this.$refs.customVariableTraceForm.resetValidation();
			this.customVariableTraceToAdd = {
				Expression: undefined,
				Type: undefined,
				File: undefined,
				LineNumber: undefined,
			};
			this.traceDialogAlert = { show: false, text: "", type: "warning" };
		},
		requestAddCustomVariableTrace() {
			if (this.validTrace(this.customVariableTraceToAdd)) {
				// @ts-ignore FIXME:
				this.customVariableTraceToAdd.LineNumber = parseInt(this.customVariableTraceToAdd.LineNumber);
				this.$emit("add-custom-trace-expression", this.customVariableTraceToAdd);
				// add the newly created expression to the trace list
				this.variableIDsToTrace.push(this.variablesList.length);
				this.closeCustomVariableTraceDialog();
			} else {
				// console.log("trace exists", this.variablesList, this.customVariableTraceToAdd);
				this.traceDialogAlert = {
					show: true,
					text: `Variable trace exists.`,
					type: "error",
				};
			}
		},
		validTrace(variableTrace: Partial<TraceVariableWithTraceType>) {
			const isUnique = !this.variablesList.some((v) => {
				return (
					v.Expression == variableTrace.Expression &&
					v.LineNumber == variableTrace.LineNumber &&
					v.File == variableTrace.File
				);
			});
			let validLineNumber = false;
			// @ts-ignore FIXME:
			const lineNumber = parseInt(variableTrace.LineNumber);
			if (lineNumber && lineNumber > 0) {
				validLineNumber = true;
			}

			return isUnique && validLineNumber;
		},
	},
});
