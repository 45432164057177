var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      staticClass: "mx-auto my-4",
      attrs: {
        color: "primary",
        dark: "",
        flat: "",
        width:
          _vm.$attrs.width ?? (_vm.$vuetify.breakpoint.mobile ? "75%" : "50%"),
        "data-test": "loader-card",
      },
    },
    [
      _c(
        "v-card-text",
        [
          _vm._v(" " + _vm._s(_vm.loadingText) + " "),
          _c("v-progress-linear", {
            staticClass: "mb-0",
            attrs: { indeterminate: "", color: "white" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }