var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-chip",
    _vm._g(
      _vm._b(
        {
          staticClass: "my-1 mx-1 font-weight-medium",
          attrs: { color: _vm.color, outlined: _vm.outlined, small: "" },
        },
        "v-chip",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "v-icon",
        {
          class: _vm.iconOnlyLoading ? "px-1" : "",
          attrs: {
            color: _vm.chipIconColor ?? _vm.color,
            left: !_vm.iconOnly,
            size: _vm.iconOnlyLoading ? 16 : 24,
          },
        },
        [_vm._v(" " + _vm._s(_vm.chipIcon) + " ")]
      ),
      _vm.loading
        ? [
            _vm.iconOnly
              ? _c(
                  "div",
                  { staticStyle: { position: "absolute", left: "14px" } },
                  [
                    _c("v-progress-circular", {
                      attrs: {
                        indeterminate: "",
                        color: _vm.color,
                        size: 22,
                        width: 2,
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticStyle: { "min-width": "50px" } },
                  [
                    _c("v-progress-linear", {
                      attrs: {
                        indeterminate: "",
                        rounded: "",
                        color: _vm.color,
                        height: "5",
                      },
                    }),
                  ],
                  1
                ),
          ]
        : [
            !_vm.iconOnly
              ? [_vm._v(" " + _vm._s(_vm.textPrettyString(_vm.chipText)) + " ")]
              : _vm._e(),
          ],
      _vm.loadErrorState
        ? [
            _c(
              "v-icon",
              { staticClass: "ml-1", attrs: { size: "14", color: "warning" } },
              [_vm._v(" mdi-cloud-alert-outline ")]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }