var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "pa-2 pa-sm-3", attrs: { rounded: "lg", outlined: "" } },
    [
      _vm.bucketData
        ? [
            _c(
              "v-row",
              { attrs: { "no-gutters": "" } },
              [
                _c(
                  "v-col",
                  { attrs: { "align-self": "baseline" } },
                  [
                    _c("v-icon", { staticClass: "mr-1" }, [
                      _vm._v(_vm._s(_vm.dataSourceIcon("Bucket"))),
                    ]),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "", "allow-overflow": true },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g(
                                      _vm._b({}, "span", attrs, false),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.bucketData.Name) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          829425381
                        ),
                      },
                      [
                        _c("div", [
                          _vm._v(
                            " Bucket Owner: " + _vm._s(_vm.bucketData.Account)
                          ),
                          _c("br"),
                          _vm._v(
                            " Bucket ARN: arn:aws:s3:::" +
                              _vm._s(_vm.bucketData.Name)
                          ),
                          _c("br"),
                          _vm._v(
                            " Unique Source ID: " +
                              _vm._s(_vm.bucketData.BucketID) +
                              " "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c("v-hover", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ hover }) {
                          return [
                            _c(
                              "v-col",
                              { staticClass: "mx-2", attrs: { cols: "auto" } },
                              [
                                _c(
                                  "v-fade-transition",
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "", "open-delay": "500" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c("v-progress-circular", {
                                                    staticStyle: {
                                                      position: "relative",
                                                      top: "0px",
                                                      right: "-28px",
                                                    },
                                                    attrs: {
                                                      indeterminate:
                                                        _vm.bucketDisconnectionInProgress,
                                                      color: "danger",
                                                      width:
                                                        _vm.bucketDisconnectionInProgress
                                                          ? 2
                                                          : 0,
                                                    },
                                                  }),
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "transition-slow-in-slow-out",
                                                          class: hover
                                                            ? "mx-0"
                                                            : "mx-1",
                                                          attrs: {
                                                            outlined: "",
                                                            size: hover
                                                              ? "24"
                                                              : "16",
                                                            color:
                                                              hover ||
                                                              _vm.bucketDisconnectionInProgress
                                                                ? "danger"
                                                                : "green",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.requestSourceDisconnect,
                                                          },
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [_vm._v("mdi-connection")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v("Disconnect")])]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3530875065
                  ),
                }),
              ],
              1
            ),
            _c(
              "v-row",
              {
                staticClass: "text-body-2 mt-1 ml-1",
                attrs: { "no-gutters": "" },
              },
              [
                _c("v-col", { staticClass: "ml-0 ml-sm-2" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { small: "" } },
                        [_vm._v("mdi-shield-account-outline")]
                      ),
                      _vm._v("Permissions: "),
                      _c(
                        "v-icon",
                        {
                          staticClass: "ml-1 mr-1",
                          attrs: {
                            small: "",
                            color: _vm.bucketData.Read ? "accent" : "warning",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.bucketData.Read ? "mdi-check" : "mdi-cancel"
                            )
                          ),
                        ]
                      ),
                      _vm._v("Read "),
                      _c(
                        "v-icon",
                        {
                          staticClass: "ml-2 mr-1",
                          attrs: {
                            small: "",
                            color: _vm.bucketData.Write ? "accent" : "warning",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.bucketData.Write ? "mdi-check" : "mdi-cancel"
                            )
                          ),
                        ]
                      ),
                      _vm._v("Write "),
                    ],
                    1
                  ),
                  !_vm.$vuetify.breakpoint.mobile
                    ? _c(
                        "div",
                        { staticClass: "mr-3" },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { small: "" } },
                            [_vm._v("mdi-checkbox-marked-circle-plus-outline")]
                          ),
                          _vm._v(
                            "Created: " +
                              _vm._s(_vm.formatDate(_vm.bucketData.CreatedAt)) +
                              " "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "mr-3" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { small: "" } },
                        [_vm._v("mdi-update")]
                      ),
                      _vm._v(
                        "Last Updated: " +
                          _vm._s(_vm.formatDate(_vm.bucketData.UpdatedAt)) +
                          " "
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "v-col",
                  {
                    staticClass: "pt-1 pt-sm-0",
                    attrs: {
                      cols: _vm.$vuetify.breakpoint.mobile ? "12" : "auto",
                      "align-self": "end",
                    },
                  },
                  [
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "", justify: "end" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("TooltipButton", {
                              attrs: {
                                color: "accent",
                                width: "50px",
                                target: "_blank",
                                exact: true,
                                href: `https://s3.console.aws.amazon.com/s3/buckets/${_vm.bucketData.Name}?tab=objects`,
                                btnIcon: "mdi-database-eye-outline",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "tooltipContent",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-1",
                                                attrs: {
                                                  color: "white",
                                                  small: "",
                                                },
                                              },
                                              [_vm._v("mdi-open-in-new")]
                                            ),
                                            _vm._v(" View Files "),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2234912450
                              ),
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "mx-1" }),
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("TooltipButton", {
                              attrs: {
                                color: "primary",
                                width: "50px",
                                tooltipText: "Configure Bucket",
                                btnIcon: "mdi-cog-outline",
                              },
                              on: { click: _vm.requestSourceEdit },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _c("v-skeleton-loader", { attrs: { type: "list-item-avatar" } }),
      _vm.showEditDialog
        ? _c("DataSourceConfigDialog", {
            attrs: {
              showDialog: _vm.showEditDialog,
              editSourceData: _vm.editSourceData,
            },
            on: {
              "update:showDialog": function ($event) {
                _vm.showEditDialog = $event
              },
              "update:show-dialog": function ($event) {
                _vm.showEditDialog = $event
              },
              "bucket-config-updated": _vm.getBucketInfo,
              "bucket-connected": _vm.bucketConnecionHandler,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }