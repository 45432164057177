
import { AccountTierDetail, TierFeatureHighlight, tiers } from "@/js/tierconfig";
import { useUserStore } from "@/stores/user";
import { mapState } from "pinia";
import { defineComponent, PropType } from "vue";

// Types

// Libraries

// Utilities
import * as util from "@/js/utilities";
import * as signaloidClient from "@/js/signaloidClient";

// Components
import TooltipButton from "@/components/Common/TooltipButton.vue";
import NoticeCard from "@/components/Common/NoticeCard.vue";
import TierHighlightsList from "@/components/Common/TierHighlightsList.vue";
import { ObjectValues } from "@/types/utilities";

// Stores

// Global variables

const TierSelectStateE = {
	ShowOptions: "ShowOptions",
	TierChosen: "TierChosen",
	WaitingForAPIResponse: "WaitingForAPIResponse",
	SubscribeToTrial: "SubscribeToTrial",
	SubscribeToFreeTier: "SubscribeToFreeTier",
	TierSubscribed: "TierSubscribed",
	FreeTrialStarted: "FreeTrialStarted",
	TierSubscribeError: "TierSubscribeError",
} as const;
type TierSelectState = ObjectValues<typeof TierSelectStateE>;

type ComponentData = {
	tierSelectState: TierSelectState;
};

export default defineComponent({
	name: "TierSelectionSlide",
	components: { TooltipButton, TierHighlightsList, NoticeCard },
	props: {
		showDialog: { type: Boolean, default: false },
		metadata: { type: Object },
		trialTierCode: { type: String, default: "pro-tier" },
		trialDurationMonths: { type: Number, default: 2 },
		initialSlide: {
			type: String as PropType<"ShowOptions" | "SubscribeToTrial" | "SubscribeToFreeTier">,
			default: TierSelectStateE.SubscribeToTrial,
		},
	},
	emits: {
		slideEnd: () => true,
	},
	data: (): ComponentData => ({
		tierSelectState: TierSelectStateE.ShowOptions,
	}),
	setup() {
		const userStore = useUserStore();
		return { userStore, util, TierSelectStateE };
	},
	watch: {
		tierSelectState: {
			handler: function (newValue: TierSelectState) {
				switch (newValue) {
					case TierSelectStateE.SubscribeToTrial:
						this.subscribeToProTrial();
						break;
					case TierSelectStateE.SubscribeToFreeTier:
						this.subscribeToFreeTier();
						break;
					case TierSelectStateE.TierSubscribed:
						this.$emit("slideEnd");
						break;
					default:
						break;
				}
			},
			immediate: true,
		},
	},
	computed: {
		...mapState(useUserStore, {
			currentUserTierDetails: "currentUserTierDetails",
		}),
		sideImageURL() {
			const imagePath = "images/overviewVideoThumbnail_abstract.png";
			return new URL(imagePath, process.env.VUE_APP_ASSET_SERVER_URL).toString();
		},
		trialTierDetails(): AccountTierDetail | undefined {
			return tiers.find((e) => e.code === this.trialTierCode);
		},
		tierHighlightsToDisplay(): TierFeatureHighlight[] {
			return this.trialTierDetails?.shortVersionPoints ?? [];
		},
	},
	methods: {
		async subscribeToProTrial() {
			try {
				//TODO:  check current sub
				const currentUserSub = await this.userStore.getCurrentUserSubscriptions();

				// if not tier, try to init free trial
				await signaloidClient.subscribeToFreeTrial("ProTier");

				// if free trial, push to end
				await this.userStore.refreshCurrentUserSession();
				this.tierSelectState = "FreeTrialStarted";
			} catch (error) {
				// if error, push to tier select
				this.tierSelectState = "SubscribeToFreeTier";
			}
		},
		async subscribeToFreeTier() {
			try {
				//TODO:  check current subal

				// if not tier, try to init free trial
				await signaloidClient.subscribeToFreeTier();

				// if free trial, push to end
				await this.userStore.refreshCurrentUserSession();
				this.tierSelectState = "TierSubscribed";
			} catch (error) {
				// if error, push to tier select
				this.tierSelectState = "TierSubscribeError";
			}
		},
		validateAndContinue() {
			switch (this.tierSelectState) {
				case TierSelectStateE.ShowOptions:
					this.tierSelectState = TierSelectStateE.TierChosen;
					break;
				case TierSelectStateE.TierChosen:
					this.tierSelectState = TierSelectStateE.WaitingForAPIResponse;
					break;
				case TierSelectStateE.WaitingForAPIResponse:
					this.tierSelectState = TierSelectStateE.TierSubscribed;
					break;
				case TierSelectStateE.TierSubscribed:
					console.warn(
						"🚀 ~ file: TierSelectionSlide.vue:273 ~ validateAndContinue ~ TierSelectStateE.TierSubscribed:",
						TierSelectStateE.TierSubscribed
					);
					this.tierSelectState = TierSelectStateE.FreeTrialStarted;
					break;
				case TierSelectStateE.FreeTrialStarted:
					this.$emit("slideEnd");
					break;

				default:
					break;
			}
		},
	},
	mounted() {
		this.tierSelectState = this.initialSlide;
	},
});
