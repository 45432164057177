var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _vm.tierSelectState == _vm.TierSelectStateE.SubscribeToTrial ||
      _vm.tierSelectState == _vm.TierSelectStateE.SubscribeToFreeTier ||
      _vm.tierSelectState == _vm.TierSelectStateE.TierSubscribeError
        ? _c(
            "NoticeCard",
            [
              _c("div", { staticClass: "text-h5" }, [
                _vm._v("Initializing your account"),
              ]),
              _c(
                "v-row",
                {
                  staticStyle: { height: "300px" },
                  attrs: { justify: "center", align: "center" },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _vm.tierSelectState ===
                      _vm.TierSelectStateE.TierSubscribeError
                        ? [
                            _c(
                              "v-alert",
                              { attrs: { type: "warning", outlined: "" } },
                              [
                                _vm._v(
                                  " A network error occurred while initializing your account. Please try again in a bit. " +
                                    _vm._s(
                                      _vm.util.ContactSignaloidSupportMessage
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-center pt-5" },
                              [
                                _c("TooltipButton", {
                                  attrs: {
                                    "btn-text": "Retry",
                                    color: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.tierSelectState =
                                        _vm.TierSelectStateE.SubscribeToTrial
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _c("v-progress-circular", {
                            attrs: {
                              color: "accent",
                              indeterminate: "",
                              size: "64",
                            },
                          }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.tierSelectState == _vm.TierSelectStateE.FreeTrialStarted
        ? _c(
            "v-card",
            {
              staticClass: "card-grid pa-6",
              attrs: {
                "min-height": "400px",
                "max-width": "650px",
                width: "100%",
              },
            },
            [
              _c("div", { staticClass: "card-grid-title" }, [
                _c("div", { staticClass: "text-h5" }, [
                  _vm._v("Your account is ready!"),
                ]),
                _c("div", { staticClass: "text-body font-italic mt-1" }, [
                  _vm._v(
                    " Enjoy " +
                      _vm._s(
                        _vm.util.numberLessThanTenToWords(
                          _vm.trialDurationMonths
                        )
                      ) +
                      " months of free access to the " +
                      _vm._s(_vm.trialTierDetails?.fullTitle) +
                      ". "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "card-grid-side-image" }, [
                _c("img", {
                  staticClass: "side-image",
                  attrs: { src: _vm.sideImageURL },
                }),
              ]),
              _c(
                "v-card-text",
                {
                  staticClass: "px-1 card-grid-details d-flex flex-column",
                  staticStyle: { "min-width": "300px" },
                },
                [
                  _c("div", { staticClass: "text-subtitle-1 text--primary" }, [
                    _vm._v("You now have access to the following features:"),
                  ]),
                  _c("TierHighlightsList", {
                    staticClass: "mt-3",
                    attrs: {
                      tierHighlightsToDisplay: _vm.tierHighlightsToDisplay,
                    },
                  }),
                  _c("div", { staticClass: "text-body font-italic mt-5" }, [
                    _vm._v(
                      " After the trial period, you can keep using the Free Tier features or subscribe to one of the paid tiers. "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card-grid-actions" },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("TooltipButton", {
                            attrs: {
                              color: "accent",
                              width: "200px",
                              "btn-text": "Continue",
                            },
                            on: { click: _vm.validateAndContinue },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }