<template>
	<v-dialog
		v-model="showDialog"
		@click:outside="closeDialog"
		@keydown.esc="closeDialog"
		scrollable
		:width="$vuetify.breakpoint.mobile ? '95%' : '500px'"
	>
		<v-card class="pb-1">
			<v-card-title class="py-3 px-4"
				>Gateway Data Streams
				<v-spacer></v-spacer>
				<v-btn
					color="white"
					class="text--primary"
					small
					outlined
					height="36"
					elevation="0"
					@click="closeDialog"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider></v-divider>
			<v-card-text
				style="max-width: 1000px"
				class="py-2 px-1"
			>
				<LoadingCard
					v-if="loadingFiles"
					loadingText="Loading Data Stream"
				>
				</LoadingCard>

				<div v-else>
					<v-list
						v-if="dataFileList && dataFileList.length > 0"
						max-width="500"
						dense
						flat
					>
						<v-list-item
							v-for="(file, i) in dataFileList"
							:key="i"
						>
							<v-list-item-icon>
								<v-icon>mdi-file-chart-outline</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title v-text="formatFilePath(file.path)"></v-list-item-title>
								<v-list-item-subtitle
									>Updated at {{ formatDate(file.updated_at) }}</v-list-item-subtitle
								>
							</v-list-item-content>
						</v-list-item>
					</v-list>

					<!-- <v-treeview
						v-if="dataFileList && dataFileList.length>0"
						v-model="fileListBuffer"
						:items.sync="dataFileList"
						:open.sync="openTrees"
						:active.sync="chosenFile"
						item-key="path"
						expand-icon="mdi-chevron-down"
						return-object
						transition
						dense
						class="text-body-2 mr-2"
						:activatable="selectable"
					>
						<template v-slot:prepend="{ open }">
							<v-icon small>
								{{ open ? 'mdi-folder-open' : 'mdi-file-document-outline' }}
							</v-icon>
						</template>
					</v-treeview> -->
					<v-alert
						v-else
						class="d-flex justify-center my-5 mx-auto"
						outlined
						width="80%"
						type="warning"
						dense
						border="left"
					>
						No data streams found...
					</v-alert>
				</div>
			</v-card-text>
			<template v-if="selectable">
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						elevation="0"
						width="150px"
					>
						Set Data Source
					</v-btn>
				</v-card-actions>
			</template>
		</v-card>
	</v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import { getThingFiles } from "@/js/signaloidClient";
import moment from "moment";
import LoadingCard from "@/components/LoadingCard.vue";
import * as dsUtil from "@/components/DataSources/utilities";
import { monitoringCaptureError } from "@/plugins/monitoring";

export default defineComponent({
	name: "GatewayDataStreamsDialog",
	components: { LoadingCard },
	props: {
		showDialog: {
			type: Boolean,
			default: false,
		},
		selectable: {
			type: Boolean,
			default: false,
		},
		gatewayID: {
			type: String,
			default: "",
		},
	},
	data: () => ({
		dataFileList: undefined,
		fileListBuffer: [],
		openTrees: [],
		chosenFile: [],
		loadingFiles: false,
	}),
	methods: {
		closeDialog() {
			this.$emit("update:showDialog", false);
		},
		async getGatewayFiles() {
			this.loadingFiles = true;
			try {
				const response = await getThingFiles(this.gatewayID);
				this.dataFileList = dsUtil.parseGatewayFiles(response.data.items);
				this.dataFileList.sort((a, b) => {
					return b.updated_at - a.updated_at;
				});
			} catch (error) {
				monitoringCaptureError(error, "Fetch gateway files list");
			} finally {
				this.loadingFiles = false;
			}
		},
		formatFilePath(path) {
			// Remove the gateway ID from the file path
			return path.split("/").slice(1).join("/");
		},
		formatDate(dtString) {
			if (dtString > 0) {
				return moment.unix(dtString).format("HH:mm:ss on DD MMM YYYY");
			} else {
				return "---";
			}
		},
	},
	mounted() {
		this.getGatewayFiles();
	},
});
</script>

<style lang="scss" scoped></style>
