
import { TaskStatusTransition } from "@/types/api/tasks";
import * as util from "@/js/utilities";
import { defineComponent, PropType } from "vue";
import moment from "moment";

// Types

// Libraries

// Utilities

// Components

// Stores

// Global variables

export default defineComponent({
	name: "TaskStatusTransitionTooltip",
	components: {},

	props: {
		taskStatusTransitions: {
			type: Array as PropType<TaskStatusTransition[]>,
			default: () => [],
		},
	},
	data: (): { d1: undefined | string } => ({
		d1: undefined,
	}),
	setup() {
		return { util };
	},

	methods: {
		formatTimestamp(timestampInSeconds) {
			return `${util.secondsSinceEpochToDateString(timestampInSeconds)} at ${moment(
				timestampInSeconds * 1000
			).format("HH:mm:ss")}`;
		},
	},
	computed: {
		timelineEvents(): TaskStatusTransition[] {
			return this.taskStatusTransitions;
		},
	},
	mounted() {
		console.log("mounted");
	},
});
