var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.$vuetify.breakpoint.mobile ? "95%" : "650px" },
      on: {
        "click:outside": _vm.closeDialog,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeDialog.apply(null, arguments)
        },
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "card-grid pa-6",
          attrs: { "min-height": "400px", "data-test": "upgrade-hint-dialog" },
        },
        [
          _c("div", { staticClass: "card-grid-title" }, [
            _c("div", { staticClass: "text-h5" }, [
              _vm._v(" " + _vm._s(_vm.title) + " "),
            ]),
          ]),
          _c("div", { staticClass: "card-grid-side-image" }, [
            _c("img", {
              staticClass: "side-image",
              attrs: { src: _vm.sideImageURL, height: "100%" },
            }),
          ]),
          _c(
            "v-card-text",
            {
              staticClass: "px-1 card-grid-details d-flex flex-column",
              staticStyle: { "min-width": "300px" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "text-subtitle-2 font-weight-regular text--primary",
                },
                [_vm._v(" " + _vm._s(_vm.description) + " ")]
              ),
              _c("v-spacer"),
              _c(
                "div",
                {
                  staticClass:
                    "text-subtitle-2 font-weight-regular text--primary",
                },
                [_vm._v(" " + _vm._s(_vm.extraDescription) + " ")]
              ),
              _c("v-spacer"),
              _c(
                "div",
                {
                  staticClass:
                    "text-subtitle-2 font-weight-regular text--primary",
                },
                [
                  _vm._v(
                    " If this error persists, please contact support at developer-support@signaloid.com. "
                  ),
                ]
              ),
              _c("v-col", { staticStyle: { padding: "0" } }, [
                _c(
                  "div",
                  {
                    staticClass: "d-inline-flex justify-start",
                    staticStyle: { "align-items": "end" },
                  },
                  [
                    _c("div", { staticClass: "font-weight-medium" }, [
                      _vm._v("Task ID:"),
                    ]),
                    _c(
                      "code",
                      {
                        staticClass:
                          "text-caption font-weight-regular truncated",
                        staticStyle: {
                          display: "inline",
                          "margin-left": "0.3em",
                          padding: "0.1em 0.6em",
                        },
                        attrs: { "data-test": "task-card-task-id-value" },
                      },
                      [_vm._v(_vm._s(_vm.taskID))]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: { height: "16px", "margin-bottom": "1em" },
                      },
                      [
                        _c("CopyToClipboardButton", {
                          attrs: {
                            "value-to-copy": _vm.taskID,
                            "value-name": "Task ID",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _c("v-spacer"),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "card-grid-side-actions" },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("TooltipButton", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showTaskDetail,
                            expression: "showTaskDetail",
                          },
                        ],
                        attrs: {
                          color: "accent",
                          width: "200px",
                          "btn-icon": _vm.upgradeButtonStyling.icon,
                          "tooltip-text": _vm.upgradeButtonStyling.text,
                          "btn-text": _vm.upgradeButtonStyling.text,
                        },
                        on: { click: _vm.goToTaskManagerPage },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }