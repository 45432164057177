
import { defineComponent } from "vue";
import { createDrive, editDriveByID } from "@/js/signaloidClient";

import MountDataSourcesDialog from "@/components/MountDataSourcesDialog.vue";
import DataSourceInlineCard from "@/components/DataSources/DataSourceInlineCard.vue";
import SourcePlaceholderCard from "@/components/DataSources/SourcePlaceholderCard.vue";
import TooltipButton from "@/components/Common/TooltipButton.vue";
import { monitoringCaptureError } from "@/plugins/monitoring";

import { cloneDeep } from "lodash";
import { mapState } from "pinia";
import { useDataSourcesStore } from "@/stores/dataSources";
import { DataDrive, LocalMountConfig } from "@/types/api/dataSources";
import axios from "axios";

type ComponentData = {
	formValid: boolean;
	alert: Alert;
	modifyDriveConfigRequest: boolean;
	showMountDataSourcesDialog: boolean;
	savingDriveConfig: boolean;
	mountConfigToUpdate: undefined | LocalMountConfig;
	dataSourcesToRemove: any[];
	driveConfig: Partial<DataDrive>;
};

type Alert = {
	show: boolean;
	text: string;
	type: string;
};

type DataSourceType = {
	text: string;
	value: string;
	disabled: boolean;
};

export default defineComponent({
	name: "DriveConfigDialog",
	components: { MountDataSourcesDialog, DataSourceInlineCard, SourcePlaceholderCard },
	props: {
		showDialog: { type: Boolean, default: false },
		driveConfigToUpdate: { type: Object, default: undefined },
	},
	emits: {
		"update:showDialog": (data: boolean) => true,
		"update-drives": () => true,
		"drive-config-updated": (data: Partial<DataDrive>) => true,
		"drive-created": (data: Partial<DataDrive>) => true,
	},
	data: (): ComponentData => ({
		formValid: false,
		alert: { show: false, text: "", type: "warning" },
		modifyDriveConfigRequest: false,
		showMountDataSourcesDialog: false,
		savingDriveConfig: false,
		mountConfigToUpdate: undefined,
		dataSourcesToRemove: [],
		driveConfig: { DriveID: undefined, Name: undefined, DataSources: [] },
	}),
	computed: {
		dialogTitle(): string {
			return this.modifyDriveConfigRequest ? "Modify Data Drive" : "Create a Data Drive";
		},
		rules() {
			return {
				required: (value) => !!value || "Required.",
			};
		},
		connectedDataSources(): string[] {
			if (!this.driveConfig.DataSources) {
				return [];
			}

			return this.driveConfig.DataSources.map((x) => {
				return x.ResourceID;
			});
		},
		...mapState(useDataSourcesStore, { driveIDList: "driveList" }),
	},
	methods: {
		closeDialog() {
			this.$emit("update:showDialog", false);
			this.alert = { show: false, text: "", type: "warning" };
		},
		async saveDriveConfig() {
			this.alert = { show: false, text: "", type: "warning" };
			//@ts-ignore FIXME:validate that no mount point is repeated
			this.$refs.driveConfigForm.validate();
			if (this.formValid) {
				this.savingDriveConfig = true;
				try {
					if (this.modifyDriveConfigRequest) {
						// HACK: workaround to keep the inline card from disappearing before the response succeeds
						if (!this.driveConfig.DriveID || !this.driveConfig) {
							throw new Error("incorrect Drive config not specified");
						}
						// @ts-ignore FIXME:
						const updatedDriveConfig = this.removeMarkedSourcesFromDriveConfig(cloneDeep(this.driveConfig));
						const resp = await editDriveByID(this.driveConfig.DriveID, updatedDriveConfig);
						this.$emit("update-drives");
						this.$emit("drive-config-updated", this.driveConfig);
						this.closeDialog();
					} else {
						if (!this.driveConfig.Name || !this.driveConfig.DataSources) {
							throw new Error("Invalid Drive name or data source config not specified");
						}
						const resp = await createDrive(this.driveConfig.Name, this.driveConfig.DataSources);
						this.$emit("update-drives");
						this.$emit("drive-created", this.driveConfig);
						this.closeDialog();
					}
				} catch (error) {
					monitoringCaptureError(error, "Save drive config");

					if (axios.isAxiosError(error)) {
						if (error.response) {
							if (error.response.status == 403) {
								this.alert = {
									show: true,
									text: `Error: You have exceeded the Data Drive limit for your tier.`,
									type: "error",
								};
							}
						} else if (error.request) {
							this.alert = {
								show: true,
								text: `Error: Could not create a Data Drive. Please try again.`,
								type: "error",
							};
						} else {
							this.alert = {
								show: true,
								text: `Error: Could not create a Data Drive. Please try again.`,
								type: "error",
							};
						}
					} else {
						this.alert = {
							show: true,
							text: `Error: Could not create a Data Drive. Please try again.`,
							type: "error",
						};
					}
				} finally {
					this.savingDriveConfig = false;
				}
			}
		},
		openMountDataSourcesDialog(initialMountConfig) {
			this.mountConfigToUpdate = initialMountConfig;
			this.showMountDataSourcesDialog = true;
		},
		addDataSource(dataSource: LocalMountConfig) {
			if (!this.driveConfig.DataSources) {
				this.driveConfig.DataSources = [];
			}
			this.driveConfig.DataSources.push({
				// Object: "DataSource",
				ResourceID: dataSource.ResourceID,
				ResourceType: dataSource.ResourceType,
				Location: dataSource.Location,
			});

			this.closeMountDataSourcesDialog();
		},
		addSourceToDisconnectList(dataSourceID: string) {
			this.dataSourcesToRemove.push(dataSourceID);
		},
		removeSourceFromDisconnectList(dataSourceID: string) {
			this.dataSourcesToRemove.splice(
				this.dataSourcesToRemove.findIndex((x) => x === dataSourceID),
				1
			);
		},
		removeMarkedSourcesFromDriveConfig(driveConfig: DataDrive) {
			if (this.dataSourcesToRemove) {
				this.dataSourcesToRemove.forEach((sourceIDToRemove) => {
					driveConfig.DataSources.splice(
						driveConfig.DataSources.findIndex((x) => x.ResourceID === sourceIDToRemove),
						1
					);
				});
			}
			return driveConfig;
		},
		closeMountDataSourcesDialog() {
			this.showMountDataSourcesDialog = false;
		},
	},

	mounted() {
		if (this.driveConfigToUpdate) {
			this.modifyDriveConfigRequest = true;
			this.driveConfig = cloneDeep(this.driveConfigToUpdate);
		}
	},
});
