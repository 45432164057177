var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "px-1 px-sm-2", attrs: { flat: "" } },
    [
      _c(
        "v-card-title",
        {
          staticClass: "pb-3 pt-1 pt-sm-4 px-1 px-sm-4",
          staticStyle: { "min-height": "64px" },
        },
        [
          _c("div", { staticClass: "text-h6 font-weight-bold" }, [
            _vm._v("Usage"),
          ]),
          _c("v-spacer"),
        ],
        1
      ),
      _c("v-divider", { staticClass: "mb-2 mb-sm-3 mx-1 mx-sm-4" }),
      _c(
        "v-container",
        {
          staticClass: "px-sm-4 px-1 py-sm-2 py-1 mx-auto",
          attrs: { fluid: "" },
        },
        [
          _c(
            "div",
            { staticClass: "usage-grid" },
            [
              _c(
                "v-row",
                {
                  staticClass: "grid-user-summary",
                  attrs: { justify: "center", "no-gutters": "" },
                },
                [_c("UserUsageAllowanceCard")],
                1
              ),
              _c(
                "div",
                { staticClass: "grid-usage-history" },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "mx-auto",
                      attrs: {
                        flat: "",
                        outlined: "",
                        rounded: "lg",
                        height: "100%",
                        "min-height": "250px",
                        "max-width": "700px",
                      },
                    },
                    [_c("UsageLineChart")],
                    1
                  ),
                ],
                1
              ),
              _c("section", { staticClass: "grid-usage-detail" }, [
                _c(
                  "div",
                  { staticClass: "usage-card-grid" },
                  [
                    _c("UsageDetailCard", {
                      attrs: {
                        title: "Compute",
                        icon: "mdi-memory",
                        subtitle: _vm.usageCardSubtitle,
                        usageQuotas: _vm.computeUsage,
                      },
                    }),
                    _c("UsageDetailCard", {
                      attrs: {
                        title: "Connections",
                        subtitle: {
                          default: "",
                          warning: "Upgrade to connect more resources.",
                        },
                        icon: "mdi-connection",
                        usageQuotas: _vm.connectionsUsage,
                      },
                    }),
                    _c("UsageDetailCard", {
                      attrs: {
                        title: "Storage",
                        subtitle: {
                          default: "",
                          warning: "Upgrade to get more storage and drives.",
                        },
                        icon: "mdi-database",
                        usageQuotas: _vm.storageUsage,
                      },
                    }),
                    _c("UsageDetailCard", {
                      attrs: {
                        title: "API",
                        icon: "mdi-api",
                        subtitle: {
                          default: "",
                          warning: "Upgrade to get more storage and drives.",
                          error:
                            "Upgrade to enable API access to Signaloid Cloud",
                        },
                        usageQuotas: _vm.apiUsage,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }