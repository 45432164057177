var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      staticClass: "text-center",
      attrs: { width: _vm.$vuetify.breakpoint.mobile ? "95%" : "500px" },
      on: {
        "click:outside": _vm.closeDialog,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeDialog.apply(null, arguments)
        },
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "pb-1",
          attrs: { "data-test": "sig-cloud-storage-file-upload-dialog" },
        },
        [
          _c(
            "v-card-title",
            { staticClass: "py-3 px-4" },
            [
              _vm._v(_vm._s(_vm.dialogTitle) + " "),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "text--primary",
                  attrs: {
                    color: "white",
                    small: "",
                    outlined: "",
                    height: "36",
                    elevation: "0",
                  },
                  on: { click: _vm.closeDialog },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "py-1 px-4" },
            [
              _vm.validateAndUpload
                ? [
                    _c(
                      "v-row",
                      {
                        staticClass: "mb-2",
                        attrs: { justify: "center", "no-gutters": "" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "mr-3" },
                          [
                            _c("v-progress-circular", {
                              staticStyle: {
                                position: "relative",
                                top: "0px",
                                right: "-21px",
                              },
                              attrs: {
                                indeterminate: _vm.uploadInProgress,
                                color: _vm.uploadButtonStyle.colour,
                                width: _vm.uploadInProgress ? "2" : "0",
                                size: "25",
                              },
                            }),
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  small: "",
                                  color: _vm.uploadButtonStyle.colour,
                                },
                              },
                              [_vm._v("mdi-cloud-upload-outline")]
                            ),
                          ],
                          1
                        ),
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.filesToUpload.length) +
                              " File(s) uploading to "
                          ),
                          _c("em", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.uploadDestination.split("/").slice(-1)[0]
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "v-slide-y-transition",
                          { staticClass: "py-0", attrs: { group: "" } },
                          [
                            _vm._l(_vm.uploadTasks, function (uploadTask, i) {
                              return [
                                _c(
                                  "v-list-item",
                                  {
                                    key: `${i}-${uploadTask.text}`,
                                    staticClass: "py-0",
                                  },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      { staticClass: "py-0" },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "py-0",
                                            attrs: { "no-gutters": "" },
                                          },
                                          [
                                            _c("div", {
                                              staticClass: "ml-4",
                                              class:
                                                _vm.taskStatusColor(
                                                  uploadTask.status
                                                ) + "--text",
                                              domProps: {
                                                textContent: _vm._s(
                                                  uploadTask.text
                                                ),
                                              },
                                            }),
                                            _c("v-spacer"),
                                            _c(
                                              "v-scale-transition",
                                              {
                                                attrs: { "hide-on-leave": "" },
                                              },
                                              [
                                                uploadTask.status == "success"
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color:
                                                            _vm.taskStatusColor(
                                                              uploadTask.status
                                                            ),
                                                        },
                                                      },
                                                      [_vm._v(" mdi-check ")]
                                                    )
                                                  : uploadTask.status == "fail"
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color:
                                                            _vm.taskStatusColor(
                                                              uploadTask.status
                                                            ),
                                                        },
                                                      },
                                                      [_vm._v(" mdi-close ")]
                                                    )
                                                  : uploadTask.status ==
                                                    "ignored"
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color:
                                                            _vm.taskStatusColor(
                                                              uploadTask.status
                                                            ),
                                                        },
                                                      },
                                                      [_vm._v(" mdi-minus ")]
                                                    )
                                                  : _c(
                                                      "v-progress-circular",
                                                      {
                                                        staticClass:
                                                          "text-caption",
                                                        attrs: {
                                                          width: 3,
                                                          size: 25,
                                                          color:
                                                            _vm.taskStatusColor(
                                                              uploadTask.status
                                                            ),
                                                          indeterminate:
                                                            !uploadTask.uploadProgress,
                                                          value:
                                                            uploadTask.uploadProgress,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              uploadTask.uploadProgress
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-slide-y-transition", [
                                          uploadTask.inputText
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass:
                                                        "mx-auto my-3",
                                                      attrs: {
                                                        justify: "center",
                                                        "no-gutters": "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "warning--text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                uploadTask.inputText
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass:
                                                        "mx-auto text-center",
                                                      attrs: {
                                                        "no-gutters": "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mx-2",
                                                              attrs: {
                                                                color: "accent",
                                                                outlined: "",
                                                                width: "75px",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateTask(
                                                                      uploadTask.task,
                                                                      "userInput",
                                                                      true
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " mdi-check "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" Yes "),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mx-2",
                                                              attrs: {
                                                                outlined: "",
                                                                color:
                                                                  "primary",
                                                                width: "75px",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateTask(
                                                                      uploadTask.task,
                                                                      "userInput",
                                                                      false
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " mdi-cancel "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" No "),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "v-form",
                      {
                        ref: "mainForm",
                        model: {
                          value: _vm.formValid,
                          callback: function ($$v) {
                            _vm.formValid = $$v
                          },
                          expression: "formValid",
                        },
                      },
                      [
                        _c("div", { staticClass: "my-2" }, [
                          _vm._v("Destination"),
                        ]),
                        _c("v-text-field", {
                          attrs: {
                            placeholder: "Upload destination",
                            "prepend-icon": "mdi-folder-account-outline",
                            rules: [
                              _vm.rules.required,
                              _vm.rules.noSpace,
                              _vm.rules.maxSize,
                              _vm.rules.noRestrictedCharacters,
                            ],
                            outlined: "",
                            dense: "",
                            "data-test": "upload-destination-input",
                          },
                          model: {
                            value: _vm.uploadDestination,
                            callback: function ($$v) {
                              _vm.uploadDestination = $$v
                            },
                            expression: "uploadDestination",
                          },
                        }),
                        _c(
                          "v-row",
                          { attrs: { justify: "center" } },
                          [
                            _c("v-icon", { staticClass: "mb-1 mt-2" }, [
                              _vm._v(" mdi-transfer-up "),
                            ]),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "my-2" }, [_vm._v("Source")]),
                        _c("v-file-input", {
                          attrs: {
                            placeholder: "Select your files",
                            outlined: "",
                            dense: "",
                            multiple: "",
                            "prepend-icon":
                              "mdi-file-document-multiple-outline",
                            "show-size": 1000,
                            rules: [_vm.rules.atleastOneFileToUpload],
                            counter: "",
                            "data-test": "upload-file-input",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "selection",
                              fn: function ({ index, text }) {
                                return [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        color: "primary",
                                        dark: "",
                                        label: "",
                                        small: "",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(text) + " ")]
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.filesToUpload,
                            callback: function ($$v) {
                              _vm.filesToUpload = $$v
                            },
                            expression: "filesToUpload",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c(
                "v-row",
                { attrs: { "align-content": "center", "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-5" },
                    [
                      _c(
                        "v-expand-transition",
                        [
                          _c(
                            "v-alert",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showError,
                                  expression: "showError",
                                },
                              ],
                              staticClass: "text-body-2 pa-2 pl-3 mb-0",
                              attrs: {
                                icon: false,
                                border: "left",
                                type: _vm.alertType,
                                outlined: "",
                                elevation: "0",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("LimitableActionButton", {
                        attrs: {
                          color: _vm.uploadButtonStyle.colour,
                          loading: _vm.uploadInProgress,
                          "limits-to-check": [
                            _vm.UserLimitsE.CloudStorageBytes,
                          ],
                          disabled: !_vm.formValid,
                          "data-test": "upload-file-button",
                        },
                        on: { "valid-click": _vm.uploadButtonClickHandler },
                        scopedSlots: _vm._u([
                          {
                            key: "btnContent",
                            fn: function () {
                              return [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-2",
                                    attrs: {
                                      size: _vm.uploadButtonStyle.text
                                        ? "16"
                                        : "24",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.uploadButtonStyle.icon))]
                                ),
                                _vm._v(
                                  " " + _vm._s(_vm.uploadButtonStyle.text) + " "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }