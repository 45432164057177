
import { defineComponent, PropType } from "vue";
// Stores
import { mapState } from "pinia";
import { useCoresStore } from "@/stores/cores";
import { useUserStore } from "@/stores/user";

// utilities
import { createCore, editCoreByID } from "@/js/signaloidClient";
import {
	filterEveryNValue,
	symmetricFilter,
	toStringWithSIMagnitude,
	toStringWithByteMagnitude,
	coreDescriptionString,
} from "@/js/utilities";

import { monitoringCaptureError } from "@/plugins/monitoring";

// components
import UpgradeButton from "@/components/Common/UpgradeButton.vue";
import LimitableActionButton from "@/components/Common/LimitableActionButton.vue";
import DisclosureIcon from "@/components/Common/DisclosureIcon.vue";
import CopyToClipboardButton from "@/components/Common/CopyToClipboardButton.vue";

// libraries
import * as Sentry from "@sentry/vue";
import axios from "axios";

// types
import { CoreClass, CoreClassObject, CoreMicroarchitectureObject, CreateCoreConfig } from "@/types/api/cores";
import { UserLimitsE } from "@/js/tierconfig";

type PartialCoreToDisplay = CreateCoreConfig & { CoreID; Owner };

const defaultCoreData: PartialCoreToDisplay = {
	CoreID: undefined,
	Owner: "",
	Name: "",
	Class: "C0",
	Precision: 32,
	MemorySize: 2_000_000,
	Microarchitecture: "Athens",
	CorrelationTracking: "disable",
};

type ComponentData = {
	coreConfig: PartialCoreToDisplay;
	formValid: boolean;
	waitingForSeverResponse: boolean;
	remoteErrorMessage: undefined | string;
	precisionSteps: number[];
	sigmaSteps: number[];
	referencePrecisionSteps: number[];
	memorySizeSteps: number[];
	selectedPrecisionIndex: number;
	selectedMemorySizeIndex: number;
	selectedReferencePrecisionIndex: number;
	existingCoreNames: string[];
	formTitle: string;
	submitButtonText: string;
	maxCoreNameLength: number;
};

export default defineComponent({
	name: "ModifyCoreDialog",
	components: { UpgradeButton, LimitableActionButton, DisclosureIcon, CopyToClipboardButton },
	props: { showDialog: Boolean, editCoreData: { type: Object as PropType<PartialCoreToDisplay> } },
	data: (): ComponentData => ({
		coreConfig: defaultCoreData,
		formValid: false,
		waitingForSeverResponse: false,
		remoteErrorMessage: undefined,
		selectedPrecisionIndex: 3,
		selectedMemorySizeIndex: 0,
		selectedReferencePrecisionIndex: 0,
		precisionSteps: [4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192],
		sigmaSteps: [1.37, 1.83, 2.22, 2.57, 2.89, 3.18, 3.45, 3.7, 3.94, 4.17, 4.39, 4.6],
		referencePrecisionSteps: [
			4, 8, 16, 32, 64, 128, 256, 512, 1e3, 2e3, 4e3, 8e3, 16e3, 32e3, 64e3, 128e3, 256e3, 512e3, 1e6,
		],
		memorySizeSteps: [256e3, 512e3, 1e6, 2e6, 4e6, 16e6, 32e6, 64e6, 128e6, 256e6, 512e6, 1e9, 2e9],
		existingCoreNames: [],
		formTitle: "Add Execution Core",
		submitButtonText: "Create Core",
		maxCoreNameLength: 64,
	}),
	setup() {
		const coresStore = useCoresStore();
		const userStore = useUserStore();
		return { coresStore, userStore, UserLimitsE };
	},
	methods: {
		microarchitectureChangeHandler() {
			// update core precision
			this.corePrecisionChangeHandler();

			// Reset Autocorrelation tracking on microarchitecture change
			this.coreConfig.CorrelationTracking = this.supportedCorrelationTrackingMethods(
				this.coreConfig.Microarchitecture
			)[0].Code;
		},
		coreClassChangeHandler() {
			// update core precision
			this.corePrecisionChangeHandler();

			// Reset core microarchitecture on core class change
			this.coreConfig.Microarchitecture = this.microarchitecturesSupportedByCoreClass(
				this.coreConfig.Class
			)[0].Name;

			// Reset correlation tracking on core class change
			this.coreConfig.CorrelationTracking = this.supportedCorrelationTrackingMethods(
				this.coreConfig.Microarchitecture
			)[0].Code;
		},
		corePrecisionChangeHandler() {
			this.coreConfig.Precision =
				this.coreConfig.Microarchitecture === "Reference"
					? this.referencePrecisionSteps[this.selectedReferencePrecisionIndex]
					: this.precisionSteps[this.selectedPrecisionIndex];
		},
		updateCoreMemorySize() {
			this.coreConfig.MemorySize = this.memorySizeSteps[this.selectedMemorySizeIndex];
		},
		async validateAndCommitCore() {
			this.remoteErrorMessage = undefined;
			// @ts-ignore
			this.$refs.addCoreForm.validate();
			if (this.formValid) {
				try {
					this.waitingForSeverResponse = true;
					if (this.modifyCoreRequest && !this.isLocalCore) {
						const response = await editCoreByID(this.coreConfig.CoreID, this.coreConfig);
						this.$emit("core-updated", this.coreConfig);
					} else {
						const response = await createCore(this.coreConfig);

						this.$emit("core-created", this.coreConfig);

						if (this.isLocalCore) {
							// @ts-ignore deprecated code path. local cores are no longer used
							this.coresStore.removeCoreFromLocalCoresList(this.coreConfig);
						}
					}
					// @ts-ignore
					this.$refs.addCoreForm.resetValidation();
					this.resetCoreData();
					this.closeDialog();
				} catch (error) {
					if (axios.isAxiosError(error)) {
						monitoringCaptureError(error, "Save core config");
						if (error.response) {
							//non 2xx response
							const isRestrictedError = error.response.status == 403;
							this.remoteErrorMessage = isRestrictedError
								? "Could not save core due to tier restrictions."
								: "An error occurred while saving the core.";
						} else if (error.request) {
							//no response
							this.remoteErrorMessage =
								"An error occurred while saving the core. Could not connect to Signaloid servers.";
						} else {
							//making request failed
							this.remoteErrorMessage =
								"An error occurred while saving the core.Please check your internet connection.";
						}
					}
				} finally {
					this.waitingForSeverResponse = false;
				}
			}
		},
		closeDialog() {
			// @ts-ignore
			this.$refs.addCoreForm.resetValidation();
			this.resetCoreData();
			this.$emit("close-dialog");
		},
		resetCoreData() {
			this.coreConfig = defaultCoreData;
		},
		parseSliderValuesFromCoreConfig() {
			// calculate the slider indices from core config
			this.selectedMemorySizeIndex = this.memorySizeSteps.findIndex((x) => x === this.coreConfig.MemorySize) ?? 0;
			if (this.coreConfig.Microarchitecture === "Reference") {
				this.selectedReferencePrecisionIndex =
					this.referencePrecisionSteps.findIndex((x) => x === this.coreConfig.Precision) ?? 0;
			} else {
				// FIXME: this should show between steps this will cause an issue to unknown values
				this.selectedPrecisionIndex =
					this.precisionSteps.findIndex((x) => x === this.coreConfig.Precision) ?? 0;
			}
		},
		supportedCorrelationTrackingMethods(microachitecture) {
			return this.coresStore.correlationTrackingSupportedByCore(microachitecture);
		},
		microarchitecturesSupportedByCoreClass(coreClass) {
			return this.coresStore.microarchitecturesSupportedByCoreClass(coreClass);
		},
	},
	computed: {
		...mapState(useCoresStore, { filteredCoresList: "filteredCoresList" }),
		memorySizeStepsLabel(): string[] {
			return this.memorySizeSteps.map((v) => toStringWithByteMagnitude(v, ""));
		},
		currentUserTier(): string | undefined {
			return this.userStore?.currentUserTierDetails?.title;
		},
		availablePrecisionStep(): boolean {
			const thisUserRestrictions = this.userStore.currentUserTierDetails;
			if (!thisUserRestrictions) {
				return false;
			}
			const isReference = this.coreConfig.Microarchitecture === "Reference";
			const steps = isReference ? this.referencePrecisionSteps : this.precisionSteps;
			const maxPrecision = isReference
				? thisUserRestrictions.allowance.MaxReferenceQuality
				: thisUserRestrictions.allowance.MaxPrecisionBits;
			const filteredByTierPrecisionSteps = steps.filter((ps) => ps <= maxPrecision);

			const selectedIndex =
				this.coreConfig.Microarchitecture === "Reference"
					? this.selectedReferencePrecisionIndex
					: this.selectedPrecisionIndex;
			return filteredByTierPrecisionSteps.includes(steps[selectedIndex]);
		},
		availableMemorySizeStep(): boolean {
			const currentUserTier = this.userStore.currentUserTierDetails;
			if (!currentUserTier) {
				return false;
			}
			const filteredByTierMemorySteps = this.memorySizeSteps.filter(
				(ms) => ms <= currentUserTier.allowance.MaximumAttachedProcessorRam
			);
			return filteredByTierMemorySteps.includes(this.memorySizeSteps[this.selectedMemorySizeIndex]);
		},
		availableMicroarchitectures(): CoreMicroarchitectureObject[] {
			const currentUserTier = this.userStore.currentUserTierDetails;
			if (!currentUserTier) {
				return [];
			}
			const disabledByTierAvailableMicroarchitectures = this.coresStore.availableMicroarchitectures.map((am) => {
				const isAllowed = currentUserTier.allowance.AllowedMicroArchitectures.includes(am.Name);
				// Will set property if not there already
				am.Restricted = !isAllowed;
				return am;
			});
			return disabledByTierAvailableMicroarchitectures;
		},
		availableSelectedMicroarchitecture(): boolean {
			const selectedMicroarchitecture = this.coreConfig.Microarchitecture;
			const filteredArch = this.availableMicroarchitectures.filter((am) => am.Name == selectedMicroarchitecture);
			return filteredArch.length > 0 && !filteredArch[0].Restricted;
		},
		availableCoreClasses(): CoreClassObject[] {
			const currentUserTier = this.userStore.currentUserTierDetails;
			if (!currentUserTier) {
				return [];
			}
			const disabledByTierAvailableCoreClasses = this.coresStore.availableCoreClasses.map((coreClass) => {
				const isAllowed = currentUserTier.allowance.AllowedCoreClasses.includes(coreClass.Name);
				// Will set property if not there already
				coreClass.Enabled = isAllowed;
				return coreClass;
			});
			return disabledByTierAvailableCoreClasses;
		},
		availableSelectedCoreClass(): boolean {
			const selectedCoreClass = this.coreConfig.Class;
			const filteredArch = this.availableCoreClasses.filter((coreClass) => coreClass.Name == selectedCoreClass);

			return filteredArch.length > 0 && filteredArch[0].Enabled;
		},
		customCoresLimitReached(): boolean {
			const currentDefaultCoresCount = this.coresStore.getCustomCores.length;
			const currentUserTier = this.userStore.currentUserTierDetails;
			// If current user tier details are not available, be less restrictive
			if (!currentUserTier) {
				return false;
			}

			// If there is no core limit defined
			if (!currentUserTier.allowance.CoreCount) {
				return false;
			}
			const customCoreLimitReached = currentDefaultCoresCount >= currentUserTier.allowance.CoreCount;
			return customCoreLimitReached;
		},
		submitButtonDisabled(): boolean {
			// If we are on Create Popup and we reached custom core limit count
			const customCoreLimitReachedOnCreation = !this.modifyCoreRequest && this.customCoresLimitReached;
			// Precision is out of tier limit
			const precisionNotAvailable = !this.availablePrecisionStep;
			// Memory is out of tier limit
			const memoryNotAvailable = !this.availableMemorySizeStep;
			// Architecture is out of tier limit
			const microarchitectureNotAvailable = !this.availableSelectedMicroarchitecture;
			// Core class is out of tier limit
			const coreClassNotAvailable = !this.availableSelectedCoreClass;

			return (
				coreClassNotAvailable ||
				customCoreLimitReachedOnCreation ||
				precisionNotAvailable ||
				memoryNotAvailable ||
				microarchitectureNotAvailable
			);
		},
		precisionStepsLabel(): string[] {
			return this.precisionSteps.map((v) => toStringWithSIMagnitude(v, ""));
		},
		sigmaStepsLabel(): string[] {
			const sigma = String.fromCodePoint(963);
			return this.sigmaSteps.map((v) => {
				return v + sigma;
			});
		},
		sigmaStepsLabelWithFilter(): string[] {
			const sigma = String.fromCodePoint(963);
			const valuesWithSigmaSymbol = this.sigmaSteps.map((v) => {
				return v + sigma;
			});
			const sigmaSteps = symmetricFilter(valuesWithSigmaSymbol, 1);
			return sigmaSteps;
		},
		referencePrecisionStepsLabel(): string[] {
			return this.referencePrecisionSteps.map((v) => toStringWithSIMagnitude(v, ""));
		},
		filteredMemorySizeSteps(): string[] {
			if (this.$vuetify.breakpoint.mobile) {
				return filterEveryNValue(this.memorySizeStepsLabel, 3);
			} else {
				return symmetricFilter(this.memorySizeStepsLabel, 1);
			}
		},
		filteredPrecisionSteps(): string[] {
			if (this.$vuetify.breakpoint.mobile) {
				return filterEveryNValue(this.precisionStepsLabel, 2);
			} else {
				return this.precisionStepsLabel;
			}
		},
		filteredReferencePrecisionSteps(): string[] {
			return filterEveryNValue(this.referencePrecisionStepsLabel, 3);
		},
		coreSupportsCustomPrecision(): boolean {
			return this.coresStore.customPrecisionSupportedByCore(this.coreConfig.Microarchitecture);
		},
		coreSupportsSigmaEquivalent(): boolean {
			return this.coreConfig.Microarchitecture == "Athens";
		},
		modifyCoreRequest(): boolean {
			return this.editCoreData !== undefined;
		},
		isLocalCore(): boolean {
			return this.coreConfig.CoreID && !this.coreConfig.CoreID.includes("cor_");
		},
		isEditable(): boolean {
			if (this.coreConfig && this.coreConfig.Owner !== "*") {
				return true;
			}
			return false;
		},
		coreDescription() {
			if (this.coreConfig && this.coreConfig.Owner == "*") {
				const description = coreDescriptionString(this.coreConfig.Name, false);
				return description;
			} else {
				return "";
			}
		},
	},
	created() {
		this.userStore.getCurrentUserTierDetails();
		this.existingCoreNames = this.filteredCoresList.map((v) => v.Name);

		if (this.editCoreData !== undefined) {
			this.coreConfig = { ...this.editCoreData };
			this.existingCoreNames = this.existingCoreNames.filter((v) => v != this.coreConfig.Name);

			// reset correlation if invalid
			if (
				typeof this.coreConfig.CorrelationTracking === "object" &&
				this.coreConfig.CorrelationTracking !== null &&
				!Array.isArray(this.coreConfig.CorrelationTracking)
			) {
				console.warn("correlation tracking is set as an object");
				this.coreConfig.CorrelationTracking =
					// @ts-ignore deprecated code path
					this.coreConfig.CorrelationTracking?.Code ?? defaultCoreData.CorrelationTracking;
			}
		}

		this.parseSliderValuesFromCoreConfig();

		this.formTitle = !this.isEditable
			? "Execution Core Details"
			: this.modifyCoreRequest
			? "Modify Execution Core"
			: "Add Execution Core";
		this.submitButtonText = this.modifyCoreRequest ? "Save Core" : "Create Core";
	},
});
