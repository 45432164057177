var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", {
    ref: "editor",
    staticClass: "monaco-editor-container",
    style: _vm.style,
    attrs: {
      id: "monaco-editor-container",
      "data-test": "monaco-editor-container",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }