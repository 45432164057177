
import { defineComponent, PropType } from "vue";

// Types
import { UserLimits } from "@/js/tierconfig";

// Components
import LimitableActionValidator from "@/components/Common/LimitableActionValidator.vue";
import TooltipButton from "@/components/Common/TooltipButton.vue";

export default defineComponent({
	name: "LimitableActionButton",
	components: { LimitableActionValidator, TooltipButton },
	inheritAttrs: false,
	emits: {
		"valid-click": () => true,
		"invalid-click": (violatedLimits: UserLimits[]) => true,
	},
	props: {
		limitsToCheck: { type: Array as PropType<UserLimits[]>, default: () => [] },
		validationThresholdOverrides: { type: Array as PropType<any[]>, default: () => [] },
		resourceUsageOverrides: { type: Array as PropType<any[]>, default: () => [] },
		errorOnUndefinedUsage: { type: Boolean, default: false },
	},
	methods: {
		validClickHandler() {
			if (!this.$attrs.disabled) {
				this.$emit("valid-click");
			}
		},
		invalidClickHandler(exceededLimits) {
			if (!this.$attrs.disabled) {
				this.$emit("invalid-click", exceededLimits);
			}
		},
	},
});
