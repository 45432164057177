
import { defineComponent, PropType } from "vue";

// Utilities
import { getRepoData, mockTimeout, initiateGithubLoginRepositoryRetry } from "@/js/githubClient";
import { signaloidApiClient } from "@/js/signaloidClient";
import * as util from "@/js/utilities";
import { monitoringCaptureError } from "@/plugins/monitoring";
import { generateGithubResponseErrorMessage } from "@/js/githubUtilities";

// Libraries
import axios, { AxiosError } from "axios";

// Stores
import { useRepositoriesStore } from "@/stores/repositories";
import { useGithubStore } from "@/stores/github";
import { useRootStore } from "@/stores/root";

// Components
import AlertMessage from "@/components/Common/AlertMessage.vue";

// Types
import { RepoConnectionConfig } from "@/types/general";
import { Endpoints } from "@octokit/types";
import { RepositoryConnectionRequestConfig } from "@/js/signaloidClient.types";
import { TierLimitEventTypeE } from "@/eventBus/tierLimitEventBus";
import { UserLimitsE } from "@/js/tierconfig";

export const TaskE = {
	CheckRepoDoesntExist: "sig_checkExisting",
	ConnectToGithub: "gh_comm",
	CheckForSignaloidConfig: "sig_conf",
	CheckSrcPathExists: "sig_srcPath",
	CheckInputsPathExists: "sig_inputsPath",
	AddRepoToAccount: "sig_connectToRepo",
	RedirectToRepoPage: "sig_redirect",
} as const;
export type Task = (typeof TaskE)[keyof typeof TaskE];

export const TaskStatusE = {
	InProgress: "In Progress",
	Success: "Success",
	Fail: "Fail",
	Ignored: "Ignored",
	Error: "Error",
} as const;
export type TaskStatus = (typeof TaskStatusE)[keyof typeof TaskStatusE];
type AuthTaskStatusColor = "info" | "success" | "error" | "grey";

type RepoContents = Endpoints["GET /repos/{owner}/{repo}"]["response"]["data"];

type AuthTask = {
	task: Task;
	status: TaskStatus;
	text: string;
};
type ComponentData = {
	showError: boolean;
	errorMessage: string;
	authTasks: AuthTask[];
	rootContents: undefined | RepoContents;
	forceAddLoading: boolean;
	isTierRestrictionError: boolean;
};
export default defineComponent({
	name: "RepositoryConnectionValidationCard",
	components: { AlertMessage },
	props: {
		connectionConfig: { type: Object as PropType<RepoConnectionConfig>, required: true },
		dialogVisible: { type: Boolean },
	},
	emits: {
		"close-dialog": () => true,
		"repository-connected": (data: RepoConnectionConfig) => true,
	},
	data: (): ComponentData => ({
		showError: false,
		errorMessage: "",
		authTasks: [],
		rootContents: undefined,
		forceAddLoading: false,
		isTierRestrictionError: false,
	}),
	setup() {
		const repositoriesStore = useRepositoriesStore();
		const githubStore = useGithubStore();
		const rootStore = useRootStore();
		return { repositoriesStore, githubStore, rootStore, TaskStatusE };
	},
	computed: {
		fullNameOfRepoToConnect(): string {
			return this.connectionConfig.remoteURL.pathname.replace(/^\/|\/$/g, "");
		},
		alertType(): "error" | "info" {
			return this.showError ? "error" : "info";
		},
	},
	methods: {
		authTaskStatusColor(taskStatus: TaskStatus): AuthTaskStatusColor {
			switch (taskStatus) {
				case TaskStatusE.InProgress:
					return "info";
				case TaskStatusE.Success:
					return "success";
				case TaskStatusE.Fail:
					return "error";
				case TaskStatusE.Ignored:
					return "grey";
				default:
					return "error";
			}
		},
		async getRootContents() {
			try {
				const response = await signaloidApiClient.get<RepoContents>(
					`proxy/github/repos/${this.fullNameOfRepoToConnect}/contents`
				);
				this.rootContents = response.data;
			} catch (error) {
				monitoringCaptureError(error, "Connect to repository: get contents");
				if (axios.isAxiosError(error)) {
					this.errorMessage = generateGithubResponseErrorMessage(error, this.githubStore.githubLoggedIn);
				}
			}
		},
		githubLogin() {
			//@ts-ignore
			this.$posthog?.capture("github_login_initiated");

			mockTimeout(500).then(() => {
				initiateGithubLoginRepositoryRetry(this.connectionConfig.remoteURL.toString());
			});
		},
		async getDefaultBranch() {
			try {
				const response = await getRepoData(this.fullNameOfRepoToConnect);
				return response.data.default_branch;
			} catch (error) {
				return "master";
			}
		},
		async validConfigFile() {
			// let configIndex = this.rootContents.data.findIndex((x) => x.name === "signaloid.yaml");
			// return configIndex >= 0 ? true : false;
			return false;
		},
		async validSrcDirectory(srcPath) {
			if (!this.rootContents || !Array.isArray(this.rootContents)) {
				return false;
			}

			const srcIndex = this.rootContents.findIndex((x) => x.name === srcPath);
			return srcIndex >= 0 ? true : false;
		},
		async validInputsDirectory(inputsPath) {
			if (!this.rootContents || !Array.isArray(this.rootContents)) {
				return false;
			}

			const inputsIndex = this.rootContents.findIndex((x) => x.name === inputsPath);
			return inputsIndex >= 0 ? true : false;
		},
		async checkCSourceFiles(srcPath) {
			let srcContents;
			let cFileList = [];
			try {
				srcContents = await signaloidApiClient.get(`/proxy/github/repos/${this.fullNameOfRepoToConnect}/contents/${srcPath}`);
				cFileList = srcContents.data.filter((x) => {
					const fName = x.name.toLowerCase();
					return (
						fName.includes(".c") ||
						fName.includes(".cc") ||
						fName.includes(".cp") ||
						fName.includes(".cpp") ||
						fName.includes(".c++") ||
						fName.includes(".cxx")
					);
				});
			} catch (error) {
				monitoringCaptureError(error, "Connect to repository: check for source files");
			}

			return cFileList.length > 0 ? true : false;
		},
		setTaskStatus(taskName: Task, status: TaskStatus) {
			this.authTasks[this.authTasks.findIndex((x) => x.task === taskName)].status = status;
		},
		retryValidation() {
			this.authTasks = [];
			this.showError = false;
			this.errorMessage = "";
			this.validateRepositoryAndConnect();
		},
		async validateRepositoryAndConnect() {
			try {
				// Wait for the connected repo list to be loaded
				await util.waitFor(() => this.repositoriesStore.connectedRepositories !== undefined);

				if (this.repositoriesStore.connectedRepositories === undefined) {
					throw new Error("Could not fetch connected repositories. Please try again ");
				}

				const repoInConnectedList = this.repositoriesStore.connectedRepositories.find(
					(x) => x.RemoteURL === this.connectionConfig?.remoteURL.href
				);
				if (repoInConnectedList) {
					this.authTasks.push({
						task: TaskE.CheckRepoDoesntExist,
						status: TaskStatusE.Success,
						text: "Already connected to the repository...",
					});
				} else {
					this.authTasks.push({
						task: TaskE.ConnectToGithub,
						status: TaskStatusE.InProgress,
						text: "Communicating with GitHub...",
					});

					// Get contents from the root of the repository
					await this.getRootContents();

					if (this.rootContents) {
						this.setTaskStatus(TaskE.ConnectToGithub, TaskStatusE.Success);

						// Check if there is a config file...
						this.authTasks.push({
							task: TaskE.CheckForSignaloidConfig,
							status: TaskStatusE.InProgress,
							text: "Looking for Signaloid configuration file...",
						});

						const srcPath = "src";
						const inputsPath = "inputs";

						const configValid = await this.validConfigFile();
						if (configValid) {
							// TODO: get scrPath and other params from config
							this.setTaskStatus(TaskE.CheckForSignaloidConfig, TaskStatusE.Success);
						} else {
							this.setTaskStatus(TaskE.CheckForSignaloidConfig, TaskStatusE.Ignored);
						}

						// Check if the `src` path specified exists
						this.authTasks.push({
							task: TaskE.CheckSrcPathExists,
							status: TaskStatusE.InProgress,
							text: `Looking for './${srcPath}' directory...`,
						});

						// Check if the `inputs` path specified exists
						this.authTasks.push({
							task: TaskE.CheckInputsPathExists,
							status: TaskStatusE.InProgress,
							text: `Looking for input files in './${inputsPath}'...`,
						});

						const inputFilesExist = await this.validInputsDirectory(inputsPath);
						if (inputFilesExist) {
							this.setTaskStatus(TaskE.CheckInputsPathExists, TaskStatusE.Success);
						} else {
							this.setTaskStatus(TaskE.CheckInputsPathExists, TaskStatusE.Ignored);
						}

						const srcExists = await this.validSrcDirectory(srcPath);
						if (!srcExists) {
							this.setTaskStatus(TaskE.CheckSrcPathExists, TaskStatusE.Fail);
							this.errorMessage = "There is no './src' directory in the repository.";
							throw new Error(this.errorMessage);
						} else {
							this.setTaskStatus(TaskE.CheckSrcPathExists, TaskStatusE.Success);
						}

						this.authTasks.push({
							task: TaskE.AddRepoToAccount,
							status: TaskStatusE.InProgress,
							text: `Adding to your Signaloid Cloud Account...`,
						});

						const repoConnected = await this.connectToRepository();
						if (repoConnected) {
							this.setTaskStatus(TaskE.AddRepoToAccount, TaskStatusE.Success);
						} else {
							this.setTaskStatus(TaskE.AddRepoToAccount, TaskStatusE.Fail);
							throw new Error(this.errorMessage);
						}
					} else {
						this.setTaskStatus(TaskE.ConnectToGithub, TaskStatusE.Fail);
						throw new Error(this.errorMessage);
					}
				}
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error.response) {
						//non 2xx response
						this.errorMessage = `A network error occurred while connecting the repository - ${error.response}. Please try again.`;
					} else {
						this.errorMessage = `A browser error occurred while connecting the repository - Request Failed. Please check your network connection and try again.`;
					}
				} else {
					// this.errorMessage = String(error.message);
				}
				monitoringCaptureError(error, "Connect to repository");

				this.showError = true;
			} finally {
				if (!this.showError) {
					this.authTasks.push({
						task: TaskE.RedirectToRepoPage,
						status: TaskStatusE.InProgress,
						text: "redirecting...",
					});
					mockTimeout(1000).then(() => {
						this.$emit("repository-connected", this.connectionConfig);
						this.closeDialog();
					});
				}
			}
		},
		async forceAddRepository() {
			this.authTasks.push({
				task: TaskE.AddRepoToAccount,
				status: TaskStatusE.InProgress,
				text: `Forcefully adding to your Signaloid Cloud Account...`,
			});
			this.showError = false;
			this.forceAddLoading = true;
			mockTimeout(1000).then(async () => {
				await this.connectToRepository();
				this.closeDialog();
			});
		},
		async connectToRepository() {
			const defaultBranch = await this.getDefaultBranch();
			const repoConfig: RepositoryConnectionRequestConfig = {
				RemoteURL: this.connectionConfig.remoteURL.href,
				Commit: "HEAD",
				BuildDirectory: "src",
				Arguments: "",
				Branch: defaultBranch,
			};

			try {
				await this.repositoriesStore.connectToRepository(repoConfig);
				this.$emit("repository-connected", this.connectionConfig);
				this.rootStore.tierLimitEventBus.emit({
					type: TierLimitEventTypeE.UsageChanged,
					affectedLimits: [UserLimitsE.RepositoryCount],
				});
				this.setTaskStatus(TaskE.AddRepoToAccount, TaskStatusE.Success);
				return true;
			} catch (error) {
				monitoringCaptureError(error, "Connect to repository");

				if (axios.isAxiosError(error)) {
					this.errorMessage = "Could not connect to Signaloid Cloud.";
					if (error.response) {
						//non 2xx response
						console.log("Non 2xx response:", error.response);
						if (error.response.status === 403) {
							// user not allowed to perform this action
							this.isTierRestrictionError = true;
							this.rootStore.tierLimitEventBus.emit({
								type: TierLimitEventTypeE.LimitExceeded,
								affectedLimits: [UserLimitsE.RepositoryCount],
							});
							this.errorMessage = "Connected repositories limit reached.";
						}
					} else if (error.request) {
						//no response
						console.log("Request Failed:", error.request);
					} else {
						//making request failed
						console.log("Preflight Error", error);
					}
				}
				this.setTaskStatus(TaskE.AddRepoToAccount, TaskStatusE.Fail);
			}
			return false;
		},
		closeDialog() {
			this.$emit("close-dialog");
		},
	},
	async mounted() {
		await this.validateRepositoryAndConnect();
	},
});
