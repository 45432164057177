const fallbackDefaultCoreID = "cor_b21e4de9927158c1a5b603c2affb8a09"; //"signaloid_base_core_C0-S+";
const fallbackDefaultReferenceCoreID = "cor_9a3efb0094405df5aeb61cf1f29606a0"; //"signaloid_base_core_C0-Reference";

import * as signaloidClient from "@/js/signaloidClient";
import { defineStore } from "pinia";
import { useUserStore } from "@/stores/user";
import { checkCoreRequirements, toStringWithSIMagnitude } from "@/js/utilities";

import {
	Core,
	CoreClass,
	CoreClassObject,
	CoreCorrelationTypeCodeE,
	CoreCorrelationTypeE,
	CoreRequirementMatch,
	CoreMicroarchitectureObject,
	CoreCorrelationTypeObject,
	CoreMicroarchitectureE,
} from "@/types/api/cores";
import { RemoteAccessState } from "@/types/general";
import { monitoringCaptureError } from "@/plugins/monitoring";

const kJupiterEnabled: boolean = process.env.VUE_APP_ENABLE_JUPITER == "true";

export const availableMicroarchitectures: CoreMicroarchitectureObject[] = [
	{
		ID: 0,
		Name: CoreMicroarchitectureE.Zurich,
		Support: {
			CorrelationTracking: [CoreCorrelationTypeCodeE.Disabled, CoreCorrelationTypeCodeE.Autocorrelation],
			CustomPrecision: true,
			DistributionTracking: true,
		},
		Experimental: false,
		Disabled: false,
		Restricted: false,
	},
	{
		ID: 1,
		Name: CoreMicroarchitectureE.Athens,
		Support: {
			CorrelationTracking: [CoreCorrelationTypeCodeE.Disabled, CoreCorrelationTypeCodeE.Autocorrelation],
			CustomPrecision: true,
			DistributionTracking: true,
		},
		Experimental: false,
		Disabled: false,
		Restricted: false,
	},
	{
		ID: 2,
		Name: CoreMicroarchitectureE.Bypass,
		Support: {
			CorrelationTracking: [CoreCorrelationTypeCodeE.Disabled],
			CustomPrecision: false,
			DistributionTracking: false,
		},
		Experimental: false,
		Disabled: false,
		Restricted: false,
	},
	{
		ID: 3,
		Name: CoreMicroarchitectureE.Reference,
		Support: {
			CorrelationTracking: [CoreCorrelationTypeCodeE.Disabled],
			CustomPrecision: false,
			DistributionTracking: false,
		},
		Experimental: false,
		Disabled: false,
		Restricted: false,
	},
	{
		ID: 6,
		Name: CoreMicroarchitectureE.Jupiter,
		Support: {
			CorrelationTracking: [CoreCorrelationTypeCodeE.Disabled],
			CustomPrecision: true,
			DistributionTracking: true,
		},
		Experimental: true,
		Disabled: !kJupiterEnabled,
		Restricted: false,
	},
];

const availableCoreClasses: CoreClassObject[] = [
	{
		Name: "C0",
		Enabled: true,
		SupportedMicroarchitectures: [
			CoreMicroarchitectureE.Zurich,
			CoreMicroarchitectureE.Athens,
			CoreMicroarchitectureE.Mars,
			CoreMicroarchitectureE.Saturn,
			CoreMicroarchitectureE.Jupiter,
			CoreMicroarchitectureE.Reference,
			CoreMicroarchitectureE.Bypass,
		],
	},
	{
		Name: "C0Pro",
		Enabled: true,
		Experimental: true,
		SupportedMicroarchitectures: [
			CoreMicroarchitectureE.Zurich,
			CoreMicroarchitectureE.Athens,
			CoreMicroarchitectureE.Jupiter,
			CoreMicroarchitectureE.Reference,
			CoreMicroarchitectureE.Bypass,
		],
	},
];

const SpecialMicroarchitecturesE = {
	Bypass: CoreMicroarchitectureE.Bypass,
	Reference: CoreMicroarchitectureE.Reference,
} as const;

function isDistTracking(microarchitecture: string) {
	const info = availableMicroarchitectures.filter((am) => am.Name == microarchitecture);
	return info.length > 0 && info[0].Support.DistributionTracking;
}

const availableCorrelationTrackingMethods: CoreCorrelationTypeObject[] = [
	{
		Name: CoreCorrelationTypeE.Disabled,
		Code: CoreCorrelationTypeCodeE.Disabled,
		Experimental: false,
		SupportedMicroarchitectures: availableMicroarchitectures
			.filter((am) => am.Support.CorrelationTracking.includes(CoreCorrelationTypeCodeE.Disabled))
			.map((am) => am.Name),
	},
	{
		Name: CoreCorrelationTypeE.Autocorrelation,
		Code: CoreCorrelationTypeCodeE.Autocorrelation,
		Experimental: false,
		SupportedMicroarchitectures: availableMicroarchitectures
			.filter((am) => am.Support.CorrelationTracking.includes(CoreCorrelationTypeCodeE.Autocorrelation))
			.map((am) => am.Name),
	},
];

type StoreState = {
	localCoresList: undefined | Core[];
	remoteCoresList: undefined | Core[];
	defaultCoresList: undefined | Core[];
	defaultCoreID: string | undefined;
	defaultReferenceCoreID: string | undefined;
	availableCorrelationTrackingMethods: CoreCorrelationTypeObject[];
	defaultCoreUpdateStatus: RemoteAccessState;
	defaultRefCoreUpdateStatus: RemoteAccessState;
	defaultCoreFetchStatus: RemoteAccessState;
	userCoreFetchStatus: RemoteAccessState;
	coresFilteredCoresByUserTier: undefined | Core[];
	availableMicroarchitectures: CoreMicroarchitectureObject[];
	availableCoreClasses: CoreClassObject[];
};

export const useCoresStore = defineStore("cores", {
	state: (): StoreState => ({
		localCoresList: [],
		remoteCoresList: undefined,
		defaultCoresList: undefined,
		defaultCoreID: undefined,
		defaultReferenceCoreID: undefined,
		availableCorrelationTrackingMethods: availableCorrelationTrackingMethods,
		defaultCoreUpdateStatus: { loading: false, error: false, message: "" },
		defaultRefCoreUpdateStatus: { loading: false, error: false, message: "" },
		defaultCoreFetchStatus: { loading: false, error: false, message: "" },
		userCoreFetchStatus: { loading: false, error: false, message: "" },
		coresFilteredCoresByUserTier: undefined,
		availableMicroarchitectures: availableMicroarchitectures,
		availableCoreClasses: availableCoreClasses,
	}),
	getters: {
		loadingCores: (state) => {
			return state.userCoreFetchStatus.loading || state.defaultCoreFetchStatus.loading;
		},
		allCoresList(state): Core[] {
			const coresList: Core[] = [];
			if (state.defaultCoresList) {
				coresList.push(...state.defaultCoresList);
			}
			if (state.localCoresList) {
				coresList.push(...state.localCoresList);
			}
			if (state.remoteCoresList) {
				coresList.push(...state.remoteCoresList);
			}
			return coresList;
		},
		filteredCoresList(state): Core[] {
			/*
			 *	Filtered cores are cores with the Hidden/Immutable/Disabled
			 *	fields correctly populated.
			 */
			const coresList: Core[] = [];
			if (state.coresFilteredCoresByUserTier) {
				coresList.push(...state.coresFilteredCoresByUserTier);
			}
			return coresList;
		},
		filteredVisibleCoresList(state): Core[] {
			return this.filteredCoresList.filter((c) => {
				return !c.Hidden;
			});
		},
		filteredVisibleReferenceCoresList(): Core[] {
			return this.filteredVisibleCoresList.filter((c) => {
				return c?.Microarchitecture === SpecialMicroarchitecturesE.Reference;
			});
		},
		filteredVisibleNonReferenceCoresList(): Core[] {
			return this.filteredVisibleCoresList.filter((c) => {
				return c?.Microarchitecture != SpecialMicroarchitecturesE.Reference;
			});
		},
		correlationTrackingMethodByCode: (state) => (code: string) => {
			return availableCorrelationTrackingMethods.find((m) => {
				return m.Code == code;
			});
		},
		correlationTrackingSupportedByCore:
			(state) =>
			(microarchitecture: string): CoreCorrelationTypeObject[] => {
				return state.availableCorrelationTrackingMethods.filter((m) => {
					return m.SupportedMicroarchitectures.includes(microarchitecture);
				});
			},
		customPrecisionSupportedByCore:
			(_state) =>
			(microarchitecture: string): boolean => {
				const filteredArch = availableMicroarchitectures.filter((am) => am.Name === microarchitecture);

				return filteredArch.length > 0 && filteredArch[0].Support.CustomPrecision;
			},
		coreMicroarchitectureStringFormat:
			(_state) =>
			(microarchitecture: string, precision: number | undefined): string => {
				const precisionStr = toStringWithSIMagnitude(precision, "");

				switch (microarchitecture) {
					case SpecialMicroarchitecturesE.Bypass:
						return `${microarchitecture}`;
					default:
						if (precision === undefined) {
							console.warn(`Missing precision for microarchitecture ${precision}`);
						}
						return `${microarchitecture}-${precisionStr}`;
				}
			},
		microarchitecturesSupportedByCoreClass:
			(state) =>
			(coreClass: CoreClass): CoreMicroarchitectureObject[] => {
				const coreClassObject = availableCoreClasses.find((c: CoreClassObject) => c.Name === coreClass);

				if (!coreClassObject) {
					return [];
				}

				/*
				 *	Also check if core is enabled
				 */
				return state.availableMicroarchitectures.filter((m) => {
					return coreClassObject.SupportedMicroarchitectures.includes(m.Name) && !m.Disabled;
				});
			},
		//FIXME: this should return Core or undefined
		defaultCore(state): Core {
			let core: Core | undefined = undefined;

			if (this.defaultCoreID) {
				core = this.allCoresList.find((core) => {
					return core.CoreID === this.defaultCoreID;
				});
			}
			if (!core) {
				core = this.allCoresList[0];
			}
			return core;
		},

		//FIXME: this should return Core or undefined
		defaultReferenceCore(state): Core {
			let core: Core | undefined = undefined;

			if (this.defaultReferenceCoreID) {
				core = this.filteredVisibleReferenceCoresList.find((core) => {
					return core.CoreID === this.defaultReferenceCoreID;
				});
			}
			if (!core) {
				core = this.filteredVisibleReferenceCoresList[0];
			}
			return core;
		},
		validateCore() {
			return (activeCore: Core) => {
				if (activeCore) {
					// * Check if the specified core is valid
					if (this.allCoresList.find((x) => x.CoreID === activeCore.CoreID)) {
						return activeCore;
					}
					console.warn(`Specified core ${activeCore?.Name} (CoreID=${activeCore?.CoreID}) cannot be found`);
				}

				/**
				 *	If argument was falsy or specified core is not found, then return the pre-specified default core.
				 */
				console.warn("Resetting to default core: ??? -> ", this.defaultCore.Name);
				return this.defaultCore;
			};
		},
		getCoreByID: (state) => (coreID: string, coresList: Core[]) => {
			return coresList.find((x) => {
				return x.CoreID === coreID;
			});
		},
		coresSatisfyMinConfig(state) {
			return (minConfig: Partial<Core>) => {
				return Object.assign(
					{},
					...this.allCoresList.map((core) => {
						// eslint-disable-next-line prefer-const
						let output = {};
						const requirementMatch = checkCoreRequirements(core, minConfig) as CoreRequirementMatch;
						for (const key in requirementMatch) {
							if (requirementMatch[key] == false) {
								output[core.CoreID] = false;
								return output;
							}
						}
						output[core.CoreID] = true;
						return output;
					})
				);
			};
		},
		getSmallestDefaultCore(): Core {
			let coreToReturn = this.defaultCore;
			this.allCoresList.forEach((core: Core) => {
				// If current coreToReturn has undefined precision always go with
				// the next option instead.
				if (coreToReturn.Precision === undefined) {
					coreToReturn = core;
					return;
				}

				if (core?.Precision && core.Precision < coreToReturn.Precision) {
					coreToReturn = core;
				}
			});

			return coreToReturn;
		},
		getCustomCores(state): Core[] {
			return state.remoteCoresList ? state.remoteCoresList : [];
		},
	},
	actions: {
		setDefaultCoresList(defaultCoresList: Core[]) {
			// sort cores by non ref cores, ref cores, then bypass,
			// non-ref cores are sorted by precision and then by name

			const distTrackingCores = defaultCoresList.filter((core) => {
				return isDistTracking(core.Microarchitecture);
			});

			const nonDistTrackingCores = defaultCoresList.filter((core) => {
				return !isDistTracking(core.Microarchitecture);
			});

			const distTrackingCoresWithoutPrecision: string[] = [];

			for (const core of distTrackingCores) {
				if (core.Precision === undefined) {
					distTrackingCoresWithoutPrecision.push(core.Name);
				}
			}

			if (distTrackingCoresWithoutPrecision.length > 0) {
				const badCores = distTrackingCoresWithoutPrecision.join(", ");
				console.warn(`All distTrackings should have precision. Exceptions are: "${badCores}"`);
			}

			distTrackingCores.sort((a, b) => {
				// sort by precision (flush undefined precision to 0)

				const precisionDifference = (a.Precision || 0) - (b.Precision || 0);

				if (precisionDifference != 0) {
					return precisionDifference;
				}

				// if the precision is the same, then sort by name
				const nameA = a.Name.toLowerCase(); // ignore upper and lowercase
				const nameB = b.Name.toLowerCase(); // ignore upper and lowercase
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}
				return 0;
			});

			this.defaultCoresList = [...distTrackingCores, ...nonDistTrackingCores];
		},
		setRemoteUserCoresList(remoteCoresList: Core[]) {
			this.remoteCoresList = remoteCoresList;
		},
		removeCoreFromLocalCoresList(incomingCoreData: Core) {
			if (!this.localCoresList) {
				return;
			}
			const coreIndex = this.localCoresList.findIndex((x) => x.CoreID === incomingCoreData.CoreID);
			this.localCoresList.splice(coreIndex, 1);
			localStorage.setItem("signaloid-local-user-cores-list", JSON.stringify(this.localCoresList));
		},
		async setDefaultCore(coreID: string, syncWithRemote: boolean) {
			this.defaultCoreUpdateStatus.loading = true;
			const coreInCoresStore = this.getCoreByID(coreID, this.allCoresList);
			if (coreInCoresStore) {
				this.defaultCoreID = coreInCoresStore.CoreID;
			} else {
				console.warn(
					`Specified default core not found: ${coreID}. Sticking with existing default: ${this.defaultCoreID}`
				);
				return;
			}

			if (syncWithRemote) {
				const authUserStore = useUserStore();
				await authUserStore.syncUserPreferenceWithRemote({
					Execution_DefaultCore: this.defaultCoreID,
				});
			}
			this.defaultCoreUpdateStatus.loading = false;
		},
		async setDefaultReferenceCore(coreID: string, syncWithRemote: boolean) {
			this.defaultRefCoreUpdateStatus.loading = true;
			const coreInCoresStore = this.getCoreByID(coreID, this.filteredVisibleReferenceCoresList);
			if (coreInCoresStore) {
				this.defaultReferenceCoreID = coreInCoresStore.CoreID;
			} else {
				console.warn(
					`Specified default reference core not found: ${coreID}. Sticking with existing default: ${this.defaultReferenceCoreID}`
				);
				return;
			}

			if (syncWithRemote) {
				const authUserStore = useUserStore();
				await authUserStore.syncUserPreferenceWithRemote({
					Execution_DefaultReferenceCore: this.defaultReferenceCoreID,
				});
			}
			this.defaultRefCoreUpdateStatus.loading = false;
		},
		async fetchUserCoresFromRemote() {
			// console.log("updating user cores from remote...");
			try {
				this.userCoreFetchStatus.loading = true;
				const resp = await signaloidClient.getUserCores();
				let remoteCores = resp.data.Cores ?? [];
				remoteCores = remoteCores.map((core) => {
					core.CorrelationTracking =
						core.CorrelationTracking.charAt(0).toUpperCase() + core.CorrelationTracking.slice(1);
					return core;
				});
				this.setRemoteUserCoresList(remoteCores);
			} catch (error) {
				monitoringCaptureError(error, "Fetch user cores from cloud");
			} finally {
				this.userCoreFetchStatus.loading = false;
			}
		},
		async fetchDefaultCoresFromRemote() {
			// console.log("updating default cores from remote...");
			try {
				this.defaultCoreFetchStatus.loading = true;
				const resp = await signaloidClient.getDefaultCores();
				const remoteCores = resp.data.Cores ?? [];
				this.setDefaultCoresList(remoteCores);
			} catch (error) {
				monitoringCaptureError(error, "Fetch default cores from cloud");
			} finally {
				this.defaultCoreFetchStatus.loading = false;
				// console.log("updating default remote cores...Done");
			}
		},
		resetCoreIdIfRestricted(candidateCoreID: string | undefined) {
			if (!candidateCoreID) {
				return fallbackDefaultCoreID;
			}
			const listOfDisabledCores = this.coresFilteredCoresByUserTier?.filter((c) => c.Disabled);
			const listOfDisabledCoreIds = listOfDisabledCores?.map((c) => c.CoreID);

			// Force change preference to default core if out of tier core.
			if (listOfDisabledCoreIds?.includes(candidateCoreID)) {
				return fallbackDefaultCoreID;
			}
			return candidateCoreID;
		},
		async resetEditorCoreIfRestricted(candidateCoreID: string, fallbackCoreID: string) {
			const listOfDisabledCores = await this.getDisabledOnlyCoresByUserTier();
			const listOfDisabledCoreIds = listOfDisabledCores?.map((c) => c.CoreID);

			// Force change preference to default core if out of tier core.
			if (listOfDisabledCoreIds?.includes(candidateCoreID)) {
				return fallbackCoreID;
			}
			return candidateCoreID;
		},
		async initialiseExecutionCoresFromRemote() {
			// Get cores from remote
			try {
				await Promise.allSettled([this.fetchDefaultCoresFromRemote(), this.fetchUserCoresFromRemote()]);
			} catch (error) {
				monitoringCaptureError(error, "Initialise cores");
			}

			// Get core preferences
			const authUserStore = useUserStore();
			const preferencesFromDB = await authUserStore.getCurrentUserPreferences();
			if (preferencesFromDB) {
				const listOfDisabledCores = await this.getDisabledOnlyCoresByUserTier();
				const listOfDisabledCoreIds = listOfDisabledCores?.map((c) => c.CoreID);

				let candidateDefaultCoreID = preferencesFromDB?.Execution_DefaultCore
					? preferencesFromDB?.Execution_DefaultCore
					: fallbackDefaultCoreID;

				// Force change preference to default core if out of tier core.
				if (listOfDisabledCoreIds?.includes(candidateDefaultCoreID)) {
					candidateDefaultCoreID = fallbackDefaultCoreID;
				}

				const candidateDefaultReferenceCoreID = preferencesFromDB?.Execution_DefaultReferenceCore
					? preferencesFromDB?.Execution_DefaultReferenceCore
					: fallbackDefaultReferenceCoreID;
				this.setDefaultCore(
					candidateDefaultCoreID,
					candidateDefaultCoreID !== preferencesFromDB?.Execution_DefaultCore
				);
				this.setDefaultReferenceCore(
					candidateDefaultReferenceCoreID,
					candidateDefaultReferenceCoreID !== preferencesFromDB?.Execution_DefaultReferenceCore
				);
			}
		},
		async getActiveCores() {
			if (this.remoteCoresList == undefined && !this.userCoreFetchStatus.loading) {
				this.fetchUserCoresFromRemote();
			}
			if (this.defaultCoresList == undefined && !this.defaultCoreFetchStatus.loading) {
				this.fetchDefaultCoresFromRemote();
			}

			return new Promise<Core[]>((resolve, reject) => {
				const loop = () => {
					if (this.remoteCoresList !== undefined && this.defaultCoresList !== undefined) {
						return resolve([...this.defaultCoresList, ...this.remoteCoresList]);
					} else {
						setTimeout(loop);
					}
				};
				loop();
			});
		},
		async getDisabledOnlyCoresByUserTier(): Promise<Core[]> {
			const listOfDisabledCores = (await this.getCoresFilteredCoresByUserTier()).filter((c) => c.Disabled);
			return listOfDisabledCores;
		},
		async getCoresFilteredCoresByUserTier(): Promise<Core[]> {
			await this.getActiveCores();
			const userStore = useUserStore();
			const thisUserTierDetails = await userStore.getCurrentUserTierDetails();
			if (!thisUserTierDetails) {
				return [] as Core[];
			}

			const allowedCores = this.allCoresList.map((core: Core) => {
				// items in availableMicroarchitectures may have a boolean disabled field
				type AmWithDefault = (typeof availableMicroarchitectures)[0][];

				const isKnownAndVisibleMicroArchitecture = (availableMicroarchitectures as AmWithDefault).some(
					(am) => am.Name === core.Microarchitecture && !am.Disabled
				);
				const isAllowedMicroArchitecture = thisUserTierDetails.allowance.AllowedMicroArchitectures.includes(
					core.Microarchitecture
				);
				const isReference = core.Microarchitecture == "Reference";
				const precisionLimit = isReference
					? thisUserTierDetails.allowance.MaxReferenceQuality
					: thisUserTierDetails.allowance.MaxPrecisionBits;
				const isAllowedPrecision = core.Precision ? core.Precision <= precisionLimit : true;

				const isAllowedMemory = core.MemorySize <= thisUserTierDetails.allowance.MaximumAttachedProcessorRam;

				const isAllowedCoreClass = thisUserTierDetails.allowance.AllowedCoreClasses.includes(
					core.Class as CoreClass
				);

				if (!isKnownAndVisibleMicroArchitecture) {
					core.Hidden = true;
				}

				if (!isAllowedMicroArchitecture || !isAllowedPrecision || !isAllowedMemory || !isAllowedCoreClass) {
					core.Disabled = true;
				}

				if (core.Owner === "*") {
					core.Immutable = true;
				}

				return core;
			});

			this.coresFilteredCoresByUserTier = allowedCores;
			return this.coresFilteredCoresByUserTier;
		},
	},
});
