var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "carousel" },
    [
      _c(
        "v-carousel",
        {
          attrs: {
            continuous: true,
            cycle: false,
            height: "100%",
            "delimiter-icon": "mdi-minus",
            "hide-delimiters": "",
            "show-arrows": false,
          },
          model: {
            value: _vm.activeSlide,
            callback: function ($$v) {
              _vm.activeSlide = $$v
            },
            expression: "activeSlide",
          },
        },
        _vm._l(_vm.validatedSpotlightSlides, function (slideInfo, i) {
          return _c(
            "v-carousel-item",
            { key: i, attrs: { value: i } },
            [_c("SpotlightSlide", { attrs: { slideData: slideInfo } })],
            1
          )
        }),
        1
      ),
      _vm.validatedSpotlightSlides.length > 1
        ? _c(
            "v-item-group",
            {
              staticClass: "carousel__nav",
              attrs: { mandatory: "" },
              model: {
                value: _vm.activeSlide,
                callback: function ($$v) {
                  _vm.activeSlide = $$v
                },
                expression: "activeSlide",
              },
            },
            _vm._l(_vm.validatedSpotlightSlides, function (_, i) {
              return _c("v-item", {
                key: i,
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ active, toggle }) {
                        return [
                          _c(
                            "v-btn",
                            {
                              staticClass: "carousel__indicator",
                              attrs: {
                                icon: "",
                                color: active ? "accent" : "white",
                                small: "",
                              },
                              on: { click: toggle },
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(
                                  _vm._s(
                                    active
                                      ? "mdi-square-circle"
                                      : "mdi-square-outline"
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          )
        : _vm._e(),
      _vm.validatedSpotlightSlides.length > 1
        ? _c(
            "v-btn",
            {
              staticClass: "carousel__button carousel__button--left",
              attrs: { icon: "", outlined: "" },
              on: { click: _vm.previousSlide },
            },
            [
              _c("v-icon", { attrs: { large: "" } }, [
                _vm._v("mdi-chevron-left"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.validatedSpotlightSlides.length > 1
        ? _c(
            "v-btn",
            {
              staticClass: "carousel__button carousel__button--right",
              attrs: { icon: "", outlined: "" },
              on: { click: _vm.nextSlide },
            },
            [
              _c("v-icon", { attrs: { large: "" } }, [
                _vm._v("mdi-chevron-right"),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }