var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "section",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("div", { staticClass: "mb-1 mr-md-1 text-body-2" }, [
                    _vm._v("Key name (required)"),
                  ]),
                  _c("v-text-field", {
                    staticClass: "mx-0 mr-md-1",
                    attrs: {
                      label: "What is this key for?",
                      maxlength: "500",
                      placeholder: "e.g., Production Server Key",
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      "data-test": "generate-key-name-input",
                    },
                    model: {
                      value: _vm.keyName,
                      callback: function ($$v) {
                        _vm.keyName = $$v
                      },
                      expression: "keyName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: _vm.validitySelection === "Custom..." ? 3 : 6,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "mb-1 mt-4 mt-md-0 ml-0 text-body-2" },
                    [_vm._v("Validity period")]
                  ),
                  _c("v-select", {
                    attrs: {
                      items: _vm.validityDateOptions,
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      "data-test": "generate-key-validity-period-selector",
                    },
                    model: {
                      value: _vm.validitySelection,
                      callback: function ($$v) {
                        _vm.validitySelection = $$v
                      },
                      expression: "validitySelection",
                    },
                  }),
                ],
                1
              ),
              _vm.validitySelection === "Custom..."
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "mb-1 mt-4 mt-md-0 mx-md-2 text-body-2",
                        },
                        [_vm._v("Date")]
                      ),
                      _c("SignaloidDatePicker", {
                        staticClass: "ml-md-2",
                        attrs: {
                          date: _vm.signaloidDatePickerPickedDate,
                          "hide-details": "",
                          readonly: "",
                          "allowed-dates": _vm.datePickerAllowedDates,
                        },
                        on: {
                          "update:date": function ($event) {
                            _vm.signaloidDatePickerPickedDate = $event
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticStyle: {
                    "justify-content": "end",
                    display: "flex",
                    "align-content": "center",
                    "flex-wrap": "wrap",
                  },
                  attrs: {
                    cols: "12",
                    md: "8",
                    "data-test": "generate-key-expiration-description",
                  },
                },
                [
                  _vm.validitySelection !== "Valid forever"
                    ? _c("div", { staticClass: "ml-2 text-body-2" }, [
                        _vm._v(
                          " The key will expire on " +
                            _vm._s(_vm.expirationDateFormatted) +
                            ". "
                        ),
                      ])
                    : _c("div", { staticClass: "ml-2 text-body-2" }, [
                        _vm._v(" The key will never expire. "),
                      ]),
                ]
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c("LimitableActionButton", {
                    staticClass: "px-0",
                    attrs: {
                      color: "accent",
                      width: "100%",
                      "min-width": "16ch",
                      "data-test": "generate-key-button",
                      disabled: _vm.generateKeyButtonDisabled,
                      loading: _vm.generateKeyButtonLoading,
                      "limits-to-check": [_vm.UserLimitsE.KeyCount],
                    },
                    on: { "valid-click": _vm.generateKey },
                    scopedSlots: _vm._u([
                      {
                        key: "btnContent",
                        fn: function () {
                          return [
                            _c("v-icon", [_vm._v("mdi-plus")]),
                            _vm._v(" Generate key"),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.errorText !== ""
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "text-body-2 mt-n2",
                          attrs: {
                            value: _vm.errorText !== "",
                            dense: "",
                            outlined: "",
                            transition: "v-expand-transition",
                            type: "error",
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.errorText))])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.showKeyCreationDialog
        ? _c("APIKeyCreationDialog", {
            attrs: {
              showDialog: _vm.showKeyCreationDialog,
              "passed-name": _vm.showKeyCreationDialogConfig?.name,
              "passed-key": _vm.showKeyCreationDialogConfig?.key,
            },
            on: {
              "update:showDialog": function ($event) {
                _vm.showKeyCreationDialog = $event
              },
              "update:show-dialog": function ($event) {
                _vm.showKeyCreationDialog = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "text-body-2 mt-2 mb-4" }, [
      _c("span", [
        _vm._v(
          "Create a new API key to allow your applications to interact with the Signaloid Cloud Compute Engine API."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }