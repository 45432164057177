
import { defineComponent } from "vue";

// Types

// Libraries

// Utilities
import TooltipButton from "@/components/Common/TooltipButton.vue";

// Components

// Stores

// Global variables

export default defineComponent({
	name: "UpgradeButton",
	components: { TooltipButton },
	inheritAttrs: false,
	props: { btnText: { type: String, default: "Upgrade" } },
});
