var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      attrs: {
        outlined: "",
        width: _vm.$attrs.width ? _vm.$attrs.width : "100%",
        rounded: "lg",
        "max-width": _vm.$attrs["max-width"]
          ? _vm.$attrs["max-width"]
          : "700px",
        "data-test": "usage-detail-card",
      },
    },
    [
      _vm.showHeader
        ? _c(
            "v-card",
            {
              staticClass: "px-2 px-sm-3 d-flex",
              attrs: {
                outlined: "",
                width: "100%",
                height: "64px",
                rounded: "t-lg b-0",
                color: "grey lighten-5",
              },
            },
            [
              _vm._t("header", function () {
                return [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _vm.icon
                            ? _c(
                                "v-icon",
                                {
                                  attrs: {
                                    size: _vm.iconSize,
                                    color: _vm.iconColor,
                                  },
                                },
                                [_vm._v(_vm._s(_vm.icon))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("v-col", [
                        _c("div", { staticClass: "font-weight-bold" }, [
                          _vm._v(" " + _vm._s(_vm.title) + " "),
                          _vm.showUpgradeButton
                            ? _c(
                                "span",
                                { staticClass: "ml-2" },
                                [
                                  _c("UpgradeButton", {
                                    attrs: { "btn-text": "Upgrade" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "text-body-2 line-clamp-1" }, [
                          _vm._v(" " + _vm._s(_vm.subtitleToDisplay) + " "),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.showHeader ? _c("v-divider") : _vm._e(),
      _c(
        "div",
        { staticClass: "px-3 px-sm-4 py-2" },
        _vm._l(_vm.usageQuotas, function (quota, i) {
          return _c(
            "div",
            { key: i, staticClass: "usage-detail-card-row mb-0 mb-sm-1" },
            [
              _c("div", { staticClass: "text-subtitle-2" }, [
                _vm._v(" " + _vm._s(quota.name) + " "),
              ]),
              _c("v-progress-linear", {
                attrs: {
                  value: _vm.usagePercentage(quota.usage, quota.limit),
                  color: _vm.usageBarColour(quota.usage, quota.limit),
                  rounded: "",
                  height: "7",
                },
              }),
              _c("div", { staticClass: "text-body-2" }, [
                _c("span", { staticClass: "d-inline" }, [
                  _c(
                    "span",
                    {
                      staticClass: "d-inline text-subtitle-2",
                      attrs: { "data-test": `quota-usage-${quota.id}` },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.toStringWithSIMagnitude(quota.usage)) +
                          _vm._s(quota.unit)
                      ),
                    ]
                  ),
                  _vm._v(" of "),
                  quota.limit === undefined
                    ? _c(
                        "span",
                        {
                          staticClass: "d-inline text-subtitle-2",
                          attrs: { "data-test": `quota-limit-${quota.id}` },
                        },
                        [_vm._v(" unlimited ")]
                      )
                    : quota.limit !== null && quota.limit !== 0
                    ? _c(
                        "span",
                        {
                          staticClass: "d-inline text-subtitle-2",
                          attrs: { "data-test": `quota-limit-${quota.id}` },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.toStringWithSIMagnitude(quota.limit)) +
                              _vm._s(quota.unit)
                          ),
                        ]
                      )
                    : _c(
                        "span",
                        {
                          staticClass: "d-inline text-subtitle-2",
                          attrs: { "data-test": `quota-limit-${quota.id}` },
                        },
                        [_vm._v(" 0 " + _vm._s(quota.unit))]
                      ),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.suffix(quota.quotaSuffix, quota.usage, quota.limit)
                      ) +
                      " "
                  ),
                ]),
              ]),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }