var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "TooltipButton",
    _vm._g(
      _vm._b(
        {
          attrs: {
            "x-small": "",
            outlined: "",
            "btn-class": "px-1 pr-2",
            color: "accent",
            width: "auto",
            "btn-icon": "mdi-chevron-double-up",
            "tooltip-text": "Upgrade",
            "btn-text": _vm.btnText,
            to: { path: "/billing" },
          },
        },
        "TooltipButton",
        { ..._vm.$attrs },
        false
      ),
      { ..._vm.$listeners }
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }