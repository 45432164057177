
import { defineComponent } from "vue";
import TooltipButton from "@/components/Common/TooltipButton.vue";

export default defineComponent({
	name: "FileViewer",
	components: {
		TooltipButton,
	},
	props: {
		showDialog: { type: Boolean, default: false },
		title: { type: String, default: "" },
		fileText: { type: String, default: "" },
	},
	methods: {
		closeDialog() {
			this.$emit("update:showDialog", false);
		},
		requestFileDownload() {
			this.$emit("download-file-request", this.title);
		},
	},
});
