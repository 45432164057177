import { FileTree } from "@/types/general";
import { AxiosError } from "axios";

// TODO: Link to documentation saying how to do it.
const loginToAccessPrivateReposMessage =
	` To access private repositories, connect to your GitHub account using the 'Login' button on the repositories page.`;
const defaultErrorMessage =
	"Our system encountered an error while getting information about the repository from GitHub.";

export function generateGithubResponseErrorMessage(error: AxiosError<any>, githubLoggedIn: boolean): string {
	let errorMessage = defaultErrorMessage;
	if (error.response) {
		// Received a failing HTTP response code (4xx, 5xx)
		switch (error.response?.status) {
			case 401:
				errorMessage = `GitHub error: unauthorized access. 
				Please ensure you have permission to access the repository.`;
				if (!githubLoggedIn) {
					errorMessage += loginToAccessPrivateReposMessage;
				}
				break;
			case 403:
				// This is might be an actual forbidden or might be a throttle from GitHub.
				// https://docs.github.com/en/rest/overview/resources-in-the-rest-api?apiVersion=2022-11-28#exceeding-the-rate-limit
				// TODO: Check current rate limit status
				if (
					error.response.headers["x-ratelimit-remaining"] &&
					error.response.headers["x-ratelimit-remaining"] == "0"
				) {
					errorMessage = `GitHub error: The Signaloid Cloud Developer Platform has made too many unauthenticated requests to 
					GitHub on your behalf. Please log into your GitHub account using the 'Login' button on the 
					repositories page to bypass this rate limiting from GitHub.`;
				} else {
					errorMessage = `GitHub error: access denied to the repository. 
					Please ensure you have permission to access the repository.`;
					if (!githubLoggedIn) {
						errorMessage += loginToAccessPrivateReposMessage;
					}
				}
				break;
			case 404:
				errorMessage = `The Signaloid Cloud Developer Platform was not able to find this repository on GitHub. 
				Please ensure the repository exists and you have access to it.`;
				if (!githubLoggedIn) {
					errorMessage += loginToAccessPrivateReposMessage;
				}
				break;
			case 408:
				errorMessage = `The request to GitHub timed out. 
				Please ensure that you have an active internet connection.`;
				break;
			default:
				errorMessage =
					`GitHub returned error '${error.response.status} ${error.response.statusText}' while the Signaloid 
					platform was trying to query repository information.`;
				break;
		}
	} else if (error.request) {
		// No response
		errorMessage = `A request to fetch repository information initiated but the Signaloid Cloud Developer Platform did not receive a 
		response from GitHub. 
		Please ensure that you have an active internet connection. 
		If this error persists, please contact support at developer-support@signaloid.com.`;
	} else {
		// Making the request failed
		errorMessage = `A browser error occurred when trying to query repository information from GitHub. 
		Please ensure that you have an active internet connection. 
		If this error persists, please contact support at developer-support@signaloid.com.`;
	}

	return errorMessage;
}


type GitHubTree = {
	sha: string;
	url: string;
	truncated: boolean;
	tree: {
		path?: string | undefined;
		mode?: string | undefined;
		type?: 'tree' | string | undefined;
		sha?: string | undefined;
		size?: number | undefined;
		url?: string | undefined;
	}[];
};

export function githubTreeToFileTree(tree: GitHubTree): FileTree {

	const directories = tree.tree.filter((v) => v.type == 'tree' && v.path !== undefined);
	console.log(directories);

	const root: FileTree = {
		children: [],
		name: './',
		path: "",
		type: "dir"
	}

	directories.map(v => v.path).forEach((path) => {
		const parts = path!.split('/').filter(Boolean); // Remove any empty strings
		let current = root;

		for (let i = 0; i < parts.length; i++) {
			const part = parts[i];

			if (!current.children.find((v) => v.name === part)) {
				current.children.push({
					name: part,
					children: [],
					path: parts.slice(0, i + 1).join("/"),
					type: "dir"
				});
			}
			current = current.children.find((v) => v.name === part)!;	// This exists because we just added it
		}
	});

	return root;
}
