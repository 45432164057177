var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "px-1 px-sm-2", attrs: { flat: "" } },
    [
      _vm.userCustomCores.length > 0
        ? [
            _c(
              "v-card-title",
              { staticClass: "pb-3 pt-1 pt-sm-4 px-1 px-sm-4" },
              [
                _c("div", { staticClass: "text-h6 font-weight-bold" }, [
                  _vm._v("Custom Cores"),
                ]),
                _c("v-spacer"),
                _c("LimitableActionButton", {
                  attrs: {
                    color: "accent",
                    btnClass: "float-right",
                    tooltipText: "Add Core",
                    "data-test": "add-core-button",
                    "limits-to-check": [_vm.UserLimitsE.CoreCount],
                  },
                  on: { "valid-click": _vm.addCore },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "btnContent",
                        fn: function () {
                          return [
                            _c("v-icon", { attrs: { size: "19" } }, [
                              _vm._v("mdi-plus"),
                            ]),
                            _c("v-icon", { staticClass: "ml-1" }, [
                              _vm._v("mdi-memory"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2782011145
                  ),
                }),
              ],
              1
            ),
            _c("v-divider", { staticClass: "mb-2 mb-sm-3 mx-1 mx-sm-4" }),
            _c(
              "v-container",
              {
                staticClass: "px-sm-4 px-1 py-sm-2 py-1 mx-auto mb-5",
                attrs: { fluid: "" },
              },
              [
                _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  [
                    _vm._l(_vm.userCustomCores, function (executionCore) {
                      return _c(
                        "v-col",
                        {
                          key: executionCore.CoreID,
                          attrs: {
                            cols: "12",
                            sm: "12",
                            md: "6",
                            lg: "4",
                            xl: "3",
                          },
                        },
                        [
                          _c("CoreCard", {
                            staticClass: "fill-height ma-0 ma-sm-0",
                            attrs: {
                              coreConfig: executionCore,
                              outlined: "",
                              tile: "",
                            },
                            on: {
                              "edit-core": _vm.editCore,
                              "delete-core": _vm.deleteCore,
                              "core-synced": _vm.updateCoreList,
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    _vm.coresStore.loadingCores
                      ? _c("LoadingCard", {
                          attrs: { loadingText: "Loading Cores" },
                        })
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c(
        "v-card-title",
        { staticClass: "pb-3 pt-1 pt-sm-4 px-1 px-sm-4" },
        [
          _c("div", { staticClass: "text-h6 font-weight-bold" }, [
            _vm._v("Signaloid Cores"),
          ]),
          _c("v-spacer"),
          _vm.userCustomCores.length === 0
            ? _c("LimitableActionButton", {
                attrs: {
                  color: "accent",
                  btnClass: "float-right",
                  tooltipText: "Add Core",
                  "data-test": "add-core-button",
                  "limits-to-check": [_vm.UserLimitsE.CoreCount],
                },
                on: { "valid-click": _vm.addCore },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "btnContent",
                      fn: function () {
                        return [
                          _c("v-icon", { attrs: { size: "19" } }, [
                            _vm._v("mdi-plus"),
                          ]),
                          _c("v-icon", { staticClass: "ml-1" }, [
                            _vm._v("mdi-memory"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2782011145
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("v-divider", { staticClass: "mb-2 mb-sm-3 mx-1 mx-sm-4" }),
      _c(
        "v-container",
        {
          staticClass: "px-sm-4 px-1 py-sm-2 py-1 mx-auto",
          attrs: { fluid: "" },
        },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _vm._l(_vm.defaultCores, function (executionCore) {
                return _c(
                  "v-col",
                  {
                    key: executionCore.CoreID,
                    attrs: { cols: "12", sm: "12", md: "6", lg: "4", xl: "3" },
                  },
                  [
                    _c("CoreCard", {
                      staticClass: "fill-height ma-0 ma-sm-0",
                      attrs: {
                        coreConfig: executionCore,
                        outlined: "",
                        tile: "",
                      },
                      on: {
                        "edit-core": _vm.editCore,
                        "delete-core": _vm.deleteCore,
                        "core-synced": _vm.updateCoreList,
                      },
                    }),
                  ],
                  1
                )
              }),
              _vm.coresStore.loadingCores
                ? _c("LoadingCard", { attrs: { loadingText: "Loading Cores" } })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm.defaultProCores.length > 0
        ? [
            _c(
              "v-card-title",
              { staticClass: "pb-3 pt-1 pt-sm-4 px-1 px-sm-4" },
              [
                _c("div", { staticClass: "text-h6 font-weight-bold" }, [
                  _vm._v("Signaloid Pro Cores"),
                ]),
              ]
            ),
            _c("v-divider", { staticClass: "mb-2 mb-sm-3 mx-1 mx-sm-4" }),
            _c(
              "v-container",
              {
                staticClass: "px-sm-4 px-1 py-sm-2 py-1 mx-auto",
                attrs: { fluid: "" },
              },
              [
                _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  _vm._l(_vm.defaultProCores, function (executionCore) {
                    return _c(
                      "v-col",
                      {
                        key: executionCore.CoreID,
                        attrs: {
                          cols: "12",
                          sm: "12",
                          md: "6",
                          lg: "4",
                          xl: "3",
                        },
                      },
                      [
                        _c("CoreCard", {
                          staticClass: "fill-height ma-0 ma-sm-0",
                          attrs: {
                            coreConfig: executionCore,
                            outlined: "",
                            tile: "",
                          },
                          on: {
                            "edit-core": _vm.editCore,
                            "delete-core": _vm.deleteCore,
                            "core-synced": _vm.updateCoreList,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  1
                ),
                _vm.coresStore.loadingCores
                  ? _c("LoadingCard", {
                      attrs: { loadingText: "Loading Cores" },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.showModifyCoreDialog
        ? _c("ModifyCoreDialog", {
            attrs: {
              showDialog: _vm.showModifyCoreDialog,
              editCoreData: _vm.editCoreData,
            },
            on: {
              "close-dialog": function ($event) {
                _vm.showModifyCoreDialog = false
              },
              "core-created": _vm.updateCoreList,
              "core-updated": _vm.updateCoreList,
            },
          })
        : _vm._e(),
      _vm.showDeleteCoreDialog
        ? _c("DeleteCoreDialog", {
            attrs: {
              showDialog: _vm.showDeleteCoreDialog,
              deleteCoreData: _vm.deleteCoreData,
            },
            on: {
              "close-dialog": function ($event) {
                _vm.showDeleteCoreDialog = false
              },
              "core-deleted": _vm.coreSuccessfullyDeleted,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }