
import { defineComponent } from "vue";

const maxArgLength = 100_000;
export default defineComponent({
	name: "RunArgumentsDialog",
	props: {
		showDialog: { type: Boolean, default: false },
		initialRunArguments: { type: String, default: "" },
	},
	data: () => ({
		runArguments: "",
		maxArgLength: maxArgLength,
		formIsValid: false,
		argumentRules: [
			(value) => {
				const validArgumentCharacters = !value.includes("\n");
				return validArgumentCharacters || "Command line arguments cannot include line breaks.";
			},
			(value) => {
				const validArgumentLength = value.length < maxArgLength;
				return (
					validArgumentLength || `Maximum command line argument length allowed is ${maxArgLength} characters.`
				);
			},
		],
	}),
	methods: {
		closeDialog() {
			this.$emit("close-dialog");
		},
		setArguments() {
			this.$emit("set-arguments", { runArguments: this.runArguments });
		},
	},
	mounted() {
		this.runArguments = this.initialRunArguments;
	},
});
