
import { defineComponent, PropType } from "vue";

// Types
import {
	AccountGroupCode,
	UserLimits,
	UserLimitsE,
	tiers,
	AccountTierDetail,
	TierFeatureHighlight,
	tierFromCode,
} from "@/js/tierconfig";

// Libraries
import { StripeCheckout } from "@vue-stripe/vue-stripe";

// Utilities
import * as util from "@/js/utilities";
import { monitoringCaptureError } from "@/plugins/monitoring";

// Components
import TooltipButton from "@/components/Common/TooltipButton.vue";

import TierHighlightsList from "@/components/Common/TierHighlightsList.vue";
import InterestFrom from "@/components/InterestForm.vue";

// Stores
import { mapState } from "pinia";
import { useUserStore } from "@/stores/user";
import { postCheckoutSession, getStripeCheckoutPublishableKey } from "@/js/signaloidClient";

// Global variables

type DialogTypes = "info" | "warning";

type ComponentData = {
	stripePublishableKey: string;
	stripeSessionId: string;
	monthlySubscription: boolean;
	showInterestFormDialog: boolean;
	interestFormProps: {
		plan: string;
		fullname: string;
		email: string;
	};
	quickCheckout: boolean;
};

export default defineComponent({
	name: "TierUpgradeHintDialog",
	components: { TooltipButton, TierHighlightsList, StripeCheckout, InterestFrom },
	props: {
		showDialog: { type: Boolean, default: false },
		messageType: { type: String as PropType<DialogTypes>, default: "info" },
		triggeredLimit: { type: String as PropType<UserLimits> | undefined, default: undefined },
		metadata: { type: Object },
	},
	data: (): ComponentData => ({
		stripePublishableKey: "",
		stripeSessionId: "",
		monthlySubscription: true,
		showInterestFormDialog: false,
		interestFormProps: {
			plan: "",
			fullname: "",
			email: "",
		},
		quickCheckout: false,
	}),
	// watch: {
	// 	showDialog: {
	// 		handler(newValue) {
	// 			if (newValue === true) {
	// 				// @ts-ignore
	// 				this.$posthog?.capture("upgrade_subscription_dialog_shown", {});
	// 			}
	// 		},
	// 	},
	// },
	setup() {
		const userStore = useUserStore();
		return { userStore };
	},
	computed: {
		...mapState(useUserStore, {
			currentUserResourceUsage: "currentUserResourceUsage",
			currentUserTierDetails: "currentUserTierDetails",
			currentUserTierCode: "currentUserTierCode",
		}),
		suggestedUserTierCode(): AccountGroupCode {
			switch (this.currentUserTierCode) {
				case "free-tier":
					return "developer-tier";
				case "developer-tier":
					return "enterprise-tier";
				case "pro-tier":
					return "enterprise-tier";
				default:
					return "enterprise-tier";
			}
		},
		nextUserTierDetails(): AccountTierDetail | undefined {
			return tiers.find((e) => e.code === this.suggestedUserTierCode);
		},
		sideImageURL() {
			const imagePath = "images/overviewVideoThumbnail_abstract.png";
			return new URL(imagePath, process.env.VUE_APP_ASSET_SERVER_URL).toString();
		},
		currentTierName(): string {
			return this.currentUserTierDetails?.fullTitle ?? "Current Tier";
		},
		nextTierName(): string {
			return this.nextUserTierDetails?.fullTitle ?? "Next Tier";
		},
		title(): string {
			if (this.hitEnterpriseTierLimit) {
				return `You've Outgrown your ${this.currentTierName} quota`;
			} else {
				return `You've Outgrown the ${this.currentTierName}`;
			}
		},
		subtitle(): string {
			return ``;
		},
		description(): string {
			let currentAllowance;

			if (
				this.currentUserTierDetails?.allowance &&
				this.triggeredLimit !== undefined &&
				this.currentUserTierDetails.allowance[this.triggeredLimit] === undefined
			) {
				/*
				 * Handle unlimited tier limits
				 * This code path should never hit, if it has, something has gone wrong
				 */
				monitoringCaptureError(
					new Error("User exceeded a supposedly unlimited limit"),
					"Display tier upgrade dialog"
				);

				if (this.hitEnterpriseTierLimit) {
					return `You have used up your ${this.currentTierName} resource allocation. Please contact support at developer-support@signaloid.com.`;
				} else {
					return `You have hit a limit of the ${this.currentTierName}. Please contact support at developer-support@signaloid.com.`;
				}
			}

			switch (this.triggeredLimit) {
				case UserLimitsE.DynamicInstructionCount:
					return `You have reached your ${this.currentTierName} computation limit for this month.`;

				case UserLimitsE.ConcurrentTaskCount:
					currentAllowance = util.numberLessThanTenToWords(
						this.currentUserTierDetails?.allowance.ConcurrentTaskCount
					);
					return `On the ${this.currentTierName} you can only run ${currentAllowance} task(s) concurrently.`;

				case UserLimitsE.CoreCount:
					currentAllowance = util.numberLessThanTenToWords(this.currentUserTierDetails?.allowance.CoreCount);
					return `You can only instantiate ${currentAllowance} custom C0 processor core(s) on the ${this.currentTierName}.`;

				case UserLimitsE.RepositoryCount:
					currentAllowance = util.numberLessThanTenToWords(
						this.currentUserTierDetails?.allowance.RepositoryCount
					);
					return `You can only connect ${currentAllowance} GitHub ${
						this.currentUserTierDetails?.allowance.ConcurrentTaskCount == 1 ? "repository" : "repositories"
					} to your account on the ${this.currentTierName}.`;

				case UserLimitsE.DataDriveCount:
					currentAllowance = util.numberLessThanTenToWords(
						this.currentUserTierDetails?.allowance.DataDriveCount
					);
					return `You can only create ${currentAllowance} data drive(s) on the ${this.currentTierName}.`;

				case UserLimitsE.PlotCount:
					currentAllowance = util.numberLessThanTenToWords(this.currentUserTierDetails?.allowance.PlotCount);
					return `You can only generate ${currentAllowance} distribution plots per month on the ${this.currentTierName}.`;

				case UserLimitsE.BucketCount:
					currentAllowance = util.numberLessThanTenToWords(
						this.currentUserTierDetails?.allowance.BucketCount
					);

					if (currentAllowance && currentAllowance > 0) {
						return `You can only connect to ${currentAllowance} AWS S3 Buckets on the ${this.currentTierName}.`;
					} else {
						return `You cannot connect to AWS S3 Buckets on the ${this.currentTierName}.`;
					}

				case UserLimitsE.CloudStorageBytes:
					currentAllowance = util.toStringWithByteMagnitude(
						this.currentUserTierDetails?.allowance.CloudStorageBytes
					);
					return `On the ${this.currentTierName} your cloud storage allowance is limited to ${currentAllowance}.`;

				case UserLimitsE.TaskHistoryLength:
					if (this.hitEnterpriseTierLimit) {
						return `You have hit your account's concurrent task limit.`;
					}

					currentAllowance = util.numberLessThanTenToWords(
						this.currentUserTierDetails?.allowance.TaskHistoryLength
					);

					return `Don't worry, your task history is safe. On the ${this.currentTierName} you can only see the details of the last ${currentAllowance} tasks.`;

				case UserLimitsE.AllowedCoreClasses:
					currentAllowance = this.currentUserTierDetails?.allowance.AllowedCoreClasses;

					return `On the ${this.currentTierName} you can only access
						${currentAllowance.length === 1 ? "the" : ""}
						${this.listToReadableString(currentAllowance)}
						core ${currentAllowance.length === 1 ? "class" : "classes"}.`;

				case UserLimitsE.AllowedMicroArchitectures:
					currentAllowance = this.currentUserTierDetails?.allowance.AllowedMicroArchitectures;

					return `On the ${this.currentTierName} you can only access ${util.numberLessThanTenToWords(
						currentAllowance.length
					)} Signaloid C0 processor ${
						currentAllowance.length === 1 ? "microarchitecture" : "microarchitectures"
					}.`;

				case UserLimitsE.KeyCount:
					currentAllowance = util.numberLessThanTenToWords(this.currentUserTierDetails?.allowance.KeyCount);
					return `On the ${this.currentTierName} you can only have up to ${currentAllowance} API keys.`;
				default:
					if (this.hitEnterpriseTierLimit) {
						return `You have used up your ${this.currentTierName} resource allocation.`;
					} else {
						return `You have hit a limit of the ${this.currentTierName}.`;
					}
			}
		},
		upgradeBenefitsText(): string {
			// If enterprise tier dont add an upgrade benefits intro text
			if (this.hitEnterpriseTierLimit) {
				return "";
			}

			let upgradedAllowance;
			let currentAllowance;
			switch (this.triggeredLimit) {
				case UserLimitsE.DynamicInstructionCount:
					return `Upgrade your account to increase your monthly quota.`;

				case UserLimitsE.ConcurrentTaskCount:
					return `Upgrade your account to run more tasks in parallel.`;

				case UserLimitsE.CoreCount:
					upgradedAllowance = util.numberLessThanTenToWords(this.nextUserTierDetails?.allowance.CoreCount);
					return `Upgrade your account to the ${this.nextTierName} to create up to ${upgradedAllowance} custom cores.`;

				case UserLimitsE.RepositoryCount:
					upgradedAllowance = util.numberLessThanTenToWords(
						this.nextUserTierDetails?.allowance.RepositoryCount
					);
					return `Upgrade your account to the ${this.nextTierName} to connect up to ${upgradedAllowance} repositories.`;

				case UserLimitsE.DataDriveCount:
					upgradedAllowance = util.numberLessThanTenToWords(
						this.nextUserTierDetails?.allowance.DataDriveCount
					);
					return `Upgrade your account to the ${this.nextTierName} to connect up to ${upgradedAllowance} data drive(s).`;

				case UserLimitsE.PlotCount:
					upgradedAllowance = util.numberLessThanTenToWords(this.nextUserTierDetails?.allowance.PlotCount);
					return `Upgrade your account to the ${this.nextTierName} to generate up to ${upgradedAllowance} plots per month.`;

				case UserLimitsE.CloudStorageBytes:
					upgradedAllowance = util.toStringWithByteMagnitude(
						this.nextUserTierDetails?.allowance.CloudStorageBytes
					);
					return `Upgrade your account to the ${this.nextTierName} to access up to ${upgradedAllowance} in cloud storage.`;

				case UserLimitsE.TaskHistoryLength:
					upgradedAllowance = util.numberLessThanTenToWords(
						this.nextUserTierDetails?.allowance.TaskHistoryLength
					);
					return `Upgrade your account to the ${this.nextTierName} to access the details of ${
						upgradedAllowance == "unlimited" ? "" : "up to"
					} ${upgradedAllowance} historical tasks.`;

				case UserLimitsE.AllowedCoreClasses:
					upgradedAllowance = this.nextUserTierDetails?.allowance.AllowedCoreClasses;
					currentAllowance = this.currentUserTierDetails?.allowance.AllowedCoreClasses;

					upgradedAllowance = upgradedAllowance.filter((v) => !currentAllowance.includes(v));

					if (upgradedAllowance) {
						return `Upgrade your account to the ${this.nextTierName} to access
						${upgradedAllowance.length === 1 ? "the" : ""}
						${this.listToReadableString(upgradedAllowance)}
						core ${upgradedAllowance.length === 1 ? "class" : "classes"}.`;
					} else {
						return `Upgrade your account to the ${this.nextTierName} to access more core classes.`;
					}

				case UserLimitsE.AllowedMicroArchitectures:
					upgradedAllowance = this.nextUserTierDetails?.allowance.AllowedMicroArchitectures;
					currentAllowance = this.currentUserTierDetails?.allowance.AllowedMicroArchitectures;

					upgradedAllowance = upgradedAllowance.filter((v) => !currentAllowance.includes(v));

					if (upgradedAllowance) {
						return `Upgrade your account to the ${this.nextTierName} to access
						${upgradedAllowance.length === 1 ? "the" : ""}
						${this.listToReadableString(upgradedAllowance)}
						core ${upgradedAllowance.length === 1 ? "microarchitecture" : "microarchitectures"}.`;
					} else {
						return `Upgrade your account to the ${this.nextTierName} to access more core microarchitectures.`;
					}
				case UserLimitsE.KeyCount:
					upgradedAllowance = util.numberLessThanTenToWords(this.nextUserTierDetails?.allowance.KeyCount);
					return `Upgrade your account to the ${this.nextTierName} to access up to create up to ${upgradedAllowance} API keys.`;

				default:
					return `Upgrade your account to the ${this.nextTierName} to access more features.`;
			}
		},
		nextTierIntroText(): string {
			const defaultNextTierIntro = this.hitEnterpriseTierLimit
				? `\nContact us to increase your quota for:`
				: `\nUpgrade to the ${this.nextTierName} for:`;
			switch (this.triggeredLimit) {
				default:
					return defaultNextTierIntro;
			}
		},
		tierHighlightsToDisplay(): TierFeatureHighlight[] {
			if (!this.nextUserTierDetails) {
				return [];
			}

			if (this.hitEnterpriseTierLimit) {
				return [
					{
						text: "Dynamic instructions per month.",
						icon: "plus",
					},
					{
						text: "Number of custom cores.",
						icon: "plus",
					},
					{
						text: "API invocations per month.",
						icon: "plus",
					},
					{
						text: "Any other Enterprise Tier limit.",
						icon: "playlist-plus",
					},
				];
			}

			switch (this.triggeredLimit) {
				default:
					return this.nextUserTierDetails.shortVersionPoints;
			}
		},
		hitEnterpriseTierLimit(): boolean {
			return this.suggestedUserTierCode === "enterprise-tier" && this.currentUserTierCode === "enterprise-tier";
		},
		upgradeButtonStyling() {
			if (this.hitEnterpriseTierLimit) {
				return { text: "Contact Us", icon: "mdi-email-outline" };
			} else {
				return { text: "Upgrade", icon: "mdi-chevron-double-up" };
			}
		},
	},
	async created() {
		await this.userStore.getCurrentUserTierDetails();
	},
	methods: {
		closeDialog() {
			this.$emit("update:showDialog", false);
		},
		async initiateUpgrade() {
			this.closeDialog();

			// @ts-ignore
			this.$posthog?.capture("tier_upgrade_button_clicked", {
				planClicked: this.suggestedUserTierCode,
			});

			if (this.quickCheckout) {
				const userCognitoGroups = await this.userStore.getCurrentUserGroups();
				switch (this.suggestedUserTierCode) {
					case "developer-tier":
					case "pro-tier":
						if (userCognitoGroups?.includes("CustomerPortalUser")) {
							this.$emit("customer-portal");
						} else {
							const response = await getStripeCheckoutPublishableKey();
							this.stripePublishableKey = response.data["StripePublicKey"];

							// @ts-ignore FIXME:
							const stripePriceId = this.monthlySubscription
								? tierFromCode(this.suggestedUserTierCode)?.monthlySubscriptionStripePriceId
								: tierFromCode(this.suggestedUserTierCode)?.yearlySubscriptionStripePriceId;
							await this.postCheckoutSession(stripePriceId);
							// @ts-ignore FIXME:
							this.$refs.checkoutRef.redirectToCheckout();
						}
						break;

					case "enterprise-tier":
						this.initiateContactUs("enterprise-tier");
						break;

					default:
						break;
				}
			} else {
				if (this.hitEnterpriseTierLimit) {
					this.initiateContactUs("enterprise-tier");
				} else {
					this.goToBillingPage();
				}
			}
		},
		async postCheckoutSession(price_id) {
			try {
				const response = await postCheckoutSession(price_id);
				// console.log(response);
				this.stripeSessionId = response.data["SessionID"];
				// console.log(this.sessionId);
			} catch (error) {
				monitoringCaptureError(error, "Stripe checkout");
				// @ts-ignore
				this.message = error?.message;
			}
		},
		async initiateContactUs(planClicked) {
			try {
				// @ts-ignore
				this.$posthog?.capture("upgrade_subscription_contact_button_clicked", {
					currentUserTier: this.currentUserTierCode,
					planClicked: planClicked,
				});

				this.interestFormProps.fullname = this.userStore.currentUser?.name ?? "";
				this.interestFormProps.email = this.userStore.currentUser?.email ?? "";

				switch (planClicked) {
					case "academic-research-tier":
						// console.log('tier clicked academic');
						this.interestFormProps.plan = "Developer";
						break;
					case "pro-tier":
						// console.log('tier clicked pro');
						this.interestFormProps.plan = "Pro";
						break;
					case "enterprise-tier":
						// console.log('tier clicked pro');
						this.interestFormProps.plan = "Enterprise";
						break;

					default:
						break;
				}
			} catch (error) {
				monitoringCaptureError(error, "Initiate contact us");
			} finally {
				/*
				 *  Even if setting up the props failed, we can still prompt the
				 *  user to the form.
				 */
				this.showInterestFormDialog = true;
			}
		},
		goToBillingPage() {
			this.closeDialog();
			this.$router.push({ path: "/billing" });
		},
		listToReadableString(values: string[]): string {
			const oxfordComma = values.length > 2;
			console.log("values :>> ", values);
			return values.reduce((accumulator, currentValue, idx) => {
				// if last element
				if (idx === values.length - 1) {
					// add an oxford comma if the list is longer than 2 elements
					accumulator += `${oxfordComma ? ", and " : " and "}`;
				} else {
					accumulator += ", ";
				}

				accumulator += currentValue;
				return accumulator;
			});
		},
	},
});
