
import { defineComponent } from "vue";
import AddRepositoryCard from "@/components/Repositories/AddRepositoryCard.vue";
import IntersectionObserver from "@/components/IntersectionObserver.vue";
import LoadingCard from "@/components/LoadingCard.vue";
import EndOfRepoListMarker from "./EndOfRepoListMarker.vue";
import { useGithubStore } from "@/stores/github";
import { Endpoints } from "@octokit/types";
import { RepoConnectionConfig } from "@/types/general";
import { signaloidApiClient } from "@/js/signaloidClient";

type ComponentData = {
	fullRepoList: Endpoints["GET /user/repos"]["response"]["data"];
	displayRepoList: Endpoints["GET /user/repos"]["response"]["data"];
	searchQuery: undefined | string;
	repositoriesPerPage: number;
	repositoriesPage: number;
	loadingRepos: boolean;
	searchBoxVisible: boolean;
	resultsBoxVisible: boolean;
	endOfRepoList: boolean;
};

export default defineComponent({
	name: "SearchGithubReposDialog",
	components: { AddRepositoryCard, IntersectionObserver, LoadingCard, EndOfRepoListMarker },
	props: { dialogVisible: Boolean },
	data: (): ComponentData => ({
		repositoriesPerPage: 20,
		repositoriesPage: 0,
		fullRepoList: [],
		displayRepoList: [],
		loadingRepos: true,
		searchBoxVisible: false,
		resultsBoxVisible: false,
		searchQuery: "",
		endOfRepoList: false,
	}),
	emits: {
		"close-dialog": () => true,
		"github-login": () => true,
		"repo-connection-request": (data: RepoConnectionConfig) => true,
		"repository-disconnected": (data: { repositoryID: string }) => true,
	},
	setup() {
		const githubStore = useGithubStore();
		return { githubStore };
	},
	methods: {
		searchBoxKeydownHandler(event) {
			if (event.key == "Enter" && this.searchQuery) {
				this.searchGithub();
			}
		},
		searchGithub() {
			this.searchBoxVisible = false;
			this.resultsBoxVisible = true;
			this.repositoriesPage = 0;
			this.endOfRepoList = false;
			this.fullRepoList = [];
			this.displayRepoList = [];
			this.loadNextPage();
		},

		loadNextPage() {
			this.loadingRepos = true;
			this.fetchNextRepositoryBatch().then(() => {
				this.loadingRepos = false;
				this.displayRepoList = this.fullRepoList;
			});
		},
		fetchNextRepositoryBatch() {
			this.repositoriesPage += 1;
			return signaloidApiClient
				.get(
					`proxy/github/search/repositories?q=${this.searchQuery}&per_page=${this.repositoriesPerPage}&page=${this.repositoriesPage}`
				)
				.then((response) => {
					if (response.data.items.length < this.repositoriesPerPage) {
						this.endOfRepoList = true;
					}
					this.fullRepoList = [...this.fullRepoList, ...response.data.items];
				})
				.catch((error) => console.log(error));
		},
		closeSearchDialog() {
			if (this.resultsBoxVisible) {
				this.searchBoxVisible = false;
			} else {
				this.closeDialog();
			}
		},
		closeDialog() {
			this.searchBoxVisible = false;
			this.$emit("close-dialog");
		},
		requestRepoConnect(data: RepoConnectionConfig) {
			this.$emit("repo-connection-request", data);
		},
		repoDisconnectionEventHandler(data) {
			this.$emit("repository-disconnected", data);
		},
	},
	computed: {
		showNoResultsBanner(): boolean {
			return !this.loadingRepos && this.displayRepoList.length == 0;
		},
	},
	mounted() {
		this.searchBoxVisible = true;
	},
});
