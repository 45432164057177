export type EditorTheme =
    "active4d"
    | "all-hallows-eve"
    | "amy"
    | "birds-of-paradise"
    | "blackboard"
    | "brilliance-black"
    | "brilliance-dull"
    | "chrome-devtools"
    | "clouds-midnight"
    | "clouds"
    | "cobalt"
    | "dawn"
    | "dreamweaver"
    | "eiffel"
    | "espresso-libre"
    | "github"
    | "idle"
    | "katzenmilch"
    | "kuroir-theme"
    | "lazy"
    | "magicwb--amiga-"
    | "merbivore-soft"
    | "merbivore"
    | "monokai-bright"
    | "monokai"
    | "night-owl"
    | "oceanic-next"
    | "pastels-on-dark"
    | "slush-and-poppies"
    | "spacecadet"
    | "solarized-dark"
    | "solarized-light"
    | "sunburst"
    | "textmate--mac-classic-"
    | "tomorrow-night-blue"
    | "tomorrow-night-bright"
    | "tomorrow-night-eighties"
    | "tomorrow-night"
    | "tomorrow"
    | "twilight"
    | "upstream-sunburst"
    | "vibrant-ink"
    | "xcode-default"
    | "zenburnesque"
    | "iplastic"
    | "idlefingers"
    | "krtheme"
    | "monoindustrial"

export const themes: Record<EditorTheme, string> = {
    "active4d": "Active4D",
    "all-hallows-eve": "All Hallows Eve",
    "amy": "Amy",
    "birds-of-paradise": "Birds of Paradise",
    "blackboard": "Blackboard",
    "brilliance-black": "Brilliance Black",
    "brilliance-dull": "Brilliance Dull",
    "chrome-devtools": "Chrome DevTools",
    "clouds-midnight": "Clouds Midnight",
    "clouds": "Clouds",
    "cobalt": "Cobalt",
    "dawn": "Dawn",
    "dreamweaver": "Dreamweaver",
    "eiffel": "Eiffel",
    "espresso-libre": "Espresso Libre",
    "github": "GitHub",
    "idle": "IDLE",
    "katzenmilch": "Katzenmilch",
    "kuroir-theme": "Kuroir Theme",
    "lazy": "LAZY",
    "magicwb--amiga-": "MagicWB (Amiga)",
    "merbivore-soft": "Merbivore Soft",
    "merbivore": "Merbivore",
    "monokai-bright": "Monokai Bright",
    "monokai": "Monokai",
    "night-owl": "Night Owl",
    "oceanic-next": "Oceanic Next",
    "pastels-on-dark": "Pastels on Dark",
    "slush-and-poppies": "Slush and Poppies",
    "solarized-dark": "Solarized-dark",
    "solarized-light": "Solarized-light",
    "spacecadet": "SpaceCadet",
    "sunburst": "Sunburst",
    "textmate--mac-classic-": "Textmate (Mac Classic)",
    "tomorrow-night-blue": "Tomorrow-Night-Blue",
    "tomorrow-night-bright": "Tomorrow-Night-Bright",
    "tomorrow-night-eighties": "Tomorrow-Night-Eighties",
    "tomorrow-night": "Tomorrow-Night",
    "tomorrow": "Tomorrow",
    "twilight": "Twilight",
    "upstream-sunburst": "Upstream Sunburst",
    "vibrant-ink": "Vibrant Ink",
    "xcode-default": "Xcode_default",
    "zenburnesque": "Zenburnesque",
    "iplastic": "iPlastic",
    "idlefingers": "idleFingers",
    "krtheme": "krTheme",
    "monoindustrial": "monoindustrial"
}
