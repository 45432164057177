<template>
	<v-dialog
		v-model="showDialog"
		:width="$vuetify.breakpoint.mobile ? '95%' : '500px'"
		@click:outside="closeDialog"
		@keydown.esc="closeDialog"
	>
		<v-sheet
			class="pa-10 pa-lg-10"
			:width="$vuetify.breakpoint.mobile ? '100%' : '500px'"
			:tile="$vuetify.breakpoint.mobile"
			:elevation="!$vuetify.breakpoint.mobile ? '5' : '0'"
			rounded
		>
			<div>
				<div class="d-flex justify-space-between">
					<div class="text-h5 pa-3 pt-0 font-weight-medium">Delete Account</div>
					<v-btn
						color="white"
						class="text--primary"
						small
						outlined
						height="36"
						elevation="0"
						@click="closeDialog"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>

				<div class="text-body-2 pa-3 mt-3">
					<span>Please type your email to confirm that you want to delete your Signaloid Cloud account.</span>
				</div>
				<v-container>
					<v-row>
						<v-col
							class="pt-1 pb-2"
							cols="12"
							md="12"
						>
							<v-text-field
								v-model="email"
								type="email"
								name="email"
								:placeholder="this.passedEmail"
								required
								outlined
								dense
								hide-details
							></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col
							class="mt-4"
							cols="12"
							md="12"
						>
							<v-btn
								color="secondary"
								class="text-body-1 font-weight-medium auth-button"
								depressed
								width="100%"
								:loading="buttonIsLoading"
								type="submit"
								:disabled="!readyToSubmit"
								@click="deleteAccount"
								>Delete Account</v-btn
							>
						</v-col>
					</v-row>
					<v-row v-if="showSupportMessage">
						<v-col class="text-center">
							<v-alert
								dense
								outlined
								type="warning"
								border="left"
								style="text-align: left"
							>
								{{ getSupportMessage }}
							</v-alert>
						</v-col>
					</v-row>
					<v-row>
						<v-col class="text-center">
							<span class="text-body-2"
								><a
									href="#"
									@click="closeDialog"
									>Go back</a
								></span
							>
						</v-col>
					</v-row>
				</v-container>
			</div>
		</v-sheet>
	</v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import { useUserStore } from "@/stores/user";
import { deleteUserProfile } from "@/js/signaloidClient";
import { Auth } from "aws-amplify";
import { useOutputsStore } from "@/stores/outputs";

const SupportMessage = `Please try again later.\n If this issue persists, please contact Signaloid Support at\n developer-support@signaloid.com. `;

export default defineComponent({
	name: "DeleteAccount",
	data: () => ({
		buttonIsLoading: false,
		email: "",
		showSupportMessage: false,
	}),
	props: {
		showDialog: { type: Boolean, default: false },
		passedEmail: {
			type: String,
			default: null,
		},
	},
	computed: {
		readyToSubmit() {
			return this.email == this.passedEmail;
		},
		getSupportMessage() {
			return SupportMessage;
		},
	},
	setup() {
		const userStore = useUserStore();
		const taskOutputsStore = useOutputsStore();
		return { userStore, taskOutputsStore };
	},
	methods: {
		async deleteAccount() {
			this.buttonIsLoading = true;
			try {
				const resp = await deleteUserProfile(this.userStore.currentUser.userID);

				console.log(resp.status);
				this.buttonIsLoading = false;
				this.$emit("delete-profile", "success");
				await this.taskOutputsStore.purgeCache();
				await Auth.signOut();
				window.location.href = "/";
			} catch (e) {
				this.buttonIsLoading = false;
				this.showSupportMessage = true;
				this.$emit("delete-profile", "failure");
			}
		},
		closeDialog() {
			this.$emit("closeDeleteDialog");
		},
	},
});
</script>

<style>
.password-field .v-text-field__details {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
</style>
