var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      staticClass: "d-flex flex-column justify-space-between",
      attrs: { outlined: "", rounded: "lg", height: "100%" },
    },
    [
      _c(
        "v-sheet",
        {
          staticStyle: {
            "border-top": "none",
            "border-left": "none",
            "border-right": "none",
          },
          attrs: { height: "2.5em", rounded: "t-lg", outlined: "" },
        },
        [
          _c(
            "v-container",
            { staticClass: "fill-height py-0 px-2", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { staticClass: "pr-1" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _vm.selectedCodeLanguage
                        ? _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on: menu, attrs }) {
                                      return [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              top: "",
                                              "open-delay": "250",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({
                                                    on: tooltip,
                                                  }) {
                                                    return [
                                                      _c(
                                                        "v-chip",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "my-2 mx-1 font-weight-medium",
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                outlined: "",
                                                                small: "",
                                                              },
                                                            },
                                                            "v-chip",
                                                            attrs,
                                                            false
                                                          ),
                                                          {
                                                            ...tooltip,
                                                            ...menu,
                                                          }
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                left: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-file-code-outline "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm
                                                                  .selectedCodeLanguage
                                                                  .name
                                                              ) +
                                                              " "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Code Language"),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1262875235
                              ),
                            },
                            [
                              _c(
                                "v-list",
                                _vm._l(
                                  _vm.codeLanguagesList,
                                  function (codeLanguage) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: codeLanguage.id,
                                        attrs: {
                                          disabled: !codeLanguage.active,
                                          link: "",
                                          dense: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.setCodeLanguage(
                                              codeLanguage
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          {
                                            staticClass:
                                              "ma-0 pa-0 text-body-2 font-weight-regular",
                                          },
                                          [_vm._v(_vm._s(codeLanguage.name))]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-tooltip", {
                        attrs: {
                          top: "",
                          "open-delay": "250",
                          color: "rgba(255, 0, 0, 0)",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on: tooltip }) {
                              return [
                                _c(
                                  "LoadableChip",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          loading:
                                            !_vm.coreList ||
                                            !_vm.editorCore ||
                                            _vm.editorStore.coreUpdateStatus
                                              .loading,
                                          chipIcon: "mdi-memory",
                                          chipText: _vm.editorCore?.Name,
                                          "data-test": "core-selector-chip",
                                        },
                                        on: { click: _vm.openSelectCoreDialog },
                                      },
                                      "LoadableChip",
                                      _vm.attrs,
                                      false
                                    ),
                                    { ...tooltip, ..._vm.menu }
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("v-menu", {
                        attrs: { "offset-y": "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on: menu, attrs }) {
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "", "open-delay": "250" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on: tooltip }) {
                                            return [
                                              _c(
                                                "LoadableChip",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        loading:
                                                          _vm.editorStore
                                                            .runArgumentsUpdateStatus
                                                            .loading,
                                                        chipIconColor:
                                                          _vm.runArguments
                                                            ? "primary"
                                                            : "grey",
                                                        iconOnly: true,
                                                        loadErrorState:
                                                          !_vm.editorStore
                                                            .runArgumentsUpdateStatus
                                                            .loading &&
                                                          _vm.editorStore
                                                            .runArgumentsUpdateStatus
                                                            .error,
                                                        chipIcon: "mdi-console",
                                                        "data-test":
                                                          "runArgs-selector-chip",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.openRunArgumentsDialog,
                                                      },
                                                    },
                                                    "LoadableChip",
                                                    attrs,
                                                    false
                                                  ),
                                                  { ...tooltip, ...menu }
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("Command Line Arguments"),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("v-menu", {
                        attrs: { "offset-y": "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on: menu, attrs }) {
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "", "open-delay": "250" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on: tooltip }) {
                                            return [
                                              _c(
                                                "LoadableChip",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        loading:
                                                          _vm.editorStore
                                                            .dataSourceUpdateStatus
                                                            .loading,
                                                        chipIconColor:
                                                          _vm.isLocalMountConfig(
                                                            _vm.mountConfig
                                                          )
                                                            ? "primary"
                                                            : "grey",
                                                        iconOnly: true,
                                                        chipIcon:
                                                          _vm.isLocalMountConfig(
                                                            _vm.mountConfig
                                                          )
                                                            ? "mdi-database-arrow-right-outline"
                                                            : "mdi-database-off-outline",
                                                        loadErrorState:
                                                          !_vm.editorStore
                                                            .dataSourceUpdateStatus
                                                            .loading &&
                                                          _vm.editorStore
                                                            .dataSourceUpdateStatus
                                                            .error,
                                                        "data-test":
                                                          "data-source-selector-chip",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.openMountDataSourcesDialog,
                                                      },
                                                    },
                                                    "LoadableChip",
                                                    attrs,
                                                    false
                                                  ),
                                                  { ...tooltip, ...menu }
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [_c("span", [_vm._v("Mount Data Source")])]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  !_vm.disableVariableViewer
                    ? _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-row-reverse px-0",
                          attrs: { cols: "auto", "align-self": "end" },
                        },
                        [
                          _c("TooltipButton", {
                            attrs: {
                              icon: true,
                              tooltipText: "Toggle variable viewer",
                              "data-test":
                                "editor-toggle-variable-viewer-button",
                            },
                            on: { click: _vm.toggleVariableViewerVisibility },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "btnContent",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            size: "1.6em",
                                            color: _vm.variableViewerVisibility
                                              ? "primary"
                                              : "grey",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "mdi-application-variable-outline"
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3179089603
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.disableLayoutChange
                    ? _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-row-reverse px-0",
                          attrs: { cols: "auto", "align-self": "end" },
                        },
                        [
                          _c("TooltipButton", {
                            attrs: {
                              icon: true,
                              disabled: !_vm.layoutChangeButtonEnabled,
                              tooltipText: "Toggle Layout",
                            },
                            on: { click: _vm.toggleEditorLayout },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "btnContent",
                                  fn: function () {
                                    return [
                                      _vm.editorLayout == "horizontal"
                                        ? _c("v-icon", [
                                            _vm._v("mdi-flip-vertical"),
                                          ])
                                        : _c("v-icon", [
                                            _vm._v("mdi-flip-horizontal"),
                                          ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1532134601
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-row-reverse px-0",
                      attrs: { cols: "auto", "align-self": "end" },
                    },
                    [
                      _c("TooltipButton", {
                        attrs: {
                          icon: true,
                          tooltipText: "Set editor to preferred theme",
                          btnIcon: "mdi-star-outline",
                        },
                        on: { click: _vm.toggleEditorPreferredTheme },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-row-reverse px-0",
                      attrs: { cols: "auto", "align-self": "end" },
                    },
                    [
                      _c("TooltipButton", {
                        attrs: {
                          icon: true,
                          tooltipText: "Set editor to light theme",
                          btnIcon: "mdi mdi-weather-sunny",
                        },
                        on: { click: _vm.toggleEditorWhiteTheme },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-row-reverse px-0",
                      attrs: { cols: "auto", "align-self": "end" },
                    },
                    [
                      _c("TooltipButton", {
                        attrs: {
                          icon: true,
                          tooltipText: "Set editor to dark theme",
                          btnIcon: "mdi-weather-night",
                        },
                        on: { click: _vm.toggleEditorDarkTheme },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-row-reverse px-0",
                      attrs: { cols: "auto", "align-self": "end" },
                    },
                    [
                      _c("TooltipButton", {
                        attrs: {
                          icon: true,
                          tooltipText: "Restore editor code to default example",
                          btnIcon: "mdi-restore",
                        },
                        on: { click: _vm.resetCodeToDefault },
                      }),
                    ],
                    1
                  ),
                  !_vm.$vuetify.breakpoint.mobile
                    ? _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-row-reverse px-0",
                          attrs: { cols: "auto", "align-self": "end" },
                        },
                        [
                          _c("TooltipButton", {
                            attrs: {
                              icon: true,
                              tooltipText: "Toggle fullscreen",
                              btnIcon: "mdi-fullscreen",
                            },
                            on: { click: _vm.requestFullscreen },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.foldable
                    ? _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-row-reverse px-0",
                          attrs: { cols: "auto", "align-self": "end" },
                        },
                        [
                          _c("TooltipButton", {
                            attrs: {
                              icon: true,
                              tooltipText: _vm.showEditor
                                ? "Hide editor"
                                : "Show editor",
                              btnIcon: _vm.showEditor
                                ? "mdi-chevron-up"
                                : "mdi-chevron-down",
                            },
                            on: {
                              click: function ($event) {
                                _vm.showEditor = !_vm.showEditor
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        {
          staticClass: "flex-grow-1",
          attrs: {
            flat: "",
            align: "center",
            rounded: "0",
            "min-height": "0",
            loading: _vm.editorCode === undefined,
          },
        },
        [
          _c(
            "v-expand-transition",
            [
              _vm.showEditor
                ? _c("MonacoEditor", {
                    ref: "monacoEditor",
                    attrs: {
                      height: _vm.editorHeight,
                      width: _vm.editorWidth,
                      theme: _vm.editorTheme,
                    },
                    on: {
                      change: _vm.saveChange,
                      "request-build": function ($event) {
                        return _vm.$emit("request-build")
                      },
                    },
                    model: {
                      value: _vm.codeBuffer,
                      callback: function ($$v) {
                        _vm.codeBuffer = $$v
                      },
                      expression: "codeBuffer",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("EditorControlBar", {
        attrs: {
          taskStatus: _vm.taskStatus,
          taskAccessState: _vm.taskAccessState,
          taskID: _vm.taskID,
          activeTask: _vm.activeTask,
          buildID: _vm.buildID,
          buildStatus: _vm.buildStatus,
          buildAccessState: _vm.buildAccessState,
          activeBuild: _vm.activeBuild,
        },
        on: {
          "create-task-request": function ($event) {
            return _vm.$emit("create-task-request")
          },
          "cancel-active-task-request": function ($event) {
            return _vm.$emit("cancel-active-task-request")
          },
          "cancel-active-build-request": function ($event) {
            return _vm.$emit("cancel-active-build-request")
          },
        },
      }),
      _vm.showRunArgumentsDialog
        ? _c("RunArgumentsDialog", {
            attrs: {
              showDialog: _vm.showRunArgumentsDialog,
              initialRunArguments: _vm.runArguments,
            },
            on: {
              "close-dialog": _vm.closeRunArgumentsDialog,
              "set-arguments": _vm.setRunArguments,
            },
          })
        : _vm._e(),
      _vm.showSelectCoreDialog
        ? _c("SelectCoreDialog", {
            attrs: {
              showDialog: _vm.showSelectCoreDialog,
              minimumCore: undefined,
              activeCore: _vm.editorCore,
            },
            on: {
              "close-dialog": _vm.closeSelectCoreDialog,
              "set-core": _vm.setActiveCoreFromDialog,
            },
          })
        : _vm._e(),
      _vm.showMountDataSourcesDialog
        ? _c("MountDataSourcesDialog", {
            attrs: {
              showDialog: _vm.showMountDataSourcesDialog,
              initialMountConfig: _vm.mountConfig ?? undefined,
            },
            on: {
              "close-dialog": _vm.closeMountDataSourcesDialog,
              "set-data-sources": _vm.setMountConfig,
              "reset-data-sources": _vm.setMountConfig,
              "remove-data-sources": _vm.setMountConfig,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }