
import { defineComponent } from "vue";
export default defineComponent({
	name: "EditValueDialog",
	props: {
		showDialog: { type: Boolean, default: false },
		title: { type: String, required: true },
		valueHint: { type: String, required: true },
		value: { type: String, default: "" },
	},
	data: () => ({
		newValue: "",
		formValid: false,
		rules: {
			required: (value: string) => !!value || "Required.",
			counter: (value: string) => value.length <= 50 || "Max 50 characters",
		},
	}),
	methods: {
		closeDialog() {
			this.$emit("update:showDialog", false);
		},
		setValue() {
			if (this.formValid) {
				this.$emit("update:value", this.newValue.trim());
				this.closeDialog();
			}
		},
	},
	mounted() {
		this.newValue = this.value;
	},
});
