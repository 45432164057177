
import { defineComponent } from "vue";

// Types

// Libraries

// Utilities

// Components

// Stores

// Global variables

export default defineComponent({
	name: "LoadableChip",
	components: {},
	inheritAttrs: false,
	props: {
		loading: { type: Boolean, default: false },
		chipText: { type: String, default: undefined },
		chipIcon: { type: String, default: "mdi-border-none-variant" },
		iconOnly: { type: Boolean, default: false },
		chipIconColor: { type: String },
		tooltipText: { type: String, default: undefined },
		color: { type: String, default: "primary" },
		loadErrorState: { type: Boolean, default: false },
		loadErrorMessage: { type: Boolean, default: false },
		maxTextLength: { type: Number, default: 12 },
		showMaxTextTail: { type: Boolean, default: false },
		circularLoader: { type: Boolean, default: false },
		outlined: { type: Boolean, default: true },
	},
	methods: {
		textPrettyString(inputString: string): string {
			const ellipsesPadding = 2;
			if (!inputString) {
				return "-";
			}

			if (inputString === "") {
				return "./";
			} else if (inputString) {
				let prettyString: string;
				if (inputString.length > this.maxTextLength + ellipsesPadding) {
					if (this.showMaxTextTail) {
						prettyString = "..." + inputString.slice(0 - this.maxTextLength);
					} else {
						prettyString = inputString.slice(0, this.maxTextLength) + "...";
					}
				} else {
					prettyString = inputString;
				}
				return prettyString;
			} else {
				return "-";
			}
		},
	},
	computed: {
		iconOnlyLoading(): boolean {
			return this.iconOnly && this.loading;
		},
	},
});
