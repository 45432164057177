import { defineStore } from "pinia";

// Libraries
import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";

// Utilities
import * as signaloidClient from "@/js/signaloidClient";
import * as dsUtil from "@/components/DataSources/utilities";
import { monitoringCaptureError } from "@/plugins/monitoring";

// Types
import { Repository } from "@/types/api/repositories";
import { RepositoryConnectionRequestConfig } from "@/js/signaloidClient.types";
import { isLocalMountConfig, LocalMountConfig } from "@/types/api/dataSources";
import { RemoteAccessState } from "@/types/general";

// Stores
import { useUserStore } from "./user";
import { useDataSourcesStore } from "./dataSources";
import { useGithubStore } from "./github";
import Vue from "vue";

type StoreState = {
	connectedRepositories: undefined | Repository[];
	remoteRepositoryUpdateStatus: RemoteAccessState;
};

export const useRepositoriesStore = defineStore("repositories", {
	state: (): StoreState => ({
		connectedRepositories: undefined,
		remoteRepositoryUpdateStatus: { loading: false, error: false, message: "" },
	}),

	actions: {
		async fetchRepositoryByID(repositoryID: string) {
			/*
			 *	Let it throw
			 */
			const response = await signaloidClient.getRepositoryByID(repositoryID);
			const repository = response.data as Repository;

			if (this.connectedRepositories === undefined) {
				this.connectedRepositories = [];
			}

			const idx = this.connectedRepositories.findIndex((r) => r.RepositoryID === repository.RepositoryID);
			if (idx === -1) {
				// See https://v2.vuejs.org/v2/guide/reactivity.html#For-Arrays
				Vue.set(this.connectedRepositories, 0, repository);
			} else {
				// See https://v2.vuejs.org/v2/guide/reactivity.html#For-Arrays
				Vue.set(this.connectedRepositories, idx, repository);
			}
			// this.connectedRepositories[idx] = repository;

			return repository;
		},
		async getRepositoryByID(repositoryID: string) {
			if (this.connectedRepositories) {
				// if connectedRepositories is truthy, there is at least one repo in memory
				const repoInMemory = this.connectedRepositories.find((repoInMemory) => {
					return repoInMemory.RepositoryID === repositoryID;
				});
				if (repoInMemory) {
					return repoInMemory;
				}
			}
			// For now, always fetch from remote as a fallback
			const repository = await this.fetchRepositoryByID(repositoryID);
			return repository;
		},
		updateRepoInMemoryByID(repositoryID: string, repositoryItem: Repository) {
			if (this.connectedRepositories) {
				// if connectedRepositories is truthy, there is at least one repo in memory
				const idOfRepoInMemory = this.connectedRepositories.findIndex((repoInMemory) => {
					return repoInMemory.RepositoryID === repositoryID;
				});
				if (idOfRepoInMemory !== -1) {
					// this.connectedRepositories[idOfRepoInMemory] = repositoryItem;
					// See https://v2.vuejs.org/v2/guide/reactivity.html#For-Arrays
					Vue.set(this.connectedRepositories, idOfRepoInMemory, repositoryItem);
				}
			}
		},

		async connectToRepository(repoRequestConfig: RepositoryConnectionRequestConfig) {
			return signaloidClient.addRepository(repoRequestConfig);
		},
		async disconnectFromRepository(repositoryID: string) {
			return signaloidClient.deleteRepositoryByID(repositoryID);
		},
		async updateRepositoryConfig(
			repositoryID: string,
			repoRequestConfig: Partial<RepositoryConnectionRequestConfig>
		) {
			return signaloidClient.editRepositoryByID(repositoryID, repoRequestConfig);
		},
		async removeRepositoryConfigKey(
			repositoryID: string,
			keysToRemove: (keyof RepositoryConnectionRequestConfig)[]
		) {
			return signaloidClient.removeKeysInRepositoryObject(repositoryID, keysToRemove);
		},
		async fetchRemoteRepositories() {

			//TODO: add pagination
			try {
				const response = await signaloidClient.getUserRepositories();
				this.connectedRepositories = response.data.Repositories;
			} catch (error) {
				monitoringCaptureError(error, "Get user repository list");
			}
		},

		/*
		 * Repository update functions
		 */
		async setRepositoryCoreID(repositoryID: string, coreID: string, updateStatus: RemoteAccessState) {
			// The update status is a reference to a RemoteAccessState object which will be mutated inside this function to set the loading state and also error states
			try {
				updateStatus.loading = true;
				const partialRepoConfigToUpdate: Partial<RepositoryConnectionRequestConfig> = {
					Core: coreID,
				};
				const response = await this.updateRepositoryConfig(repositoryID, partialRepoConfigToUpdate);
				this.updateRepoInMemoryByID(repositoryID, response.data);
				updateStatus.loading = false;
				return response.data;
			} catch (error) {
				monitoringCaptureError(error, "Set repository core");

				if (axios.isAxiosError(error)) {
					updateStatus.error = true;
					if (error.response) {
						//non 2xx response
						updateStatus.message = "An error occurred while updating the core. Please try again.";
					} else if (error.request) {
						//no response
						updateStatus.message =
							"Could not connect to Signaloid Cloud: Please check your internet connection.";
					} else {
						//making request failed
						updateStatus.message =
							"Could not connect to Signaloid Cloud: Please check your internet connection.";
					}
				} else {
					updateStatus.message = "Could not update core. Unknown error occurred";
				}
				updateStatus.loading = false;
				throw null;
			}
		},
		async setRepositoryWorkingBranch(
			repositoryID: string,
			branchName: string,
			commitHash: string,
			updateStatus: RemoteAccessState
		) {
			// The update status is a reference to a RemoteAccessState object which will be mutated inside this function to set the loading state and also error states
			try {
				updateStatus.loading = true;
				const partialRepoConfigToUpdate: Partial<RepositoryConnectionRequestConfig> = {
					Branch: branchName,
					Commit: commitHash,
				};

				const response = await this.updateRepositoryConfig(repositoryID, partialRepoConfigToUpdate);
				this.updateRepoInMemoryByID(repositoryID, response.data);
				updateStatus.loading = false;
				return response.data;
			} catch (error) {
				monitoringCaptureError(error, "Set repository working directory");

				if (axios.isAxiosError(error)) {
					updateStatus.error = true;
					if (error.response) {
						//non 2xx response
						updateStatus.message = "An error occurred while updating the working branch. Please try again.";
					} else if (error.request) {
						//no response
						updateStatus.message =
							"Could not connect to Signaloid Cloud: Please check your internet connection.";
					} else {
						//making request failed
						updateStatus.message =
							"Could not connect to Signaloid Cloud: Please check your internet connection.";
					}
				} else {
					updateStatus.message = "Could not update core. Unknown error occurred";
				}
				updateStatus.loading = false;
				throw null;
			}
		},
		async setRepositoryRunArguments(repositoryID: string, runArguments: string, updateStatus: RemoteAccessState) {
			// The update status is a reference to a RemoteAccessState object which will be mutated inside this function to set the loading state and also error states
			try {
				updateStatus.loading = true;
				const partialRepoConfigToUpdate: Partial<RepositoryConnectionRequestConfig> = {
					Arguments: runArguments,
				};

				const response = await this.updateRepositoryConfig(repositoryID, partialRepoConfigToUpdate);
				this.updateRepoInMemoryByID(repositoryID, response.data);
				updateStatus.loading = false;
				return response.data;
			} catch (error) {
				monitoringCaptureError(error, "Set repository run arguments");

				if (axios.isAxiosError(error)) {
					updateStatus.error = true;
					if (error.response) {
						//non 2xx response
						updateStatus.message =
							"An error occurred while updating the runtime arguments. Please try again.";
					} else if (error.request) {
						//no response
						updateStatus.message =
							"Could not connect to Signaloid Cloud: Please check your internet connection.";
					} else {
						//making request failed
						updateStatus.message =
							"Could not connect to Signaloid Cloud: Please check your internet connection.";
					}
				} else {
					updateStatus.message = "Could not update the runtime arguments. Unknown error occurred";
				}
				updateStatus.loading = false;
				throw null;
			}
		},
		async setRepositoryBuildDirectory(
			repositoryID: string,
			buildDirectory: string,
			updateStatus: RemoteAccessState
		) {
			// The update status is a reference to a RemoteAccessState object which will be mutated inside this function to set the loading state and also error states
			// TODO: Stop doing the above. Return values exist for this reason.
			try {
				updateStatus.loading = true;
				const partialRepoConfigToUpdate: Partial<RepositoryConnectionRequestConfig> = {
					BuildDirectory: buildDirectory,
				};

				const response = await this.updateRepositoryConfig(repositoryID, partialRepoConfigToUpdate);
				this.updateRepoInMemoryByID(repositoryID, response.data);
				updateStatus.loading = false;
				return response.data;
			} catch (error) {
				monitoringCaptureError(error, "Set repository build directory");
				if (axios.isAxiosError(error)) {
					updateStatus.error = true;
					if (error.response) {
						//non 2xx response
						updateStatus.message =
							"An error occurred while updating the build directory. Please try again.";
					} else if (error.request) {
						//no response
						updateStatus.message =
							"Could not connect to Signaloid Cloud: Please check your internet connection.";
					} else {
						//making request failed
						updateStatus.message =
							"Could not connect to Signaloid Cloud: Please check your internet connection.";
					}
				} else {
					updateStatus.message = "Could not update the build directory. Unknown error occurred";
				}
				updateStatus.loading = false;
				throw null;
			}
		},
		async setRepositoryDataSources(
			repositoryID: string,
			mountConfig: null | undefined | LocalMountConfig,
			updateStatus: RemoteAccessState
		) {
			// The update status is a reference to a RemoteAccessState object which will be mutated inside this function to set the loading state and also error states
			try {
				updateStatus.loading = true;
				let partialRepoConfigToUpdate: undefined | Partial<RepositoryConnectionRequestConfig> = undefined;

				if (mountConfig === null) {
					// if mountConfig = null it means we want to disable mounting
					partialRepoConfigToUpdate = {
						DataSources: [],
					};
				} else {
					// if mountConfig is not valid or the user requested to reset to default
					if (!isLocalMountConfig(mountConfig) || mountConfig === undefined) {
						// if invalid mount config try to set to the default configs
						const dataSourcesStore = useDataSourcesStore();
						mountConfig =
							(await dataSourcesStore.getGlobalDefaultMountConfig()) ??
							(await dataSourcesStore.getFallbackMountConfig());
					}

					// construct the partial repo config object
					partialRepoConfigToUpdate = {
						DataSources: await dsUtil.formatDataSourcesPayload(
							mountConfig?.ResourceID,
							mountConfig?.ResourceType,
							mountConfig?.Location
						),
					};
				}

				const response = await this.updateRepositoryConfig(repositoryID, partialRepoConfigToUpdate);
				this.updateRepoInMemoryByID(repositoryID, response.data);
				updateStatus.loading = false;
				return response.data;
			} catch (error) {
				monitoringCaptureError(error, "Set repository data sources");
				if (axios.isAxiosError(error)) {
					updateStatus.error = true;
					if (error.response) {
						//non 2xx response
						updateStatus.message =
							"An error occurred while updating the build directory. Please try again.";
					} else if (error.request) {
						//no response
						updateStatus.message =
							"Could not connect to Signaloid Cloud: Please check your internet connection.";
					} else {
						//making request failed
						updateStatus.message =
							"Could not connect to Signaloid Cloud: Please check your internet connection.";
					}
				} else {
					updateStatus.message = "Could not update the build directory. Unknown error occurred";
				}
				updateStatus.loading = false;
				throw null;
			}
		},
	},
	getters: {
		getConnectedRepositories: (state) => {
			if (!state.connectedRepositories) {
				return [] as Repository[];
			}
			return state.connectedRepositories;
		},
	},
});
