
import { useGithubStore } from "@/stores/github";
import { defineComponent } from "vue";

import { getRepositoryTreeRecursive } from "@/js/githubClient";
import { githubTreeToFileTree, generateGithubResponseErrorMessage } from "@/js/githubUtilities";
import { FileTree } from "@/types/general";
import { monitoringCaptureError } from "@/plugins/monitoring";

import AlertMessage from "@/components/Common/AlertMessage.vue";
import axios from "axios";

type ComponentData = {
	repositoryDirectories: FileTree[];
	selectedItems: string[];
	openItems: string[];
	showError: boolean;
	errorMessage: string;
	githubLoggedIn: boolean;
	loading: boolean;
};

const defaultWorkingDirectory = { name: "./", path: "", type: "dir", children: [] };

export default defineComponent({
	name: "RepositoryBuildDirectoryDialog",
	components: { AlertMessage },
	props: {
		showDialog: { type: Boolean, default: false },
		repositoryFullName: { type: String, default: undefined },
		shaName: { type: String, default: undefined },
		selectedDirectoryPath: { type: String, default: undefined },
	},
	emits: {
		"update:showDialog": (show: boolean) => true,
		"update:selectedDirectoryPath": (dir: string) => true,
	},
	data: (): ComponentData => ({
		repositoryDirectories: [defaultWorkingDirectory],
		selectedItems: [defaultWorkingDirectory.path],
		openItems: [defaultWorkingDirectory.path],
		showError: false,
		errorMessage: "",
		githubLoggedIn: false,
		loading: true,
	}),
	setup() {
		const githubStore = useGithubStore();
		return {
			githubStore,
		};
	},
	methods: {
		closeDialog() {
			this.$emit("update:showDialog", false);
		},
		setBuildDirectoryHandler() {
			if (this.selectedItems.length > 0) {
				this.$emit("update:selectedDirectoryPath", this.selectedItems[0]);
			}
			this.closeDialog();
		},
	},
	async created() {
		if (this.repositoryFullName != undefined && this.shaName != undefined) {
			try {
				const response = await getRepositoryTreeRecursive(this.repositoryFullName, this.shaName);
				const fileTree = githubTreeToFileTree(response.data);
				this.repositoryDirectories = [fileTree];
			} catch (error) {
				if (axios.isAxiosError(error)) {
					this.errorMessage = generateGithubResponseErrorMessage(error, this.githubLoggedIn);

					/*
					 *	Override generateGithubResponseErrorMessage return message to give component specific error message.
					 */
					if (error.response) {
						/*
						 *	According to https://docs.github.com/en/rest/git/trees?apiVersion=2022-11-28#get-a-tree--status-codes
						 *	this endpoint can only fail with 404 and 422.
						 */
						switch (error.response.status) {
							case 404: {
								this.errorMessage =
									`GitHub error: ${this.repositoryFullName}@${this.shaName} not found.` +
									" If you think this is a mistake, please contact support at developer-support@signaloid.com.";
								break;
							}
							case 422: {
								this.errorMessage =
									`GitHub error: Validation failed, or the endpoint has been spammed.` +
									" If this error persists, please contact support at developer-support@signaloid.com.";
								break;
							}
							/*
							 *	Other cases handled in generateGithubResponseErrorMessage above.
							 */
						}
					}
				} else {
					this.errorMessage = `The Signaloid Cloud Developer Platform encountered an error while accessing the repository. 
					Please try again. If this error persists, please contact support at developer-support@signaloid.com.`;
				}

				this.showError = true;

				const privacyFriendlyErrorMessage = this.errorMessage.replaceAll(
					this.repositoryFullName,
					"<repository-name-redacted>"
				);
				monitoringCaptureError(error, "getRepositoryTreeRecursive", {
					extras: {
						userMessage: privacyFriendlyErrorMessage,
					},
				});

				/*
				 *	Nothing much to do if we don't have the directories
				 */
				return;
			}
		}

		/*
		 *	Breadth-first search for correct item. We could just do:
		 *
		 *	    ```js
		 *	    this.selectedItems = [this.selectedDirectoryPath];
		 *	    ```
		 *
		 *	but the problem is this component does not know if the passed path is valid yet. The
		 *	getRepositoryTreeRecursive() is made right above so the parent may have an invalid path.
		 */
		const queue = [this.repositoryDirectories[0]];
		let found = false;
		while (queue.length > 0) {
			const item = queue.shift()!; // We just checked the length
			if (item.path === this.selectedDirectoryPath) {
				this.selectedItems = [item.path];
				found = true;
				break;
			}
			queue.push(...item.children);
		}
		if (!found) {
			/*
			 *	The parent has passed a selectedDirectoryPath that we could not find in the full tree of the given
			 *	repository and sha/branch. The parent needs to update its selection.
			 */
			this.selectedItems = [this.repositoryDirectories[0].path];
			this.$emit("update:selectedDirectoryPath", this.repositoryDirectories[0].path);
		}

		/*
		 *
		 */

		this.loading = false;
	},
});
