var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-row",
        {
          staticClass: "text-h7 pb-1 mb-2 font-weight-bold",
          attrs: { "no-gutters": "", "data-test": "task-list-section-heading" },
        },
        [
          _c(
            "v-tooltip",
            {
              attrs: { top: "", "max-width": "500px" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "div",
                        _vm._g(_vm._b({}, "div", attrs, false), on),
                        [
                          _vm._v(" " + _vm._s(_vm.sectionTitle) + " "),
                          _c(
                            "v-icon",
                            { staticClass: "ml-1", attrs: { small: "" } },
                            [_vm._v("mdi-information-outline")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [_vm._v(" " + _vm._s(_vm.sectionDescription) + " ")]
          ),
        ],
        1
      ),
      _vm.taskList && _vm.taskList.length > 0
        ? [
            _c(
              "v-slide-y-transition",
              { attrs: { group: "" } },
              _vm._l(_vm.taskList, function (task) {
                return _c("TaskCard", {
                  key: task.TaskID,
                  staticClass: "my-2",
                  attrs: { taskID: task.TaskID, taskData: task },
                })
              }),
              1
            ),
          ]
        : _vm._e(),
      _vm.taskUpdateStatus && _vm.taskUpdateStatus.loading === true
        ? _c("LoadingCard", { attrs: { loadingText: _vm.tasksLoadingMessage } })
        : _vm.taskList?.length === 0
        ? [
            _c("SourcePlaceholderCard", {
              attrs: {
                "data-test": "no-tasks-message",
                height: "80px",
                disabled: true,
              },
              scopedSlots: _vm._u([
                {
                  key: "buttonText",
                  fn: function () {
                    return [_vm._v(_vm._s(_vm.noTasksMessage))]
                  },
                  proxy: true,
                },
              ]),
            }),
          ]
        : _vm._e(),
      _vm.canLoadMore
        ? [
            _c("div", { staticClass: "my-3 d-flex justify-center" }, [
              _vm.taskList !== undefined && _vm.continuationKey
                ? _c(
                    "span",
                    { attrs: { "data-test": "load-more-tasks-button" } },
                    [
                      _vm._t("loadMoreButton", function () {
                        return [
                          _c("LimitableActionButton", {
                            attrs: {
                              "btn-text": "Load more tasks",
                              width: "350px",
                              color: "primary",
                              loading: _vm.taskUpdateStatus?.loading,
                              "limits-to-check": [
                                _vm.UserLimitsE.TaskHistoryLength,
                              ],
                              "resource-usage-overrides": [_vm.taskList.length],
                            },
                            on: {
                              "valid-click": function ($event) {
                                return _vm.$emit("load-more-tasks")
                              },
                            },
                          }),
                        ]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }