
import { Task } from "@/types/api/tasks";
import { RemoteAccessState } from "@/types/general";
import { defineComponent, PropType } from "vue";

// Types

// Libraries

// Utilities

// Components
import TaskCard from "@/components/TaskManager/TaskCard.vue";
import LoadingCard from "@/components/LoadingCard.vue";
import LimitableActionButton from "@/components/Common/LimitableActionButton.vue";
import SourcePlaceholderCard from "@/components/DataSources/SourcePlaceholderCard.vue";
import { UserLimitsE } from "@/js/tierconfig";

// Stores

// Global variables

export default defineComponent({
	name: "TaskListDisplay",
	components: { TaskCard, LoadingCard, LimitableActionButton, SourcePlaceholderCard },
	props: {
		sectionTitle: { type: String, required: true },
		sectionDescription: { type: String, required: true },
		taskList: { type: Array as PropType<Task[]>, default: () => [] },
		taskUpdateStatus: { type: Object as PropType<RemoteAccessState> },
		continuationKey: { type: String },
		tasksLoadingMessage: { type: String, required: true },
		noTasksMessage: { type: String, required: true },
		canLoadMore: { type: Boolean, default: false },
	},
	emits: { "load-more-tasks": () => true },
	setup() {
		return { UserLimitsE };
	},
});
