
import { defineComponent } from "vue";

// Types
import { isTaskActive, Task } from "@/types/api/tasks";

// Libraries

// Utilities
import * as signaloidClient from "@/js/signaloidClient";
import * as util from "@/js/utilities";
import { monitoringCaptureError } from "@/plugins/monitoring";

// Components
import Panel from "@/components/Panel.vue";
import TaskCard from "@/components/TaskManager/TaskCard.vue";

// Stores
import { useTasksStore } from "@/stores/tasks";
import { ResultsPanelTab, TaskBusEvent } from "@/types/general";

// Global variables
import { defaultTabs } from "@/assets/defaultTabs";
import { useOutputsStore } from "@/stores/outputs";
import { Build } from "@/types/api/builds";
import { useBuildsStore } from "@/stores/builds";

// Local Types
type ComponentData = {
	taskData: undefined | null | Task;
	buildData: undefined | null | Build;
	taskID: undefined | string;
	results: undefined | ResultsPanelTab[];
	resultsLoading: boolean;
	taskEventBusSub: undefined | Function;
	breadcrumbs: Breadcrumb[];
};

type Breadcrumb = {
	text: string;
	disabled: boolean;
	exact?: boolean;
	to?: {
		name: string;
	};
};

export default defineComponent({
	name: "TaskDetail",
	components: { Panel, TaskCard },
	data: (): ComponentData => ({
		taskID: undefined,
		taskData: undefined,
		buildData: undefined,
		results: undefined,
		resultsLoading: false,
		taskEventBusSub: undefined,
		breadcrumbs: [
			{
				text: "Task Manager",
				disabled: false,
				exact: true,
				to: { name: "TaskManager" },
			},
			{
				text: `Task Detail`,
				disabled: true,
			},
		],
	}),
	setup() {
		const tasksStore = useTasksStore();
		const buildsStore = useBuildsStore();
		const taskOutputsStore = useOutputsStore();
		return { tasksStore, taskOutputsStore, buildsStore };
	},
	computed: {
		resultsPanelText(): readonly ResultsPanelTab[] {
			return this.results ?? defaultTabs;
		},
	},
	methods: {
		async taskEventBusCallback(event: TaskBusEvent) {
			// console.log("taskEventBusSub event (in task detail): ", event);
			// @ts-ignore FIXME:
			if (this.taskID && event.taskData.TaskID === this.taskID) {
				// console.log("update for this task :>> status = ", event.taskData.Status);
				this.taskData = event.taskData;
				
				this.buildData = this.buildsStore.getBuildByID(event.taskData.BuildID);
			}
		},
		getBackToTaskList() {
			this.$router.push(`/tasks`);
		},
	},
	async created() {
		// get task ID from the route param "id"
		this.taskID = this.$route.params.id;

		// Idea: not sure whether I should bypass the store and fetch the task by ID, and let the API do the restriction
		// Get task from store
		this.resultsLoading = true;
		this.taskData = this.tasksStore.getTaskByID(this.taskID);

		if (!this.taskData) {
			//if cant find it, get task from API
			try {
				const response = await signaloidClient.getTaskByID(this.taskID);
				this.taskData = response.data;
			} catch (error) {
				monitoringCaptureError(error, "Fetch details of a historical task");
			}
		}

		if (this.taskData === undefined || this.taskData == null) {
			/*
			 * Task not found. Either TaskID was not defined or the task with the TaskID does not exist in the store
			 * This could be because the TaskID is invalid or that the user is trying to access a task that is not
			 * In any case we should return the user to the task manager page
			 */
			console.warn(`Task with ID ${this.taskID} not found! Redirecting to task manager`);
			this.$router.push({ name: "TaskManager" });
		}

		// At this point we should have the task data, or we wont be on this page. So we cast it to Task type
		this.taskData = this.taskData as Task;

		// set the breadcrumbs
		this.breadcrumbs[
			this.breadcrumbs.length - 1
		].text = `Task Detail - Build: ${this.taskData?.BuildID}`;
		// if task is in a terminal state...
		if (this.taskData?.Status && !isTaskActive(this.taskData?.Status)) {
			// fetch the task results
			// await this.taskOutputsStore.loadOutputs(this.taskID);
		} else {
			// else (if task is active), create subscription to the task event queue...
			// console.log("Subscribing to task Bus (in task detail) :>> ");
			this.taskEventBusSub = this.tasksStore.taskEventBus.on(this.taskEventBusCallback);
		}
	},
	beforeDestroy() {
		// Unsubscribe from the task even bus on dismount
		if (this.taskEventBusSub !== undefined) {
			// console.log("Unsubscribing to task Bus (in task detail) :>> ");
			this.taskEventBusSub();
		}
	},
});
