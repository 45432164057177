var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("UsageDetailCard", {
    ref: "usageDetailCard",
    attrs: {
      width: "100%",
      "max-width": "unset",
      usageQuotas: _vm.summaryUsage,
      "data-test": "usage-summary-card",
    },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "v-row",
              { staticClass: "mx-0", attrs: { align: "center" } },
              [
                _c(
                  "v-col",
                  { staticClass: "ml-3 ml-sm-2 px-0", attrs: { cols: "auto" } },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "pa-auto",
                        attrs: { rounded: "circle", outlined: "" },
                      },
                      [
                        _c("v-icon", { attrs: { size: 48 } }, [
                          _vm._v(" mdi-account-circle "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-col", { staticClass: "line-clamp-1" }, [
                  _c(
                    "div",
                    { staticClass: "line-clamp-1" },
                    [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          _vm._s(
                            _vm.authenticatedUser?.attributes?.name ??
                              _vm.authenticatedUser?.attributes?.email
                          )
                        ),
                      ]),
                      _vm.$vuetify.breakpoint.smAndUp &&
                      _vm.authenticatedUser?.attributes?.name
                        ? [
                            _vm._v(
                              " (" +
                                _vm._s(
                                  _vm.authenticatedUser?.attributes?.email
                                ) +
                                ") "
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c("div", { staticClass: "text-body-2 line-clamp-1" }, [
                    _vm.usageDetailCard?.showUpgradeButton
                      ? _c(
                          "span",
                          { staticClass: "mr-2" },
                          [
                            _c("UpgradeButton", {
                              attrs: { "btn-text": "Upgrade" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" " + _vm._s(_vm.usageCardSubtitle) + " "),
                  ]),
                ]),
                _c(
                  "v-col",
                  { attrs: { cols: "auto" } },
                  [
                    _vm.currentUserTierCode
                      ? _c("TierMarker", {
                          attrs: {
                            tierCode: _vm.currentUserTierCode,
                            small: true,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }