
import { defineComponent } from "vue";
// Components
import HeadwayWidget from "@/components/HeadwayWidget.vue";

// Utilities
import * as util from "@/js/utilities";
import { monitoringCaptureError } from "@/plugins/monitoring";

// Libraries
import { Auth } from "aws-amplify";

// Stores

// Types
import { CognitoUserSession } from "amazon-cognito-identity-js";

const legalLinks: LegalLinkObject[] = [
	{
		title: "Signaloid Cloud Service Agreement",
		icon: "mdi-file-document",
		url: "https://legal.signaloid.io/signaloid-cloud-service-agreement.html",
	},
	{
		title: "Privacy Policy",
		icon: "mdi-lock",
		url: "https://legal.signaloid.io/privacy-policy.html",
	},
	{
		title: "Cookie Policy",
		icon: "mdi-cookie",
		url: "https://legal.signaloid.io/cookie-policy.html",
	},
];

type LegalLinkObject = {
	title: string;
	icon: string;
	url: string;
};
type ComponentData = {
	legalLinks: LegalLinkObject[];
	session: null | CognitoUserSession;
	userFullname: string;
	userEmail: string;
	appVersion: string;
};

export default defineComponent({
	name: "TopNav",
	components: { HeadwayWidget },
	data: (): ComponentData => ({
		legalLinks: legalLinks,
		session: null,
		userFullname: "",
		userEmail: "",
		appVersion: "",
	}),
	emits: {
		"sign-out": () => true,
	},
	methods: {
		openDocs() {
			window.open('https://docs.signaloid.io/', '_blank');
		}
	},
	async created() {
		try {
			this.session = await Auth.currentSession();
			this.userFullname = this.session.getIdToken()?.payload?.name;
			this.userEmail = this.session.getIdToken()?.payload?.email;
		} catch (error) {
			monitoringCaptureError(error, "Get current user details");
			window.location.href = "/";
		}
		const version = process.env.VUE_APP_VERSION ?? "Unknown";
		const hash = process.env.VUE_APP_COMMIT_HASH?.slice(0, 7) ?? null;
		if (hash === null) {
			this.appVersion = version;
		} else {
			this.appVersion = `${version}+${hash}`;
		}
	},
});
