
import { defineComponent, PropType } from "vue";

// Stores
import { mapState } from "pinia";
import { useUserStore } from "@/stores/user";

// Components

// Types
import { UserLimits, UserLimitsE } from "@/js/tierconfig";
import { useRootStore } from "@/stores/root";
import { TierLimitEventTypeE } from "@/eventBus/tierLimitEventBus";

type ComponentData = {
	exceededLimits: UserLimits[];
};
export default defineComponent({
	name: "LimitableActionValidator",
	inheritAttrs: false,
	components: {},
	emits: {
		"valid-click": () => true,
		"invalid-click": (violatedLimits: UserLimits[]) => true,
	},
	props: {
		limitsToCheck: { type: Array as PropType<UserLimits[]>, default: () => [] },
		validationThresholdOverrides: { type: Array as PropType<any[]>, default: () => [] },
		resourceUsageOverrides: { type: Array as PropType<any[]>, default: () => [] },
		errorOnUndefinedUsage: { type: Boolean, default: false },
	},
	setup() {
		const rootStore = useRootStore();
		return { rootStore, UserLimitsE };
	},
	data: (): ComponentData => ({
		exceededLimits: [],
	}),
	computed: {
		...mapState(useUserStore, {
			currentUserResourceUsage: "currentUserResourceUsage",
			currentUserTierDetails: "currentUserTierDetails",
		}),
	},
	methods: {
		clickHandler() {
			// on click check for all the limits
			// if there are no limits to check, emit valid click
			if (this.limitsToCheck.length === 0) {
				this.$emit("valid-click");
				return;
			}

			const checkCustomLimits = this.limitsToCheck.length === this.validationThresholdOverrides.length;
			const checkCustomUsage = this.limitsToCheck.length === this.resourceUsageOverrides.length;

			this.exceededLimits = this.limitsToCheck.filter(
				(limit, limitIndex) => !this.userWithinLimits(limit, limitIndex, checkCustomLimits, checkCustomUsage)
			);

			// if all limits are satisfied
			if (this.exceededLimits.length === 0) {
				this.$emit("valid-click");

				// this.rootStore.tierLimitEventBus.emit({
				// 	type: TierLimitEventTypeE.LimitNearThreshold,
				// 	affectedLimits: this.exceededLimits,
				// });

				// TODO: add support for "close to tier limit" warnings
				// } else if (undefined) {
				// 	/*
				// 	 * if any of the warning limits are within the threshold,
				// 	 * emit valid click, but also show the upgrade hint with warning
				// 	 */
				// 	this.$emit("valid-click");
				// 	// if any of the limits are exceeded, show the upgrade hint dialog
				// 	this.rootStore.tierLimitEventBus.emit({
				// 		type: TierLimitEventTypeE.LimitNearThreshold,
				// 		affectedLimits: this.exceededLimits,
				// 	});
				// 	this.showLimitHintDialog = true;
			} else {
				// if any of the limits are exceeded, show the upgrade hint dialog
				this.$emit("invalid-click", this.exceededLimits);
				this.rootStore.tierLimitEventBus.emit({
					type: TierLimitEventTypeE.LimitExceeded,
					affectedLimits: this.exceededLimits,
				});
			}
		},
		userWithinLimits(
			limitToCheck: UserLimits,
			limitIndex: number,
			checkCustomLimits: boolean = false,
			checkCustomUsage: boolean = false
		) {
			if (!this.currentUserTierDetails) {
				// console.log(`Checking "${limitToCheck}" :>> Tier usage limits not available.`);
				return false;
			}

			// get current tier limit for the requested limit
			let tierLimit;
			if (checkCustomLimits) {
				tierLimit = this.validationThresholdOverrides[limitIndex];
			} else {
				tierLimit = this.currentUserTierDetails.allowance[limitToCheck];
			}

			// if tier limit is null it means that there is no limit
			if (tierLimit == null) {
				// console.log(`Checking "${limitToCheck}" :>> No limit (${tierLimit})`);
				return true;
			}

			// if the tier has a limit but the user's usage is unavailable return false
			// FIXME: this seems like a bad thing to do
			if (!this.currentUserResourceUsage) {
				// console.log(`Checking "${limitToCheck}" :>> Current usage not available.`);
				return false;
			}

			// get the user's current usage
			let currentUsage;
			if (checkCustomUsage) {
				currentUsage = this.resourceUsageOverrides[limitIndex];
			} else {
				currentUsage = this.currentUserResourceUsage[limitToCheck];
			}

			// console.log(`Checking "${limitToCheck}" :>> ${currentUsage} of ${tierLimit}`);

			if (currentUsage === undefined || currentUsage === null) {
				/*
				 * if the current usage cannot be found, be on the safe side
				 * and let the user try to do what they are trying to do
				 */
				return !this.errorOnUndefinedUsage;
			} else {
				if (typeof tierLimit === "number") {
					// if limit is numeric
					return currentUsage < tierLimit ? true : false;
				} else if (tierLimit.constructor === Array) {
					// if limit is an array (list of options)
					return tierLimit.includes(currentUsage);
				} else {
					return false;
				}
			}
		},
	},
});
