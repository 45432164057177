var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "px-1 px-sm-2", attrs: { flat: "" } },
    [
      _c("v-breadcrumbs", {
        staticClass: "pa-1 pl-2 ma-0",
        attrs: { items: _vm.breadcrumbs },
      }),
      _c(
        "v-container",
        {
          staticClass: "px-sm-4 px-1 py-1 py-sm-2 mx-auto",
          attrs: { fluid: true },
        },
        [
          _c("section", { staticClass: "repo-detail-grid" }, [
            _c(
              "div",
              { staticClass: "grid-repoCard" },
              [
                _vm.repositoryID && _vm.repository
                  ? _c("ConnectedRepositoryCard", {
                      ref: "connectedRepositoryCard",
                      attrs: {
                        repositoryID: _vm.repositoryID,
                        repository: _vm.repository,
                        uiRepositoryDetailMode: true,
                        initialBuildBarStatus: true,
                        variableTraceList: _vm.repositoryVariableTraceList,
                      },
                      on: {
                        "open-execution-log":
                          _vm.repositoryExecutionResultsHandler,
                        "repository-variables-updated": _vm.updateVariables,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "grid-variableViewer" },
              [
                _c("VariableViewer", {
                  ref: "variableViewer",
                  attrs: {
                    id: "variableViewer",
                    disableRequestPanelClose: true,
                    variablesList: _vm.completeVariablesList,
                    referenceCoreChosen: _vm.referenceCoreChosen,
                    variableDiscoveryInProgress:
                      _vm.variableDiscoveryInProgress,
                    currentCodeHash: _vm.repositoryHash,
                  },
                  on: {
                    "discover-variables": _vm.discoverVariables,
                    "update-variable-trace-list": _vm.updateVariableTraceList,
                    "switch-to-ref-core": _vm.switchToReferenceCore,
                    "add-custom-trace-expression": _vm.addCustomTraceExpression,
                    "remove-custom-trace-expression":
                      _vm.removeCustomTraceExpression,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "grid-resultsPanel" },
              [_c("Panel", { attrs: { taskID: _vm.taskID } })],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }