var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "pa-2 pa-sm-3", attrs: { rounded: "lg", outlined: "" } },
    [
      _vm.driveConfig
        ? [
            _c(
              "v-row",
              { attrs: { "no-gutters": "" } },
              [
                _c(
                  "v-col",
                  { attrs: { "align-self": "baseline" } },
                  [
                    _c("v-icon", { staticClass: "mr-1" }, [
                      _vm._v(_vm._s(_vm.dataSourceIcon("Drive"))),
                    ]),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "", "allow-overflow": true },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g(
                                      _vm._b({}, "span", attrs, false),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.driveConfig.Name) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          383650525
                        ),
                      },
                      [
                        _c("div", [
                          _vm._v(
                            "Unique Source ID: " +
                              _vm._s(_vm.driveConfig.DriveID)
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c("v-hover", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ hover }) {
                          return [
                            _c(
                              "v-col",
                              { staticClass: "mx-2", attrs: { cols: "auto" } },
                              [
                                _c(
                                  "v-fade-transition",
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "", "open-delay": "500" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c("v-progress-circular", {
                                                    staticStyle: {
                                                      position: "relative",
                                                      top: "0px",
                                                      right: "-28px",
                                                    },
                                                    attrs: {
                                                      indeterminate:
                                                        _vm.driveDisconnectionInProgress,
                                                      color: "danger",
                                                      width:
                                                        _vm.driveDisconnectionInProgress
                                                          ? 2
                                                          : 0,
                                                    },
                                                  }),
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "transition-slow-in-slow-out",
                                                          class: hover
                                                            ? "mx-0"
                                                            : "mx-1",
                                                          attrs: {
                                                            outlined: "",
                                                            size: hover
                                                              ? "24"
                                                              : "16",
                                                            color:
                                                              hover ||
                                                              _vm.driveDisconnectionInProgress
                                                                ? "danger"
                                                                : "green",
                                                            "data-test":
                                                              "data-drive-disconnect",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.requestDriveDisconnect,
                                                          },
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [_vm._v("mdi-connection")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v("Disconnect")])]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2969783243
                  ),
                }),
              ],
              1
            ),
            _c(
              "v-row",
              {
                staticClass: "text-body-2 mt-1 ml-1",
                attrs: { "no-gutters": "" },
              },
              [
                _c("v-col", { staticClass: "ml-0 ml-sm-2" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { small: "" } },
                        [_vm._v("mdi-tray-arrow-down")]
                      ),
                      _vm.connectedSourceCount > 0
                        ? [
                            _vm._v("Source Mount Points: "),
                            _vm.gatewayCount != 0
                              ? [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { small: "" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.dataSourceIcon("Gateway"))
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.gatewayCount) +
                                      " " +
                                      _vm._s(
                                        _vm.gatewayCount == 1
                                          ? "Gateway"
                                          : "Gateways"
                                      ) +
                                      " "
                                  ),
                                ]
                              : _vm._e(),
                            _vm.bucketCount != 0
                              ? [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { small: "" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.dataSourceIcon("Bucket"))
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.bucketCount) +
                                      " " +
                                      _vm._s(
                                        _vm.bucketCount == 1
                                          ? "S3 Bucket"
                                          : "S3 Buckets"
                                      ) +
                                      " "
                                  ),
                                ]
                              : _vm._e(),
                            _vm.signaloidCloudStorageConnected
                              ? [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { small: "" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dataSourceIcon(
                                            "SignaloidCloudStorage"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" Signaloid Cloud Storage "),
                                ]
                              : _vm._e(),
                          ]
                        : [_vm._v("No Source Mount Points Set!")],
                    ],
                    2
                  ),
                  !_vm.$vuetify.breakpoint.mobile
                    ? _c(
                        "div",
                        { staticClass: "mr-3" },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { small: "" } },
                            [_vm._v("mdi-checkbox-marked-circle-plus-outline")]
                          ),
                          _vm._v(
                            "Created: " +
                              _vm._s(
                                _vm.formatDate(_vm.driveConfig.CreatedAt)
                              ) +
                              " "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "mr-3" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { small: "" } },
                        [_vm._v("mdi-update")]
                      ),
                      _vm._v(
                        "Last Updated: " +
                          _vm._s(_vm.formatDate(_vm.driveConfig.UpdatedAt)) +
                          " "
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "v-col",
                  {
                    staticClass: "pt-1 pt-sm-0",
                    attrs: {
                      cols: _vm.$vuetify.breakpoint.mobile ? "12" : "auto",
                      "align-self": "end",
                    },
                  },
                  [
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "", justify: "end" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("TooltipButton", {
                              attrs: {
                                color: "primary",
                                width: "50px",
                                btnIcon: "mdi-cog-outline",
                                tooltipText: "Edit Drive Configuration",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showDriveConfigDialog = true
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _c("v-skeleton-loader", { attrs: { type: "list-item-avatar" } }),
      _vm.showDriveConfigDialog
        ? _c("DriveConfigDialog", {
            attrs: {
              showDialog: _vm.showDriveConfigDialog,
              driveConfigToUpdate: _vm.driveConfig,
            },
            on: {
              "update:showDialog": function ($event) {
                _vm.showDriveConfigDialog = $event
              },
              "update:show-dialog": function ($event) {
                _vm.showDriveConfigDialog = $event
              },
              "drive-config-updated": _vm.getDriveInfo,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }