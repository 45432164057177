
import { defineComponent } from "vue";
export default defineComponent({
	name: "TooltipButton",
	/*
	 *	By default Vue passes attributes to the root element of the component. In this case that would be the v-tooltip
	 *	component. We actually want to pass these to the v-btn inside the v-tooltip so we disable the automatic
	 *	fallthrough behaviour and manually pass attributes to the v-btn.
	 *
	 *	We also want to pass the activator for the tooltip to the v-btn so we merge it with the attributes.
	 *
	 *	Relevant Vue documentation: https://vuejs.org/guide/components/attrs.html#accessing-fallthrough-attributes-in-javascript
	 */
	inheritAttrs: false,
	props: {
		btnClass: { type: String },
		btnText: { type: String },
		btnIcon: { type: String },
		tooltipText: { type: String },
	},
	computed: {
		width(): undefined | string {
			if (this.$attrs.icon) {
				return undefined;
			} else if (this.$attrs.width) {
				return this.$attrs.width;
			} else {
				return this.$vuetify.breakpoint.mobile ? "75px" : "100px";
			}
		},
	},
});
