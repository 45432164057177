var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-tooltip",
    {
      attrs: {
        top: "",
        "max-width": "500px",
        disabled: _vm.tooltipText === "",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "a",
                _vm._g(
                  _vm._b(
                    {
                      attrs: { href: _vm.documentationLink, target: "_blank" },
                    },
                    "a",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "ml-1",
                      attrs: { small: "", color: "success" },
                    },
                    [_vm._v("mdi-help-circle-outline ")]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [_vm._v(" " + _vm._s(_vm.tooltipText) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }