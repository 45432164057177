var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "px-2 pt-sm-2",
      class: `launch-grid-${_vm.computedEditorLayout}${
        _vm.showVariableViewer ? "-variableViewer" : ""
      }`,
    },
    [
      _c(
        "div",
        { staticClass: "grid-editor" },
        [
          _c("Editor", {
            ref: "editor",
            attrs: {
              editorHeight: "100%",
              id: "editor",
              alt: "Editor",
              taskID: _vm.taskID,
              taskStatus: _vm.taskStatus,
              activeTask: _vm.activeTask,
              taskAccessState: _vm.taskAccessState,
              buildID: _vm.buildID,
              buildStatus: _vm.buildStatus,
              buildAccessState: _vm.buildAccessState,
              activeBuild: _vm.activeBuild,
              editorLayout: _vm.computedEditorLayout,
              variableViewerVisibility: _vm.showVariableViewer,
            },
            on: {
              "request-build": _vm.submitTaskRequest,
              "code-changed": _vm.calculateCodeHash,
              "create-task-request": _vm.submitBuildRequest,
              "cancel-active-task-request": _vm.cancelActiveTask,
              "cancel-active-build-request": _vm.cancelActiveBuild,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showVariableViewer,
              expression: "showVariableViewer",
            },
          ],
          staticClass: "grid-variableViewer",
        },
        [
          _c("VariableViewer", {
            ref: "variableViewer",
            attrs: {
              id: "variableViewer",
              "max-height":
                _vm.viewportRatio[_vm.computedEditorLayout].variableViewer
                  .height,
              variablesList: _vm.completeVariablesList,
              variableDiscoveryInProgress: _vm.variableDiscoveryInProgress,
              referenceCoreChosen: _vm.referenceCoreChosen(),
              currentCodeHash: _vm.codeHash,
            },
            on: {
              "discover-variables": _vm.discoverVariables,
              "close-panel": function ($event) {
                return _vm.editorStore.setVariableViewerVisibility(false, true)
              },
              "switch-to-ref-core": _vm.switchToReferenceCore,
              "add-custom-trace-expression": _vm.addCustomTraceExpression,
              "remove-custom-trace-expression": _vm.removeCustomTraceExpression,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "grid-resultsPanel" },
        [_c("Panel", { attrs: { taskID: _vm.taskID, buildID: _vm.buildID } })],
        1
      ),
      _c("OutputFailedDialog", {
        attrs: { "show-dialog": _vm.showTaskFailedDialog, taskID: _vm.taskID },
        on: {
          "update:showDialog": function ($event) {
            _vm.showTaskFailedDialog = $event
          },
          "update:show-dialog": function ($event) {
            _vm.showTaskFailedDialog = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }