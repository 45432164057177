var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.$vuetify.breakpoint.mobile ? "95%" : "600px" },
      on: {
        "click:outside": _vm.closeDialog,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeDialog.apply(null, arguments)
        },
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pb-1" },
        [
          _c("v-card-title", { staticClass: "py-3 px-4" }, [
            _vm._v(_vm._s(_vm.applicationInfo.titleText)),
          ]),
          _c("v-divider"),
          _vm.repoAccessState.loading
            ? _c("LoadingCard", {
                attrs: { loadingText: "Loading Application Details" },
              })
            : _c(
                "v-card-text",
                { staticClass: "py-2 px-4" },
                [
                  _vm.demoVideoURL
                    ? _c("VideoPlayerComponent", {
                        staticClass: "video",
                        attrs: { videoURL: _vm.demoVideoURL },
                      })
                    : _vm._e(),
                  _vm.repoData.description
                    ? _c(
                        "div",
                        { staticClass: "my-3 text-body text-justify" },
                        [_vm._v(" " + _vm._s(_vm.repoData.description) + " ")]
                      )
                    : _vm.repoAccessState.error
                    ? _c(
                        "AlertMessage",
                        {
                          attrs: {
                            "show-alert": true,
                            type: "warning",
                            outlined: true,
                          },
                        },
                        [
                          _vm._v(
                            " An Error occurred while fetching the application description. " +
                              _vm._s(_vm.repoAccessState.message) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c("TooltipButton", {
                attrs: {
                  disabled: !_vm.applicationInfo.applicationURL,
                  color: "primary",
                  tooltipText: "Open on github.com",
                  btnIcon: "mdi-github",
                  btnClass: "float-right",
                  target: "_blank",
                  href: _vm.applicationInfo.applicationURL,
                },
                scopedSlots: _vm._u([
                  {
                    key: "tooltipContent",
                    fn: function () {
                      return [
                        _vm._v(" Open on github.com "),
                        _c("v-icon", { attrs: { small: "", color: "white" } }, [
                          _vm._v("mdi-open-in-new"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("div", { staticClass: "mx-1" }),
              _c("TooltipButton", {
                attrs: {
                  color: "accent",
                  disabled: !_vm.applicationInfo.applicationURL,
                  to: {
                    name: "Repositories",
                    query: { connect: _vm.applicationInfo.applicationURL },
                  },
                  tooltipText: "Connect repository",
                  btnIcon: "mdi-book-plus",
                  btnClass: "float-right",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }