
import { defineComponent } from "vue";
import { getUserThings, getUserBuckets, getUserDrives } from "@/js/signaloidClient";
import DataSourcePickerCard from "@/components/DataSources/DataSourcePickerCard.vue";
import LoadingCard from "@/components/LoadingCard.vue";
import { Auth } from "aws-amplify";
import { useDataSourcesStore } from "@/stores/dataSources";
import { useUserStore } from "@/stores/user";
import { DataSourceTypeE, MountableDataSource } from "@/types/api/dataSources";
import { monitoringCaptureError } from "@/plugins/monitoring";

type ComponentData = {
	gatewayList: MountableDataSource[];
	bucketList: MountableDataSource[];
	driveList: MountableDataSource[];
	signaloidCloudStorage: MountableDataSource[];
	loadingGateways: boolean;
	loadingBuckets: boolean;
	loadingDrives: boolean;
};

export default defineComponent({
	name: "DataSourceListDialog",
	components: { DataSourcePickerCard, LoadingCard },
	props: {
		showDialog: {
			type: Boolean,
			default: false,
		},
		dataSourceExcludeList: {
			type: Array,
			default: () => [],
		},
	},
	data: (): ComponentData => ({
		gatewayList: [],
		bucketList: [],
		driveList: [],
		signaloidCloudStorage: [],
		loadingGateways: false,
		loadingBuckets: false,
		loadingDrives: false,
	}),
	computed: {
		unfilteredSourceList(): MountableDataSource[] {
			return [...this.gatewayList, ...this.bucketList, ...this.driveList, ...this.signaloidCloudStorage];
		},
		dataSourceList(): any {
			return this.unfilteredSourceList.filter((x) => {
				return !this.isInExcludeList(x);
			});
		},
		loadingSources(): boolean {
			return this.loadingGateways || this.loadingBuckets || this.loadingDrives;
		},
	},
	setup() {
		// Note: gets called after beforeCreate in vue2
		const dataSourcesStore = useDataSourcesStore();
		const userStore = useUserStore();
		return { dataSourcesStore, userStore };
	},
	methods: {
		closeDialog() {
			this.$emit("close-dialog");
		},
		setDataSource(dataSource: MountableDataSource) {
			this.$emit("set-data-source", dataSource);
			this.closeDialog();
		},
		async fetchDataSources() {
			// FIXME: parallise
			// * get gateways
			try {
				this.loadingGateways = true;

				//get things
				const userThings = await getUserThings();
				this.gatewayList = userThings.data.thing_ids.map((thingID: string): MountableDataSource => {
					return {
						ResourceID: thingID,
						ResourceType: DataSourceTypeE.Gateway,
					};
				});
			} catch (error) {
				monitoringCaptureError(error, "Fetch data source list");
			} finally {
				this.loadingGateways = false;
			}

			// * get buckets
			try {
				this.loadingBuckets = true;

				//get buckets
				const userBuckets = await getUserBuckets();
				this.bucketList = userBuckets.data.bucket_ids.map((bucketID: string): MountableDataSource => {
					return {
						ResourceID: bucketID,
						ResourceType: DataSourceTypeE.Bucket,
					};
				});
			} catch (error) {
				monitoringCaptureError(error, "Fetch data source list");
			} finally {
				this.loadingBuckets = false;
			}

			// * get drive list
			try {
				this.loadingDrives = true;

				//get drives
				const userDrives = await getUserDrives();
				this.dataSourcesStore.setDriveList(userDrives.data.drive_ids);
				// FIXME: this could potentially be done in the store
				this.driveList = userDrives.data.drive_ids.map((driveID: string): MountableDataSource => {
					return {
						ResourceID: driveID,
						ResourceType: DataSourceTypeE.Drive,
					};
				});
			} catch (error) {
				monitoringCaptureError(error, "Fetch data source list");
			} finally {
				this.loadingDrives = false;
			}
			// * get signaloid cloud storage list
			try {
				const userID = await this.userStore.getCurrentUserID();

				this.signaloidCloudStorage = [
					{
						ResourceID: `signaloid-cloud-storage:/${userID}`,
						ResourceType: "SignaloidCloudStorage",
					},
				];
			} catch (error) {
				monitoringCaptureError(error, "Fetch data source list");
			}
		},
		isInExcludeList(mountableDataSource: MountableDataSource) {
			return this.dataSourceExcludeList.some((excludeID) => {
				return excludeID == mountableDataSource.ResourceID;
			});
		},
	},

	mounted() {
		this.fetchDataSources();
	},
});
