var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-chart", {
    staticClass: "chart",
    attrs: { id: "chart-container", option: _vm.option, autoresize: "" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }