var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      staticClass: "d-flex flex-column justify-space-between",
      attrs: {
        height: _vm.height,
        outlined: "",
        rounded: "lg",
        "data-test": "variable-viewer-panel",
      },
    },
    [
      _c(
        "v-sheet",
        {
          staticStyle: {
            "border-top": "none",
            "border-left": "none",
            "border-right": "none",
            position: "relative",
          },
          attrs: { height: "2.5rem", outlined: "" },
        },
        [
          _c(
            "v-container",
            {
              staticClass: "py-0 pl-3 pr-1 font-weight-medium",
              attrs: { fluid: "" },
            },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "", align: "center" } },
                [
                  _c(
                    "v-col",
                    [
                      _c("span", [_vm._v("Variable Viewer")]),
                      _c("DisclosureIcon", {
                        attrs: {
                          "disclosure-text":
                            "This panel shows the variables declared in your application. When you click the 'Discover variables' button the Signaloid Cloud Developer Platform runs your application to identify the variables in your application. This run will show up in your Task Manager as a reference core run.",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "px-0",
                      attrs: { cols: "auto", "align-self": "end" },
                    },
                    [
                      _c("TooltipButton", {
                        attrs: {
                          icon: true,
                          btnClass: "mx-0 px-0",
                          disabled: _vm.variableIDsToTrace.length == 0,
                          btnIcon: "mdi-backup-restore",
                          tooltipText: "Reset marked variables",
                          "data-test": "reset-marked-variables-button",
                        },
                        on: { click: _vm.resetVariableMarks },
                      }),
                    ],
                    1
                  ),
                  _vm.disableRequestPanelClose === false
                    ? _c(
                        "v-col",
                        {
                          staticClass: "px-0",
                          attrs: { cols: "auto", "align-self": "end" },
                        },
                        [
                          _c("TooltipButton", {
                            attrs: {
                              icon: true,
                              btnClass: "mx-0 px-0",
                              btnIcon: "mdi-window-close",
                              tooltipText: "Close Variable Viewer",
                              "data-test": "close-variable-viewer-button",
                            },
                            on: { click: _vm.requestPanelClose },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-sheet",
        { staticClass: "flex-grow-1", staticStyle: { "overflow-y": "scroll" } },
        [
          _c(
            "v-overlay",
            {
              attrs: {
                absolute: "",
                value: _vm.variableDiscoveryInProgress,
                color: "rgb(180, 180, 180)",
              },
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "rgb(100, 100, 100)" },
              }),
            ],
            1
          ),
          _vm.showVariablePlaceholderButton
            ? [
                _c("LimitableActionValidator", {
                  attrs: {
                    "limits-to-check": [
                      _vm.UserLimitsE.DynamicInstructionCount,
                      _vm.UserLimitsE.ConcurrentTaskCount,
                    ],
                  },
                  on: { "valid-click": _vm.requestVariableDiscovery },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "clickTarget",
                        fn: function () {
                          return [
                            _c("SourcePlaceholderCard", {
                              staticClass: "mt-2 mx-4",
                              attrs: {
                                loading: _vm.variableDiscoveryInProgress,
                                "data-test":
                                  "discover-variables-placeholder-button",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "icon",
                                    fn: function () {
                                      return [
                                        _c("v-icon", [
                                          _vm._v("mdi-text-box-search-outline"),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "buttonText",
                                    fn: function () {
                                      return [_vm._v("Discover variables")]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3053561358
                              ),
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1813313858
                  ),
                }),
              ]
            : _c(
                "v-list",
                {
                  style: _vm.variableDiscoveryInProgress
                    ? "filter: blur(2px);"
                    : "",
                  attrs: { dense: "" },
                },
                [
                  _c(
                    "v-fade-transition",
                    [
                      _vm.variablesList.length === 0 &&
                      !_vm.variableDiscoveryInProgress
                        ? _c(
                            "v-alert",
                            {
                              staticClass:
                                "d-flex justify-center text-body-2 font-weight-light warning--text mt-2 mx-4",
                              attrs: {
                                outlined: "",
                                color: "warning",
                                dense: "",
                              },
                            },
                            [_vm._v(" No variables detected. ")]
                          )
                        : _c(
                            "v-list-item-group",
                            {
                              attrs: {
                                multiple: "",
                                "active-class": "blue--text",
                              },
                              model: {
                                value: _vm.variableIDsToTrace,
                                callback: function ($$v) {
                                  _vm.variableIDsToTrace = $$v
                                },
                                expression: "variableIDsToTrace",
                              },
                            },
                            [
                              _vm._l(_vm.variablesList, function (variable) {
                                return [
                                  _c("v-list-item", {
                                    key: `${variable.Expression}_${String(
                                      variable.LineNumber
                                    )}_${variable.File}_${variable.Type}`,
                                    staticClass: "px-2",
                                    attrs: {
                                      "data-test": "discovered-variable-item",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ active }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    size: "14",
                                                    color: active
                                                      ? "primary"
                                                      : "grey lighten-1",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        active
                                                          ? "mdi-checkbox-marked-outline"
                                                          : "mdi-checkbox-blank-outline"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color: active
                                                      ? "primary"
                                                      : "grey lighten-1",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.variableIcon(
                                                          variable.Type
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                { staticClass: "py-1" },
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        variable.Expression
                                                      )
                                                    ),
                                                  ]),
                                                  _c("v-list-item-subtitle", [
                                                    _vm._v(
                                                      " Line " +
                                                        _vm._s(
                                                          variable.LineNumber
                                                        ) +
                                                        " in " +
                                                        _vm._s(variable.File) +
                                                        " "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-action",
                                                { staticClass: "my-0" },
                                                [
                                                  _c("TooltipButton", {
                                                    attrs: {
                                                      btnClass: "mx-auto",
                                                      icon: true,
                                                      tooltipText:
                                                        (variable.Type ==
                                                        "UserExpression"
                                                          ? "Edit"
                                                          : "Add") +
                                                        " Custom Trace Expression",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.openCustomVariableTraceDialog(
                                                          variable
                                                        )
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "btnContent",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    size: "18",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        variable.Type ==
                                                                          "UserExpression"
                                                                          ? "mdi-playlist-edit"
                                                                          : "mdi-playlist-plus"
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ]
                              }),
                            ],
                            2
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        2
      ),
      _c("v-sheet", [
        _c(
          "div",
          { staticClass: "sticky-bar" },
          [
            _c(
              "v-expand-transition",
              [
                _c(
                  "v-alert",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showCodeUpdatedBanner,
                        expression: "showCodeUpdatedBanner",
                      },
                    ],
                    staticClass: "text-caption my-0 primary--text",
                    staticStyle: { cursor: "pointer" },
                    attrs: {
                      color: "light-blue lighten-5",
                      rounded: "0",
                      dense: "",
                    },
                    on: { click: _vm.requestVariableDiscovery },
                  },
                  [_vm._v(" Code updated. Click to refresh variables. ")]
                ),
              ],
              1
            ),
            _c(
              "v-expand-transition",
              [
                _c(
                  "v-alert",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showSwitchToRefCoreBanner,
                        expression: "showSwitchToRefCoreBanner",
                      },
                    ],
                    staticClass: "text-caption my-0 warning--text",
                    staticStyle: { cursor: "pointer" },
                    attrs: {
                      color: "orange lighten-5",
                      rounded: "0",
                      dense: "",
                    },
                    on: { click: _vm.requestDefaultReferenceCore },
                  },
                  [
                    _vm._v(
                      " Variable tracing requires an execution core with the "
                    ),
                    _c("em", [_vm._v("Reference")]),
                    _vm._v(
                      " Microarchitecture. Click here to switch to a compatible core. "
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "v-expand-transition",
              [
                _c(
                  "v-alert",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showPickVariablesBanner,
                        expression: "showPickVariablesBanner",
                      },
                    ],
                    staticClass: "text-caption my-0 accent--text",
                    attrs: {
                      color: "light-green lighten-5",
                      rounded: "0",
                      dense: "",
                    },
                  },
                  [
                    _vm._v(
                      " To view the variable value distributions, choose at least one variable to trace. "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "v-sheet",
        {
          staticStyle: {
            "border-bottom": "none",
            "border-left": "none",
            "border-right": "none",
          },
          attrs: { "min-height": "50px", outlined: "", rounded: "b-lg" },
        },
        [
          _c(
            "v-container",
            { staticClass: "fill-height py-0 px-2", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "space-between" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex",
                      attrs: { cols: "auto", "align-self": "center" },
                    },
                    [
                      _c("LimitableActionButton", {
                        attrs: {
                          "limits-to-check": [
                            _vm.UserLimitsE.DynamicInstructionCount,
                            _vm.UserLimitsE.ConcurrentTaskCount,
                          ],
                          loading: _vm.variableDiscoveryInProgress,
                          disabled: _vm.discoverVariablesDisabled,
                          color: _vm.staleVariableList ? "primary" : "primary",
                          tooltipText: "Discover Variables",
                          btnIcon: "mdi-text-box-search-outline",
                          width: _vm.$vuetify.breakpoint.mobile
                            ? "50px"
                            : "64px",
                          "data-test": "discover-variables-button",
                        },
                        on: { "valid-click": _vm.requestVariableDiscovery },
                      }),
                      _c("div", { staticClass: "mx-1" }),
                      _c("TooltipButton", {
                        attrs: {
                          color: "warning",
                          tooltipText: "Add Custom Trace Expression",
                          btnIcon: "mdi-playlist-plus",
                          width: _vm.$vuetify.breakpoint.mobile
                            ? "50px"
                            : "64px",
                          "data-test": "add-custom-trace-expression-button",
                        },
                        on: { click: _vm.openCustomVariableTraceDialog },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: _vm.$vuetify.breakpoint.mobile ? "95%" : "400px" },
          on: {
            "click:outside": _vm.closeCustomVariableTraceDialog,
            keydown: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape",
                  ])
                )
                  return null
                return _vm.closeCustomVariableTraceDialog.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.requestAddCustomVariableTrace.apply(null, arguments)
              },
            ],
          },
          model: {
            value: _vm.showCustomVariableTraceDialog,
            callback: function ($$v) {
              _vm.showCustomVariableTraceDialog = $$v
            },
            expression: "showCustomVariableTraceDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pb-1" },
            [
              _c(
                "v-card-title",
                { staticClass: "py-3 px-4" },
                [
                  _vm._v("Add Custom Variable Trace "),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text--primary",
                      attrs: {
                        color: "white",
                        small: "",
                        outlined: "",
                        height: "36",
                        elevation: "0",
                      },
                      on: { click: _vm.closeCustomVariableTraceDialog },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                {
                  staticClass: "py-0 pt-4 px-4",
                  staticStyle: { "min-width": "350px" },
                },
                [
                  _c(
                    "v-form",
                    {
                      ref: "customVariableTraceForm",
                      model: {
                        value: _vm.customVariableTraceFormValid,
                        callback: function ($$v) {
                          _vm.customVariableTraceFormValid = $$v
                        },
                        expression: "customVariableTraceFormValid",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "", align: "center" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                staticClass: "text-caption",
                                attrs: {
                                  maxlength: "50",
                                  rules: [_vm.rules.required],
                                  dense: "",
                                  outlined: "",
                                  label: "Variable Name",
                                },
                                model: {
                                  value:
                                    _vm.customVariableTraceToAdd.Expression,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.customVariableTraceToAdd,
                                      "Expression",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "customVariableTraceToAdd.Expression",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "", align: "center" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                staticClass: "text-caption",
                                attrs: {
                                  maxlength: "50",
                                  rules: [_vm.rules.required],
                                  dense: "",
                                  outlined: "",
                                  label: "File",
                                  hint: "The file in which the variable is defined.",
                                },
                                model: {
                                  value: _vm.customVariableTraceToAdd.File,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.customVariableTraceToAdd,
                                      "File",
                                      $$v
                                    )
                                  },
                                  expression: "customVariableTraceToAdd.File",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "", align: "center" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                staticClass: "text-caption",
                                attrs: {
                                  maxlength: "50",
                                  rules: [
                                    _vm.rules.required,
                                    _vm.rules.isNumber,
                                  ],
                                  dense: "",
                                  outlined: "",
                                  label: "Line number",
                                  hint: "Line number where the variable is defined.",
                                },
                                model: {
                                  value:
                                    _vm.customVariableTraceToAdd.LineNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.customVariableTraceToAdd,
                                      "LineNumber",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "customVariableTraceToAdd.LineNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _vm.traceDialogAlert.show
                    ? _c(
                        "v-alert",
                        {
                          staticClass: "my-0 text-caption",
                          attrs: {
                            dense: "",
                            outlined: "",
                            type: _vm.traceDialogAlert.type,
                            border: "left",
                            width: "70%",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.traceDialogAlert.text) + " ")]
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  _c("TooltipButton", {
                    attrs: {
                      btnText: "Add Trace",
                      width: "100px",
                      color: "primary",
                      disabled: !_vm.customVariableTraceFormValid,
                    },
                    on: { click: _vm.requestAddCustomVariableTrace },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }