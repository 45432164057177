var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-alert",
    _vm._g(
      _vm._b(
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showAlert,
              expression: "showAlert",
            },
          ],
          class: _vm.alertClasses,
          attrs: {
            elevation: "0",
            border: "left",
            type: _vm.alertType,
            outlined: _vm.alertOutlined,
          },
        },
        "v-alert",
        { ..._vm.$attrs },
        false
      ),
      { ..._vm.$listeners }
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }