import { render, staticRenderFns } from "./NoticeCard.vue?vue&type=template&id=32fed6cc&scoped=true"
import script from "./NoticeCard.vue?vue&type=script&lang=ts"
export * from "./NoticeCard.vue?vue&type=script&lang=ts"
import style0 from "./NoticeCard.vue?vue&type=style&index=0&id=32fed6cc&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32fed6cc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/buildhome/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('32fed6cc')) {
      api.createRecord('32fed6cc', component.options)
    } else {
      api.reload('32fed6cc', component.options)
    }
    module.hot.accept("./NoticeCard.vue?vue&type=template&id=32fed6cc&scoped=true", function () {
      api.rerender('32fed6cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Common/NoticeCard.vue"
export default component.exports