var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.$vuetify.breakpoint.mobile ? "95%" : "600px" },
      on: {
        "click:outside": _vm.closeDialog,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeDialog.apply(null, arguments)
        },
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-sheet",
        {
          staticClass: "pa-6 pa-lg-8",
          attrs: {
            width: _vm.$vuetify.breakpoint.mobile ? "100%" : "600px",
            tile: _vm.$vuetify.breakpoint.mobile,
            elevation: !_vm.$vuetify.breakpoint.mobile ? "5" : "0",
            rounded: "",
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "d-flex justify-space-between" },
                [
                  _c(
                    "div",
                    { staticClass: "text-h5 pt-0 font-weight-medium" },
                    [_vm._v("Your new secret SCCE API key")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text--primary",
                      attrs: {
                        color: "white",
                        small: "",
                        outlined: "",
                        height: "36",
                        elevation: "0",
                      },
                      on: { click: _vm.closeDialog },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "text-body-2 mt-4" }, [
                _c("span", [
                  _vm._v(
                    "Use this key in your application to make programmatic requests to the Signaloid Cloud Compute Engine. Put the key as the value of the Authorization HTTP header."
                  ),
                ]),
              ]),
              _c("v-text-field", {
                class:
                  "grey lighten-3 font-weight-medium mt-4" +
                  (_vm.keyVisible ? "" : " obscured"),
                attrs: {
                  outlined: "",
                  readonly: "",
                  "hide-details": "",
                  "data-test": "api-key-creation-dialog-key-textfield",
                  "data-test-value": _vm.passedKey,
                  dense: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [
                        _c("TooltipButton", {
                          attrs: {
                            tooltipText: _vm.keyVisible
                              ? "Hide key"
                              : "Reveal key",
                            icon: true,
                            btnClass: "mr-n2 mt-n1 align-self-center",
                          },
                          on: { click: _vm.toggleKeyVisible },
                          scopedSlots: _vm._u([
                            {
                              key: "btnContent",
                              fn: function () {
                                return [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.keyVisible
                                          ? "mdi-eye-off"
                                          : "mdi-eye"
                                      )
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                        _c("CopyToClipboardButton", {
                          staticClass: "mr-n3 mt-n1 align-self-center",
                          attrs: {
                            "value-to-copy": _vm.passedKey,
                            "value-name": "Key ID",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.passedKey,
                  callback: function ($$v) {
                    _vm.passedKey = $$v
                  },
                  expression: "passedKey",
                },
              }),
              _c("div", { staticClass: "text-body-2 mt-4" }, [
                _c("span", [
                  _vm._v("This key is a "),
                  _c("b", [_vm._v("secret")]),
                  _vm._v(
                    ". We will not be able to show you the value again after you close this dialog."
                  ),
                ]),
              ]),
              _c(
                "v-card-actions",
                { staticClass: "mt-2 pa-0" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "accent",
                        elevation: "0",
                        width: "140px",
                      },
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v(" Close ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }