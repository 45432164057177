
import { AccountTierDetail, TierFeatureHighlight, tiers } from "@/js/tierconfig";
import { useUserStore } from "@/stores/user";
import { mapState } from "pinia";
import { defineComponent, PropType } from "vue";

// Types

// Libraries

// Utilities
import * as util from "@/js/utilities";

// Components
import TooltipButton from "@/components/Common/TooltipButton.vue";
import TierHighlightsList from "@/components/Common/TierHighlightsList.vue";

// Stores

// Global variables

export default defineComponent({
	name: "WelcomeSlide",
	components: { TooltipButton },
	props: {
		showDialog: { type: Boolean, default: false },
		metadata: { type: Object },
		trialTierCode: { type: String, default: "pro-tier" },
		trialDurationMonths: { type: Number, default: 2 },
	},
	setup() {
		const userStore = useUserStore();
		return { userStore, util };
	},

	computed: {
		...mapState(useUserStore, {
			currentUserTierDetails: "currentUserTierDetails",
		}),

		trialTierDetails(): AccountTierDetail | undefined {
			return tiers.find((e) => e.code === this.trialTierCode);
		},
		tierHighlightsToDisplay(): TierFeatureHighlight[] {
			return this.trialTierDetails?.shortVersionPoints ?? [];
		},
	},
	methods: {
		closeDialog() {
			this.$emit("update:showDialog", false);
		},
	},
});
